import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './style.scss';

const Button = (props) => {
  const {
    color,
    status,
    outline,
    rounded,
    children,
    className,
    ...otherProps
  } = props;

  return (
    /* eslint-disable react/button-has-type */
    <button
      className={cn('btn', status, outline ? `btn-outline-${color}` : `btn-${color}`, className, { 'btn-oblong': rounded })}
      {...otherProps} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
    </button>
    /* eslint-enable react/button-has-type */
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  status: PropTypes.oneOf(['active', 'disabled']),
  className: PropTypes.string,
  color: PropTypes.string,
  outline: PropTypes.bool,
  rounded: PropTypes.bool,
  type: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  color: 'primary',
  outline: false,
  rounded: false,
  className: null,
  status: null,
};

export default Button;
