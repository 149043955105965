import React from 'react';
import { AccountIcon } from '../../../../components/Icons';
import SimpleButton from '../../../../components/SimpleButton';
import { formatDateNoUtc } from '../../../../utils/dates';
import tr from '../../../../utils/translate';

export const accountColumns = (showResetPasswordModal) => (
  [
    {
      name: tr('First name'),
      key: 'firstName',
    },
    {
      name: tr('Last name'),
      key: 'lastName',
    },
    {
      name: tr('Email address'),
      key: 'email',
    },
    {
      name: tr('Registration date'),
      key: 'registrationDate',
      render: ({ registrationDate }) => (registrationDate ? formatDateNoUtc(registrationDate) : tr('No data')),
    },
    {
      name: tr('Password'),
      key: '',
      render: () => (
        <SimpleButton onClick={showResetPasswordModal}>
          {tr('Reset')}
        </SimpleButton>
      ),
    },
  ]
);
