// import OnlineCompanyInterface from '../OnlineCompanyInterface';
import FollowUpsQuestions from '../FollowUpsQuestions';
import OnlineCampaignType from '../OnlineCampaignType';
import AudienceTargeting from '../AudienceTargeting';
import AssociatedDevices from '../AssociatedDevices';
import DemographicsForm from '../Demographics';
import FollowUpsDesign from '../FollowUpsDesign';
import CampaignPreview from '../CampaignPreview';
import SocialMediaInfo from '../SocialMediaInfo';
import StatisticsInfo from '../StatisticsInfo';
import FollowUpsTheme from '../FollowUpsTheme';
// import TemplatesForm from '../TemplatesForm';
import CampaignType from '../CampaignType';
import FacebookTabs from '../FacebookTabs';
import LocationForm from '../LocationForm';
import DetailsForm from '../DetailsForm';
import ProductInfo from '../ProductInfo';
import Interface from '../Interface';
import InterfaceShort from '../Interface/short';
import GiveawayRestriction from '../Interface/GiveawayRestriction';
import {
  DEVICE_CAMPAIGN, ONLINE_EMAIL_CAMPAIGN, ONLINE_SOCIAL_CAMPAIGN, ONLINE_EMBEDED_CAMPAIGN
} from '../../../../constants/campaigns';


const STEPS = {
  [ONLINE_SOCIAL_CAMPAIGN]: {
    facebook: [
      DetailsForm,
      SocialMediaInfo,
      ProductInfo,
      // TemplatesForm,
      FacebookTabs,
      StatisticsInfo,
      GiveawayRestriction,
      InterfaceShort,
      FollowUpsQuestions,
      FollowUpsTheme,
      FollowUpsDesign,
      CampaignPreview,
    ],
    default: [
      DetailsForm,
      SocialMediaInfo,
      ProductInfo,
      LocationForm,
      DemographicsForm,
      StatisticsInfo,
      GiveawayRestriction,
      InterfaceShort,
      FollowUpsQuestions,
      FollowUpsTheme,
      FollowUpsDesign,
      CampaignPreview,
    ],
  },
  [ONLINE_EMBEDED_CAMPAIGN]: [
    DetailsForm,
    ProductInfo,
    StatisticsInfo,
    GiveawayRestriction,
    InterfaceShort,
    FollowUpsQuestions,
    FollowUpsTheme,
    FollowUpsDesign,
    CampaignPreview,
  ],
  [ONLINE_EMAIL_CAMPAIGN]: [
    DetailsForm,
    ProductInfo,
    AudienceTargeting,
    StatisticsInfo,
    GiveawayRestriction,
    InterfaceShort,
    FollowUpsQuestions,
    FollowUpsTheme,
    FollowUpsDesign,
    CampaignPreview,
  ],
  [DEVICE_CAMPAIGN]: [
    DetailsForm,
    AssociatedDevices,
    ProductInfo,
    StatisticsInfo,
    Interface,
    FollowUpsQuestions,
    FollowUpsTheme,
    FollowUpsDesign,
    CampaignPreview,
  ],
  INITIAL_CREATE_STEPS: [
    CampaignType,
    OnlineCampaignType,
  ],
};

export default STEPS;
