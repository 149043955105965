export const jsonData = [
	{label: 'Advertising', value: 'Advertising'},
	{label: 'Agriculture', value: 'Agriculture'},
	{label: 'Architecture', value: 'Architecture'},
	{label: 'Aviation', value: 'Aviation'},
	{
		label: 'Banking',
		options: [
			{label: 'Investment banking', value: 'Investment banking'},
			{label: 'Online banking', value: 'Online banking'},
			{label: 'Retail banking', value: 'Retail banking'}
		],
	},
	{label: 'Business', value: 'Business'},
	{label: 'Construction', value: 'Construction'},
	{
		label: 'Design',
		options: [
			{label: 'Fashion design', value: 'Fashion design'},
			{label: 'Graphic design', value: 'Graphic design'},
			{label: 'Interior design', value: 'Interior design'}
		],
	},
	{label: 'Economics', value: 'Economics'},
	{label: 'Engineering', value: 'Engineering'},
	{label: 'Entrepreneurship', value: 'Entrepreneurship'},
	{label: 'Health care', value: 'Health care'},
	{label: 'Higher education', value: 'Higher education'},
	{label: 'Management', value: 'Management'},
	{label: 'Marketing', value: 'Marketing'},
	{label: 'Nursing', value: 'Nursing'},
	{
		label: 'Online',
		options: [
			{label: 'Digital marketing', value: 'Digital marketing'},
			{label: 'Display advertising', value: 'Display advertising'},
			{label: 'Email marketing', value: 'Email marketing'},
			{label: 'Online advertising', value: 'Online advertising'},
			{label: 'Search engine optimization', value: 'Search engine optimization'},
			{label: 'Social media', value: 'Social media'},
			{label: 'Social media marketing', value: 'Social media marketing'},
			{label: 'Web design', value: 'Web design'},
			{label: 'Web development', value: 'Web development'},
			{label: 'Web hosting', value: 'Web hosting'}
		],
	},
	{
		label: 'Personal finance',
		options: [
			{label: 'Creditcards', value: 'Creditcards'},
			{label: 'Insurance', value: 'Insurance'},
			{label: 'Investment', value: 'Investment'},
			{label: 'Mortgage loans', value: 'Mortgage loans'}
		],
	},
	{label: 'Real estate', value: 'Real estate'},
	{label: 'Retail', value: 'Retail'},
	{label: 'Sales', value: 'Sales'},
	{label: 'Science', value: 'Science'},
	{label: 'Small business', value: 'Small business'},
	{
		label: 'Games',
		options: [
			{label: 'Action games', value: 'Action games'},
			{label: 'Board games', value: 'Board games'},
			{label: 'Browser games', value: 'Browser games'},
			{label: 'Card games', value: 'Card games'},
			{label: 'Casino games', value: 'Casino games'},
			{label: 'First-person shooter games', value: 'First-person shooter games'},
			{label: 'Gambling', value: 'Gambling'},
			{label: 'Massively multiplayer online games', value: 'Massively multiplayer online games'},
			{label: 'Massively multiplayer online role-playing games', value: 'Massively multiplayer online role-playing games'},
			{label: 'Online games', value: 'Online games'},
			{label: 'Online poker', value: 'Online poker'},
			{label: 'Puzzle video games', value: 'Puzzle video games'},
			{label: 'Racing games', value: 'Racing games'},
			{label: 'Role-playing games', value: 'Role-playing games'},
			{label: 'Shooter games', value: 'Shooter games'},
			{label: 'Simulation games', value: 'Simulation games'},
			{label: 'Sports games', value: 'Sports games'},
			{label: 'Strategy games', value: 'Strategy games'},
			{label: 'Video games', value: 'Video games'},
			{label: 'Word games', value: 'Word games'}
		],
	},
	{
		label: 'Live events',
		options: [
			{label: 'Ballet', value: 'Ballet'},
			{label: 'Bars', value: 'Bars'},
			{label: 'Concerts', value: 'Concerts'},
			{label: 'Dancehalls', value: 'Dancehalls'},
			{label: 'Music festivals', value: 'Music festivals'},
			{label: 'Nightclubs', value: 'Nightclubs'},
			{label: 'Parties', value: 'Parties'},
			{label: 'Plays', value: 'Plays'},
			{label: 'Theatre', value: 'Theatre'}
		],
	},
	{
		label: 'Movies',
		options: [
			{label: 'Action movies', value: 'Action movies'},
			{label: 'Animated movies', value: 'Animated movies'},
			{label: 'Anime movies', value: 'Anime movies'},
			{label: 'Bollywood movies', value: 'Bollywood movies'},
			{label: 'Comedy movies', value: 'Comedy movies'},
			{label: 'Documentary movies', value: 'Documentary movies'},
			{label: 'Drama movies', value: 'Drama movies'},
			{label: 'Fantasy movies', value: 'Fantasy movies'},
			{label: 'Horror movies', value: 'Horror movies'},
			{label: 'Musical theatre', value: 'Musical theatre'},
			{label: 'Science fiction movies', value: 'Science fiction movies'},
			{label: 'Thriller movies', value: 'Thriller movies'}
		],
	},
	{
		label: 'Music',
		options: [
			{label: 'Blues music', value: 'Blues music'},
			{label: 'Classical music', value: 'Classical music'},
			{label: 'Country music', value: 'Country music'},
			{label: 'Dance music', value: 'Dance music'},
			{label: 'Electronic music', value: 'Electronic music'},
			{label: 'Gospel music', value: 'Gospel music'},
			{label: 'Heavy metal music', value: 'Heavy metal music'},
			{label: 'Hip hop music', value: 'Hip hop music'},
			{label: 'Jazz music', value: 'Jazz music'},
			{label: 'Music videos', value: 'Music videos'},
			{label: 'Pop music', value: 'Pop music'},
			{label: 'Rhythm and blues music', value: 'Rhythm and blues music'},
			{label: 'Rock music', value: 'Rock music'},
			{label: 'Soul music', value: 'Soul music'}
		],
	},
	{
		label: 'Reading',
		options: [
			{label: 'Books', value: 'Books'},
			{label: 'Comics', value: 'Comics'},
			{label: 'E-books', value: 'E-books'},
			{label: 'Fiction books', value: 'Fiction books'},
			{label: 'Literature', value: 'Literature'},
			{label: 'Magazines', value: 'Magazines'},
			{label: 'Manga', value: 'Manga'},
			{label: 'Mystery fiction', value: 'Mystery fiction'},
			{label: 'Newspapers', value: 'Newspapers'},
			{label: 'Non-fiction books', value: 'Non-fiction books'},
			{label: 'Romance novels', value: 'Romance novels'}
		],
	},
	{
		label: 'TV',
		options: [
			{label: 'TV comedies', value: 'TV comedies'},
			{label: 'TV game shows', value: 'TV game shows'},
			{label: 'TV reality shows', value: 'TV reality shows'},
			{label: 'TV talkshows', value: 'TV talkshows'}
		],
	},
	{label: 'Dating', value: 'Dating'},
	{label: 'Family', value: 'Family'},
	{label: 'Fatherhood', value: 'Fatherhood'},
	{label: 'Friendship', value: 'Friendship'},
	{label: 'Marriage', value: 'Marriage'},
	{label: 'Motherhood', value: 'Motherhood'},
	{label: 'Parenting', value: 'Parenting'},
	{label: 'Weddings', value: 'Weddings'},
	{label: 'Bodybuilding', value: 'Bodybuilding'},
	{label: 'Meditation', value: 'Meditation'},
	{label: 'Physical exercise', value: 'Physical exercise'},
	{label: 'Physical fitness', value: 'Physical fitness'},
	{label: 'Running', value: 'Running'},
	{label: 'Weight training', value: 'Weight training'},
	{label: 'Yoga', value: 'Yoga'},
	{
		label: 'Alcoholic beverages',
		options: [
			{label: 'Beer', value: 'Beer'},
			{label: 'Distilled beverage', value: 'Distilled beverage'},
			{label: 'Wine', value: 'Wine'}
		],
	},
	{
		label: 'Beverages',
		options: [
			{label: 'Coffee', value: 'Coffee'},
			{label: 'Energy drinks', value: 'Energy drinks'},
			{label: 'Juice', value: 'Juice'},
			{label: 'Soft drinks', value: 'Soft drinks'},
			{label: 'Tea', value: 'Tea'}
		],
	},
	{
		label: 'Cooking',
		options: [
			{label: 'Baking', value: 'Baking'},
			{label: 'Recipes', value: 'Recipes'}
		],
	},
	{
		label: 'Cuisine',
		options: [
			{label: 'Chinese cuisine', value: 'Chinese cuisine'},
			{label: 'French cuisine', value: 'French cuisine'},
			{label: 'German cuisine', value: 'German cuisine'},
			{label: 'Greek cuisine', value: 'Greek cuisine'},
			{label: 'Indian cuisine', value: 'Indian cuisine'},
			{label: 'Italian cuisine', value: 'Italian cuisine'},
			{label: 'Japanese cuisine', value: 'Japanese cuisine'},
			{label: 'Korean cuisine', value: 'Korean cuisine'},
			{label: 'Latin American cuisine', value: 'Latin American cuisine'},
			{label: 'Mexican cuisine', value: 'Mexican cuisine'},
			{label: 'Middle Eastern cuisine', value: 'Middle Eastern cuisine'},
			{label: 'Spanish cuisine', value: 'Spanish cuisine'},
			{label: 'Thai cuisine', value: 'Thai cuisine'},
			{label: 'Vietnamese cuisine', value: 'Vietnamese cuisine'}
		],
	},
	{
		label: 'Food',
		options: [
			{label: 'Barbecue', value: 'Barbecue'},
			{label: 'Chocolate', value: 'Chocolate'},
			{label: 'Desserts', value: 'Desserts'},
			{label: 'Fast food', value: 'Fast food'},
			{label: 'Organic food', value: 'Organic food'},
			{label: 'Pizza', value: 'Pizza'},
			{label: 'Seafood', value: 'Seafood'},
			{label: 'Veganism', value: 'Veganism'},
			{label: 'Vegetarianism', value: 'Vegetarianism'}
		],
	},
	{
		label: 'Restaurants',
		options: [
			{label: 'Coffeehouses', value: 'Coffeehouses'},
			{label: 'Diners', value: 'Diners'},
			{label: 'Fast casual restaurants', value: 'Fast casual restaurants'},
			{label: 'Fast food restaurants', value: 'Fast food restaurants'}
		],
	},
	{
		label: 'Arts and music',
		options: [
			{label: 'Acting', value: 'Acting'},
			{label: 'Crafts', value: 'Crafts'},
			{label: 'Dance', value: 'Dance'},
			{label: 'Drawing', value: 'Drawing'},
			{label: 'Drums', value: 'Drums'},
			{label: 'Fine art', value: 'Fine art'},
			{label: 'Guitar', value: 'Guitar'},
			{label: 'Painting', value: 'Painting'},
			{label: 'Performing arts', value: 'Performing arts'},
			{label: 'Photography', value: 'Photography'},
			{label: 'Sculpture', value: 'Sculpture'},
			{label: 'Singing', value: 'Singing'},
			{label: 'Writing', value: 'Writing'}
		],
	},
	{label: 'Current events', value: 'Current events'},
	{
		label: 'Home and garden',
		options: [
			{label: 'Do it yourself (DIY)', value: 'Do it yourself (DIY)'},
			{label: 'Furniture', value: 'Furniture'},
			{label: 'Gardening', value: 'Gardening'},
			{label: 'Home Appliances', value: 'Home Appliances'},
			{label: 'Home improvement', value: 'Home improvement'}
		],
	},
	{
		label: 'Pets',
		options: [
			{label: 'Birds', value: 'Birds'},
			{label: 'Cats', value: 'Cats'},
			{label: 'Dogs', value: 'Dogs'},
			{label: 'Fish', value: 'Fish'},
			{label: 'Horses', value: 'Horses'},
			{label: 'Pet food', value: 'Pet food'},
			{label: 'Rabbits', value: 'Rabbits'},
			{label: 'Reptiles', value: 'Reptiles'}
		],
	},
	{
		label: 'Politics and social issues',
		options: [
			{label: 'Charity and causes', value: 'Charity and causes'},
			{label: 'Community issues', value: 'Community issues'},
			{label: 'Environmentalism', value: 'Environmentalism'},
			{label: 'Law', value: 'Law'},
			{label: 'Military', value: 'Military'},
			{label: 'Politics', value: 'Politics'},
			{label: 'Religion', value: 'Religion'},
			{label: 'Sustainability', value: 'Sustainability'},
			{label: 'Veterans', value: 'Veterans'},
			{label: 'Volunteering', value: 'Volunteering'}
		],
	},
	{
		label: 'Travel',
		options: [
			{label: 'Adventure travel', value: 'Adventure travel'},
			{label: 'Air travel', value: 'Air travel'},
			{label: 'Beaches', value: 'Beaches'},
			{label: 'Car rentals', value: 'Car rentals'},
			{label: 'Cruises', value: 'Cruises'},
			{label: 'Ecotourism', value: 'Ecotourism'},
			{label: 'Hotels', value: 'Hotels'},
			{label: 'Lakes', value: 'Lakes'},
			{label: 'Mountains', value: 'Mountains'},
			{label: 'Nature', value: 'Nature'},
			{label: 'Theme parks', value: 'Theme parks'},
			{label: 'Tourism', value: 'Tourism'},
			{label: 'Vacations', value: 'Vacations'}
		],
	},
	{
		label: 'Vehicles',
		options: [
			{label: 'Automobiles', value: 'Automobiles'},
			{label: 'Boats', value: 'Boats'},
			{label: 'Electric vehicle', value: 'Electric vehicle'},
			{label: 'Hybrids', value: 'Hybrids'},
			{label: 'Minivans', value: 'Minivans'},
			{label: 'Motorcycles', value: 'Motorcycles'},
			{label: 'RVs', value: 'RVs'},
			{label: 'SUVs', value: 'SUVs'},
			{label: 'Scooters', value: 'Scooters'},
			{label: 'Trucks', value: 'Trucks'}
		],
	},
	{
		label: 'Beauty',
		options: [
			{label: 'Beauty salons', value: 'Beauty salons'},
			{label: 'Cosmetics', value: 'Cosmetics'},
			{label: 'Fragrances', value: 'Fragrances'},
			{label: 'Hair products', value: 'Hair products'},
			{label: 'Spas', value: 'Spas'},
			{label: 'Tattoos', value: 'Tattoos'}
		],
	},
	{
		label: 'Clothing',
		options: [
			{label: 'Children\'s clothing', value: 'Children\'s clothing'},
			{label: 'Men\'s clothing', value: 'Men\'s clothing'},
			{label: 'Shoes', value: 'Shoes'},
			{label: 'Women\'s clothing', value: 'Women\'s clothing'}
		],
	},
	{
		label: 'Fashionaccessories',
		options: [
			{label: 'Dresses', value: 'Dresses'},
			{label: 'Handbags', value: 'Handbags'},
			{label: 'Jewelry', value: 'Jewelry'},
			{label: 'Sunglasses', value: 'Sunglasses'}
		],
	},
	{
		label: 'Shopping',
		options: [
			{label: 'Boutiques', value: 'Boutiques'},
			{label: 'Coupons', value: 'Coupons'},
			{label: 'Discount stores', value: 'Discount stores'},
			{label: 'Luxury goods', value: 'Luxury goods'},
			{label: 'Online shopping', value: 'Online shopping'},
			{label: 'Shopping malls', value: 'Shopping malls'}
		],
	},
	{label: 'Toys', value: 'Toys'},
	{
		label: 'Outdoor recreation',
		options: [
			{label: 'Boating', value: 'Boating'},
			{label: 'Camping', value: 'Camping'},
			{label: 'Fishing', value: 'Fishing'},
			{label: 'Horseback riding', value: 'Horseback riding'},
			{label: 'Hunting', value: 'Hunting'},
			{label: 'Mountain biking', value: 'Mountain biking'},
			{label: 'Surfing', value: 'Surfing'}
		],
	},
	{
		label: 'Sports',
		options: [
			{label: 'American football', value: 'American football'},
			{label: 'Association football (Soccer)', value: 'Association football (Soccer)'},
			{label: 'Auto racing', value: 'Auto racing'},
			{label: 'Baseball', value: 'Baseball'},
			{label: 'Basketball', value: 'Basketball'},
			{label: 'College football', value: 'College football'},
			{label: 'Golf', value: 'Golf'},
			{label: 'Marathons', value: 'Marathons'},
			{label: 'Skiing', value: 'Skiing'},
			{label: 'Snowboarding', value: 'Snowboarding'},
			{label: 'Swimming', value: 'Swimming'},
			{label: 'Tennis', value: 'Tennis'},
			{label: 'Thriathlons', value: 'Thriathlons'},
			{label: 'Volleyball', value: 'Volleyball'}
		],
	},
	{
		label: 'Computers',
		options: [
			{label: 'Computer memory', value: 'Computer memory'},
			{label: 'Computer monitors', value: 'Computer monitors'},
			{label: 'Computer processors', value: 'Computer processors'},
			{label: 'Computer servers', value: 'Computer servers'},
			{label: 'Desktop computers', value: 'Desktop computers'},
			{label: 'Free software', value: 'Free software'},
			{label: 'Hard drives', value: 'Hard drives'},
			{label: 'Network storage', value: 'Network storage'},
			{label: 'Software', value: 'Software'},
			{label: 'Tablet computers', value: 'Tablet computers'}
		],
	},
	{
		label: 'Consumer electronics',
		options: [
			{label: 'Audio equipment', value: 'Audio equipment'},
			{label: 'Camcorders', value: 'Camcorders'},
			{label: 'Cameras', value: 'Cameras'},
			{label: 'E-book readers', value: 'E-book readers'},
			{label: 'GPS devices', value: 'GPS devices'},
			{label: 'Game consoles', value: 'Game consoles'},
			{label: 'Mobile phones', value: 'Mobile phones'},
			{label: 'Portable media players', value: 'Portable media players'},
			{label: 'Projectors', value: 'Projectors'},
			{label: 'Smartphones', value: 'Smartphones'},
			{label: 'Televisions', value: 'Televisions'}
		],
	}
]