import moment from 'moment';

import tr from '../../../../utils/translate';

const validate = ({
  contact_info
}) => {
  const errors = {};

  if (contact_info) {
    const csvValues = contact_info && contact_info.split('\n');
    const trimedArr = csvValues.map((val) => val.trim());
    if (!trimedArr[0]) {
      errors.contact_info = tr('Required');
    }
    if (trimedArr[0] !== 'email,gender,age,first_name,last_name' || trimedArr[0].split(',').length !== 5) {
      errors.contact_info = tr('Wrong header should be "email,gender,age,first_name,last_name"');
    }
    if (trimedArr.length < 2) {
      errors.contact_info = tr('No contact info provided');
    }
  }


  return errors;
};

export default validate;
