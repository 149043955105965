import React from 'react';

import tr from '../../../../../utils/translate';
import { DEVICE_CAMPAIGN } from '../../../../../constants/campaigns';

export const productsColumns = (type_is, addProdcutModal, isPreview) => [
  {
    name: '',
    key: 'img',
    render: ({ img, name }) => (<div className="product-table-image"><img src={img || 'https://via.placeholder.com/60'} alt={name} /></div>),
  },
  {
    name: tr('Name'),
    key: 'name',
  },
  {
    name: tr('tag'),
    key: 'tag',
  },
  {
    name: tr('Description'),
    key: 'description',
    render: ({ description }) => (<div className="product-info-description">{description}</div>),
  },
  {
    name: tr('Size'),
    key: 'size',
    render: ({ width_in, height_in, depth_in }) => (width_in && height_in && depth_in && `${width_in} x ${height_in} x ${depth_in}`

    ),
  },
  {
    name: tr('Weight'),
    key: 'weight_lbs',
  },
  ...type_is !== DEVICE_CAMPAIGN ? [{
    name: tr('Quantity'),
    key: 'product_stock',
    render: ({ tray, product_stock }) => (
      <>
        <span>
          {product_stock}
          {' '}
        </span>
        {!isPreview && (
        <button
          onClick={(e) => {
            e.preventDefault();
            addProdcutModal({ titleModal: tr('Add products '), id: tray });
          }}
          className="copy_link mg-l-5"
        >
Add products
        </button>
        )}
      </>
    ),
  }] : [],
];
