import React from 'react';
import PropTypes from 'prop-types';
import { BackarrowIcon, DocumentBigIcon } from '../../../../../components/Icons';
import UploadFile from '../../../../../components/UploadFile';
import ReduxForm from '../../../../../components/ReduxForm';
import Loader from '../../../../../components/Loader';
import Select from '../../../../../components/Select';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Input';
import tr from '../../../../../utils/translate';
import useInterface from '../hooks';
import validate from './validate';
import '../styles.scss';

const giveAwayRestrictions = [
	{ label: tr('Restrict'), value: -1 },
	{ label: tr('Unlimited'), value: 0 },
	{ label: tr('12 Hours'), value: 12 },
	{ label: tr('24 Hours'), value: 24 },
];

const GiveawayRestriction = ({ data: { id } }) => {
	const {
		data: {
			isLoading,
			campaign,
			reduxForms,
		},
		actions: {
			goToBack,
			submitGiveawayRestriction,
		},
	} = useInterface(id);

	if (!campaign) return <Loader size="md" />;

	return (
		<>
			<ReduxForm
				enableReinitialize
				form="COMPAIGN_GIVEAWAY_RESTRICTION_FORM"
				validate={validate(campaign)}
				formData={{
					onSubmit: submitGiveawayRestriction,
					initialValues: campaign && {
						sample_giveaway_restriction: campaign.sample_giveaway_restriction,
						address_restriction: campaign.address_restriction,
						email_validation: campaign.email_validation,
					},
				}}
			>
				<div className="compaign-interface-form pd-x-25 pd-y-50">
					<h3 className="cif-title mg-b-40">{tr('Sample giveaway restriction')}</h3>

					<div className="row">
						<div className="col-12">
							<p className="cif-subtitle">{tr('Email restriction')}</p>

							<Select
								name="sample_giveaway_restriction"
								className="pd-b-15"
								options={giveAwayRestrictions}
							/>

							<Input
								name="address_restriction"
								type="checkbox"
								className="checkbox pd-b-15"
								appendFieldLabel={tr('Address restriction')}
							/>

							<Input
								name="email_validation"
								type="checkbox"
								className="checkbox pd-b-15"
								appendFieldLabel={tr('Email validation')}
							/>
						</div>
					</div>

					{reduxForms['COMPAIGN_GIVEAWAY_RESTRICTION_FORM']?.values?.email_validation && (
						<div className="row">
							<div className="col-6">
								<p className="cif-subtitle">{tr('Upload CSV')}</p>
								<UploadFile
									name="valid_emails_file"
									color="yellow"
									icon={<DocumentBigIcon />}
									data={{ file: campaign.valid_emails_file }}
									hideDeleteButton
									accept=".csv"
									maxSize={5242880}
								/>
							</div>
						</div>
					)}
				</div>
				<div className="modal-footer">
					<Button color="light" className="mg-r-30" onClick={goToBack}>
						<BackarrowIcon />
						{tr('Back')}
					</Button>
					<Button type="submit" disabled={isLoading}>
						{tr('Next')}
					</Button>
				</div>
			</ReduxForm>

		</>
	);
};

GiveawayRestriction.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.string,
	}),
};

GiveawayRestriction.defaultProps = {
	data: {},
};

export default GiveawayRestriction;
