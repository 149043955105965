import React from 'react';
import Table from '../../../../../components/Table';
import tr from '../../../../../utils/translate';

import { devicesColumns } from './data';

const DeviceTable = ({ devices }) => (
  <div className="mg-b-30">
    <Table caption={tr('Associated Devices')} columns={devicesColumns} data={devices} />
  </div>
);

export default DeviceTable;
