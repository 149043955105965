import React from 'react';
import PropTypes from 'prop-types';
import { ONLINE_EMAIL_CAMPAIGN } from '../../../../constants/campaigns';
import tr from '../../../../utils/translate';
import ReduxForm from '../../../../components/ReduxForm';
import UploadFile from '../../../../components/UploadFile';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import Input from '../../../../components/Input';
import {
  BackarrowIcon,
  FollowUpsIcon,
  DocumentBigIcon,
} from '../../../../components/Icons';
import useInterface from './hooks';
import validate from './validate';
import './styles.scss';

const giveAwayRestrictions = [
  { label: tr('Restrict'), value: -1 },
  { label: tr('Unlimited'), value: 0 },
  { label: tr('12 Hours'), value: 12 },
  { label: tr('24 Hours'), value: 24 },
];

const Interface = ({ data: { id } }) => {
  const {
    data: {
      isLoading,
      campaign,
    },
    actions: {
      goToBack,
      submit,
    },
  } = useInterface(id);

  if (!campaign) return <Loader size="md" />;


  return (
    <>
      <ReduxForm
        enableReinitialize
        form="COMPAIGN_INTERFACE_FORM"
        validate={validate(campaign)}
        formData={{
          onSubmit: submit,
          initialValues: campaign && {
            sample_giveaway_restriction: campaign.sample_giveaway_restriction,
            invitation_email: campaign?.direct_mail?.email_template || '',
            address_restriction: campaign.address_restriction,
          },
        }}
      >
        <div className="compaign-interface-form pd-x-25 pd-y-50">
          <h3 className="cif-title mg-b-40">{tr('Interface')}</h3>

          <div className="row">
            <div className="col-6">
              <p className="cif-subtitle">{tr('Interface upload')}</p>
              <UploadFile
                name="file"
                color="blue"
                icon={<DocumentBigIcon />}
                data={campaign.file}
                hideDeleteButton
              />
            </div>
            {campaign.type_is === ONLINE_EMAIL_CAMPAIGN
            && (
            <div className="col-6 pd-b-15">
              <p className="cif-subtitle">{tr('Main email')}</p>
              <UploadFile
                name="invitation_email"
                color="green"
                icon={<FollowUpsIcon />}
                data={campaign?.direct_mail?.email_template ? { file: campaign.direct_mail.email_template } : {}}
                hideDeleteButton
              />
            </div>
            )}
            <div className="col-6">
              <p className="cif-subtitle">{tr('Email receipt')}</p>
              <UploadFile
                name="email_receipt"
                color="green"
                icon={<FollowUpsIcon />}
                data={campaign.email_receipt}
                hideDeleteButton
              />
            </div>
          </div>
          <div className="row mg-t-70">
            <div className="col-12">
              <p className="cif-subtitle tx-center">{tr('Sample giveaway restriction')}</p>

              <Select
                name="sample_giveaway_restriction"
                placeholder={tr('Sample giveaway restriction')}
                className="pd-b-15"
                options={giveAwayRestrictions}
              />

              <Input
                name="address_restriction"
                type="checkbox"
                className="checkbox"
                appendFieldLabel={tr('Address restriction')}
              />
            </div>
          </div>
        </div>
        <div className="compaign-interface-form pd-x-25 pd-b-50">
          <h3 className="cif-title mg-b-40">{tr('Follow Ups')}</h3>

          <div className="row">
            <div className="col-6">
              <p className="cif-subtitle">{tr('Follow Ups upload')}</p>
              <UploadFile
                name="follow_up_email"
                color="yellow"
                icon={<FollowUpsIcon />}
                data={campaign?.follow_up?.email_template ? { file: campaign.follow_up.email_template } : {}}
                hideDeleteButton
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button color="light" className="mg-r-30" onClick={goToBack}>
            <BackarrowIcon />
            {tr('Back')}
          </Button>
          <Button type="submit" disabled={isLoading}>
            {tr('Next')}
          </Button>
        </div>
      </ReduxForm>

    </>
  );
};

Interface.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }),
};

Interface.defaultProps = {
  data: {},
};

export default Interface;
