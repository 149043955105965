import emailValidator from 'email-validator';

import translate from '../../../utils/translate';

const validate = ({
  user_to,
  company_name,
}) => {
  const errors = {};

  if (!user_to) {
    errors.user_to = translate('Email is required');
  } else if (!emailValidator.validate(user_to)) {
    errors.user_to = translate('Invalid Email');
  }

  if (!company_name) {
    errors.company_name = translate('Company name is required');
  }

  return errors;
};

export default validate;
