import { createRequestTypes } from '../../../../utils/actionHelpers';

const prefix = 'APP_LAYOUT';

export const SHOW_NOTIFICATIONS = `${prefix}/SHOW_NOTIFICATIONS`;
export const HIDE_NOTIFICATIONS = `${prefix}/HIDE_NOTIFICATIONS`;

export default createRequestTypes('LAYOUT', [
  'POST_ISSUE',
]);
