import React from 'react';

import Modal from '../../../../../../../components/Modal';
import { ISSUE_MODAL } from '../../../../../../../constants/modals';
import translate from '../../../../../../../utils/translate';
import Form from './Form';

const UsersSearchModal = () => (
  <Modal title={translate('Report issue')} name={ISSUE_MODAL}>
    <Form />
  </Modal>
);

export default UsersSearchModal;
