import React from 'react';
import Table from '../../../../../components/Table';
import tr from '../../../../../utils/translate';

import { productsColumns } from './data';
import useProductTable from './hooks';
import AddProductsModal from '../../AddProductsModal';

const CampaignInfoTab = ({ products, campaign, isPreview = false }) => {
  const {
    actions: {
      openAddProductStockModal,
    },
  } = useProductTable();

  return (
    <>
      <div className="mg-b-30 campaign-details-product-list">
        <Table caption={tr('Products')} columns={productsColumns(campaign.type_is, openAddProductStockModal, isPreview)} data={products} />
      </div>
      <AddProductsModal campaignId={campaign.id} />
    </>
  );
};

export default CampaignInfoTab;
