import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../../../components/Checkbox';
import './styles.scss';

const CampaignTypeField = ({ input, list }) => (
  <div className="row campaign-checkbox-wrapper">
    {list.map(({ value, label, icon }) => (
      <Checkbox
        key={value}
        name={value}
        checked={value === input.value}
        label={label}
        icon={icon}
        onChange={(event) => {
          let newValue = input.value;
          if (event.target.checked) {
            newValue = value;
          }
          return input.onChange(newValue);
        }}
      />
    ))}
  </div>
);

CampaignTypeField.propTypes = {
  input: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.number,
    PropTypes.array,
  ])).isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CampaignTypeField;
