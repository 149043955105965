import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import _find from 'lodash/find';

import { editBrand as editBrandAction } from '../../../brand/services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import BRAND from '../../../brand/services/types';

const selector = (brand_id) => createSelector(
  createLoadingSelector(BRAND.GET_BRANDS.base),
  (state) => state.account.countries,
  (state) => _find(state.brands.list, ['id', brand_id]),
  (isLoading, countries, brand) => ({
    isLoading,
    countries,
    brand,
  }),
);

const useBrandModal = (id) => {
  const dispatch = useDispatch();
  const editBrand = useCallback((data) => {
    dispatch(editBrandAction(id, data));
  }, [dispatch, id]);

  return {
    data: useSelector(selector(id)),
    actions: {
      editBrand,
    },
  };
};

export default useBrandModal;
