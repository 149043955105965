import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import DropdownContext from './DropdownContext';
import './styles.scss';

const DropDown = forwardRef(({
  className, isOpen, toggle, disabled, children,
}, ref) => {
  const handleToggle = useCallback((e) => {
    e.preventDefault();

    if (disabled) {
      return true;
    }

    return toggle(e);
  }, [disabled, toggle]);

  const getContextValue = () => ({
    toggle: handleToggle,
    isOpen,
    disabled,
  });

  const handleOutsideClick = (e) => {
    if (isOpen) {
      handleToggle(e);
    }
  };

  return (
    <DropdownContext.Provider
      value={getContextValue()}
    >
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>

        <div
          className={cn('dropDown', className)}
          ref={ref}
        >

          {children}

        </div>

      </OutsideClickHandler>

    </DropdownContext.Provider>
  );
});

DropDown.defaultProps = {
  className: '',
  disabled: false,
  children: '',
};

DropDown.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default DropDown;
