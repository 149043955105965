import tr from '../../../../utils/translate';

const validate = ({ question, type }) => {
  const errors = {};

  if (!question) {
    errors.question = tr('Question is required.');
  }

  if (!type) {
    errors.type = tr('Type is required.');
  }

  return errors;
};

export default validate;
