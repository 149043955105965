import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Frame from '../Frame';
import './styles.scss';

const Widget = (props) => {
  const {
    className,
    color,
    icon,
    title,
    subtitle,
    description,
  } = props;

  return (
    <Frame className={cn('widget-border-wrapper', className)} classNameBody="pd-20">
      <div className="widget-border">
        <h3 className="widget-border__title">{title}</h3>

        <div className="widget-border__body">
          <div className="mg-r-15">
            <div className={cn('widget-border__logo', `widget-border__logo_${color}`)}>
              {icon}
            </div>
          </div>
          <div className="widget-border__info">
            <div className="widget-border__subtitle">{subtitle}</div>
            <div className="widget-border__description">{description}</div>
          </div>
        </div>
      </div>
    </Frame>
  );
};

Widget.propTypes = {
  color: PropTypes.oneOf(['yellow', 'green', 'blue', 'red']).isRequired,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  description: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Widget.defaultProps = {
  className: null,
};

export default Widget;
