import React from 'react';
import tr from '../../../../utils/translate';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import ReduxForm from '../../../../components/ReduxForm';
import { ONLINE_CAMPAIGN_STATUS } from '../../../../constants/modals';

const CampaignStatusModal = () => (
  <Modal name={ONLINE_CAMPAIGN_STATUS} size="lg">
    {({ handleHide, ...initialValues }) => (
      <ReduxForm
        enableReinitialize
        form="SUGGEST_OTHER_FORM"
        formData={{
          onSubmit: initialValues.submitAction,
        }}
      >
        <div className="compaign-details-form pd-x-25 pd-y-50">
          <div className="mg-b-30">
            <Input
              name="comment"
              placeholder={tr('Write a comment')}
              rows={1}
              type="textarea"
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button color="light" className="mg-r-30" onClick={handleHide}>
            {tr('Cancel')}
          </Button>
          <Button
            type="submit"
          >
            {tr('Save')}
          </Button>
        </div>
      </ReduxForm>
    )}
  </Modal>

);

export default CampaignStatusModal;
