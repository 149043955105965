import React, {useState} from 'react';
import Frame from '../../../../components/Frame';
import FrameHeader from '../../../../components/FrameHeader';
import tr from '../../../../utils/translate';
import { transformDuration } from '../../../../utils/dates';
import Table from '../../../../components/Table';
import { questionsStatColumns } from '../../../../helpers/tableData';
import './styles.scss';
import useModal from '../../../../utils/useModal';
import { DELETE_MODAL } from '../../../../constants/modals';
import Button from '../../../../components/Button';
import DeleteModal from '../../../campaigns/components/DeleteModal';

const expandRender = ({ question_answers }) => question_answers.map((item, index) => (
  <div className="row mg-b-5" key={index}>
    <div className="col col-7">{`${index + 1}. ${item.question}`}</div>
    <div className="col col-3">{item.answer}</div>
    <div className="col col-2">{transformDuration(item.duration)}</div>
  </div>
));
const DeviceDetailedTab = ({ statData, dateRange, deviceId }) => {
  const [interactions, setInteractions] = useState([]);
  const { showModal: showDeleteModal } = useModal(DELETE_MODAL);
  const tableHeader = (
    <Button
      onClick={() => showDeleteModal(({ titleModal: tr('Delete') }))}
    >
      { tr('Delete')}
    </Button>
  );
  return (
    <Frame
      header={<FrameHeader title={tr('User interactions')} rightBlock={tableHeader} />}
      className="user-interaction mg-b-30"
    >
      <Table
        columns={questionsStatColumns(false)}
        data={statData || []}
        deleteSessionHandler={(id) => setInteractions([...interactions, id])}
        expandRenderer={expandRender}
        checkedList={interactions}
        deleteSessionAdd
      />
      <DeleteModal
        pollSessions={interactions}
        deviceId={deviceId}
        handleCancel={() => setInteractions([])}
      />
    </Frame>
  );
};

export default DeviceDetailedTab;
