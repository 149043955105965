import React from 'react';

import translate from '../../../../utils/translate';
import PageHeader from '../../../../components/PageHeader';
import Table from '../../../../components/Table';
import Button from '../../../../components/Button';
import { ROLE_COMPANY_ADMIN, ROLE_DEPARTMENT_ADMIN, ROLE_SUPERADMIN } from '../../../../constants/roles';
import { useUsers } from './hooks';
import { getUserColumns } from './tableData';
import AccessLevelModal from '../AccessLevelModal';
import DeactivateModal from '../DeactivateModal';
import InviteUserModal from '../InviteUserModal';
import AssignDevices from '../AssignDevices';
import './styles.scss';

const tabs = [
  { key: 'active', name: translate('Active') },
  { key: 'disabled', name: translate('Disabled') },
];

const Main = () => {
  const {
    data: {
      isLoading,
      accountDetail,
      pagination,
      users,
    },
    actions: {
      changeRoleModal,
      changeStatusModal,
      handleInviteUser,
      assignDevicesModal,
      changeSorting,
      getNextPage,
    },
    sortBy,
    setActiveTab,
    activeTab,
  } = useUsers();

  const columns = getUserColumns(
    changeRoleModal,
    changeStatusModal,
    accountDetail,
    assignDevicesModal,
    changeSorting,
    sortBy,
  );

  const headerButtons = (
    <div>
      <Button onClick={handleInviteUser}>{translate('Invite member')}</Button>
    </div>
  );

  const handleSelectTab = (index, { name }) => {
    setActiveTab(name);
  };

  return (
    <div className="page-inner pd-t-25 pd-b-25">
      <PageHeader title={translate('Members')}>
        {[ROLE_COMPANY_ADMIN, ROLE_SUPERADMIN, ROLE_DEPARTMENT_ADMIN].includes(accountDetail.access_level)
          && headerButtons}
      </PageHeader>
      <div className="row">
        <div className="col-md-12 employees-table">
          <Table
            caption={translate('All members')}
            columns={columns}
            tabs={tabs}
            loading={isLoading}
            data={{ [activeTab]: users }}
            onSelectTab={handleSelectTab}
            pagination={{...pagination, getNextPage }}
          />
        </div>
      </div>
      <AccessLevelModal />
      <DeactivateModal />
      <InviteUserModal />
      <AssignDevices />
    </div>
  );
};

export default Main;
