import React from 'react';
import tr from '../../../../utils/translate';
import SimpleButton from '../../../../components/SimpleButton';
import { DEVICE_CAMPAIGN } from '../../../../constants/campaigns';

export const getProductsTable = (removeProduct, changeProductQuantity, campaignType ) => [
  {
    name: '',
    key: 'img',
    render: ({ img, name }) => (<div className="product-table-image"><img src={img ? img : 'https://via.placeholder.com/60' } alt={name}/></div>),
  },
  {
    name: tr('Name'),
    key: 'name',
  },
  {
    name: tr('tag'),
    key: 'tag',
  },
  {
    name: tr('Description'),
    key: 'description',
    render: ({ description }) => (<div className="product-info-description">{description}</div>),
  },
  {
    name: tr('Size'),
    key: 'size',
    render: ({ width_in, height_in, depth_in }) => ( width_in && height_in && depth_in && `${width_in} x ${height_in} x ${depth_in}`
        
    ),
  },
  {
    name: tr('Weight'),
    key: 'weight_lbs',
  },
  ...campaignType !== DEVICE_CAMPAIGN ? [{
    name: tr('Quantity'),
    key: 'quantity',
    render: ({id, product_stock}) => (
      <input className='product-quantity-input' type='number' name={`quantity.${id}`} onChange={(e) => changeProductQuantity(id, e.target.value)} value={product_stock}/>
    )
  }] : [],
  {
    name: tr('Options'),
    key: 'options',
    render: ({ id }) => (
        <SimpleButton
          className="cell-link"
          onClick={() => {
            removeProduct(id);
          }}
        >
          {tr('Remove')}
        </SimpleButton>
    ),
  },
];
