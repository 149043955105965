import _get from 'lodash/get';

const loadingReducer = (state = {}, action) => {
  const type = _get(action, 'type', null);
  const matches = /(.*)\/(REQUEST|SUCCESS|FAILURE)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === 'REQUEST',
  };
};

export default loadingReducer;
