import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';

import tr from '../../../../utils/translate';
import ReduxForm from '../../../../components/ReduxForm';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import { AUTH } from '../../../../constants/forms';
import validate from '../SignUp/validate';
import FormWrap from '../common/FormWrap';
// import ChangePasswordForm from './Form';
import useChangePassword from './hooks';


const ChangePassword = ({ location }) => {
  const { actions: { changePassword }, data: { isLoading } } = useChangePassword();

  const handleSubmit = ({ password }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    changePassword({ password, ...params });
  };

  return (
    <FormWrap title={tr('Recover your account')} subtitle={tr('Please reset your password')}>
      <ReduxForm
        form={AUTH.CHANGE_PASSWORD_FORM}
        validate={validate}
        formData={{
          onSubmit: handleSubmit,
        }}
      >
        <Input
          name="password"
          prependFieldLabel={tr('New password')}
          type="password"
          className="pd-b-15"
        />

        <Input
          name="password_confirm"
          prependFieldLabel={tr('Confirm password')}
          type="password"
          className="pd-b-15"
        />

        <div className="pd-t-4 mg-b-30">
          <Button
            type="submit"
            className="btn-block"
            status={isLoading ? 'disabled' : 'active'}
            disabled={isLoading}
          >
            {tr('Save new password')}
          </Button>
        </div>
      </ReduxForm>
      {/* <ChangePasswordForm loading={isLoading} onSubmit={handleSubmit} /> */}
    </FormWrap>
  );
};

ChangePassword.propTypes = {
  location: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default ChangePassword;
