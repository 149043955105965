import emailValidator from 'email-validator';

import tr from '../../../../utils/translate';

const validate = ({
  company_name,
  first_name,
  last_name,
  email,
  password,
  repassword,
}) => {
  const errors = {};

  if (!company_name) errors.company_name = tr('Company name is required');

  if (!first_name) errors.first_name = tr('First name is required');
  else if (first_name.length > 30) errors.first_name = tr('First name must be no longer than 30 characters');

  if (!last_name) errors.last_name = tr('Last name is required');
  else if (last_name.length > 150) errors.last_name = tr('First name must be no longer than 150 characters');

  if (!email) errors.email = tr('Email is required');
  else if (!emailValidator.validate(email)) errors.email = tr('Invalid Email');

  if (!password) {
    errors.password = tr('New password is required.');
  } else if (password.length < 8) {
    errors.password = tr('New password must contain at least 8 characters.');
  } else if (!password.match(/[A-Z]/g)) {
    errors.password = tr('New password must contain capital letter.');
  } else if (!password.match(/[a-z]/g)) {
    errors.password = tr('New password must contain small letter.');
  } else if (!password.match(/[0-9]/g)) {
    errors.password = tr('New password must contain number.');
  }

  if (!repassword) errors.repassword = tr('Confirm password is required');
  else if (repassword !== password) errors.repassword = tr('Password and Confirm password must be the same');

  return errors;
};

export default validate;
