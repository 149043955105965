import React from 'react';
import Modal from '../../../../components/Modal';
import ReduxForm from '../../../../components/ReduxForm';
import ModalFormFooter from '../../../../components/ModalFormFooter';
import MaskedInput from '../../../../components/MaskedInput';
import tr from '../../../../utils/translate';
import validate from './validate';
import useValidateDomain from './hooks';
import { VALIDATE_DOMAIN_MODAL } from '../../../../constants/modals';

// const required = (value) => (value ? undefined : 'Required');

const ValidateDomainModal = ({ campaign_id }) => {
  const {
    actions: {
      validateDomain,
    },
    data: {
      isLoading,
    },
  } = useValidateDomain(campaign_id);

  return (
    <Modal name={VALIDATE_DOMAIN_MODAL} title="" size="sm">
      {({ handleHide, ...initialValues }) => (
        <ReduxForm
          enableReinitialize
          form="VALIDATE_DOMAIN_FORMS"
          validate={validate}
          formData={{
            onSubmit: validateDomain,
          }}
        >
          <div className="modal-body">
            <MaskedInput
              label={tr('Verification code')}
              name="code"
            />
          </div>
          <div className="modal-footer">
            <ModalFormFooter
              handleClose={handleHide}
              confirmText={tr('Verify')}
              isLoading={isLoading}
            />
          </div>
        </ReduxForm>
      )}
    </Modal>
  );
};

export default ValidateDomainModal;
