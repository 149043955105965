import React from 'react';
import Dropzone from 'react-dropzone';

import Table from '../../../../components/Table';
import { TrashIcon } from '../../../../components/Icons';
import translate from '../../../../utils/translate';
import useTableScreensaver from './hooks';
import './styles.scss';

const TableScreensaver = ({ serial_number, data }) => {
  const { actions } = useTableScreensaver(serial_number);

  const columns = [
    {
      name: translate('Upload'),
      key: 'upload',
      render: ({ id }, index) => (
        <Dropzone
          name={index}
          accept="image/*"
          onDrop={([file]) => { actions.uploadImage(id, file); }}
        >
          {({ getRootProps, getInputProps }) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div {...getRootProps()}>
              <input
              // eslint-disable-next-line react/jsx-props-no-spreading
                {...getInputProps()}
              />
              <label htmlFor={index} className="cell-link">{translate('Load image')}</label>
            </div>
          )}
        </Dropzone>
      ),
    },
    {
      name: translate('Name'),
      key: 'upload_name',
      render: ({ file }) => {
        // eslint-disable-next-line no-useless-escape
        const [, fileName = ''] = file ? file.match(/\/([^\/]+)$/) : [];

        return decodeURI(fileName) || translate('Not uploaded');
      },
    },
    {
      name: translate('Preview'),
      key: 'upload_preview',
      render: ({ file }) => file && (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a href={file} target="_blank" rel="noopener noreferrer" className="cell-link">{translate('Show image')}</a>
      ),
    },
    {
      name: translate('Remove'),
      key: 'upload_remove',
      render: ({ id }) => id && (
        <button type="button" onClick={() => { actions.deleteImage(id); }} className="file-input__trash-btn">
          <TrashIcon />
        </button>
      ),
    },
  ];

  return (
    <Table
      classNameWrapper="table-screensavers"
      caption={translate('Device screensaver')}
      // tooltip={translate('Tooltip about this section')}
      columns={columns}
      data={data.length ? data : [{}]}
      footer={(
        <span
          className="cell-link"
          onClick={actions.addRowImage}
        >
          {translate('+ Add row')}
        </span>
      )}
    />
  );
};

export default TableScreensaver;
