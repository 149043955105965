import React from 'react';
import PropTypes from 'prop-types';

import { Hamburger } from '../../../../../components/Icons';
import translate from '../../../../../utils/translate';
import HeaderProfile from '../HeaderProfile';
import HeaderLogout from '../HeaderLogout';
import HeaderIssue from '../HeaderIssue';
// import HeaderNotification from '../HeaderNotification';
import './style.scss';

const Header = ({ handleToggleSidebar }) => (
  <div className="page-header">
    <nav className="navbar header-navbar navbar-expand-lg">
      <ul className="list-inline list-unstyled mg-l-15 mg-r-30 mg-b-0">
        <li className="list-inline-item">
          <button type="button" className="toggle-button" onClick={handleToggleSidebar}>
            <Hamburger />
          </button>
        </li>
      </ul>

      <ul className="list-inline list-unstyled mr-auto mg-b-0">
        <li className="list-inline-item align-text-top">
          <a
            href="https://portal.odore.co.uk/static/OdoreLimited-Vendingmachine-TermsofUse.doc"
            download
            className=""
          >
            {translate('Terms')}
          </a>
          {translate(' and ')}
          <a
            href="https://portal.odore.co.uk/static/OdoreLimited-Privacypolicy-Machine.doc"
            download
            className=""
          >
            {translate('Privacy Policy')}
          </a>
        </li>
      </ul>

      <div className="header-right pull-right">
        <ul className="list-inline justify-content-end mg-b-0">
          <li className="list-inline-item">
            <HeaderProfile />
          </li>
          <li className="list-inline-item">
            <HeaderIssue />
          </li>
          {/* <li className="list-inline-item">
            <HeaderNotification />
          </li> */}
          <li className="list-inline-item">
            <HeaderLogout />
          </li>
        </ul>
      </div>
    </nav>
  </div>
);

Header.propTypes = {
  handleToggleSidebar: PropTypes.func.isRequired,
};

export default Header;
