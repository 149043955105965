import React from 'react';
import tr from '../../../../utils/translate';
import { DEVICE_CAMPAIGN, ONLINE_SOCIAL_CAMPAIGN } from '../../../../constants/campaigns';
import './styles.scss';
import SocialMediaTable from './SocialMediaTable';
import ProductTable from './ProductTable';
import DeviceTable from './DeviceTable';
import InfoTable from './InfoTable';
import SuggestionsTable from './SuggestionsTable';
import ActionsTable from './ActionsTable';
import StatisticsTable from './StatisticsTable';
import FollowUpSummery from './FollowUpSummery';
// import { ROLE_SUPERADMIN } from '../../../../constants/roles';

const CampaignInfoTab = ({
  campaign, products, isPreview = false, access_level, social_networks, ad_campaign,
}) => {
  if (!campaign) return null;

  const {
    devices,
    type_is,
  } = campaign;
  // const direct_mail = campaign && campaign.direct_mail;

  const detailedInfo = campaign?.ad_campaign?.id && social_networks.map(({ slug }) => slug).includes('facebook')
    ? [{
      location: Object.keys((ad_campaign?.ad_sets?.[0]?.targeting?.geo_locations?.[0] || {}))
        .map((key) => ad_campaign?.ad_sets?.[0]?.targeting.geo_locations?.[0][key].map(({ name }) => name).join(';'))
        .join(';'),
      social_networks: campaign.social_networks,
      demographic: {
        age_from: ad_campaign?.ad_sets?.[0]?.targeting.age_min,
        age_to: ad_campaign?.ad_sets?.[0]?.targeting.age_max,
        gender: ad_campaign?.ad_sets?.[0]?.targeting.genders?.[0] || 3,
        interests: ad_campaign?.ad_sets?.[0]?.targeting.interests.map(({ name }) => name).join(';'),
      },
    }]
    : [{
      location: campaign.location,
      social_networks: campaign.social_networks,
      demographic: campaign.demographic,
    }];

  return (
    <>
      { campaign && Number(campaign.type_is) === 3 && (
      <div className="pd-b-30 campaing-alert-info">
        {tr(`In order to start the mailing process for the campaign (or send test emails), 
      the domain used for mailing needs to be verified first. To do this, 
      click the "Verify" button in the "Domain verification" section. 
      The domains that were verified previously do not need an additional check`)}
      </div>
      )}

      <InfoTable campaign={campaign} isPreview={isPreview} social_networks={social_networks} access_level={access_level} />

      { type_is === DEVICE_CAMPAIGN && <DeviceTable devices={devices} /> }

      <ProductTable campaign={campaign} products={products} isPreview={isPreview} />

      { type_is === ONLINE_SOCIAL_CAMPAIGN && <SocialMediaTable detailedInfo={detailedInfo} /> }

      <StatisticsTable campaign={campaign} />

      <FollowUpSummery campaign={campaign} />

      <SuggestionsTable campaignId={campaign.id} />

      { !isPreview && <ActionsTable campaignId={campaign.id} /> }
    </>
  );
};

export default CampaignInfoTab;
