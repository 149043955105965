import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Field } from 'redux-form';
import cn from 'classnames';
import { toast } from 'react-toastify';

import tr from '../../utils/translate';
import { TrashIcon } from '../Icons';
import './styles.scss';

const InputFileImage = ({
  input,
  meta: { error, touched },
  label,
  disabled,
  className,
  maxSize,
  additionalInfo,
}) => {
  // eslint-disable-next-line no-useless-escape
  const [, uploadedFileName = ''] = (typeof (input.value) === 'string' && input.value.match(/\/([^\/]+)$/)) || [];
  const [file] = input.value || [];
  const [image, setImage] = useState(null);

  const handleDeleteFile = () => {
    input.onChange([]);
    setImage(null);
  };

  /* eslint-disable react/jsx-props-no-spreading */
  /* eslint-disable jsx-a11y/img-redundant-alt */
  return (
    <div className={cn('file-input', className)}>
      <div className="file-input__wrap">
        <Dropzone
          disabled={disabled}
          name={input.name}
          accept="image/*"
          {...maxSize ? { maxSize } : {}}
          onDrop={([currentFile]) => {
            if (!currentFile) {
              return;
            }
            /* need update if require multiple files support in the future */
            input.onChange([currentFile]);

            const reader = new FileReader();
            reader.readAsDataURL(currentFile);
            reader.onloadend = () => {
              setImage(reader.result);
            };
          }}
          onDropRejected={([rejectedFile]) => {
            if (rejectedFile) {
              if (maxSize && rejectedFile.size > maxSize) {
                toast.error(tr(`File size is larger than ${(maxSize / 1048576).toFixed(0)} mb`));
              }
            }
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <div className="file-input__file">
                <input {...getInputProps()} />
                <div className="file-input__label-wrapper">
                  <label htmlFor={input.name} className="file-input__label btn btn-light">{label}</label>
                  {additionalInfo && <span>{additionalInfo}</span>}
                </div>
                {(uploadedFileName || file) && (
                  <span className="file-input__file-name file-input__file-name_active">{decodeURI(uploadedFileName) || (file && file.name)}</span>
                )}
              </div>

              {(uploadedFileName || image) && (
                <div>
                  <img className="file-input__preview-image" src={image || input.value} alt="image" />
                </div>
              )}
            </div>
          )}
        </Dropzone>
        <div>
          <button type="button" disabled={!file && !uploadedFileName} onClick={handleDeleteFile} className="file-input__trash-btn">
            <TrashIcon />
          </button>
        </div>
      </div>
      {touched && error && (
        <div className="invalid-feedback show-input-error">{error}</div>
      )}
    </div>
  );
};
/* eslint-enable react/jsx-props-no-spreading */
/* eslint-enable jsx-a11y/img-redundant-alt */

InputFileImage.defaultProps = {
  label: tr('Image'),
  color: 'blue',
  data: null,
  disabled: false,
  additionalInfo: null,
};

InputFileImage.propTypes = {
  color: PropTypes.oneOf(['yellow', 'green', 'blue', 'red']),
  input: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.number,
  ])).isRequired,
  meta: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ])).isRequired,
  label: PropTypes.string,
  data: PropTypes.shape(),
  disabled: PropTypes.bool,
  maxSize: PropTypes.number,
  additionalInfo: PropTypes.node,
};
// eslint-disable-next-line react/jsx-props-no-spreading
const ReduxInputFileImage = (props) => <Field {...props} component={InputFileImage} />;

export default ReduxInputFileImage;
