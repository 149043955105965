import React from 'react';
import PropTypes from 'prop-types';

import ReduxForm from '../../../../components/ReduxForm';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import { AuthPeopleIcon } from '../../../../components/Icons/auth-icons';
import InputFileImage from '../../../../components/InputFileImage';
import ModalFormFooter from '../../../../components/ModalFormFooter';
import Modal from '../../../../components/Modal';
import { DEVICE_EDIT_TRAY_MODAL } from '../../../../constants/modals';
import tr from '../../../../utils/translate';
import validate from './validate';
import useEditTray from './hooks';

const EditTrayModal = ({ serial_number, countItemsInTray, company }) => {
  const { actions, data: { isLoading, products } } = useEditTray(serial_number, company);

  const options = products && products.map((product) => ({ value: product.id, label: `${product.name} [${product.tag}]` }));

  return (
    <Modal name={DEVICE_EDIT_TRAY_MODAL} size="md" title={tr('Edit product stock')}>
      {({ handleHide, ...initialValues }) => (
        <ReduxForm
          enableReinitialize
          form="EDIT_TRAY_FORM"
          validate={validate}
          formData={{
            onSubmit: actions.submit,
            initialValues,
          }}
        >
          <div className="modal-body">
            <div className="row">
              <div className="col col-12 pd-b-6">
                <Select
                  name="product_id"
                  options={options}
                  prepend={<AuthPeopleIcon />}
                  placeholder={tr('Product ID')}
                />
              </div>
            </div>

            <div className="row pd-b-30">
              <div className="col col-6">
                <Input
                  type="number"
                  name="product_stock"
                  prependFieldLabel={tr('Product stock')}
                  max={countItemsInTray[`tray${initialValues.number}`]}
                  min={0}
                />
              </div>
              <div className="col col-6">
                <Input
                  type="number"
                  name="product_stock_alert"
                  prependFieldLabel={tr('Low stock alert')}
                  max={countItemsInTray[`tray${initialValues.number}`]}
                  min={0}
                />
              </div>
            </div>

          </div>
          <div className="modal-footer">
            <ModalFormFooter
              handleClose={handleHide}
              confirmText={tr('Save changes')}
              isLoading={isLoading}
            />
          </div>
        </ReduxForm>
      )}
    </Modal>
  );
};

EditTrayModal.propTypes = {
  serial_number: PropTypes.string.isRequired,
};

export default EditTrayModal;
