import React from 'react';
import tr from '../../../../../utils/translate';

export const statisticsColumns = [
  {
    name: tr('Metrics'),
    key: 'metric',
    render: ({ metric }) => {
      const renderData = metric && metric.map((value, index) => value && <span key={index}>{value}</span>);
      return <div className="detailed_badges">{renderData}</div>;
    },
  },
];
