import React, { useState } from 'react';
import sendRequest from '../../../../../utils/apiService';

const FacebookInterestsAutocomplete = ({
	accountId,
	onSelect,
	children,
}) => {
	const [state, setState] = useState({
		loading: false,
		suggestions: [],
		savedSuggestions: {},
		value: '',
	});

	const clearSuggestions = () => {
		setState((prevState) => ({
			...prevState,
			suggestions: [],
		}));
	};

	const request = async (value) => {
		if (value.length) {
			if(state.savedSuggestions[value]) {
				setState((prevState) => ({
					...prevState,
					suggestions: prevState.savedSuggestions[value],
				}));
			} else {
				setState((prevState) => ({
					...prevState,
					loading: true,
				}));

				const data = await sendRequest({
					method: 'get',
					path: `bo/accounts/ad-accounts/${accountId}/search_interests/`,
					data: {
						q: value
					},
				});

				setState((prevState) => {
					const suggestions = data.map((p) => ({
						id: p.id,
						name: p.name,
					}));

					return {
						...prevState,
						loading: false,
						savedSuggestions: {
							...prevState.savedSuggestions,
							[value]: suggestions,
						},
						suggestions: suggestions,
					};
				});
			}
		}
	}

	const getInputProps = (options = {}) => ({
		...options,
		type: 'text',
		autoComplete: 'off',
		value: state.value,
		onChange: (event) => {
			const { value } = event.target;

			setState((prevState) => ({
				...prevState,
				value,
			}));

			if (!value) {
				clearSuggestions();
				return;
			}

			request(value);
		},
	});
	const getSuggestionItemProps = (suggestion, options = {}) => ({
		...options,
		key: suggestion.id,
		role: 'option',
		onClick: (event) => {
			if (event && event.preventDefault) {
				event.preventDefault();
			}

			clearSuggestions();

			setState((prevState) => ({
				...prevState,
				value: '',
			}))

			onSelect(suggestion);
		},
	});

	return (
		<>

			{children({
				getInputProps,
				getSuggestionItemProps,
				loading: state.loading,
				suggestions: state.suggestions,
			})}

		</>
	);
};

export default FacebookInterestsAutocomplete;