import { useEffect, useCallback, useState } from 'react';
import _filter from 'lodash/filter';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getBrands } from '../services/actions';
import useModal from '../../../utils/useModal';
import { BRAND_MODAL, DEACTIVATE_BRAND_MODAL } from '../../../constants/modals';
import createLoadingSelector from '../../../selectors/loadingSelector';
import BRAND from '../services/types';

const selector = createSelector(
  createLoadingSelector(BRAND.GET_BRANDS.base),
  (state) => state.brands.list,
  (state) => ({
    count: state.brands.count,
    activePage: state.brands.activePage,
  }),
  (isLoading, brands, pagination) => ({
    isLoading,
    brands,
    pagination,
  }),
);

const useBrandsTable = () => {
  const dispatch = useDispatch();
  const data = useSelector(selector);
  const [activeTab, setActiveTab] = useState('active');
  const [sortBy, setSortBy] = useState({});

  const { showModal: showEditModal } = useModal(BRAND_MODAL);
  const { showModal: brandStatusChange } = useModal(DEACTIVATE_BRAND_MODAL);
  const getAllBrands = useCallback((params) => {
    dispatch(getBrands(params));
  }, [dispatch]);

  const changeSorting = (field) => {
    if (sortBy.key === field) {
      setSortBy({
        key: field,
        direction: sortBy.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSortBy({
        key: field,
        direction: 'asc',
      });
    }
  };

  useEffect(() => {
    getAllBrands({ is_active: activeTab === 'active' });
  }, [getAllBrands, activeTab]);

  useEffect(() => {
    const ordering = sortBy && (sortBy.direction === 'desc' ? `-${sortBy.key}` : sortBy.key);
    dispatch(getBrands({ ordering, is_active: activeTab === 'active' }));
  }, [dispatch, sortBy,activeTab]);

  const getNextPage = (page) => {
    const ordering = sortBy && (sortBy.direction === 'desc' ? `-${sortBy.key}` : sortBy.key);
    dispatch(getBrands({ ordering, page, is_active: activeTab === 'active' }));
  };

  return {
    data,
    activeTab,
    setActiveTab,
    sortBy,
    actions: {
      getAllBrands,
      showEditModal,
      brandStatusChange,
      changeSorting,
      getNextPage,
    },
  };
};

export default useBrandsTable;
