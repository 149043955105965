import { useEffect, useCallback } from 'react';
// import _find from 'lodash/find';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { getBrandsMini, setActiveBrand } from '../../../../brand/services/actions';
import { getDepartmentsMini, getDepartment, setActiveDepartment } from '../../../../department/services/actions';

const createSideMenuSelector = () => createSelector(
  (state) => state.brands.listMini,
  (state) => state.departments.listMini,
  (state) => state.brands.activeBrand,
  (state) => state.departments.activeDepartment,
  (state) => state.departments.activeDepartmentData,
  (brands, departments, activeBrand, activeDepartment, activeDepartmentData) => ({
    brands,
    departments,
    activeBrand,
    activeDepartment,
    activeDepartmentData,
  }),
);

const useSidebar = () => {
  const dispatch = useDispatch();
  const data = useSelector(createSideMenuSelector());

  const openPage = useCallback(() => {
    dispatch(getBrandsMini({ is_active: 1 }));
  }, [dispatch]);

  const handleBrandChange = (value) => {
    dispatch(setActiveBrand({ brand: value }));
    dispatch(setActiveDepartment({ department: '' }));
  };

  const handleDepartmentChange = (value) => {
    dispatch(setActiveDepartment({ department: value }));

    if (value) {
      dispatch(getDepartment(value));
    }
  };

  useEffect(() => {
    dispatch(getDepartmentsMini({ company: data.activeBrand, is_active: 1 }));
  }, [dispatch, data.activeBrand]);

  useEffect(() => {
    openPage();
  }, [openPage]);

  useEffect(() => {
    if (data.activeDepartmentData) {
      dispatch(setActiveBrand({ brand: data.activeDepartmentData.company.id }));
    }
  }, [data.activeDepartmentData, dispatch])

  return {
    data,
    formValues: {
      brand: data.activeBrand || '',
      department: data.activeDepartment || '',
    },
    actions: {
      handleBrandChange,
      handleDepartmentChange,
    },
  };
};

export default useSidebar;
