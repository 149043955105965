import React from 'react';
import _find from 'lodash/find';
import Table from '../../../../../components/Table';
import tr from '../../../../../utils/translate';
import { statisticsColumns } from './data';
import useFollowUpsQuestions from './hooks';
import './styles.scss';

const FollowUpSummery = ({ campaign }) => {
  const { data } = useFollowUpsQuestions();
  const followUp = (campaign && campaign.follow_up) || {};
  const tableData = followUp.questions && followUp.questions.length && {
    ...followUp,
    questions: followUp.questions.map((id) => _find(data.questions, { id })),
  };

  return (
    <div className="mg-b-30 follow_up_table">
      <Table
        caption={tr('Follow ups')}
        columns={statisticsColumns}
        data={tableData ? [tableData] : []}
      />
    </div>
  );
};

export default FollowUpSummery;
