import React from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import tr from '../../../../utils/translate';
import InfoTooltip from '../../../../components/InfoTooltip';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import SelectFont from '../../../../components/SelectFont';
import SelectColor from '../../../../components/SelectColor';
import ReduxForm from '../../../../components/ReduxForm';
import { BackarrowIcon, ImageIcon } from '../../../../components/Icons';
import UploadFile from '../../../../components/UploadFile';
import FollowUpsPreviewModal from '../FollowUpsPreviewModal';
import useFollowUpsDesign from './hooks';
import { validateSend, validateMain } from './validate';
import './styles.scss';

const FollowUpsDesign = ({ data: { id, isNew } }) => {
  const {
    data: {
      isLoadingSubmit,
      isLoadingData,
      compaign,
      theme,
      questions,
      formValues,
    },
    actions: {
      goToBack,
      handleSend,
      handlePreview,
      handleSubmitTheme,
      handleDeleteBackground,
    },
  } = useFollowUpsDesign(id, isNew);

  if (isLoadingData || !theme || !compaign || !compaign.follow_up) return <Loader size="md" />;

  const questionOptions = compaign.follow_up.questions.map((questionId) => {
    const currentQuestion = _find(questions, { id: questionId });

    return { label: currentQuestion.title, value: currentQuestion.id };
  });

  return (
    <>
      <div className="pd-x-25 pd-y-50 follow-ups-design">
        <h3 data-tip="tip" className="cadf-title mg-b-40">{tr('Follow up')}</h3>
        <div className="fud-description">{tr('Customize design in a follow up')}</div>
        <div className="follow-ups-design-wrapper">
          <ReduxForm
            form="FOLLOW_UPS_DESIGN_FORM"
            autoComplete="off"
            validate={validateMain}
            formData={{
              onSubmit: () => {},
              initialValues: {
                font: theme.font,
                colors: theme.colors,
                background_url: theme.background_url || '',
                email_subject: compaign.follow_up.email_subject,
                from_email_address: compaign.follow_up.from_email_address,
                first_question: compaign.follow_up.first_question || (questionOptions[0] && questionOptions[0].value),
              },
            }}
          >
            <div className="row mg-b-35">
              <div className="col-md-6">
                <p>{tr('Select first question')}</p>
                <Select
                  name="first_question"
                  options={questionOptions}
                />
              </div>
              <div className="col-md-6">
                <p>{tr('Font')}</p>
                <SelectFont name="font" />
              </div>
            </div>
            <div className="row mg-b-35">
              <div className="col-md-6">
                <p>{tr('Select colors')}</p>
                <div>
                  <div className="follow-ups-design-item mg-b-15">
                    <SelectColor
                      name="colors.question"
                    />
                    <p>{tr('Question:')}</p>
                  </div>
                  <div className="follow-ups-design-item mg-b-15">
                    <SelectColor
                      name="colors.answer"
                    />
                    <p>{tr('Answers')}</p>
                  </div>
                  <div className="follow-ups-design-item mg-b-15">
                    <SelectColor
                      name="colors.button"
                    />
                    <p>{tr('Buttons')}</p>
                  </div>
                  <div className="follow-ups-design-item">
                    <SelectColor
                      name="colors.background"
                    />
                    <p>{tr('Background')}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 fud-bg">
                <div>
                  <p>{tr('Background')}</p>
                  <UploadFile
                    accept="image/*"
                    name="background"
                    maxSize={2097152}
                    label={tr('Max size 2 Mb')}
                    color="blue"
                    icon={<ImageIcon />}
                    data={{ name: formValues.background_url }}
                    onDeleted={handleDeleteBackground}
                  />
                </div>
                <div>
                  <Button className="fud-btn" color="light" onClick={handlePreview} disabled={isLoadingSubmit}>
                    {tr('Open preview')}
                  </Button>
                </div>
              </div>
            </div>
            <div className="row mg-b-10">
              <div className="col-md-6">
                <p>{tr('Email subject')}</p>
                <Input name="email_subject" autoComplete="off" />
              </div>
            </div>
            <div className="row mg-b-10">
              <div className="col-md-6">
                <p>
                  {tr('Sender email')}
                  {' '}
                  <InfoTooltip text={tr('Please Verify Your Sender Identity by confirming a link in your mailbox')} />
                </p>
                <Input
                  name="from_email_address"
                  autoComplete="off"
                />
              </div>
            </div>
          </ReduxForm>
          <ReduxForm
            enableReinitialize
            form="FOLLOW_UPS_DESIGN_SEND_FORM"
            validate={validateSend}
            autoComplete="off"
            formData={{
              onSubmit: handleSend,
              initialValues: {
                recipient: '',
              },
            }}
          >
            <div className="row">
              <div className="col-md-6">
                <p>{tr('Receiver email')}</p>
                <Input name="recipient" placeholder="Email" autoComplete="off" />
              </div>
              <div className="col-md-6 fud-btn-send">
                <Button
                  type="submit"
                  className="fud-btn"
                  color="light"
                  disabled={isLoadingSubmit}
                >
                  {tr('Send test email')}
                </Button>
              </div>
            </div>
          </ReduxForm>
        </div>
      </div>
      <div className="modal-footer">
        <Button color="light" className="mg-r-30" onClick={goToBack}>
          <BackarrowIcon />
          {tr('Back')}
        </Button>
        <Button
          disabled={isLoadingSubmit}
          onClick={handleSubmitTheme}
        >
          {tr('Summary')}
        </Button>
      </div>
      <FollowUpsPreviewModal />
    </>
  );
};

FollowUpsDesign.defaultProps = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }),
};

FollowUpsDesign.defaultProps = {
  data: {},
};

export default FollowUpsDesign;
