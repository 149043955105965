
import EMPLOYEES from './types';

const defaultState = {
  list: [],
  assigned_devices: {},
  count: null,
  activePage: 1,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case EMPLOYEES.READ_USERS_LIST.SUCCESS: {
      return {
        ...state,
        list: payload.res.results,
        count: payload.res.count,
        activePage: payload.req.data.page || 1,
      };
    }

    case EMPLOYEES.CHANGE_ROLE.SUCCESS:
      return {
        ...state,
        list: state.list.map((user) => (
          user.id === payload.req.params.id ? {...user, ...payload.res} : user
        )),
      };

    case EMPLOYEES.ACTIVATE_EMPLOYEE.SUCCESS: 
      return {
        ...state,
        list: state.list.map((user) => (
          user.id === payload.req.params.id ? {...user, is_active: true} : user
        )),
      };
      
    case EMPLOYEES.DEACTIVATE_EMPLOYEE.SUCCESS: 
      return {
        ...state,
        list: state.list.map((user) => (
          user.id === payload.req.params.id ? {...user, is_active: false} : user
        )),
      };
    case EMPLOYEES.USER_DEVICES.SUCCESS: 
      return {
        ...state,
        assigned_devices: {
          ...state.assigned_devices,
          [payload.req.params.id]: payload.res,
        }
      };
    default:
      return state;
  }
};
