import React from 'react';
import _filter from 'lodash/filter';

import ReduxForm from '../../../components/ReduxForm';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import ModalFormFooter from '../../../components/ModalFormFooter';
import validate from './validate';
import useBrandModal from './hooks';
import { DEPARTMENT } from '../../../constants/forms';
import tr from '../../../utils/translate';
import { generateSelect } from '../../../helpers/arrayHelpers';
import { ROLE_SUPERADMIN } from '../../../constants/roles';

// const required = (value) => (value ? undefined : 'Required');

const DepartmentForm = ({ data, handleHide }) => {
  const {
    actions, data: {
      isLoading, countries, department, brands, access_level,
    },
  } = useBrandModal(data.id);
  const brandSelect = generateSelect(brands, 'name');
  return (
    <ReduxForm
      form={DEPARTMENT.EDIT_DEPARTMENT_FORM}
      validate={validate}
      formData={{
        onSubmit: data.id ? actions.editDepartment : actions.createDepartment,
        initialValues: department && {
          name: department.name,
          country: department.country && Number(department.country.id),
          company: department.company && Number(department.company.id),
        },
      }}
    >
      <div className="modal-body">
        <Input
          name="name"
          prependFieldLabel={tr('Team name')}
          className="pd-b-30"
        />
        {access_level === ROLE_SUPERADMIN
        && (
        <Select
          disabled={!!department}
          fieldLabel={tr('Select brand')}
          className="pd-b-30"
          name="company"
          options={brandSelect}
        />
        )}
        <Select
          name="country"
          placeholder={tr('Country')}
          className="pd-b-15"
          options={countries}
        />
      </div>
      <div className="modal-footer">
        <ModalFormFooter
          handleClose={handleHide}
          confirmText={tr('Apply')}
          isLoading={isLoading}
        />
      </div>
    </ReduxForm>
  );
};

export default DepartmentForm;
