import React, { useState, useEffect, useRef } from 'react';
import _map from 'lodash/map';
import PlacesAutocomplete from 'react-places-autocomplete';
import TagsInput from 'react-tagsinput';
import tr from '../../../../utils/translate';

import 'react-tagsinput/react-tagsinput.css';
import './styles.scss';

const LocationField = ({ input, data, fieldLabel }) => {
  const [activeTags, setTags] = useState(data);
  const [address, setAddress] = useState('');
  const inputEl = useRef(null);
  const forminputEl = useRef(null);
  const handleSelect = async (value) => {
    if (value) {
      setTags([...activeTags, value]);
      setAddress('');
    }
  };

  const handleChange = (tags) => {
    setTags(tags);
  };

  useEffect(() => {
    forminputEl.current.value = activeTags.join(';');

    return input.onChange(activeTags.join(';'));
  }, [activeTags, input]);

  return (
    <div className="locations-tag-input" onClick={() => inputEl.current.focus()}>

      <div className="location-input-label">{fieldLabel || tr('Enter a region, for example “France”')}</div>

      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
        searchOptions={{ types: ['(regions)'], language: 'en' }}
      >
        {({
          getInputProps, suggestions, getSuggestionItemProps, loading,
        }) => (
          <div>
            <TagsInput
              value={activeTags}
              renderInput={() => <input ref={inputEl} {...getInputProps({ className: 'react-tagsinput-input' })} />}
              onChange={handleChange}
            />
            <div className="autocomplete-dropdown-container">

              {loading ? <div>...loading</div> : null}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                return (
                  <div {...getSuggestionItemProps(suggestion, { className })}>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <input type="hidden" ref={forminputEl} name="locations" />
    </div>
  );
};

export default LocationField;
