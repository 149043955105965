import React from 'react';
import PropTypes from 'prop-types';
import Widget from '../../../../components/Widget';
import {
  FollowUpsIcon,
  LargeClockIcon,
  PieChartIcon,
  BottleIcon,
} from '../../../../components/Icons';
import tr from '../../../../utils/translate';

import './styles.scss';

const FacebookBadges = ({ contacts, statisticFacebook }) => (
  <div className="info-badges row mg-b-30">

    <div className="col">
      <Widget
        color="yellow"
        icon={(<BottleIcon />)}
        title={tr('Impressions')}
        description={statisticFacebook?.impressions_percent_total || 0}
        subtitle={statisticFacebook?.impressions_total || 0}
      />
    </div>

    <div className="col">
      <Widget
        color="green"
        icon={(<LargeClockIcon />)}
        title={tr('Clicks')}
        description={statisticFacebook?.cost_per_click_total || 0}
        subtitle={statisticFacebook?.clicks_total || 0}
      />
    </div>

    <div className="col">
      <Widget
        color="blue"
        icon={(<FollowUpsIcon />)}
        title={tr('Contacts')}
        description=""
        subtitle={contacts || 0}
      />
    </div>

    <div className="col">
      <Widget
        color="red"
        icon={(<PieChartIcon />)}
        title={tr('Spend')}
        description=""
        subtitle={statisticFacebook?.spend_total || 0}
      />
    </div>

  </div>
);

FacebookBadges.propTypes = {
  statisticFacebook: PropTypes.object.isRequired,
};

export default FacebookBadges;
