import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import tr from '../../../../../utils/translate';
import { COPY_SOCIAL_LINK_MODAL, VALIDATE_DOMAIN_MODAL } from '../../../../../constants/modals';
import useModal from '../../../../../utils/useModal';
import { getCsvFile, editCampaign } from '../../../services/actions';


const useInfoTable = (campaign, social_networks) => {
  const dispatch = useDispatch();

  const { showModal: openValidateDomainModal } = useModal(VALIDATE_DOMAIN_MODAL);
  const { showModal: openCopyLinkModal } = useModal(COPY_SOCIAL_LINK_MODAL);

  const downloadSCV = useCallback(() => {
    dispatch(getCsvFile(campaign.id));
  }, [campaign.id, dispatch]);

  const handleEditCampaign = useCallback((data) => {
    dispatch(editCampaign(campaign.id, data));
  }, [campaign.id, dispatch]);

  const handleCopyLinkCLick = useCallback(() => {
    openCopyLinkModal({ titleModal: tr('Copy link'), social_networks, link: campaign.online_campaign_link });
  }, [campaign.online_campaign_link, openCopyLinkModal, social_networks]);

  return {
    actions: {
      downloadSCV,
      handleCopyLinkCLick,
      openValidateDomainModal,
      handleEditCampaign,
    },
  };
};

export default useInfoTable;
