import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import CAMPAIGNS from '../../services/types';
import {
  getDeviceCampaignDetails,
  editCampaign as editCampaignAction,
  prevStep,
  getAdAccounts,
  getFbPages,
  getAdCampaign,
  getAccountExists,
  getReachEstimate,
  getFbTargetings,
  clearFacebookCampaign
} from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import sendRequest from '../../../../utils/apiService';
import _pickBy from 'lodash/pickBy';
import { createAction } from 'redux-actions';
import { initialize } from 'redux-form';

const submitLoadingSelector = createLoadingSelector(CAMPAIGNS.CREATE_CAMPAIGN.base);
const detailsLoadingSelector = createLoadingSelector(CAMPAIGNS.READ_DETAILS.base);

const createCampaignSelector = (id) => createSelector(
  detailsLoadingSelector,
  submitLoadingSelector,
  (state) => state.campaigns.details[id],
  (state) => state.account.accountDetails.access_level,
  (state) => state.brands.list,
  (state) => state.departments.list,
  (state) => state.brands.activeBrand,
  (state) => state.departments.activeDepartment,
  (state) => state.campaigns.editStep,
  (state) => state.campaigns.campaign_type,
  (state) => state.campaigns.ad_accounts,
  (state) => state.form['CAMPAIGN_FACEBOOK_FORM'],
  (state) => state.campaigns.fb_pages,
  (state) => state.campaigns.ad_campaigns,
  (state) => state.campaigns.account_exists,
  (state) => state.campaigns.audience_size,
  (state) => state.campaigns.fb_targetings,
  (isLoadingData, isLoadingSubmit, campaign, access_level, brands, departments, activeBrand, activeDepartment, step, campaign_type, ad_accounts, form, pages, ad_campaigns, account_exists, audience_size, fb_targetings) => ({
    isLoadingData,
    isLoadingSubmit,
    campaign,
    access_level,
    brands,
    departments,
    activeDepartment,
    activeBrand,
    step, 
    campaign_type,
    ad_accounts,
    form,
    fb_pages: pages[form?.values?.ad_account],
    ad_campaigns,
    account_exists: account_exists[form?.values?.ad_account],
    audience_size,
    fb_targetings: fb_targetings[form?.values?.ad_account],
  }),
);

const useCampaignAdForm = (id) => {
  const dispatch = useDispatch();

  const prevReachEstimate = useRef({});

  const selector = createCampaignSelector(id);

  const data = useSelector(selector);

  const submitHandler = useCallback((fields) => {
    const request = async () => {
      const method = data.campaign.ad_campaign?.id ? 'patch' : 'post'

      const path1 = data.campaign.ad_campaign?.id
        ? `bo/marketing/ad-campaigns/${data.campaign.ad_campaign?.id}/`
        : 'bo/marketing/ad-campaigns/';

      const response1 = await sendRequest({
        method,
        path: path1,
        data: {
	        ad_account: fields.ad_account,
          campaign: data?.campaign?.id,
        },
      });

      const path2 = data.campaign.ad_campaign?.id
        ? `bo/marketing/ad-sets/${data.ad_campaigns[data.campaign.ad_campaign?.id]?.ad_sets[0]?.id}/`
        : 'bo/marketing/ad-sets/';

      const response2 = await sendRequest({
        method,
        path: path2,
        data: {
          ad_campaign: response1.id,
          start_time: fields.start_time,
          end_time: fields.end_time,
          targeting: {
            geo_locations: [fields.location],
            interests: fields.interests,
            genders: fields.gender === 3 ? [] : [fields.gender],
            age_min: Number(fields.age_from),
            age_max: Number(fields.age_to),
            publisher_platforms: Object.keys(_pickBy(fields.placement))
          },
          budget_type: fields.type_budget,
          budget: Number(fields.type_budget === 'LIFETIME' ? fields.budget_life : fields.budget_per_day),
        },
      });

      const formData = new FormData();
      formData.append('ad_set', response2.id);
      formData.append('page_id', fields.facebook_page);

      if(typeof fields.image === 'object' && fields.image[0]) {
        formData.append('file', fields.image[0]);
      }

      formData.append('title', fields.headline);
      formData.append('body', fields.text_body);
      formData.append('call_to_action_type', fields.call_to_action);

      const path3 = data.campaign.ad_campaign?.id
        ? `bo/marketing/ads/${data.ad_campaigns[data.campaign.ad_campaign?.id]?.ad_sets[0]?.ads[0]?.id}/`
        : 'bo/marketing/ads/';

      await sendRequest({
        method,
        path: path3,
        data: formData,
      });

      dispatch(editCampaignAction(id, {
        stop_ad: fields.stop_ad,
      }));
    };

    /*if (!fields.template) {
     throw new SubmissionError({
     'template-validations': 'Required'
     })
     }*/

    request();
  }, [dispatch, id, data]);

  const goToPrevStep = useCallback(() => {
    dispatch(prevStep());
  }, [dispatch]);

  useEffect(() => {
    if (id && !data.campaign) {
      dispatch(getDeviceCampaignDetails(id));
    }
  }, [data.campaign, dispatch, id]);

  useEffect(() => {
    if(!data.ad_accounts) {
      dispatch(getAdAccounts());
    }
  }, [dispatch, data]);

  useEffect(() => {
    if(data.form?.values?.ad_account && !data.fb_pages) {
      dispatch(getFbPages({ id: data.form?.values?.ad_account }));
    }
  }, [dispatch, data]);

  useEffect(() => {
    const adCampaignId = data.campaign.ad_campaign?.id || data.form?.values?.account_exist;

    if(adCampaignId && !data.ad_campaigns[adCampaignId]) {
      dispatch(getAdCampaign({ id: adCampaignId }));
    }
  }, [dispatch, data]);

  useEffect(() => {
    const adAccountId = data.form?.values?.ad_account;

    if(adAccountId && data.form?.values?.from_campaign === 'exist' && !data.account_exists) {
      dispatch(getAccountExists({ id: adAccountId }));
    }
  }, [dispatch, data]);

  useEffect(() => {
    const adAccountId = data.form?.values?.ad_account;

    if(adAccountId && data.form?.values?.from_audience === 'exist' && !data.fb_targetings) {
      dispatch(getFbTargetings({ id: adAccountId }));
    }
  }, [dispatch, data]);

  useEffect(() => {
    const adAccountId = data.form?.values?.ad_account;
    const location = data.form?.values?.location;
    const interests = data.form?.values?.interests;
    const gender = data.form?.values?.gender;
    const age_min = data.form?.values?.age_from;
    const age_max = data.form?.values?.age_to;
    const publisher_platforms = Object.keys(_pickBy(data.form?.values?.placement));

    const requestData = {
      targeting: {
        geo_locations: [location],
        interests,
        genders: gender === 3 ? [] : [gender],
        age_min,
        age_max,
        publisher_platforms,
      },
    };

    if(
      adAccountId && (
        location?.countries?.length > 0
        || location?.regions?.length > 0
        || location?.cities?.length > 0
      )
      && gender && age_min && age_max
      && age_min < age_max && publisher_platforms?.length > 0
    ) {
      if(JSON.stringify(prevReachEstimate.current) !== JSON.stringify(requestData)) {
        prevReachEstimate.current = requestData;

        dispatch(getReachEstimate({
          id: adAccountId,
          data: requestData,
        }));
      }
    } else if(Object.keys(data.audience_size).length > 0) {
      dispatch(createAction(CAMPAIGNS.CLEAR_REACH_ESTIMATE.base)());
    }
  }, [dispatch, data]);

  useEffect(() => {
    const formValues = data.form?.values;

    if(formValues?.account_exist) {
      const ad_campaign = data.ad_campaigns[formValues?.account_exist] || {};

      if(formValues?.account_exist && Object.keys(ad_campaign).length) {

        const ad_sets = ad_campaign?.ad_sets?.[0];

        const ads = ad_sets?.ads?.[0];

        const targeting = ad_sets?.targeting;

        const gender = targeting?.genders?.[0];

        const getImage = async () => {
          const file = await sendRequest({
            method: 'GET',
            // path: 'https://images.unsplash.com/photo-1444703686981-a3abbc4d4fe3?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8cGljdHVyZXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80',
            path: ads.file,
            headers: {
              "Content-Type": 'application/octet-stream',
            },
            configs: {
              responseType: 'arraybuffer',
            }
          })
            .then((response) => new Blob([response], {type: "image/jpg"}))
            .then((blob) => new File([blob], 'image.jpg', {type: blob.type}));

          dispatch(initialize('CAMPAIGN_FACEBOOK_FORM', {
            ...formValues,
            ad_account: ad_campaign?.ad_account,
            facebook_page: ads.page_id,
            // image: ads.file,
            image: [file],
            text_body: ads.body,
            headline: ads.title,
            call_to_action: ads.call_to_action_type,
            location: targeting.geo_locations?.[0],
            interests: targeting.interests,
            age_from: targeting.age_min,
            age_to: targeting.age_max,
            gender: !gender ? 3 : Number(gender),
            placement: targeting.publisher_platforms.reduce((acc, item) => {
              acc[item] = true;

              return acc;
            }, {}),
            type_budget: ad_sets.budget_type,
            [ad_sets.budget_type === 'LIFETIME' ? 'budget_life' : 'budget_per_day']: ad_sets.budget,
            start_time: ad_sets.start_time,
            end_time: ad_sets.end_time,
            stop_ad: ad_sets.stop_ad,
            from_campaign: 'new',
            account_exist: '',
          }));
        };

        getImage();
      }
    }

    if(formValues?.audience) {
      const audience = data.fb_targetings[formValues?.audience];

      if(formValues?.audience && Object.keys(audience).length) {
        dispatch(initialize('CAMPAIGN_FACEBOOK_FORM', {
          ...formValues,
          location: audience.geo_locations?.[0],
          interests: audience.interests,
          age_from: audience.age_min,
          age_to: audience.age_max,
          gender: audience.genders?.length > 0 ? Number(audience.genders[0]) : 3,
          placement: audience.publisher_platforms.reduce((acc, item) => {
            acc[item] = true;

            return acc;
          }, {}),
          from_audience: 'new',
          audience: '',
        }));
      }
    }

  }, [data, dispatch]);

  return {
    data,
    actions: {
      goToPrevStep,
      submitHandler
    },
  };
};

export default useCampaignAdForm;
