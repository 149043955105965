import React from 'react';
import Modal from '../../../../../components/Modal';
import Button from '../../../../../components/Button';
import tr from '../../../../../utils/translate';
import { CHOOSE_SOCIALS_MODAL } from '../../../../../constants/modals';

const ChooseSocialsModal = ({ onSubmit }) => {
  return (
    <Modal name={CHOOSE_SOCIALS_MODAL} size="md" title={tr('Choose a network to connect')}>
      <div className="social_modal">
        <Button outline onClick={onSubmit}>{tr('Facebook')}</Button>
      </div>
    </Modal>
  );
};

export default ChooseSocialsModal;
