import React, { useState, useEffect, useRef } from 'react';
import TagsInput from 'react-tagsinput';
import FacebookInterestsAutocomplete from './FacebookInterestsAutocomplete';

import 'react-tagsinput/react-tagsinput.css';
import './styles.scss';

const InterestsFacebookField = ({ accountId, input, meta, fieldLabel }) => {
  const inputEl = useRef(null);

  const formInputEl = useRef(null);

  const handleSelect = async (suggestion) => {
    if (suggestion) {
      input.onChange([...new Set([...input.value, suggestion])]);
    }
  };

  const handleChange = (tags) => {
    const filtered = input.value.filter(({ name }) => tags.includes(name));

    input.onChange(filtered);
  };

  useEffect(() => {
    formInputEl.current.value = input.value;
  }, [input]);

  return (
    <div
      className="locations-tag-input"
      onClick={() => inputEl.current.focus()}
    >

      <div className="location-input-label">

        {fieldLabel}

      </div>

      <FacebookInterestsAutocomplete
        accountId={accountId}
        onSelect={handleSelect}
      >

        {({ suggestions, getInputProps, getSuggestionItemProps, loading }) => (
          <div>

            <TagsInput
              value={input.value.map((tab) => tab?.name)}
              renderInput={() => <input ref={inputEl} {...getInputProps({ className: 'react-tagsinput-input'})} />}
              onChange={handleChange}
            />

            <div className="autocomplete-dropdown-container">

              {loading ? <div>...loading</div> : null}

              {suggestions.map((suggestion) => (
                <div {...getSuggestionItemProps(suggestion)}>
                  {suggestion.name}
                </div>
              ))}

            </div>

          </div>
        )}

      </FacebookInterestsAutocomplete>

      <input type="hidden" ref={formInputEl} name={input.name} />

      {meta.touched && meta.error && (
        <div className="invalid-feedback show-input-error">{meta.error}</div>
      )}

    </div>
  );
};

export default InterestsFacebookField;
