import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  prevStep,
  setCampaignType as setCampaignTypeAction,
} from '../../services/actions';


const useOnlineCampaignType = () => {
  const dispatch = useDispatch();

  const goToBack = useCallback(() => {
    dispatch(prevStep());
  }, [dispatch]);


  const setCampaignType = useCallback((fields) => {
    dispatch(setCampaignTypeAction(fields));
  }, [dispatch]);

  return {
    actions: {
      goToBack,
      setCampaignType,
    },
  };
};

export default useOnlineCampaignType;
