import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import useModal from '../../../utils/useModal';
import createLoadingSelector from '../../../selectors/loadingSelector';
import BRAND from '../services/types';
import { activateBrand, deactivateBrand } from '../services/actions';

const selector = createSelector(
  createLoadingSelector(BRAND.INVITE_BRAND.base),
  (isLoading) => ({
    isLoading,
  }),
);

const useBrandStatusModal = () => {
  const dispatch = useDispatch();

  const handleBrandStatusChange = useCallback((id, isActive) => {
    dispatch(isActive ? deactivateBrand(id) : activateBrand(id));
  }, [dispatch]);

  return {
    actions: {
      handleBrandStatusChange,
    },
    data: useSelector(selector),
  };
};

export default useBrandStatusModal;
