import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-text-mask';
import cn from 'classnames';
import { Field } from 'redux-form';
import './styles.scss';

const Input = (props) => {
  const {
    label,
    pattern,
    className,
    classNameBody,
    input,
    meta: { error, touched },
    ...otherProps
  } = props;

  return (
    <div className={cn('input-wrapper masked-input-wrapper', className)}>
      {label && (
        <div className="input-label-wrap">
          <label htmlFor={input.name}>{label}</label>
        </div>
      )}
      <div className={cn('input-group', classNameBody)}>
        <InputMask
          mask={pattern}
          showMask
          {...otherProps} // eslint-disable-line react/jsx-props-no-spreading
          {...input} // eslint-disable-line react/jsx-props-no-spreading
        />
        {touched && error && (
          <div className="invalid-feedback show-input-error">{error}</div>
        )}
      </div>
    </div>
    /* eslint-enable react/jsx-props-no-spreading */
  );
};

Input.propTypes = {
  input: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.number,
  ])).isRequired,
  meta: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ])).isRequired,
  type: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
};
// eslint-disable-next-line react/jsx-props-no-spreading
const ReduxInput = (props) => <Field {...props} component={Input} />;

ReduxInput.propTypes = {
  classNameBody: PropTypes.string,
  className: PropTypes.string,
  pattern: PropTypes.array.isRequired,
  prepend: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

ReduxInput.defaultProps = {
  classNameControl: null,
  classNameBody: null,
  className: null,
  pattern: [/[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, ' '],
  label: null,
};

export default ReduxInput;
