import { createRequestTypes } from '../../../utils/actionHelpers';

export default createRequestTypes('AUTH', [
  'TOKEN_REFRESH',
  'SIGN_IN',
  'SIGN_UP',
  'FORGOT_PASSWORD',
  'CHANGE_PASSWORD',
  'CONFIRM_INVITATION',
  'CONFIRM_BRAND_INVITATION',
  'GET_INVITATION',
], [
  'SIGN_OUT',
]);
