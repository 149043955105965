import moment from 'moment';
import emailValidator from 'email-validator';

import tr from '../../../../utils/translate';
import { ONLINE_EMBEDED_CAMPAIGN, ONLINE_EMAIL_CAMPAIGN } from '../../../../constants/campaigns';

const validate = ({
  name,
  date_start,
  date_end,
  description,
  direct_mail,
  type_is,
  department,
  company,
}) => {
  const errors = {};
  if (!name) {
    errors.name = tr('Name is required');
  } else if (name.length > 255) {
    errors.name = tr('Name must be no longer than 255 characters');
  }
  if (!department) {
    errors.department = tr('Department is required');
  }
  if (!company) {
    errors.company = tr('Company is required');
  }

  if (!date_start) {
    errors.date_start = tr('Start date is required');
  } else if (!moment(date_start, 'YYYY-MM-DD').isValid() || !moment(date_start, 'YYYY-MM-DD HH:mm').isValid()) {
    errors.date_start = tr('Wrong format, must be YYYY-MM-DD');
  }

  if (!date_end && type_is !== ONLINE_EMBEDED_CAMPAIGN) {
    errors.date_end = tr('End date is required');
  } else if (date_end && (!moment(date_end, 'YYYY-MM-DD').isValid() || !moment(date_start, 'YYYY-MM-DD HH:mm').isValid())) {
    errors.date_end = tr('Wrong format, must be YYYY-MM-DD');
  }

  if (date_start && date_end) {
    if (moment(date_end).isBefore(date_start) || moment(date_end).isSame(date_start)) {
      errors.date_end = tr('End date must be bigger than Start date');
    }
  }

  if (!description) {
    errors.description = tr('Description is required');
  } else if (description && description.length > 255) {
    errors.description = tr('Description must be no longer than 255 characters');
  }

  if (type_is === ONLINE_EMAIL_CAMPAIGN) {
    errors.direct_mail = {};
    if (!direct_mail || (direct_mail.from_email_address && !emailValidator.validate(direct_mail.from_email_address))) {
      errors.direct_mail = {
        ...errors.direct_mail,
        from_email_address: tr('Invalid email'),
      };
    }
    if (!direct_mail || !direct_mail.email_subject) {
      errors.direct_mail = {
        ...errors.direct_mail,
        email_subject: tr('Subject is required'),
      };
    }
  }

  return errors;
};

export default validate;
