import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addDevice } from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import DEVICES from '../../services/types';

const loadingSelector = createLoadingSelector(DEVICES.ADD_NEW_DEVICE.base);

const useAddDeviceModal = () => {
  const dispatch = useDispatch();
  const addNewDevice = useCallback(({ serial_number, ...data }) => {
    dispatch(addDevice(serial_number, data));
  }, [dispatch]);
  const isLoading = useSelector(loadingSelector);

  return {
    data: {
      isLoading,
    },
    actions: {
      addNewDevice,
    },
  };
};

export default useAddDeviceModal;
