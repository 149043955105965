import React from 'react';
import _get from 'lodash/get';

import Modal from '../../../../components/Modal';
import { DEVICE_EDIT_CAMPAIGN_MODAL } from '../../../../constants/modals';
import ReduxForm from '../../../../components/ReduxForm';
import tr from '../../../../utils/translate';
import Select from '../../../../components/Select';
import ModalFormFooter from '../../../../components/ModalFormFooter';

import useEditDeviceCampaign from './hooks';

const EditDeviceCampaignModal = ({
  serial_number, currentCampaign, company, department,
}) => {
  const { actions, data: { isLoading, campaigns } } = useEditDeviceCampaign(serial_number, company, department);
  const options = campaigns.map((camp) => ({ value: camp.id, label: camp.name }));

  return (
    <Modal name={DEVICE_EDIT_CAMPAIGN_MODAL} size="sm" title={tr('Edit')}>
      {({ handleHide }) => (
        <ReduxForm
          enableReinitialize
          form="EDIT_DEVICE_CAMPAIGN_FORM"
          formData={{
            onSubmit: actions.editDevice,
            initialValues: {
              campaign: _get(currentCampaign, ['id'], null),
            },
          }}
        >
          <div className="modal-body">
            <Select name="campaign" options={options} />
          </div>
          <div className="modal-footer">
            <ModalFormFooter
              handleClose={handleHide}
              confirmText={tr('Save changes')}
              isLoading={isLoading}
            />
          </div>
        </ReduxForm>
      )}
    </Modal>
  );
};

export default React.memo(EditDeviceCampaignModal);
