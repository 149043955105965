import React from 'react';
import PropTypes from 'prop-types';

import tr from '../../../../utils/translate';
import { HeaderProfileIcon } from '../../../../components/Icons';
import SimpleButton from '../../../../components/SimpleButton';
import { ROLE_SUPERADMIN } from '../../../../constants/roles';
import './styles.scss';

const AccountBrand = ({
  addFacebook, handleEdit, handleClickLogout, companyName, name, access_level,
}) => (
  <div className="card pd-10">
    <div className="account-brand mg-b-20 pd-b-3">
      <div className="mg-r-15">
        <div className="account-brand__logo">
          <span className="account-brand__logo-icon">
            <HeaderProfileIcon />
          </span>
        </div>
      </div>
      <div className="account-brand__info">
        <h3 className="account-brand__company-name">{access_level === ROLE_SUPERADMIN ? tr('Name') : tr('Company name')}</h3>
        <span className="account-brand__brand-name">{access_level === ROLE_SUPERADMIN ? name : companyName}</span>
      </div>
    </div>
    <div className="pd-t-5">
      <SimpleButton className="mg-r-20" onClick={handleEdit}>{tr('Edit account info')}</SimpleButton>
      <SimpleButton onClick={handleClickLogout}>{tr('Logout')}</SimpleButton>
    </div>
  </div>
);

AccountBrand.defaultProps = {
  companyName: null,
};

AccountBrand.propTypes = {
  handleEdit: PropTypes.func.isRequired,
  companyName: PropTypes.string,
};

export default AccountBrand;
