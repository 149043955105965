import { createAction } from 'redux-actions';
import _omit from 'lodash/omit';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _isPlainObject from 'lodash/isPlainObject';

import { API_CALL } from '../constants/api';

export const requestTypes = [
  'REQUEST',
  'SUCCESS',
  'FAILURE',
];

export const createActionType = (base, names, separator = '/') => names.reduce((res, item) => {
  const name = item.toUpperCase();

  return { ...res, [name]: `${base}${separator}${name}` };
}, { base });

export const createRequestTypes = (base, names, otherNames = []) => {
  const types = createActionType(base, names);

  const main = Object.keys(types).reduce((res, item) => ({
    ...res,
    [item]: createActionType(types[item], requestTypes),
  }), {});

  const other = createActionType(base, otherNames);

  return { ...main, ...other };
};

export const createActionRequest = (actions, method, path, options = {}, preserveNull = false) => {
  const { setData, setDataPath, setMeta, ...currentOptions } = {
    setData: (data) => data,
    setDataPath: () => null,
    setMeta: () => null,
    showErrors: true,
    format: 'json',
    ...options,
  };
  const getPath = (...data) => {
    const dataPath = setDataPath(...data);

    if (!dataPath) return path;

    return path.replace(/{([^{}]+)}/g, (_, option) => dataPath[option]);
  };
  const getData = (...data) => {
    const dataPath = setDataPath(...data);

    const currentData = setData(...data);
    const mainData = _isPlainObject(currentData)
      ? _omit(currentData, Object.keys(dataPath || []))
      : null;
    const clearData = mainData && (preserveNull ? mainData : _omitBy(mainData, _isNil));

    if (clearData && options.format === 'form') {
      const form = new FormData();

      Object.keys(clearData).forEach((name) => {
        if (clearData[name] instanceof Array) {
          clearData[name].forEach((value, index) => {
            form.append(`${name}[${index}]`, typeof value === 'object' ? JSON.stringify(value) : value);
          });
        } else if (typeof clearData[name] === 'object' && !(clearData[name] instanceof File)) {
          form.append(name, JSON.stringify(clearData[name]));
        } else {
          form.append(name, clearData[name]);
        }
      });

      return form;
    }

    return clearData;
  };

  return createAction(actions.REQUEST, (...data) => ({
    [API_CALL]: {
      actions,
      method,
      path: getPath(...data),
      data: getData(...data),
      meta: setMeta(...data),
      params: setDataPath(...data),
      options: currentOptions,
    },
  }));
};
