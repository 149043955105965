import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import useModal from '../../utils/useModal';
import { CAMPAIGN_MODAL } from '../../constants/modals';
import { DEVICES_ADD_ROUTE } from '../../constants/routes';
import createLoadingSelector from '../../selectors/loadingSelector';
import { getDashboard } from './services/actions';
import DASHBOARD from './services/types';

const selector = createSelector(
  createLoadingSelector(DASHBOARD.READ_DASHBOARD.base),
  (state) => state.dashboard,
  (state) => state.brands.activeBrand,
  (state) => state.departments.activeDepartment,
  (state) => state.account.accountDetails.access_level,
  (isLoading, info, activeBrand, activeDepartment, access_level) => ({
    isLoading,
    info,
    activeBrand,
    activeDepartment,
    access_level,
  }),
);

const useDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const goToAddDevice = useCallback(() => {
    history.push(DEVICES_ADD_ROUTE);
  }, [history]);
  const data = useSelector(selector);

  const { showModal: newCampaign } = useModal(CAMPAIGN_MODAL);

  const getDashboardData = useCallback(() => {
    dispatch(getDashboard({
      ...(data.activeBrand && { company: data.activeBrand }),
      ...(data.activeDepartment && { department: data.activeDepartment }),
    }));
  }, [dispatch, data.activeBrand, data.activeDepartment]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  return {
    data,
    actions: {
      goToAddDevice,
      newCampaign,
      getDashboardData,
    },
  };
};

export default useDashboard;
