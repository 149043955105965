import React from 'react';
import PropTypes from 'prop-types';
import tr from '../../../../utils/translate';
import SuggestOtherModal from '../SuggestOtherModal';
import Button from '../../../../components/Button';
import ReduxForm from '../../../../components/ReduxForm';
import Input from '../../../../components/Input';
import { BackarrowIcon } from '../../../../components/Icons';
import useStatisticsInfo from './hooks';
import './styles.scss';

const statNames = {
  samples_per_period: 'Products distributed per day',
  samples: 'Products distributed',
  bounce_rate: 'Bounce rate',
  engagement_time: 'Engagement time',
  samples_by_title: 'Most popular products',
  social_media: 'Social media',
  question_answers: 'Questions and answers',
  click_through_rate: 'Click through rate',
  samples_per_time_of_day: 'Time of day',
  user_interactions: 'User Interactions',
  predicted_demographics: 'Predicted demographics',
  email_statistics: 'Email statistics',
};

const StatisticsInfo = ({ data: { id } }) => {
  const {
    data: {
      isLoadingSubmit,
      campaign,
    },
    actions: {
      goToBack,
      setStatistics,
      showSuggestOther,
    },
  } = useStatisticsInfo(id);


  return (
    <>
      <ReduxForm
        enableReinitialize
        form="COMPAIGN_STATISTICS_FORM"
        formData={{
          onSubmit: setStatistics,
          initialValues: {
            stats_visibility: campaign.stats_visibility,
          },
        }}
      >
        <div className="campaign-statistics-info-form pd-x-25 pd-y-50">
          <h3 className="cadf-title mg-b-40">{tr('Data')}</h3>
          <div className="statistic-description">Through our experiences, we collect lots of valuable customer data. Please select the key data points that you wish to obtain</div>
          <div className="checkbox-wrapper">
            {Object.keys(campaign.stats_visibility).map((key) => (
              <Input
                type="checkbox"
                key={key}
                id={`stats_visibility.${key}`}
                name={`stats_visibility.${key}`}
                appendFieldLabel={tr(statNames[key])}
                className="pd-b-15"
                tooltip={key === 'email_statistics' ? 'Clicked, opened, delivered, unsubscribed etc...' : null}
              />
            ))}
            <div className="pd-b-15 suggest-other-wrapper">
              <Button color="light" onClick={showSuggestOther}>
                {tr('Suggest other')}
              </Button>
            </div>


          </div>
          <Input type="hidden" name="custom-validation" />
        </div>
        <div className="modal-footer">
          <Button color="light" className="mg-r-30" onClick={goToBack}>
            <BackarrowIcon />
            {tr('Back')}
          </Button>
          <Button
            type="submit"
            disabled={isLoadingSubmit}
          >
            {tr('Next')}
          </Button>
        </div>
      </ReduxForm>
      <SuggestOtherModal campaignId={campaign.id} category="metric" />
    </>
  );
};

StatisticsInfo.defaultProps = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }),
};

StatisticsInfo.defaultProps = {
  data: {},
};

export default StatisticsInfo;
