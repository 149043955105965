import React from 'react';
import { Link } from 'react-router-dom';

import { SIGN_IN_ROUTE } from '../../../../constants/routes';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import FormText from '../common/FormText';
import validate from './validate';
import ReduxForm from '../../../../components/ReduxForm';
import { AUTH } from '../../../../constants/forms';
import tr from '../../../../utils/translate';
import FormWrap from '../common/FormWrap';
import useSignUp from './hooks';

const SignUp = () => {
  const { actions: { createAccount }, data: { isLoading } } = useSignUp();

  return (
    <FormWrap title={tr('Registration')} subtitle={tr('Get started with your Odore account')}>
      <ReduxForm
        form={AUTH.SIGN_UP_FORM}
        validate={validate}
        formData={{
          onSubmit: createAccount,
        }}
      >
        <Input
          name="company_name"
          prependFieldLabel={tr('Company name')}
          className="pd-b-15"
        />

        <Input
          name="first_name"
          prependFieldLabel={tr('First name')}
          className="pd-b-15"
        />

        <Input
          name="last_name"
          prependFieldLabel={tr('Last name')}
          className="pd-b-15"
        />

        <Input
          name="email"
          prependFieldLabel={tr('Email address')}
          className="pd-b-15"
        />

        <Input
          name="password"
          prependFieldLabel={tr('Password')}
          type="password"
          className="pd-b-15"
        />

        <Input
          name="password_confirm"
          prependFieldLabel={tr('Confirm password')}
          type="password"
          className="pd-b-15"
        />

        <div className="pd-t-4 mg-b-30">
          <Button
            type="submit"
            className="btn-block"
            status={isLoading ? 'disabled' : 'active'}
            disabled={isLoading}
          >
            {tr('Request access')}
          </Button>
        </div>

        <div className="text-center">
          <FormText>
            {tr('Already have an account?')}
            &nbsp;
            <Link to={SIGN_IN_ROUTE}>{tr('Sign In')}</Link>
          </FormText>
        </div>
      </ReduxForm>
    </FormWrap>
  );
};

export default SignUp;
