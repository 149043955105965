import React, { useCallback } from 'react';
import {
  reduxForm,
  propTypes as propTypesReduxForm,
  // formValues,
} from 'redux-form';

import Modal from '../../../../components/Modal';
import { DELETE_MODAL } from '../../../../constants/modals';
import tr from '../../../../utils/translate';
// import ModalFormFooter from '../../../../components/ModalFormFooter';
import Button from '../../../../components/Button';
import useDeleteModal from './hooks';
import './styles.scss';

const onSubmit = (values) => {
  console.log(values);
};

const DeleteModal = ({
  handleSubmit,
  pollSessions,
  campaign_id,
  deviceId,
  handleCancel,
  getNextPage,
}) => {
  const {
    actions: {
      hideModal,
      deleteHandler,
      deleteDeviceHandler,
    },
  } = useDeleteModal(/*pollSessions, campaign_id*/);

  const handleClick = () => {
    if (deviceId) {
      deleteDeviceHandler(pollSessions, deviceId, getNextPage);
    } else {
      deleteHandler(pollSessions, campaign_id, getNextPage);
    }
  };

  return (
    <Modal
      size="md"
      name={DELETE_MODAL}
      title={tr('DELETE_MODAL')}
    >

      <form onSubmit={handleSubmit(pollSessions)}>

        <div className="modal-body">
          <h2 onClick={() => console.log(pollSessions)}>Are you sure you want to delete this session(s)?</h2>
        </div>

        <div className="modal-footer">

          <Button onClick={() => { handleCancel(); hideModal(); }}>
                        No
          </Button>

          <Button onClick={handleClick}>
                        Yes
          </Button>
        </div>
      </form>
    </Modal>
  );
};


DeleteModal.propTypes = {
  ...propTypesReduxForm,
};

const reduxFormConfig = {
  form: 'DELETE_POLL_SESSIONS',
  onSubmit,
};


export default reduxForm(reduxFormConfig)(DeleteModal);
// export default DeleteModal;
