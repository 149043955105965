import EMPLOYEES from './types';
import { createActionRequest } from '../../../utils/actionHelpers';

export const getUsers = createActionRequest(
  EMPLOYEES.READ_USERS_LIST,
  'get',
  '/bo/brandusers/',
  {
    setData: (params) => (params),
  },
);

export const changeUserRole = createActionRequest(
  EMPLOYEES.CHANGE_ROLE,
  'put',
  '/bo/brandusers/{id}/change_access/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
);

export const activateUser = createActionRequest(
  EMPLOYEES.ACTIVATE_EMPLOYEE,
  'get',
  '/bo/brandusers/{id}/activate/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const deactivateUser = createActionRequest(
  EMPLOYEES.DEACTIVATE_EMPLOYEE,
  'get',
  '/bo/brandusers/{id}/deactivate/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const getUserDevices = createActionRequest(
  EMPLOYEES.USER_DEVICES,
  'get',
  '/bo/brandusers/{id}/devices_apply/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const setUserDevices = createActionRequest(
  EMPLOYEES.SET_USER_DEVICES,
  'put',
  '/bo/brandusers/{id}/devices_apply_edit/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
);


export const inviteUser = createActionRequest(EMPLOYEES.INVITE_USER, 'post', '/bo/invitations/');
