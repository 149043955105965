import {
  takeEvery,
  put,
  call,
} from 'redux-saga/effects';
import { show, hide } from 'redux-modal';
import { toast } from 'react-toastify';
import _get from 'lodash/get';

import history from '../../../utils/history';
import { DEVICES_ROUTE } from '../../../constants/routes';
import {
  EDIT_DEVICE_MODAL,
  ADD_DEVICE_MODAL,
  DEVICE_EDIT_TRAY_MODAL,
  DEVICE_EDIT_CAMPAIGN_MODAL,
  REBOOT_DEVICE_MODAL,
} from '../../../constants/modals';
import {
  getDeviceInfo, getDeviceUntracked, getDeviceStatistic, getDeviceCurrentCampaign,
} from './actions';
import { getDeviceCampaignDetails } from '../../campaigns/services/actions';
import DEVICES from './types';
import tr from '../../../utils/translate';

function* runAddDeviceSuccess({ payload: { req, res } }) {
  if (!req.data.pincode && res.status === 'inactive') {
    yield put(show(ADD_DEVICE_MODAL, { data: { ...req.data, serial_number: req.params.id } }));
  } else if (req.data.pincode && res.status === 'activated') {
    yield call(history.push, DEVICES_ROUTE);
  }
}

function* openDeviceInfo({ payload: id }) {
  yield put(getDeviceInfo(id));
}

function* runEditTraySuccess() {
  yield put(hide(DEVICE_EDIT_TRAY_MODAL));
}

function* runEditDeviceSuccess(action) {
  yield put(hide(EDIT_DEVICE_MODAL));
  const serial_number = _get(action, ['payload', 'res', 'serial_number'], null);
  yield put(getDeviceInfo(serial_number));
}

function* runUpdateEmailReceiptSuccess() {
  yield call(toast.success, tr('Email receipt updated'));
}

function* runCreateEmailReceiptSucces() {
  yield call(toast.success, tr('Email receipt uploaded'));
}

function* runUpdateLayoutSuccess() {
  yield call(toast.success, tr('Layout updated'));
}

function* runCreateLayoutSuccess() {
  yield call(toast.success, tr('Layout uploaded'));
}

function* runSetDeviceCampaignSuccess(action) {
  const serial_number = _get(action, ['payload', 'res', 'serial_number'], null);

  yield put(hide(DEVICE_EDIT_CAMPAIGN_MODAL));
  yield put(getDeviceUntracked(serial_number));
}

function* runGetDeviceInfoSuccess(action) {
  /*
    for now we dont have related to the current campaign info about trays, layout and, email_receipt
    get it with additional request
  */
  const currentCampaignId = _get(action, ['payload', 'res', 'campaign', 'id'], null);
  if (currentCampaignId) {
    yield put(getDeviceCampaignDetails(currentCampaignId));
  }
}

function* runRebootDeviceSuccess() {
  yield put(hide(REBOOT_DEVICE_MODAL));
  yield call(toast.success, tr('Device is rebooted'));
}

function* runGetDeviceCampaigns({ payload: { req, res } }) {
  if (!res.campaigns || res.campaigns.length === 0) {
    yield call(toast.warn, tr('No statistics available for device'));
  } else {
    const { serial_number, period } = req.params.params;
    const campaign_id = res.campaigns[0].id;
    yield put(getDeviceStatistic({
      campaign_id,
      serial_number,
      period,
    }));


    yield put(getDeviceCurrentCampaign(campaign_id));
  }
}

export default function* devices() {
  yield takeEvery(DEVICES.PAGE_DEVICE_INFO, openDeviceInfo);
  yield takeEvery(DEVICES.SET_DIVECE_INFO.SUCCESS, runEditDeviceSuccess);
  yield takeEvery(DEVICES.ADD_NEW_DEVICE.SUCCESS, runAddDeviceSuccess);
  yield takeEvery(DEVICES.UPDATE_DIVICE_TRAY.SUCCESS, runEditTraySuccess);
  yield takeEvery(DEVICES.CREATE_EMAIL_RECEIPT.SUCCESS, runCreateEmailReceiptSucces);
  yield takeEvery(DEVICES.UPDATE_EMAIL_RECEIPT.SUCCESS, runUpdateEmailReceiptSuccess);
  yield takeEvery(DEVICES.UPDATE_LAYOUT.SUCCESS, runUpdateLayoutSuccess);
  yield takeEvery(DEVICES.CREATE_LAYOUT.SUCCESS, runCreateLayoutSuccess);
  yield takeEvery(DEVICES.SET_DEVICE_CAMPAIGN.SUCCESS, runSetDeviceCampaignSuccess);
  yield takeEvery(DEVICES.GET_DIVECE_INFO.SUCCESS, runGetDeviceInfoSuccess);
  yield takeEvery(DEVICES.REBOOT_DEVICE.SUCCESS, runRebootDeviceSuccess);
  yield takeEvery(DEVICES.GET_DEVICE_CAMPAIGNS.SUCCESS, runGetDeviceCampaigns);
}
