import React, { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router';
import Loader from '../../components/Loader';
import { HOME_ROUTE } from '../../constants/routes';
import { addFacebookAccount } from '../account/services/actions';

const FacebookAuth = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { search } = useLocation();

  const code = useMemo(() => {
    const params = new URLSearchParams(search);

    return params.get('code');
  }, [search]);

  useEffect(() => {
	  if (code) {
		  dispatch(addFacebookAccount({ code }));
	  }
  }, [history, code, dispatch]);

  if (!code) {
    return (
      <Redirect
        to={HOME_ROUTE}
      />
    );
  }

  return (
    <Loader />
  );
};

export default FacebookAuth;
