import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import { RebootDeviceIcon } from '../../../../components/Icons';
import { REBOOT_DEVICE_MODAL } from '../../../../constants/modals';
import tr from '../../../../utils/translate';
import './styles.scss';

const RebootDeviceModal = ({
  handleRebootDevice,
  isLoading,
  handleClose,
  deviceNumber,
}) => (
  <Modal title={tr('Reboot')} name={REBOOT_DEVICE_MODAL}>
    <div className="modal-body">
      <div className="reboot-modal-body">
        <div className="reboot-modal-body__icon-wrap">
          <RebootDeviceIcon />
        </div>

        <div className="reboot-modal-body__text-wrap">
          {`${tr('Do you want to reboot the Device #')}${deviceNumber}?`}
        </div>
      </div>
    </div>
    <div className="modal-footer">
      <div className="reboot-modal-footer">
        <div className="reboot-modal-footer__cancel-col">
          <Button color="light" onClick={handleClose}>{tr('Cancel')}</Button>
        </div>
        <div className="reboot-modal-footer__submit-col">
          <Button
            onClick={handleRebootDevice}
            className="btn-block"
            status={isLoading ? 'disabled' : 'active'}
            disabled={isLoading}
          >
            {tr('Yes, reboot')}
          </Button>
        </div>
      </div>
    </div>
  </Modal>
);

RebootDeviceModal.propTypes = {
  handleRebootDevice: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  deviceNumber: PropTypes.string.isRequired,
};

export default RebootDeviceModal;
