import React, { useEffect } from 'react';
import Tooltip from 'react-tooltip';
import Select from 'react-select';
import tr from '../../../../utils/translate';
import Table from '../../../../components/Table';
import CampaignModal from '../CampaignModal';
import DeleteModal from '../DeleteModal';
import SuccessCreateModal from '../SuccessCreateModal';
import createColumns from './columns';
import useCampaignsTable from './hooks';
import {
  DevicesIcon, SocialTargetingIcon, MailIcon, WebsiteIcon,
} from '../../../../components/Icons';
import {
  DEVICE_CAMPAIGN, ONLINE_EMAIL_CAMPAIGN, ONLINE_EMBEDED_CAMPAIGN, ONLINE_SOCIAL_CAMPAIGN,
} from '../../../../constants/campaigns';
import './styles.scss';

const statusOptions = [
  { value: 'all', label: tr('All') },
  { value: 'created,approved', label: tr('Created/Approved') },
  { value: 'awaiting_approval', label: tr('Awating approval') },
  { value: 'partly_created,rejected', label: tr('Rejected/Partly created') },
];

const deleteCampaignsOptions = [
  { value: 'selected_campaigns', label: tr('Delete campaign(s)') },
  { value: 'selected_campaigns_poll_sessions', label: tr('Delete campaign(s) poll session(s)') },
];

const CampaignsTable = () => {
  const {
    data: {
      isLoading,
      campaigns,
      access_level,
      pagination,
    },
    actions: {
      showModal,
      changeSorting,
      setStatusFilter,
      getIdsToDelete,
      getAllCampaigns,
      showDeleteModal
    },
    activeTab,
    setActiveTab,
    filteredCampaigns,
    sortBy,
    deleteIds
  } = useCampaignsTable();

  useEffect(() => {
    Tooltip.rebuild();
  }, [filteredCampaigns]);


  const tableHeader = (
    <div className="side-options-wrapper">
      <Select
        options={statusOptions}
        classNamePrefix="status-filter"
        className="status-filter-wrapper"
        defaultValue={statusOptions[0]}
        onChange={(value) => setStatusFilter(value.value)}
      />
    </div>
  );

  const tabs = [
    { key: 'all', name: tr('All'), type: 'all' },
    {
      key: 'device',
      name: <div data-tip={tr('Device campaign')}><DevicesIcon /></div>,
      type: DEVICE_CAMPAIGN,
    },
    {
      key: 'social',
      name: <div data-tip={tr('Social media targeting')}><SocialTargetingIcon /></div>,
      type: ONLINE_SOCIAL_CAMPAIGN,
    },
    {
      key: 'website',
      name: <div data-tip={tr('Website embedding')}><WebsiteIcon /></div>,
      type: ONLINE_EMBEDED_CAMPAIGN,
    },
    {
      key: 'email',
      name: <div data-tip={tr('Email targeting')}><MailIcon /></div>,
      type: ONLINE_EMAIL_CAMPAIGN,
    },
  ];

  const handleSelectTab = (index, { name }) => {
    setActiveTab(name);
  };

  const columns = createColumns(showModal, access_level, changeSorting, sortBy);
  return (
    <>
      <Table
        caption={tr('Campaigns')}
        classNameWrapper="campaigns-list-table"
        tabs={tabs}
        columns={columns}
        data={{ [activeTab]: campaigns }}
        onSelectTab={handleSelectTab}
        loading={isLoading}
        customHeaderAdd={tableHeader}
        pagination={{...pagination, getNextPage: getAllCampaigns }}
      />
      <CampaignModal />
      <SuccessCreateModal />
      <DeleteModal deleteIds={deleteIds} />
      <Tooltip aria-haspopup="true" />
    </>
  );
};

export default CampaignsTable;
