import {
  useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import _find from 'lodash/find';

import CAMPAIGNS from '../../services/types';
import {
  prevStep,
  getCompanyProducts,
  getDeviceCampaignDetails,
  closeModal, getAdCampaign
} from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';

const productsLoading = createLoadingSelector(CAMPAIGNS.GET_PRODUCTS.base);
const loadingSelector = createLoadingSelector([
  CAMPAIGNS.READ_DETAILS.base,
  CAMPAIGNS.CREATED_CAMPAIGN.base,
]);
const createCampaignSelector = (id) => createSelector(
  loadingSelector,
  productsLoading,
  (state) => state.campaigns.details[id],
  (state) => state.campaigns.products,
  (state) => state.campaigns.social_networks,
  (state) => state.campaigns.ad_campaigns,
  (isLoading, isLoadingProducts, campaign, products, social_networks, ad_campaigns) => ({
    isLoading,
    isLoadingProducts,
    campaign,
    products,
    social_networks,
    ad_campaign: ad_campaigns[campaign.ad_campaign?.id]
  }),
);

const usePreview = (id, isNew) => {
  const dispatch = useDispatch();

  const selector = createCampaignSelector(id);

  const data = useSelector(selector);

  const goToBack = useCallback(() => {
    dispatch(prevStep());
  }, [dispatch]);

  const submitHandler = useCallback(() => {
    dispatch(closeModal(id, { isNew }));
  }, [dispatch, id, isNew]);

  const campaignProducts = useMemo(() => {
    const list = [];

    if (data.campaign && data.products) {
      data.campaign.trays.forEach((item) => {
        const product = _find(data.products.results, ['id', item.product]);

        product.product_stock = item.product_stock;

        list.push(product);
      });
    }

    return list;
  }, [data.campaign, data.products]);

  useEffect(() => {
    if (id && !data.campaign) {
      dispatch(getDeviceCampaignDetails(id));
    }
  }, [data.campaign, dispatch, id]);

  useEffect(() => {
    if (id && !campaignProducts.length) {
      dispatch(getCompanyProducts(id));
    }
  }, [campaignProducts.length, dispatch, id]);

  useEffect(() => {
    const adCampaignId = data.campaign.ad_campaign?.id;

    if(adCampaignId && !data.ad_campaign) {
      dispatch(getAdCampaign({ id: adCampaignId }));
    }
  }, [dispatch, data]);

  return {
    data,
    campaignProducts,
    actions: {
      goToBack,
      submitHandler,
    },
  };
};

export default usePreview;