import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Tooltip from 'react-tooltip';
import tr from '../../../utils/translate';
import './styles.scss';

const BarChart = ({ data, size, className, width }) => {
  if (!data || data.length === 0) {
    return (
      <div className="question-bar-chart-wrapper">
        <h3>{tr('No data')}</h3>
      </div>
    );
  }

  return (
    <>
      <div className={cn('question-bar-chart-wrapper', `qcw-${size}`, className)} style={{ width: `${width}%` }}>
        {data.some((item) => item.prepend) && (
          <div className="qci-col qci-col-prepend">
            {data.map((item, index) => (<span className="qci-prepend" key={index}>{item.prepend}</span>))}
          </div>
        )}
        <div className="qci-col qci-col-bar">
          {data.map((item, index) => (
            <div className="qci-bar-wrapper">
              {item.bar.title && <div className="qci-bar-title">{item.bar.title}</div>}
              <div className="qci-bar" key={index} data-tip={item.bar.tooltip}>
                {!!(item.bar && item.bar.prepend) && <span className="qci-bar-prepend">{item.bar.prepend}</span>}
                <div className="qci-bar-progress" style={{ width: `${item.value}%` }}>{item.bar && item.bar.text}</div>
                {!!(item.bar && item.bar.append) && <span className="qci-bar-append">{item.bar.append}</span>}
              </div>
            </div>
          ))}
        </div>
        {data.some((item) => item.append) && (
          <div className="qci-col qci-col-append">
            {data.map((item, index) => (<span className="qci-append" key={index}>{item.append}</span>))}
          </div>
        )}
      </div>
      <Tooltip />
    </>
  );
};

BarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      prepend: PropTypes.string,
      append: PropTypes.string,
      bar: PropTypes.shape({
        prepend: PropTypes.string,
        append: PropTypes.string,
      }),
    }).isRequired,
  ).isRequired,
  size: PropTypes.oneOf(['sm', 'md']),
  className: PropTypes.string,
};

BarChart.defaultProps = {
  size: 'sm',
  className: null,
};

export default BarChart;
