import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './style.scss';

const Tooltip = (props) => {
  const {
    label,
    children,
    className,
  } = props;
  const [isShow, setIsShow] = useState(false);

  return (
    <div className={cn('tooltip-wrapper', className)}>
      <div className={cn('tooltip-body', { 'tooltip-body_show': isShow })}>{label}</div>
      <div className="tooltip-control" onClick={() => setIsShow(!isShow)}>{children}</div>
    </div>
  );
};

Tooltip.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Tooltip.defaultProps = {
  className: null,
};

export default Tooltip;
