import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import _isEmpty from 'lodash/isEmpty';

import { getUsers, getLocations } from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import { USERS_SEARCH_MODAL, USERS_FILTER_MODAL } from '../../../../constants/modals';
import useModal from '../../../../utils/useModal';
import { getDeviceCampaigns } from '../../../campaigns/services/actions';
import CAMPAIGNS from '../../../campaigns/services/types';
import USERS from '../../services/types';
import { formatParams, setParams } from './helpers';

const selector = createSelector(
  createLoadingSelector(USERS.READ_USERS_LIST.base),
  (state) => state.users.users,
  (state) => state.brands.activeBrand,
  (state) => state.departments.activeDepartment,
  (state) => ({
    count: state.users.count,
    activePage: state.users.activePage,
  }),
  (isLoading, users, activeBrand, activeDepartment, pagination) => ({
    isLoading,
    users,
    activeBrand,
    activeDepartment,
    pagination,
  }),
);

export const useUsers = (location) => {
  const dispatch = useDispatch();
  const data = useSelector(selector);
  const [sortBy, setSortBy] = useState({});

  const changeSorting = (field) => {
    if (sortBy.key === field) {
      setSortBy({
        key: field,
        direction: sortBy.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSortBy({
        key: field,
        direction: 'asc',
      });
    }
  };

  const openPage = useCallback(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const ordering = sortBy && (sortBy.direction === 'desc' ? `-${sortBy.key}` : sortBy.key);

    if (_isEmpty(params)) {
      dispatch(getUsers({
        ordering,
        ...(data.activeBrand && { company: data.activeBrand }),
        ...(data.activeDepartment && { department: data.activeDepartment }),
      }));
    } else {
      dispatch(getUsers({
        ordering,
        ...params,
        ...{
          ...(data.activeBrand && { company: data.activeBrand }),
          ...(data.activeDepartment && { department: data.activeDepartment }),
        },
      }));
    }
  }, [location.search, sortBy, dispatch, data.activeBrand, data.activeDepartment]);

  const getNextPage = (page) => {
    dispatch(getUsers({
      ...(data.activeBrand && { company: data.activeBrand }),
      ...(data.activeDepartment && { department: data.activeDepartment }),
      page,
    }));
  }

  useEffect(() => {
    openPage();
  }, [openPage]);

  return {
    data,
    sortBy,
    actions: {
      changeSorting,
      getNextPage,
    },
  };
};

export const useUsersSearch = (location) => {
  const { showModal, hideModal } = useModal(USERS_SEARCH_MODAL);
  const history = useHistory();

  const hanleSearchSubmit = (values) => {
    hideModal();
    const currentParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    const params = formatParams(values);
    const url = setParams({ ...currentParams, ...params });
    history.push(`?${url}`);
  };

  return {
    actions: {
      showSearchModal: showModal,
      hideSearchModal: hideModal,
      hanleSearchSubmit,
    },
  };
};

const usersFilterSelector = createSelector(
  createLoadingSelector(CAMPAIGNS.READ_LIST.base),
  createLoadingSelector(USERS.READ_LOCATIONS_LIST.base),
  (state) => state.campaigns.list,
  (state) => state.users.locations,
  (state) => state.brands.activeBrand,
  (state) => state.departments.activeDepartment,
  (isLoadingCampaigns, isLoadingLocations, campaigns = [], locations = [], activeBrand, activeDepartment) => ({
    isLoadingCampaigns,
    isLoadingLocations,
    campaigns: [
      { value: '', label: '' },
      ...campaigns.map(({ id, name }) => ({ value: id, label: name })),
    ],
    locations: [
      { value: '', label: '' },
      ...locations.map((loc) => ({ value: loc, label: loc })),
    ],
    activeDepartment,
    activeBrand,
  }),
);

export const useUsersFilter = (location) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { showModal, hideModal } = useModal(USERS_FILTER_MODAL);
  const data = useSelector(usersFilterSelector);

  const openPage = useCallback(() => {
    dispatch(getDeviceCampaigns(
      {
        ...(data.activeBrand && { company: data.activeBrand }),
        ...(data.activeDepartment && { department: data.activeDepartment }),
      },
    ));
    dispatch(getLocations());
  }, [data.activeBrand, data.activeDepartment, dispatch]);

  useEffect(() => {
    openPage();
  }, [openPage]);

  const hanleFilterSubmit = (values) => {
    hideModal();

    const currentParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    const params = formatParams(values);
    const url = setParams({ ...currentParams, ...params });
    history.push(`?${url}`);
  };

  return {
    actions: {
      showFilterModal: showModal,
      hideFilterModal: hideModal,
      hanleFilterSubmit,
    },
    data,
  };
};
