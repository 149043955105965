import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';

import createLoadingSelector from '../../../../selectors/loadingSelector';
import { ADD_NEW_DEVICE_FORM } from '../../../../constants/forms';
import { addDevice } from '../../services/actions';
import DEVICES from '../../services/types';
import { filterDepartmentsMini } from '../../../department/services/actions';

const getDepartment = (state) => {
  const { filteredListMini, listMini } = state.departments;

  if (filteredListMini.length) {
    return filteredListMini;
  }

  return listMini;
};

const selector = createSelector(
  createLoadingSelector(DEVICES.ADD_NEW_DEVICE.base),
  getFormValues(ADD_NEW_DEVICE_FORM),
  (state) => state.account.accountDetails.access_level,
  (state) => state.brands.listMini,
  (state) => getDepartment(state),
  (state) => state.brands.activeBrand,
  (state) => state.departments.activeDepartment,
  (isLoading, formValues, access_level, brands, departments, activeBrand, activeDepartment) => ({
    isLoading,
    formValues,
    access_level,
    brands,
    departments,
    activeBrand,
    activeDepartment,
  }),
);

const useAddDevice = () => {
  const dispatch = useDispatch();
  const data = useSelector(selector);
  const [selectedBrand, setBrand] = useState(data.activeBrand);

  const addNewDevice = useCallback(() => {
    const { serial_number, ...fields } = data.formValues;
    dispatch(addDevice(serial_number, fields));
  }, [dispatch, data]);

  const handleBrandChange = (brand) => {
    setBrand(brand);
  };

  useEffect(() => {
    if(selectedBrand) {
      dispatch(filterDepartmentsMini({ company: selectedBrand }));
    };
  }, [selectedBrand, dispatch]);

  return {
    data,
    selectedBrand,
    actions: {
      addNewDevice,
      handleBrandChange,
    },
  };
};

export default useAddDevice;
