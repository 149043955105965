import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { DEVICES_ADD_ROUTE } from '../../../../constants/routes';

const useDevices = () => {
  const history = useHistory();

  const handleClickAddDevice = useCallback(() => {
    history.push(DEVICES_ADD_ROUTE);
  }, [history]);

  return {
    handleClickAddDevice,
  };
};

export default useDevices;
