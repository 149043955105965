import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { Field } from 'redux-form';
import tr from '../../../../utils/translate';
import Input from '../../../../components/Input';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import UploadFile from '../../../../components/UploadFile';
import ReduxForm from '../../../../components/ReduxForm';
import { BackarrowIcon, DocumentBigIcon } from '../../../../components/Icons';
import useCampaignDetailsForm from './hooks';
import GenderCheckboxes from '../GenderCheckboxes';
import InterestsField from '../InterestsField';
import validate from './validate';
import './styles.scss';
import Tabs from '../../../../components/Tabs';
import AgeMultiselect from '../AgeMultiselect';

const required = (value) => (value ? undefined : 'Required');

// const delimterOptions = [
//   {label: ',', value: 1},
//   {label: ';', value: 2},
//   {label: '|', value: 3}
// ]

const DetailsForm = ({ data: { id, isNew } }) => {
  const {
    data: {
      isLoadingData,
      isLoadingSubmit,
      campaign,
      step,
      users,
      selected_users,
    },
    actions: {
      goToPrevStep,
      editCampaign,
      handleFormChange,
      goToNextStep,
    },
  } = useCampaignDetailsForm(id);
  const [type, setType] = useState(isNew ? 'database' : 'csv');

  if ((id && !campaign) || isLoadingData) return <Loader size="md" />;

  const CopyPasteBlock = (
    <div className="mg-b-30">
      <h4 className="cdf-subtitle mg-b-10">
        {tr('Paste contact info')}
      </h4>
      <Input
        name="contact_info"
        rows={6}
        type="textarea"
        validate={[required]}
      />
    </div>
  );

  const databaseFilterBlock = (
    <>
      <div className="mg-b-30">
        <h4 className="cdf-subtitle mg-b-10">
          {tr('Please select demographic criteria to interact with the client.')}
        </h4>
        <div className="age-gender-selection">
          <Field
            name="age"
            component={AgeMultiselect}
          />
          <Field
            name="gender"
            component={GenderCheckboxes}
          />
        </div>
      </div>
      <div className="mg-b-30">
        <h4 className="cdf-subtitle mg-b-10">
          {tr('Please type interests or behaviors about your target customer.')}
        </h4>
        <div className="row">
          <Field
            name="interests"
            component={InterestsField}
          />

        </div>
      </div>
      <div className="mg-b-30 database-info-wrapper">
        <p className="database_info pd-r-30">
          {tr('Total clients: ')}
          <span className="database_count">{users.length || '0'}</span>
        </p>
        <p className="database_info">
          {tr('Selected: ')}
          <span className="databbase_count_selected">{selected_users || '0'}</span>
        </p>
      </div>
    </>
  );

  const csvFileBlock = (
    <div className="campaign-csv-upload">
      {/* <Select
        fieldLabel={tr('Delimeter')}
        className="pd-b-30"
        name="delimeter"
        options={delimterOptions}
        validate={[required]}
      /> */}
      <UploadFile
        label="CSV format. 5 Mb size"
        accept=".csv"
        name="file"
        color="blue"
        maxSize={5242880}
        data={_get(campaign, 'direct_mail.email_list_file') ? { name: _get(campaign, 'direct_mail.email_list_file') } : {}}
        icon={<DocumentBigIcon />}
        validate={[required]}
        hideDeleteButton
      />
    </div>
  );

  const tabs = [
    { key: 'database', name: tr('Odore database'), block: databaseFilterBlock },
    { key: 'copypaste', name: tr('Copy-Paste text'), block: CopyPasteBlock },
    { key: 'csv', name: tr('Upload csv'), block: csvFileBlock },
  ];

  return (
    <ReduxForm
      form="CAMPAIGN_AUDIENCE_FORM"
      validate={validate}
      formData={{
        onSubmit: (data) => editCampaign({...data, type }),
        initialValues: {
          type: isNew ? 'database' : 'csv',
          gender: 3,
          delimeter_type: 1,
          age: [],
          contact_info: _get(campaign, 'direct_mail.email_list_text') ? campaign.direct_mail.email_list_text : 'email,gender,age,first_name,last_name',
          fileUrl: _get(campaign, 'direct_mail.email_list_file'),
        },
        onChange: handleFormChange,
      }}
    >
      <div className="compaign-details-form campaign-csv-form pd-x-25 pd-y-50">
        <h3 className="cdf-title mg-b-40">{tr('Audience targeting')}</h3>
        <Tabs
          onChange={(tabIndex) => {
            setType(tabs[tabIndex].key);
          }}
          activeTabIndex={tabs.findIndex(({ key }) => key === type)}
        >
          {tabs.map((tab, index) => (
            <Tabs.Pane
              key={index}
              name={tab.key}
              label={tab.name}
            >
              {tab.block}
            </Tabs.Pane>
          ))}
        </Tabs>
        {campaign && campaign.direct_mail && !!campaign.direct_mail.email_list_count
          && <div className="users-list-warning pd-t-30">{`Currently there are ${campaign.direct_mail.email_list_count} people in the list of users for the email service. Press SKIP if don't want to change it`}</div>}
      </div>
      <div className="modal-footer">
        <Button color="light" className="mg-r-30" onClick={goToPrevStep}>
          <BackarrowIcon />
          {tr('Back')}
        </Button>

        <Button color="light" className="mg-r-30" onClick={goToNextStep}>
          {tr('Skip')}
        </Button>

        <Button
          type="submit"
          disabled={isLoadingSubmit}
        >
          {tr('Next')}
        </Button>
      </div>
    </ReduxForm>
  );
};

DetailsForm.defaultProps = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }),
};

DetailsForm.defaultProps = {
  data: {},
};

export default DetailsForm;
