import React from 'react';
import PropTypes from 'prop-types';

import ReduxForm from '../../../../components/ReduxForm';
import Input from '../../../../components/Input';
import ModalFormFooter from '../../../../components/ModalFormFooter';
import Modal from '../../../../components/Modal';
import { UPDATE_PRODUCT_STOCK } from '../../../../constants/modals';
import tr from '../../../../utils/translate';
import validate from './validate';
import useEditProductStock from './hooks';

// const allowOnlyNumbers = (event) => {
//   if ([69, 187, 188, 189, 190].includes(event.keyCode)) {
//     event.preventDefault();
//   }
// };

const AddProductsModal = ({ campaignId }) => {
  const { actions, data: { isLoading } } = useEditProductStock(campaignId);
  return (
    <Modal name={UPDATE_PRODUCT_STOCK} size="sm" title={tr('Add products')}>
      {({ handleHide, ...initialValues }) => (
        <ReduxForm
          enableReinitialize
          form="EDIT_TRAY_FORM"
          validate={validate}
          formData={{
            onSubmit: (fields) => actions.submit(initialValues.id, fields),
          }}
        >
          <div className="modal-body">
            <div className="row">
              <div className="col col-12 pd-b-6">
                <Input
                  type="number"
                  name="quantity"
                  prependFieldLabel={tr('Number of products to add')}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <ModalFormFooter
              handleClose={handleHide}
              confirmText={tr('Add')}
              isLoading={isLoading}
            />
          </div>
        </ReduxForm>
      )}
    </Modal>
  );
};

AddProductsModal.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

export default AddProductsModal;
