import { createAction } from 'redux-actions';
import BRAND from './types';
import { createActionRequest } from '../../../utils/actionHelpers';

export const inviteBrand = createActionRequest(BRAND.INVITE_BRAND, 'post', '/bo/invite-company/');

export const activateBrand = createActionRequest(
  BRAND.ACTIVATE_BRAND,
  'get',
  '/bo/companies/{id}/activate/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const deactivateBrand = createActionRequest(
  BRAND.DEACTIVATE_BRAND,
  'get',
  '/bo/companies/{id}/deactivate/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const editBrand = createActionRequest(
  BRAND.EDIT_BRAND,
  'patch',
  '/bo/companies/{id}/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
);

export const getBrands = createActionRequest(
  BRAND.GET_BRANDS,
  'get',
  '/bo/companies/',
);

export const getBrandsMini = createActionRequest(
  BRAND.GET_BRANDS_MINI,
  'get',
  '/bo/companies/all-mini/',
);


export const setActiveBrand = createAction(BRAND.SET_ACTIVE_BRAND);
