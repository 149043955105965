import React from 'react';
import Modal from '../../../../components/Modal';
import { CAMPAIGN_FOLLOW_UP_PREVIEW_MODAL } from '../../../../constants/modals';
import tr from '../../../../utils/translate';
import './styles.scss';

const FollowUpsPreviewModal = () => (
  <Modal name={CAMPAIGN_FOLLOW_UP_PREVIEW_MODAL} size="xl" title={tr('Follow up preview')}>
    {({ preview_url }) => (
      // eslint-disable-next-line jsx-a11y/iframe-has-title
      <iframe src={preview_url} frameBorder="0" className="follow-ups-preview" />
    )}
  </Modal>
);

export default FollowUpsPreviewModal;
