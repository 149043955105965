import { createRequestTypes } from '../../../utils/actionHelpers';

export default createRequestTypes('DEPARTMENT',
  [
    'GET_DEPARTMENTS',
    'GET_DEPARTMENT',
    'GET_DEPARTMENTS_MINI',
    'FILTER_DEPARTMENTS_MINI',
    'CREATE_DEPARTMENT',
    'EDIT_DEPARTMENT',
    'INVITE_USER',
    'ACTIVATE_DEPARTMENT',
    'DEACTIVATE_DEPARTMENT',
    'INVITE_MEMBER_GET_DEPARTMENTS_MINI',
  ],
  [
    'SET_ACTIVE_DEPARTMENT',
  ]);
