import React from 'react';

import Button from '../../components/Button';
import PageHeader from '../../components/PageHeader';
import tr from '../../utils/translate';
import DepartmentModal from './DepartmentModal';
import InviteUserModal from './InviteUserModal';
import DepartmentsTable from './DepartmentTable';
import DeactivateModal from './DeactivateModal';
import useDepartments from './hooks';
import './styles.scss';

const BrandsPage = () => {
  const { actions } = useDepartments();

  const headerButtons = (
    <div>
      <Button onClick={actions.addDepartmentModal}>{tr('Create team')}</Button>
    </div>
  );

  return (
    <div className="page-inner pd-t-25 pd-b-25">
      <PageHeader title={tr('Teams')}>
        {headerButtons}
      </PageHeader>

      <div className="row">
        <div className="col-md-12 departments-list">
          <DepartmentsTable />
        </div>
      </div>
      <DepartmentModal />
      <InviteUserModal />
      <DeactivateModal />
    </div>
  );
};

export default BrandsPage;
