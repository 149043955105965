export const getArraySum = (arrayData) => {
  // eslint-disable-next-line no-param-reassign, no-return-assign
  const sumReducer = (accumulator, key) => accumulator += arrayData[key];
  const dataSum = Object.keys(arrayData).reduce(sumReducer, 0);
  return dataSum;
};

export const generateSelect = (array, labelField, initialValue = '', initialLabel = '') => {
  const initialOption = [{ label: initialLabel, value: initialValue }];
  const labels = array.map((data) => ({ label: data[labelField], value: Number(data.id) }));
  return [...initialOption, ...labels];
};
