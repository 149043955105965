export const DEVICE_CAMPAIGN = 1;
export const ONLINE_CAMPAIGN = 'online';
export const ONLINE_EMAIL_CAMPAIGN = 3;
export const ONLINE_SOCIAL_CAMPAIGN = 2;
export const ONLINE_EMBEDED_CAMPAIGN = 4;

export const genders = {
  1: 'Male',
  2: 'Female',
  3: 'All',
};

export const CAMPAIGN_PARTLY_CREATED = 'partly_created';
export const CAMPAIGN_APPROVED = 'approved';
export const CAMPAIGN_REJECTED = 'rejected';
export const CAMPAIGN_CREATED = 'created';
export const CAMPAIGN_AWAITING_APPROVAL = 'awaiting_approval';
