import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import createLoadingSelector from '../../../../selectors/loadingSelector';
import { editProductAction, createProductAction } from '../../services/actions';
import CAMPAIGN from '../../services/types';

const useEditTray = (id) => {
  const dispatch = useDispatch();
  const editTray = useCallback((number, data) => {
    if (number !== undefined) {
      dispatch(editProductAction(number, data));
    } else {
      dispatch(createProductAction(data));
    }
  }, [dispatch]);
  const submit = useCallback((data) => {
    const {
      id: productId,
      file: files,
      ...otherData
    } = data;
    const [file] = files instanceof Array ? files : [];
    editTray(
      productId,
      {
        ...otherData,
        productId,
        file,
      },
    );
  }, [editTray]);
  const isLoading = useSelector(createLoadingSelector(CAMPAIGN.UPDATE_TRAY.base));

  return {
    data: {
      isLoading,
    },
    actions: {
      submit,
    },
  };
};

export default useEditTray;
