import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Field } from 'redux-form';
import './styles.scss';

const Input = (props) => {
  const {
    append,
    prepend,
    size,
    className,
    classNameBody,
    classNameControl,
    input,
    meta: { error, touched },
    type,
    placeholder,
    appendFieldLabel,
    prependFieldLabel,
    tooltip,
    autoComplete,
    ...otherProps
  } = props;

  const [showLabel, setShowLabel] = useState(!!input.value);

  return (
    <div className={cn('input-wrapper', className)}>
      {prependFieldLabel && (
        <div className="input-label-wrap">
          {(type === 'textarea' || showLabel) && <label className={cn({ 'text-input-group-label': type === 'text' })} htmlFor={input.name}>{prependFieldLabel}</label>}
        </div>
      )}
      <div className={cn('input-group', classNameBody)}>
        {prepend && (
          <div className="input-group-prepend">
            <span className="input-group-text">{prepend}</span>
          </div>
        )}
        {type === 'textarea' && (
          <textarea
            {...otherProps} // eslint-disable-line react/jsx-props-no-spreading
            {...input} // eslint-disable-line react/jsx-props-no-spreading
            className={cn('form-control', classNameControl, { 'is-invalid': touched && error }, size && `form-control-${size}`)}
            onChange={(event) => {
              event.target.style.height = 'auto';
              event.target.style.height = `${event.target.scrollHeight + 1}px `;
              return input.onChange(event.target.value);
            }}
          />
        )}

        {['checkbox', 'radio'].includes(type) && (
          <input
            className={cn('form-control', classNameControl, { 'is-invalid': touched && error }, size && `form-control-${size}`)}
            autoComplete={autoComplete}
            {...otherProps} // eslint-disable-line react/jsx-props-no-spreading
            {...input} // eslint-disable-line react/jsx-props-no-spreading
            type={type}

            // checked={input.value === otherProps.value}
            onChange={input.onChange}
            placeholder={prependFieldLabel || placeholder}
          />
        )}

        {!['textarea', 'checkbox', 'radio'].includes(type) && (
          <input
            className={cn('form-control', classNameControl, { 'is-invalid': touched && error }, size && `form-control-${size}`)}
            autoComplete={autoComplete}
            {...otherProps} // eslint-disable-line react/jsx-props-no-spreading
            {...input} // eslint-disable-line react/jsx-props-no-spreading
            type={type}

            onChange={(event) => {
              event.target.value ? setShowLabel(true) : setShowLabel(false);

              return input.onChange(event.target.value);
            }}
            placeholder={prependFieldLabel || placeholder}
          />
        )}

        {appendFieldLabel && (
          <div className="input-label-wrap">
            <label htmlFor={input.name} data-tip={tooltip}>
              {appendFieldLabel instanceof Function
                ? appendFieldLabel()
                : appendFieldLabel
              }
            </label>
          </div>
        )}
        {append && (
          <div className="input-group-append">
            <span className="input-group-text">{append}</span>
          </div>
        )}

        {touched && error && (
          <div className="invalid-feedback show-input-error">{error}</div>
        )}
      </div>
    </div>
    /* eslint-enable react/jsx-props-no-spreading */
  );
};

Input.propTypes = {
  input: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.number,
  ])).isRequired,
  meta: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ])).isRequired,
  type: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
};
// eslint-disable-next-line react/jsx-props-no-spreading
const ReduxInput = (props) => <Field {...props} component={Input} />;

ReduxInput.propTypes = {
  classNameControl: PropTypes.string,
  classNameBody: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  append: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  prepend: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  name: PropTypes.string.isRequired,
};

ReduxInput.defaultProps = {
  classNameControl: null,
  classNameBody: null,
  className: null,
  type: 'text',
  size: null,
  append: null,
  prepend: null,
};

export default ReduxInput;
