import axios from 'axios';
import { BASE_API_URL } from '../constants/api';

// Set up Base Axios instance
axios.defaults.headers.post['Content-Type'] = 'application/json';

const baseAxiosInstance = axios.create();

baseAxiosInstance.defaults.baseURL = BASE_API_URL;

const getError = (errors, status) => {
  if (typeof (errors) === 'string') {
    // eslint-disable-next-line no-undef
    return {
      status,
      message: `Error code: ${status}`,
    };
  }

  if (errors.non_field_errors instanceof Array) {
    return {
      status,
      errors,
      message: errors.non_field_errors.join('. '),
    };
  }

  return {
    status,
    errors,
    message: errors.non_field_errors || errors.detail,
  };
};

baseAxiosInstance.interceptors.response.use(
  (response) => response.data,
  ({ response }) => {
    const { data, status } = response;
    // @TODO for development purposes, remove it later
    console.log('RESPONSE', response);

    const error = getError(data, status);

    return Promise.reject(error);
  },
);

export const setAuthHeader = (token) => {
  localStorage.setItem('auth', token);
};

export const removeAuthHeader = () => {
  localStorage.removeItem('auth');
};

export default function sendRequest({ method, path, data, headers = {}, configs = {} }) {
  const token = localStorage.getItem('auth');

  let AuthHeader = {
    ...headers,
  };

  if (token) {
    AuthHeader = {
      ...AuthHeader,
      Authorization: `Bearer ${token}`,
    };
  }

  const dataProperty = method === 'get' ? 'params' : 'data';
  const request = {
    ...configs,
    method,
    url: path,
    [dataProperty]: data,
    headers: AuthHeader,
  };

  return baseAxiosInstance(request);
}
