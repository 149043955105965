import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
// import { hide } from 'redux-modal';
import _pickBy from 'lodash/pickBy';

import CAMPAIGNS from '../../services/types';
import { ONLINE_SOCIAL_CAMPAIGN } from '../../../../constants/campaigns';
import {
  prevStep,
  editSocialNetworks,
  getCampaignSocialNetworks,
  getDeviceCampaignDetails,
  sendSuggestion,
} from '../../services/actions';
import { SUGGEST_OTHER_MODAL } from '../../../../constants/modals';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import useModal from '../../../../utils/useModal';
import { SubmissionError } from 'redux-form';

const submitLoadingSelector = createLoadingSelector(CAMPAIGNS.EDIT_SOCIAL_NETWORKS.base);
const socialMediaLoading = createLoadingSelector(CAMPAIGNS.READ_SOCIAL_NETWORKS.base);
const loadingSelector = createLoadingSelector(CAMPAIGNS.READ_DETAILS.base);

const createCampaignSelector = (id) => createSelector(
  loadingSelector,
  submitLoadingSelector,
  socialMediaLoading,
  (state) => state.campaigns.details[id],
  (state) => state.campaigns.social_networks,
  (state) => state.account.accountDetails,
  (state) => state.account.facebookAccounts,
  (isLoading, isLoadingSubmit, isSocialLoadig, campaign, social_networks, account_details, facebookAccounts) => ({
    isLoading,
    isLoadingSubmit,
    isSocialLoadig,
    campaign,
    social_networks, 
    account_details,
    facebookAccounts,
  }),
);

const useSocialmediaInfo = (id) => {
  const dispatch = useDispatch();

  const selector = createCampaignSelector(id);

  const data = useSelector(selector);

  const openPage = useCallback(() => {
    if (id && !data.campaign) {
      dispatch(getDeviceCampaignDetails(id));
    } else if (data.campaign.type_is === ONLINE_SOCIAL_CAMPAIGN) {
      dispatch(getCampaignSocialNetworks(id));
    }
  }, [data.campaign, dispatch, id]);

  const goToBack = useCallback(() => {
    dispatch(prevStep());
  }, [dispatch]);

  const submitHandler = useCallback((fields) => {
    const selectedMedia = _pickBy(fields.social_networks);

    if (!selectedMedia ||  Object.keys(selectedMedia).length === 0 ) {
      throw new SubmissionError({ 
        suggest_other: 'Select at least one social network' 
      })
    }
    
    dispatch(editSocialNetworks(id, {social_networks: Object.keys(selectedMedia)}));
  }, [dispatch, id]);

  const submitSuggestOther = useCallback((fields) => {
    if (data.account_details) {
      dispatch(sendSuggestion({
        category: 'Social networks',
        content: fields.suggest,
        email: data.account_details.email,
        company: data.account_details.company && data.account_details.company.name
      }))
    }
  }, [dispatch, data.campaign, data.account_details]);

  useEffect(() => {
    openPage();
  }, [openPage]);

  const { showModal: showSuggestOther } = useModal(SUGGEST_OTHER_MODAL);

  return {
    data,
    actions: {
      goToBack,
      submitHandler,
      showSuggestOther,
      submitSuggestOther
    },
  };
};

export default useSocialmediaInfo;
