import React from 'react';
import _filter from 'lodash/filter';

import ReduxForm from '../../../../components/ReduxForm';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import tr from '../../../../utils/translate';
import InputFileImage from '../../../../components/InputFileImage';
import ModalFormFooter from '../../../../components/ModalFormFooter';
import { ROLE_SUPERADMIN } from '../../../../constants/roles';
import validate from './validate';
import useProductForm from './hooks';
import { generateSelect } from '../../../../helpers/arrayHelpers';
import './styles.scss';


const required = (value) => (value ? undefined : 'Required');

const allowOnlyNumbers = (event) => {
  if ([69, 187, 188, 189, 190].includes(event.keyCode)) {
    event.preventDefault();
  }
};

const ProductForm = ({ data, handleHide }) => {
  const {
    actions, data: {
      isLoadingCreate, isLoadingUpdate, product, activeBrand, account, brands,
    },
  } = useProductForm(data.id);

  const brandSelect = generateSelect(_filter(brands, ['is_active', true]), 'name');

  return (
    <ReduxForm
      enableReinitialize
      className="product-edit-form"
      form="PRODUCT_FORM"
      validate={validate}
      formData={{
        onSubmit: actions.submit,
        initialValues: {
          ...product,
          ...(!product && {
            company: account.access_level === ROLE_SUPERADMIN
              ? activeBrand : account.company && account.company.id,
          }),
        },
      }}
    >
      <div className="modal-body">
        {account.access_level === ROLE_SUPERADMIN
          && (
          <Select
            disabled={!!product}
            fieldLabel={tr('Select brand')}
            className="pd-b-30"
            name="company"
            options={brandSelect}
            validate={[required]}
          />
          )}
        <div className="row">
          <div className="col pd-b-15">
            <Input
              name="name"
              prependFieldLabel={tr('Product name')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col pd-b-15">
            <Input
              name="tag"
              prependFieldLabel={tr('Product ID/TAG')}
            />
          </div>
          <div className="col pd-b-15">
            <Input
              name="code"
              prependFieldLabel={tr('Product code')}
            />
          </div>
        </div>
        <div className="product-details-fields">
          <h4 className="product-form-subtitle mg-b-20 mg-t-10">
            {tr('Product details')}
          </h4>

          <div className="row pd-b-15">
            <div className="col">
              <Input
                name="width_in"
                type="number"
                prependFieldLabel={tr('Width')}
                onKeyDown={allowOnlyNumbers}
                append="mm"
              />
            </div>
            <div className="col pd-b-15">
              <Input
                name="height_in"
                type="number"
                prependFieldLabel={tr('Height')}
                onKeyDown={allowOnlyNumbers}
                append="mm"
              />
            </div>
            <div className="col pd-b-15">
              <Input
                name="depth_in"
                type="number"
                prependFieldLabel={tr('Depth')}
                onKeyDown={allowOnlyNumbers}
                append="mm"
              />
            </div>
            <div className="col pd-b-15">
              <Input
                name="weight_lbs"
                type="number"
                prependFieldLabel={tr('Weight')}
                onKeyDown={allowOnlyNumbers}
                append="g"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input
              name="description"
              type="textarea"
              rows={1}
              maxLength="255"
              prependFieldLabel={tr('Product description (max 255 char.)')}
            />
          </div>
          <div className="col">
            <InputFileImage
              name="img"
              label={tr('Image')}
              maxSize={2097152}
              // component={InputFileImage}
              additionalInfo={tr('Max 2 mb')}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <ModalFormFooter
          handleClose={handleHide}
          confirmText={tr('Save')}
          isLoading={isLoadingCreate || isLoadingUpdate}
        />
      </div>
    </ReduxForm>
  );
};

export default ProductForm;
