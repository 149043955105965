import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import PieChart from '../../../components/PieChart';
import PieChartLegend from '../../../components/PieChartLegend';
import Frame from '../../../components/Frame';
import tr from '../../../utils/translate';
import { getArraySum } from '../../../helpers/arrayHelpers';
import './styles.scss';

const formatChartData = (data) => ({
  labels: _map(data, 'time_of_day'),
  values: _map(data, 'count'),
});

const TimeOfDayChart = ({ chartData, header, legendLabel }) => {
  const data = formatChartData(chartData);
  const dataSum = getArraySum(data.values);

  if (!dataSum) {
    data.values = [];
  }

  const generatedLegends = () => data.values.map((value, index) => {
    const percentValue = Math.round((value / dataSum) * 100);
    return (
      <PieChartLegend
        title={data.labels[index]}
        subtitle={`${value} ${legendLabel}`}
        badgeValue={`${percentValue}%`}
        badgeColor="gray"
        key={index}
      />
    );
  });

  return (
    <Frame header={header} classNameBody="day-time-pie-chart">
      <div className="day-time-chart">
        <PieChart chartData={data.values} labels={data.labels} />
      </div>
      <div className="day-time-legend">
        {generatedLegends()}
      </div>
    </Frame>
  );
};

TimeOfDayChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.shape({
    time_of_day: PropTypes.string,
    count: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })),
  header: PropTypes.node,
};

TimeOfDayChart.defaultProps = {
  chartData: [],
  header: null,
};

export default TimeOfDayChart;
