import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import Button from '../../../../components/Button';
import Table from '../../../../components/Table';
import { useParams } from 'react-router-dom';
import { unsubscribesList, exportUnsubscribes } from '../../../../modules/campaigns/services/actions';
import tr from '../../../../utils/translate';

const selector = createSelector(
  (state) => state.campaigns.unsubscribed_list,
  (unsubscribed_list) => ({
    unsubscribed_list,
  }),
);

const columns = [
  {
    name: tr('Email'),
    key: 'email_address',
  },
  {
    name: tr('Reason'),
    key: 'reason',
  },
];

const UnsubscribedForm = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const data = useSelector(selector);

  useEffect(() => {
    dispatch(unsubscribesList({ id: params.id }));
  }, [dispatch]);

  const handleExportButton = useCallback(() => {
    dispatch(exportUnsubscribes({ id: params.id }));
  },[dispatch, params.id]);
  
  return (
    <div>
      {data && data.unsubscribed_list.length !== 0 ? <Table columns={columns} data={data.unsubscribed_list} /> : <div className="no-data">No data</div>}
      <div className="modal-footer">
        <Button
          onClick={handleExportButton}
          className="mg-r-10"
          color="light"
          disabled={!(data.unsubscribed_list.length !== 0)}
        >
          {tr('Export unsubscribes list')}
        </Button>
      </div>
    </div>
  );
};

export default UnsubscribedForm;
