import React from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  propTypes as propTypesReduxForm,
} from 'redux-form';

import Input from '../../../../../../components/Input';
import ModalFormFooter from '../../../../../../components/ModalFormFooter';
import translate from '../../../../../../utils/translate';
import { useUsersSearch } from '../../hooks';

export const SearchFieldName = 'query_str';

const UsersSearchForm = ({
  handleSubmit,
  location,
}) => {
  const { actions: { hideSearchModal, hanleSearchSubmit } } = useUsersSearch(location);

  return (
    <form onSubmit={handleSubmit(hanleSearchSubmit)}>
      <div className="modal-body">
        <Input
          name={SearchFieldName}
          prependFieldLabel={translate('Enter request')}
        />
      </div>
      <div className="modal-footer">
        <ModalFormFooter
          handleClose={hideSearchModal}
          confirmText={translate('Apply')}
          isLoading={false}
        />
      </div>
    </form>
  );
};

UsersSearchForm.propTypes = {
  ...propTypesReduxForm,
  location: PropTypes.objectOf(PropTypes.string).isRequired,
};

const reduxFormConfig = {
  form: 'USERS_SEARCH_FORM',
};


export default reduxForm(reduxFormConfig)(UsersSearchForm);
