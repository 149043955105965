import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import FontPicker from 'font-picker-react';
import { Field } from 'redux-form';

import './styles.scss';

const SelectFont = (props) => {
  const {
    append,
    prepend,
    size,
    className,
    classNameControl,
    classNameWrapper,
    input,
    meta: { error, touched },
    fieldLabel,
    defaultValue,
  } = props;

  return (
    <div className={cn('select-font-wrapper', classNameWrapper)}>
      {fieldLabel && (
        <label htmlFor={input.name}>{fieldLabel}</label>
      )}
      <div className={cn('input-group', className)}>
        {prepend && (
          <div className="input-group-prepend">
            <span className="input-group-text">{prepend}</span>
          </div>
        )}
        <div
          className={
            cn('custom-font-select', classNameControl, { 'is-invalid': touched && error }, size && `form-control-${size}`)
          }
          style={{ fontFamily: input.value || defaultValue }}
        >
          <FontPicker
            apiKey="AIzaSyApv2X6CexjfEzGzQHxK1jpSCtYof9zCio"
            activeFontFamily={input.value || defaultValue}
            onChange={({ family }) => {
              input.onChange(family);
            }}
          />
        </div>
        {append && (
          <div className="input-group-append">
            <span className="input-group-text">{append}</span>
          </div>
        )}
        {touched && error && (
          <div className="invalid-feedback show-input-error">{error}</div>
        )}
      </div>
    </div>
  );
};

SelectFont.propTypes = {
  classNameControl: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  append: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  prepend: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  input: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.number,
  ])).isRequired,
  meta: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ])).isRequired,
  fieldLabel: PropTypes.string,
  classNameWrapper: PropTypes.string,
  defaultValue: PropTypes.string,
};

SelectFont.defaultProps = {
  size: null,
  fieldLabel: null,
  append: null,
  prepend: null,
  classNameControl: null,
  className: null,
  classNameWrapper: null,
  defaultValue: undefined,
};
// eslint-disable-next-line react/jsx-props-no-spreading
const ReduxSelectFont = (props) => <Field {...props} component={SelectFont} />;

export default ReduxSelectFont;
