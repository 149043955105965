import React from 'react';
import Select from '../../../../../../components/Select';

const AccountExists = ({ fieldLabel, name, account_exists }) => {
	const accountExistsOptions = [{ name: '', id: ''}, ...(account_exists || [])].map((account) => ({
		label: account.name,
		value: account.id,
	}));

	return (
		<Select
			fieldLabel={fieldLabel}
			name={name}
			options={accountExistsOptions}
		/>
	);
};

export default AccountExists;