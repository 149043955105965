import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import { START_MAILING_MODAL } from '../../../../constants/modals';
import tr from '../../../../utils/translate';
import useStartMaling from './hooks';
import './styles.scss';

const StartMailingModal = ({ campaign_id }) => (
  <Modal name={START_MAILING_MODAL} size="lg">
    {({ handleHide, ...initialValues }) => (
      <StartMailingModalContent values={initialValues} handleHide={handleHide} campaign_id={campaign_id} />
    )}
  </Modal>
);

const StartMailingModalContent = ({ values, handleHide, campaign_id }) => {
  const { actions: { handleYes }, data: { isLoading } } = useStartMaling();
  return (
    <>
      <div className="modal-body">
        <div className="reboot-modal-body">
          <div className="reboot-modal-body__text-wrap">
            {tr('Do you want to start mailing manually?')}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="reboot-modal-footer">
          <div className="reboot-modal-footer__cancel-col">
            <Button color="light" onClick={handleHide}>{tr('Cancel')}</Button>
          </div>
          <div className="reboot-modal-footer__submit-col">
            <Button
              onClick={() => handleYes(campaign_id)}
              className="btn-block"
              status={isLoading ? 'disabled' : 'active'}
              disabled={isLoading}
            >
              {tr('Yes')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartMailingModal;
