import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import CAMPAIGNS from '../../services/types';
import {
  getDeviceCampaignDetails,
  setDemographics,
  prevStep,
} from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';

const submitLoadingSelector = createLoadingSelector(CAMPAIGNS.EDIT_CAMPAIGN.base);
const detailsLoadingSelector = createLoadingSelector(CAMPAIGNS.READ_DETAILS.base);

const createCampaignSelector = (id) => createSelector(
  detailsLoadingSelector,
  submitLoadingSelector,
  (state) => state.campaigns.details[id],
  (isLoadingData, isLoadingSubmit, campaign ) => ({
    isLoadingData,
    isLoadingSubmit,
    campaign,
  }),
);

const useCampaignDetailsForm = (id) => {
  const dispatch = useDispatch();
  const selector = createCampaignSelector(id);
  const data = useSelector(selector);

  const openPage = useCallback(() => {
    if (id && !data.campaign) {
      dispatch(getDeviceCampaignDetails(id));
    }
  }, [data.campaign, dispatch, id]);
  
  const editCampaign = useCallback((data) => {
    dispatch(setDemographics(id, data));
  }, [dispatch, id]);

  const goToPrevStep = useCallback(() => {
    dispatch(prevStep());
  }, [dispatch]);

  useEffect(() => {
    openPage();
  }, [openPage]);

  return {
    data,
    actions: {
      editCampaign,
      goToPrevStep,
    },
  };
};

export default useCampaignDetailsForm;
