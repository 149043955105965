import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import PieChart from '../../../components/PieChart';
import PieChartLegend from '../../../components/PieChartLegend';
import Frame from '../../../components/Frame';
import tr from '../../../utils/translate';
import { getArraySum } from '../../../helpers/arrayHelpers';

import './styles.scss';

const formatChartData = (data) => ({
  labels: _map(data, 'label'),
  values: _map(data, 'count'),
});

const ProductDispencesChart = ({ chartData, header, legendLabel }) => {
  const data = formatChartData(chartData);
  const generatedLegends = () => {
    const dataSum = getArraySum(data.values);
    if (!dataSum) {
      return null;
    }
    return data.values.map((value, index) => {
      const percentValue = Math.round((value / dataSum) * 100);
      return <PieChartLegend key={index} title={data.labels[index]} subtitle={`${value} ${legendLabel}`} badgeValue={`${percentValue}%`} />;
    });
  };

  return (
    <Frame header={header} className="mg-b-30" classNameBody="dispences-pie-chart">
      <div>
        <PieChart chartData={data.values} labels={data.labels} />
      </div>
      <div className="dispences-pie-chart-legend">
        {generatedLegends()}
      </div>
    </Frame>
  );
};

ProductDispencesChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.shape({
    product_name: PropTypes.string,
    count: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })),
  header: PropTypes.node,
};

ProductDispencesChart.defaultProps = {
  chartData: [],
  header: null,
};

export default ProductDispencesChart;
