import React from 'react';
import moment from 'moment';
import { BackarrowIcon, CalendarIcon } from '../../../../../components/Icons';
import DatePicker from '../../../../../components/DatePicker';
import ReduxForm from '../../../../../components/ReduxForm';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Input';
import validate from '../validate';
import tr from '../../../../../utils/translate';
import FacebookPagePreview from '../FacebookPagePreview';

const BudgetForm = ({ form, audience_size, fb_pages, isLoadingSubmit, onSubmit, previousPage }) => {
	const objPage = (fb_pages || []).find(() => form?.values?.facebook_page);

	return (
		<ReduxForm
			// enableReinitialize
			form="CAMPAIGN_FACEBOOK_FORM"
			destroyOnUnmount={false}
			forceUnregisterOnUnmount={true}
			validate={validate}
			formData={{
				onSubmit,
			}}
		>

			<div className="campaign-location-form pd-x-25 pd-y-50">

				<div className="row">

					<div className="col-md-6">

						<h4 className="cdf-subtitle mg-b-10">
							{tr('Audience')}
						</h4>

						<div className="row mg-b-30">

							<Input
								id="budget_life"
								name="type_budget"
								type="radio"
								className="col checkbox"
								appendFieldLabel={tr('Budget life')}
								value="LIFETIME"
							/>

							<Input
								id="budget_per_day"
								name="type_budget"
								type="radio"
								className="col checkbox"
								appendFieldLabel={tr('Budget per day')}
								value="DAILY"
							/>

						</div>

						{form?.values?.type_budget === 'LIFETIME' && (
							<div className="mg-b-30">

								<Input
									name="budget_life"
									autoComplete="off"
									prependFieldLabel={tr('Budget')}
									append={'USD'}
								/>

							</div>
						)}

						{form?.values?.type_budget === 'DAILY' && (
							<div className="mg-b-30">

								<Input
									name="budget_per_day"
									autoComplete="off"
									prependFieldLabel={tr('Budget')}
									append={'USD'}
								/>

							</div>
						)}

						<h4 className="cdf-subtitle mg-b-10">
							{tr('Schedule')}
						</h4>

						<div className="mg-b-30 row">

							<div className="col-md-6">

								<DatePicker
									name="start_time"
									dateForm="YYYY-MM-DD HH:mm"
									showTimeInput
									timeFormat="HH:mm"
									timeIntervals={15}
									timeCaption="Time"
									append={<CalendarIcon />}
									minDate={moment().toDate()}
									fieldLabel={tr('Start date')}
								/>

							</div>

							<div className="col-md-6">

								<DatePicker
									name="end_time"
									dateForm="YYYY-MM-DD HH:mm"
									showTimeInput
									timeFormat="HH:mm"
									timeIntervals={15}
									timeCaption="Time"
									append={<CalendarIcon />}
									minDate={moment().toDate()}
									fieldLabel={tr('End date')}
								/>

							</div>

						</div>

						<div className="mg-b-30">

							<Input
								name="stop_ad"
								type="checkbox"
								className="checkbox"
								appendFieldLabel={tr('Stop Ad as samples run out')}
							/>

						</div>

					</div>

					<div className="col-md-6 pd-l-60">

						<FacebookPagePreview
							audience_size={audience_size}
							title={objPage?.name}
							icon={objPage?.image_url}
							image={form?.values?.image}
							description={form?.values?.text_body}
							content={form?.values?.headline}
							call_to_action={form?.values?.call_to_action}
						/>

					</div>

				</div>

			</div>

			<div className="modal-footer">

				<Button color="light" className="mg-r-30" onClick={previousPage}>

					<BackarrowIcon />

					{tr('Back')}

				</Button>

				<Button
					type="submit"
					disabled={isLoadingSubmit}
				>

					{tr('Next')}

				</Button>

			</div>

		</ReduxForm>
	);
};

export default BudgetForm;