import React from 'react';
import PropTypes from 'prop-types';
import Widget from '../../../../components/Widget';
import {
  FollowUpsIcon,
  LargeClockIcon,
  PieChartIcon,
  BottleIcon,
  ClientsIcon,
  StockIcon,
} from '../../../../components/Icons';
import tr from '../../../../utils/translate';
import { transformDuration } from '../../../../utils/dates';

import './styles.scss';

const OverviewBadges = ({ stats, stats_visibility }) => {
  return (
    <div className="info-badges row mg-b-30">

      <div className="col">
        <Widget
          color="dark_blue"
          icon={(<StockIcon />)}
          title={tr('Abandonment rate')}
          description={`- %`}
          subtitle={`- %`}
        />
      </div>

      <div className="col">
        <Widget
          color="cyan"
          icon={(<ClientsIcon />)}
          title={tr('Opt in rate')}
          description={`${(stats.opt_in_rate * 100).toFixed(2)} %`}
          subtitle={`${(stats.opt_in_rate_today * 100).toFixed(2)} %`}
        />
      </div>

      {stats_visibility.samples
      && (
        <div className="col">
          <Widget
            color="yellow"
            icon={(<BottleIcon />)}
            title={tr('Samples distributed')}
            description={`${stats.samplesToday} today`}
            subtitle={`${stats.samples} ${tr('samples')}`}
          />
        </div>
      )}

      {stats_visibility.engagement_time
      && (
        <div className="col">
          <Widget
            color="green"
            icon={<LargeClockIcon />}
            title={tr('Average engagement time')}
            subtitle={transformDuration(stats.engagement)}
            description={`${transformDuration(stats.engagementToday)} today`}
          />
        </div>
      )}
      {stats_visibility.click_through_rate
      && (
        <div className="col">
          <Widget
            color="blue"
            icon={<FollowUpsIcon />}
            title={tr('Email click through rate')}
            subtitle={`${(stats.click_rate || 0) * 100} %`}
          />
        </div>
      )}
      {stats_visibility.bounce_rate
      && (
        <div className="col">
          <Widget
            color="red"
            icon={<PieChartIcon />}
            title={tr('Email bounce rate')}
            subtitle={`${stats.bounce_rate} %`}
          />
        </div>
      )}

    </div>
  );
};

OverviewBadges.propTypes = {
  stats: PropTypes.shape({
    samples: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    engagement: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    samplesToday: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    engagementToday: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    through_rate: PropTypes.node,
    bounce_rate: PropTypes.node,
  }).isRequired,
};

export default OverviewBadges;
