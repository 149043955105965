import {
  useEffect, useCallback, useMemo, useState,
} from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import _find from 'lodash/find';
import qs from 'qs';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import tr from '../../../../utils/translate';
import { setParams } from '../../../../helpers/urlParamsHelpers';
import {
  CAMPAIGN_MODAL, ONLINE_CAMPAIGN_STATUS, TEST_EMAILS_MODAL, START_MAILING_MODAL, // COPY_SOCIAL_LINK_MODAL
} from '../../../../constants/modals';
import useModal from '../../../../utils/useModal';
import { ONLINE_SOCIAL_CAMPAIGN } from '../../../../constants/campaigns';
import {
  getDeviceCampaignDetails,
  exportConfig,
  exportStatistics,
  getCampaignStatistic,
  getCompanyProducts,
  approveCampaign,
  rejectCampaign,
  getFollowUpStatistic,
  getCampaignSocialNetworks,
  getAdCampaign,
  exportCustomStatistics,
  getStatisticFacebook,
  getCampaignStatisticInteractions
} from '../../services/actions';
import CAMPAIGNS from '../../services/types';

const deviceInfoLoadingSelector = createLoadingSelector(
  CAMPAIGNS.READ_DETAILS.base,
);

const exportConfigLoadingSelector = createLoadingSelector(
  CAMPAIGNS.EXPORT_CONFIG.base,
);

const exportStatisticsLoadingSelector = createLoadingSelector(
  [
    CAMPAIGNS.EXPORT_STATISTICS.base,
    CAMPAIGNS.EXPORT_CUSTOM_STATISTICS.base,
    CAMPAIGNS.GET_FOLLOW_UP_STATISTICS.base,
  ],
);

const sendTestMailsLoadingSelector = createLoadingSelector(
  CAMPAIGNS.SEND_TEST_MAILS.base,
);

const createCampaignSelector = (id) => createSelector(
  deviceInfoLoadingSelector,
  (state) => state.campaigns.details[id],
  exportConfigLoadingSelector,
  exportStatisticsLoadingSelector,
  sendTestMailsLoadingSelector,
  (state) => state.campaigns.statistic[id],
  (state) => state.campaigns.statisticInteractions[id],
  (state) => state.campaigns.statisticFacebook[id],
  (state) => state.campaigns.list,
  (state) => state.campaigns.products,
  (state) => state.campaigns.social_networks,
  (state) => state.account.accountDetails.access_level,
  (state) => state.campaigns.ad_campaigns,
  (isLoading, campaign, isLoadingConfig, isLoadingStatistics, isTestEmailsLoading, statistic, statisticInteractions, statisticFacebook, all_campaigns, products, social_networks, access_level, ad_campaigns) => ({
    isLoading,
    campaign,
    isLoadingConfig,
    isLoadingStatistics,
    isTestEmailsLoading,
    statistic,
    statisticInteractions,
    statisticFacebook,
    all_campaigns,
    products,
    social_networks,
    access_level,
    ad_campaign: ad_campaigns[campaign?.ad_campaign?.id],
  }),
);

const useCampaignDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { showModal } = useModal(CAMPAIGN_MODAL);
  const selector = createCampaignSelector(params.id);
  const data = useSelector(selector);
  const history = useHistory();
  const location = useLocation();
  const currentParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [sortBy, setSortBy] = useState({});

  const { showModal: openCampaignStatusModal } = useModal(ONLINE_CAMPAIGN_STATUS);
  const { showModal: openTestEmailsModal } = useModal(TEST_EMAILS_MODAL);
  const { showModal: openStartMailingModal } = useModal(START_MAILING_MODAL);

  const handleSorting = (field) => {
    if (sortBy.key === field) {
      setSortBy({
        key: field,
        direction: sortBy.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSortBy({
        key: field,
        direction: 'asc',
      });
    }
  };

  const openPage = useCallback((id) => {
    if (!data.campaign) {
      dispatch(getDeviceCampaignDetails(id));
    } else if (data.campaign.type_is === ONLINE_SOCIAL_CAMPAIGN) {
      dispatch(getCampaignSocialNetworks(id));
    }

    dispatch(getCompanyProducts({ company: data.campaign && data.campaign.company.id }));
  }, [dispatch, data.campaign]);

  const handleClickEditCampaign = useCallback(() => {
    showModal({
      titleModal: tr(`Edit campaign ${data.campaign.name}`),
      id: data.campaign.id,
    });
  }, [showModal, data.campaign]);

  const handleExportConfig = useCallback(() => {
    dispatch(exportConfig(params.id));
  }, [dispatch, params.id]);

  const handlePeriodChange = (value) => {
    const url = setParams({ ...currentParams, ...{ period: value } });
    history.push(`?${url}`);
  };

  const handleApprove = useCallback((fields) => {
    dispatch(approveCampaign(params.id, fields));
  }, [dispatch, params.id]);

  const handleReject = useCallback((fields) => {
    dispatch(rejectCampaign(params.id, fields));
  }, [dispatch, params.id]);

  const handleExportStatistics = useCallback((unique) => {
    dispatch(exportStatistics({
      id: params.id,
      unique,
    }));
  }, [dispatch, params.id]);

  const handleExportCustomStatistics = useCallback(() => {
    dispatch(exportCustomStatistics(params.id));
  }, [dispatch, params.id]);

  const campaignProducts = useMemo(() => {
    const campaignProducts = [];
    const products = data.campaign && data.products && data.products.results || [];

    if (data.campaign && products.length) {
      data.campaign.trays.forEach((item) => {
        const product = _find(products, ['id', item.product]);
        if (!product) return;
        product.product_stock = item.product_stock;
        product.tray = item.id;
        campaignProducts.push(product);
      });
    }
    return campaignProducts;
  }, [data.campaign, data.products]);

  const handleStatusButtonsClick = useCallback((type = 'approve') => {
    openCampaignStatusModal({ titleModal: tr(`${type === 'approve' ? 'Approve' : 'Reject'} campaign ${data.campaign.name}`), submitAction: type === 'approve' ? handleApprove : handleReject });
  }, [data.campaign, handleApprove, handleReject, openCampaignStatusModal]);

  const handleTestEmailClick = useCallback(() => {
    openTestEmailsModal({ titleModal: tr(`Send test emails for ${data.campaign.name} campaign`) });
  }, [data.campaign, openTestEmailsModal]);

  useEffect(() => {
    openPage(params.id);
  }, [openPage, params.id]);

  useEffect(() => {
    dispatch(getFollowUpStatistic({ campaign_id: params.id, period: currentParams.period || 'all' }));
  }, [currentParams.period, dispatch, params.id]);

  useEffect(() => {
    const ordering = sortBy && (sortBy.direction === 'desc' ? `-${sortBy.key}` : sortBy.key);

    dispatch(getCampaignStatistic({
      ordering,
      campaign_id: params.id,
      period: currentParams.period || 'all',
    }));

    dispatch(getCampaignStatisticInteractions({
      ordering,
      campaign_id: params.id,
      period: currentParams.period || 'all',
    }));

    dispatch(getStatisticFacebook({ ordering, campaign_id: params.id, period: currentParams.period || 'all' }));
  }, [currentParams.period, dispatch, params.id, sortBy]);

  useEffect(() => {
    const adCampaignId = data.campaign?.ad_campaign?.id;

    if (adCampaignId && !data.ad_campaign) {
      dispatch(getAdCampaign({ id: adCampaignId }));
    }
  }, [dispatch, data]);

  return {
    data,
    sortBy,
    stat_period: currentParams.period || 'all',
    campaignProducts,
    actions: {
      handleClickEditCampaign,
      handleExportConfig,
      handleExportStatistics,
      handleExportCustomStatistics,
      handlePeriodChange,
      handleStatusButtonsClick,
      handleTestEmailClick,
      openStartMailingModal,
      handleSorting,
    },
  };
};

export default useCampaignDetails;
