import ACCOUNT from './types';

const defaultState = {
  accountDetails: {},
  countries: [],
  accounts: [],
  facebookAccounts: [],
  activeBrand: null,
  activeDepartment: null,
  brands: [],
  departments: [],
  socialsDetails: {},
  socialsAvailableAccounts: {},
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case ACCOUNT.GET_ACCOUNT_DETAILS.SUCCESS:
      return {
        ...state,
        accountDetails: {
          ...payload.res,
          access_level: payload.res.is_staff ? 'superadmin' : payload.res.access || payload.res.access_level ,
        },
      };
    case ACCOUNT.GET_COUNTRIES.SUCCESS:
      return {
        ...state,
        countries: payload.res.map(({ id, name }) => ({ id, value: id, label: name })),
      };
    case ACCOUNT.GET_ACCOUNTS.SUCCESS:
      return {
        ...state,
        accounts: payload.res,
      };
    case ACCOUNT.GET_FACEBOOK_ACCOUNTS.SUCCESS:
      return {
        ...state,
        facebookAccounts: payload.res,
      };

    case ACCOUNT.ADD_FACEBOOK_ACCOUNT.SUCCESS:
      return {
        ...state,
        facebookAccounts: state.facebookAccounts.find((account) => account.id === payload.res.id)
          ? state.facebookAccounts
          : [
            ...state.facebookAccounts,
            payload.res,
          ],
      };

    case ACCOUNT.REMOVE_FACEBOOK_ACCOUNT.SUCCESS:
      return {
        ...state,
        facebookAccounts: state.facebookAccounts
          .filter((account) => account.id !== +payload.res.id),
      };

    case ACCOUNT.EDIT_ACCOUNT_DETAILS.SUCCESS:
      return {
        ...state,
        accountDetails: {...payload.res, access_level: payload.res.is_staff ? 'superadmin' : payload.res.access || payload.res.access_level},
      };

    case ACCOUNT.GET_SOCIAL_INFO_BY_ID.SUCCESS:
      return {
        ...state,
        socialsDetails: {
          ...state.socialsDetails,
          [payload.res.id]: payload.res,
        },
      };
      
    case ACCOUNT.GET_SOCIAL_AVAILABLE_AD_ACCOUNT_BY_ID.SUCCESS:
      return {
        ...state,
        socialsAvailableAccounts: {
          ...state.socialsAvailableAccounts,
          [payload.req.params.id]: payload.res,
        },
      };

    case ACCOUNT.AD_ACCOUNTS.SUCCESS: {
      const { res } = payload;
      const socialInfo = state.socialsAvailableAccounts[res.social_account] || [];

      return {
        ...state,
        socialsAvailableAccounts: {
          ...state.socialsAvailableAccounts,
          [res.social_account]: socialInfo.filter((item) => item.account_id !== res.account_id),
        },
      }; 
    }
      
    case ACCOUNT.DELETE_AD_ACCOUNT_FROM_STORE:
      const socialInfo = state.socialsDetails[payload.socialId];

      return {
        ...state,
        socialsDetails: {
          ...state.socialsDetails,
          [payload.socialId]: {
            ...socialInfo,
            ad_accounts: socialInfo.ad_accounts.filter((item) => item.account_id !== payload.accountId),
          },
        },
      };
      
    default:
      return state;
  }
};
