import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Frame from '../Frame';
import './styles.scss';

const PieChartLegendSimple = (props) => {
  const {
    className,
    title,
    badgeValue,
    // badgeColor,
    badgeColorCode,
  } = props;
  const style = badgeColorCode ? { backgroundColor: badgeColorCode } : {};
  return (
    <Frame className={cn('legend-item-simple', className)}>
      <div className="legend pd-b-3">
        <div className="mg-r-15 legend-color-indicator" style={style} />
        <div className="legend__info">
          <h3 className="legend__title">{badgeValue}</h3>
          <span className="legend__subtitle">{title}</span>
        </div>
      </div>
    </Frame>
  );
};

PieChartLegendSimple.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  badgeValue: PropTypes.string,
  badgeColor: PropTypes.string,
  badgeColorCode: PropTypes.string,
};

PieChartLegendSimple.defaultProps = {
  className: null,
  subtitle: '',
  badgeColor: 'yellow',
  badgeColorCode: '',
  badgeValue: '',
};

export default PieChartLegendSimple;
