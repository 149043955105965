import translate from '../../../../utils/translate';

const validate = ({
  quantity,
}) => {
  const errors = {};

  if (!quantity) errors.quantity = translate('This field is required');
  
  return errors;
};

export default validate;
