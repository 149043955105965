import React from 'react';
import Modal from '../../../../../components/Modal';
import Button from '../../../../../components/Button';
import tr from '../../../../../utils/translate';
import { SOCIAL_AD_ACCOUNTS_MODAL } from '../../../../../constants/modals';

const SocialAdAccountsModal = ({ data, onConnectSocialById, socialId }) => {
  const isEmpty = data.length === 0;

  return (
    <Modal name={SOCIAL_AD_ACCOUNTS_MODAL} size="md" title={tr('Ad accounts')}>
      <div className="social_modal">
        {isEmpty ? (
          <p>{tr('Empty ad accounts')}</p>
        ) : (
          data.map((item) => (
            <div className="social_modal_ad_account" key={item.id}>
              <p className="mb0">{item.name}</p>

              <Button
                onClick={() => {
                  onConnectSocialById({
                    social_account: socialId,
                    account_id: item.account_id,
                    name: item.name,
                    funding_source: item.funding_source,
                  });
                }}
              >
                {tr('Connect')}
              </Button>
            </div>
          ))
        )}
      </div>
    </Modal>
  );
};

export default SocialAdAccountsModal;
