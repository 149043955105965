/*eslint no-mixed-operators: 0*/
import React from 'react';
import tr from '../../../../utils/translate';
import SimpleButton from '../../../../components/SimpleButton';

export const getProductsTable = (showModal, isEdit, handleSorting, sortBy) => {
  const data = [
    {
      name: '',
      key: 'img',
      render: ({ img, name }) => (<div className="product-table-image"><img src={img || 'https://via.placeholder.com/60'} alt={name} /></div>),
    },
    {
      name: tr('Name'),
      key: 'name',
      className: `table-column-sort ${sortBy.key === 'name' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
      sorting: () => handleSorting('name'),
    },
    {
      name: tr('tag'),
      key: 'tag',
      className: `table-column-sort ${sortBy.key === 'tag' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
      sorting: () => handleSorting('tag'),
    },
    {
      name: tr('Description'),
      key: 'description',
      className: `table-column-sort ${sortBy.key === 'description' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
      sorting: () => handleSorting('description'),
      render: ({ description }) => (<div className="product-info-description">{description}</div>),
    },
    {
      name: tr('Size'),
      key: 'size',
      render: ({ width_in, height_in, depth_in }) => (width_in && height_in && depth_in && `${width_in} mm x ${height_in} mm x ${depth_in} mm`),
    },
    {
      name: tr('Weight'),
      key: 'weight_lbs',
      className: `table-column-sort ${sortBy.key === 'weight_lbs' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
      sorting: () => handleSorting('weight_lbs'),
      render: ({ weight_lbs }) => (weight_lbs && `${weight_lbs} g`),
    },
  ];

  if (isEdit) {
    data.push({
      name: tr('Options'),
      key: 'options',
      render: ({ id, name }) => (
        <SimpleButton
          className="cell-link"
          onClick={() => {
            showModal({
              titleModal: tr(`Update ${name}`), id,
            });
          }}
        >
          {tr('Edit')}
        </SimpleButton>
      ),
    });
  }

  return data;
};
