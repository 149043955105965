import React from 'react';
import PropTypes from 'prop-types';
import ApexCharts from 'react-apexcharts';
import tr from '../../utils/translate';

const defaultOptions = {
  dataLabels: {
    enabled: true,
    formatter: (val) => `${Math.round(val)}%`,
  },
  chart: {
    toolbar: {
      show: false,
    },
    parentHeightOffset: 20,
  },
  legend: {
    show: false,
  },
  plotOptions: {
    pie: {
      donut: {
        size: '45%',
      },
    },
  },
  tooltip: {
    enabled: true,
    fillSeriesColor: false,
    marker: {
      show: false,
    },
  },
  states: {
    hover: {
      filter: {
        type: 'darken',
        value: 0.5,
      },
    },
  },
  responsive: [
    {
      breakpoint: 800,
      options: {
        chart: {
          width: 280,
        },
      },
    },
  ],
  noData: {
    text: tr('No Data'),
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: -20,
    style: {
      fontSize: '24px',
      backgroundcColor: 'blue',
    },
  },
};

const PieChart = ({
  chartData, labels, width, colors,
}) => {
  const options = {
    ...defaultOptions,
    ...{ labels },
    colors: colors || ['#BBC8F3', '#66A4FB', '#EE8CE5', '#B0CCFA', '#65E0E0', '#FF745C', '#FF2626'],
  };
  return (
    chartData && <ApexCharts options={options} series={chartData} type="donut" width={width} />
  );
};

PieChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.number),
  labels: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.number,
};

PieChart.defaultProps = {
  chartData: [],
  labels: [],
  width: 336,
};

export default PieChart;
