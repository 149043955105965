import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import tr from '../../../../utils/translate';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import ReduxForm from '../../../../components/ReduxForm';
import { BackarrowIcon } from '../../../../components/Icons';
import TableDevices from './TableDevices';
import useAssociatedDevices from './hooks';
import './styles.scss';

const AssociatedDevices = ({ data: { id } }) => {
  const {
    data: {
      isLoadingData,
      isLoadingSubmit,
      campaign,
      devices,
    },
    actions: {
      goToBack,
      setDevices,
    },
  } = useAssociatedDevices(id);

  if ((id && !devices) || isLoadingData) return <Loader size="md" />;

  const campaignDevices = _get(campaign, 'devices', []);

  return (
    <ReduxForm
      enableReinitialize
      form="COMPAIGN_ASSOCIATED_DEVICES_FORM"
      formData={{
        onSubmit: setDevices,
        initialValues: {
          devices: campaignDevices.map(({ serial_number }) => serial_number),
        },
      }}
    >
      <div className="compaign-associated-devices-form pd-x-25 pd-y-50">
        <h3 className="cadf-title mg-b-40">{tr('Associated devices')}</h3>

        <TableDevices
          name="devices"
          classNameWrapper="cadf-table-wrapper"
          options={[...campaignDevices, ...devices]}
        />
      </div>
      <div className="modal-footer">
        <Button color="light" className="mg-r-30" onClick={goToBack}>
          <BackarrowIcon />
          {tr('Back')}
        </Button>
        <Button
          type="submit"
          disabled={isLoadingSubmit}
        >
          {tr('Next')}
        </Button>
      </div>
    </ReduxForm>
  );
};

AssociatedDevices.defaultProps = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }),
};

AssociatedDevices.defaultProps = {
  data: {},
};

export default AssociatedDevices;
