import React from 'react';
import Pagination from '../../../../components/Pagination';

import Table from '../../../../components/Table';
import translate from '../../../../utils/translate';
import columns from './columns';
import './styles.scss';

const TableCampaign = ({ data, showModal }) => {
  const cols = [
    ...columns,
    {
      name: translate('Options'),
      key: 'options',
      render: ({ id }) => id && (
        <button
          className="cell-link"
          type="button"
          onClick={showModal}
        >
          {translate('Change campaign')}
        </button>
      ),
    },
  ];

  const defaultData = columns.reduce((res, { key }) => ({ ...res, [key]: null }), {});

  return (
    <>
      <Table
        className="device-table-campaign"
        caption={translate('Current campaign')}
        // tooltip={translate('Tooltip about this section')}
        columns={cols}
        data={data ? [data] : [defaultData]}
      />
    </>
  );
};

export default TableCampaign;
