export const HOME_ROUTE = '/';
export const CAMPAIGNS_ROUTE = '/campaigns';
export const CAMPAIGNS_DETAILS_ROUTE = `${CAMPAIGNS_ROUTE}/:id`;
export const DEVICES_ROUTE = '/devices';
export const DEVICES_ADD_ROUTE = `${DEVICES_ROUTE}/add`;
export const DEVICES_INFO_ROUTE = `${DEVICES_ROUTE}/:id`;
export const NOTIFICATIONS_ROUTE = null; // TODO: set '/notifications';
export const USERS_ROUTE = '/users';
export const EMPLOYEES_ROUTE = '/employees';
export const PRODUCTS_ROUTE = '/products';
export const USERS_DETAILS_ROUTE = '/users/:id';
export const FOLLOW_ROUTE = null; // TODO: set '/follow';
export const ACCOUNT_ROUTE = '/account';
export const FACEBOOK_AUTH_ROUTE = '/oauth/facebook';

export const BRANDS_ROUTE = '/brands';
export const BRAND_DETAILS_ROUTE = `${BRANDS_ROUTE}/:id`;
export const DEPARTMENTS_ROUTE = '/departments';
export const DEPARTMENT_DETAILS_ROUTE = `${DEPARTMENTS_ROUTE}/:id`;

export const SIGN_IN_ROUTE = '/';
export const SIGN_UP_ROUTE = '/sign-up';
export const CHANGE_PASSWORD_ROUTE = '/reset-password';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const INVITE_ROUTE = '/confirm-invitation/:token';
export const INVITE_COMPANY_ROUTE = '/confirm-invitation-company/:token';
export const SUCCESS_REGISTRATION_ROUTE = '/success-registration';
