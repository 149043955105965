import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCampaignActions,
} from '../../../services/actions';
import { createSelector } from 'reselect';

const createActionsSelector = (id) => createSelector(
  (state) => state.campaigns.actions[id],
  (actions) => ({
    actions
  }),
);

const useActionsTable = (id) => {
  const dispatch = useDispatch();
  const selector = createActionsSelector(id);
  const data = useSelector(selector);

  const openPage = useCallback((id) => {
    if (!data.actions) {
      dispatch(getCampaignActions(id));
    }
  }, [dispatch, data.actions]);

  useEffect(() => {
    openPage(id);
  }, [openPage, id]);

  return {
    data,
  };
};

export default useActionsTable;
