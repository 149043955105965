import React from 'react';
import Frame from '../../../../components/Frame';
import tr from '../../../../utils/translate';
import { transformDuration } from '../../../../utils/dates';
import Table from '../../../../components/Table';
import FrameHeader from '../../../../components/FrameHeader';
import { questionsStatColumns } from '../../../../helpers/tableData';
import './styles.scss';
import { DEVICE_CAMPAIGN } from '../../../../constants/campaigns';
import useCampaignsDetailedTable from './hooks';
import Button from '../../../../components/Button';
import DeleteModal from '../DeleteModal';

const expandRender = ({ question_answers }) => question_answers.map((item, index) => (
  <div className="row mg-b-5" key={index}>
    <div className="col col-7">{`${index + 1}. ${item.question}`}</div>
    <div className="col col-3">{item.answer}</div>
    <div className="col col-2">{transformDuration(item.duration)}</div>
  </div>
));

const CampaignDetailedTab = ({
  statData, dateRange, campaign_type, handleSorting, sortBy, deleteSessionAdd, campaign_id,
}) => {
  const {
    pollSessions,
    actions: {
      getPollSessions,
      showDeleteModal,
      handleCancel,
      getNextPage,
    },
    data,
  } = useCampaignsDetailedTable();

  const tableHeader = (
    <Button
      onClick={() => showDeleteModal(({ titleModal: tr('Delete') }))}
    >
      { tr('Delete')}
    </Button>
  );

  return (
    <Frame
      header={
        <FrameHeader title={tr('User interactions')}
          // rightBlock={dateRange}
          rightBlock={tableHeader}
        />
      }
      className="user-interaction mg-b-30"
    >
      <Table
        loading={data.isLoading}
        columns={questionsStatColumns(campaign_type !== DEVICE_CAMPAIGN, handleSorting, sortBy)}
        data={statData?.list || []}
        expandRenderer={expandRender}
        deleteSessionAdd={deleteSessionAdd}
        deleteSessionHandler={getPollSessions}
        checkedList={pollSessions.idsToDeleteArr}
        pagination={{
          start: statData.start,
          stop: statData.stop,
          next_page: statData.next_page,
          activePage: statData.activePage,
          getNextPage,
        }}
      />
      <DeleteModal
        pollSessions={pollSessions}
        campaign_id={campaign_id}
        handleCancel={handleCancel}
        getNextPage={getNextPage}
      />
    </Frame>
  );
};

export default CampaignDetailedTab;
