import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import createLoadingSelector from '../../../../selectors/loadingSelector';
import AUTH from '../../services/types';
import { confirmInvitation as confirmInvitationAction, confirmBrandInvitation } from '../../services/actions'; // getInvitationInfo

const selector = createSelector(
  createLoadingSelector(AUTH.CONFIRM_INVITATION.base),
  (state) => state.auth.company_real_name,
  (isLoading, company_real_name) => ({
    isLoading,
    company_real_name,
  }),
);

const useInvite = (brandInvite) => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const { company_real_name, ...data } = useSelector(selector);
  const [, id, email, company_name,, signature] = window.atob(token).match(/^(.+)\*(.+)\*(.+)\*(.+)---(.+)$/) || [];

  const confirmInvitation = useCallback((values) => {
    brandInvite ? dispatch(confirmBrandInvitation(values))
      : dispatch(confirmInvitationAction(values));
  }, [brandInvite, dispatch]);

  return {
    actions: {
      confirmInvitation,
    },
    data: {
      ...data,
      form: {
        email,
        company_name,
        signature,
        id,
        city: '',
        state: '',
        address: '',
        zip: '',
      },
    },
  };
};

export default useInvite;
