import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { CAMPAIGN_MODAL, DELETE_MODAL } from '../../../../constants/modals';
import useModal from '../../../../utils/useModal';
import { getDeviceCampaigns } from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import CAMPAIGNS from '../../services/types';

const DEVICE_TYPES = {
  device: 1,
  social: 2,
  website: 4,
  email: 3,
};

const selector = createSelector(
  createLoadingSelector(CAMPAIGNS.READ_LIST.base),
  (state) => state.campaigns.list,
  (state) => state.brands.activeBrand,
  (state) => state.departments.activeDepartment,
  (state) => state.account.accountDetails.access_level,
  (state) => ({
    count: state.campaigns.count,
    activePage: state.campaigns.activePage,
  }),
  (isLoading, campaigns, activeBrand, activeDepartment, access_level, pagination) => ({
    isLoading,
    campaigns,
    activeBrand,
    activeDepartment,
    access_level,
    pagination,
  }),
);

const useCampaignsTable = () => {
  const dispatch = useDispatch();
  const { showModal } = useModal(CAMPAIGN_MODAL);
  const data = useSelector(selector);
  const [activeTab, setActiveTab] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [sortBy, setSortBy] = useState({
    key: 'date_created',
    direction: 'desc',
  });
  const [deleteIds, setdeleteIds] = useState({
    idsToDeleteArr: [],
  });
  const { showModal: showDeleteModal } = useModal(DELETE_MODAL);

  const getAllCampaigns = useCallback((page) => {
    dispatch(getDeviceCampaigns(
      {
        ...(data.activeBrand && { company: data.activeBrand }),
        ...(data.activeDepartment && { department: data.activeDepartment }),
        ...(activeTab !== 'all' && { type_is: DEVICE_TYPES[activeTab] }),
        ...(statusFilter !== 'all' && { status: statusFilter }),
        ordering: `${sortBy.direction === 'desc' ? '-' : ''}${sortBy.key}`,
        page,
      },
    ));
  }, [data.activeBrand, data.activeDepartment, dispatch, activeTab, sortBy, statusFilter]);

  useEffect(() => {
    getAllCampaigns();
  }, [getAllCampaigns]);

  const changeSorting = (field) => {
    if (sortBy.key === field) {
      setSortBy({
        key: field,
        direction: sortBy.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSortBy({
        key: field,
        direction: 'asc',
      });
    }
  };

  const getIdsToDelete = (id, checked) => {
    switch (checked) {
      case true:
        setdeleteIds((prevIds) => ({
          idsToDeleteArr: [...prevIds.idsToDeleteArr, id],
        }));
        break;
      case false:
        const filteredIdsArray = deleteIds.idsToDeleteArr.filter((item) => item !== id);
        setdeleteIds(() => ({
          idsToDeleteArr: filteredIdsArray,
        }));
        break;
      default:
        setdeleteIds((prevIds) => ({
          idsToDeleteArr: [...prevIds.idsToDeleteArr, id],
        }));
        break;
    }
  };

  return {
    data,
    activeTab,
    setActiveTab,
    sortBy,
    deleteIds,
    actions: {
      getAllCampaigns,
      showModal,
      changeSorting,
      setStatusFilter,
      getIdsToDelete,
      showDeleteModal,
    },
  };
};

export default useCampaignsTable;
