import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import UploadFile from '../../../../components/UploadFile';
import useCurrentCampaign from '../DeviceEmailReceipt/hooks';
import { DocumentBigIcon } from '../../../../components/Icons';

const DeviceLayout = ({ currentCampaignId }) => {
  const { data } = useCurrentCampaign(currentCampaignId);

  const file = _get(data, ['campaign', 'file'], null);

  return (
    <UploadFile
      name="file"
      color="green"
      data={file}
      disabled
      icon={<DocumentBigIcon />}
      hideDeleteButton
    />
  );
};

DeviceLayout.propTypes = {
  currentCampaignId: PropTypes.string.isRequired,
};

export default DeviceLayout;
