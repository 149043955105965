import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import CAMPAIGNS from '../../services/types';
import {
  prevStep,
  setFollowUp,
  getFollowUpQuestions,
} from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import useModal from '../../../../utils/useModal';
import { SUGGEST_OTHER_QUESTION_MODAL } from '../../../../constants/modals';

const submitLoadingSelector = createLoadingSelector(CAMPAIGNS.SET_FOLLOW_UP.base);
const detailsLoadingSelector = createLoadingSelector(CAMPAIGNS.GET_FOLLOW_UP_QUESTIONS.base);

const createFollowUpsSelector = (id) => createSelector(
  detailsLoadingSelector,
  submitLoadingSelector,
  (state) => state.campaigns.questions,
  (state) => state.campaigns.details[id].follow_up,
  (state) => state.campaigns.editStep,
  (isLoadingData, isLoadingSubmit, questions, followUp, step) => ({
    isLoadingData,
    isLoadingSubmit,
    followUp,
    questions,
    step,
  }),
);

const useFollowUpsQuestions = (id) => {
  const dispatch = useDispatch();
  const data = useSelector(createFollowUpsSelector(id));

  const goToBack = useCallback(() => {
    dispatch(prevStep());
  }, [dispatch]);

  const { showModal: showSuggestOther } = useModal(SUGGEST_OTHER_QUESTION_MODAL);

  const setQuestions = useCallback((values) => {
    const questions = Object.keys(values.questions).filter(
      (questionId) => values.questions[questionId],
    );
    dispatch(setFollowUp(id, { questions, delay: +values.delay }, { isNextStep: true }));
  }, [dispatch, id]);

  useEffect(() => {
    if (!data.questions || !data.questions.length) {
      dispatch(getFollowUpQuestions());
    }
  }, [data.questions, dispatch]);

  return {
    data,
    actions: {
      goToBack,
      setQuestions,
      showSuggestOther,
    },
  };
};

export default useFollowUpsQuestions;
