import React from 'react';
import { Link } from 'react-router-dom';

import { SIGN_IN_ROUTE } from '../../../../constants/routes';
import FormText from '../common/FormText';
import tr from '../../../../utils/translate';
import FormWrap from '../common/FormWrap';

const SuccessRegistration = () => (
  <FormWrap title={tr('Success')} subtitle={tr('Access has been requested, await confirmation by admin')}>
    <div className="text-center">
      <FormText>
        Back to &nbsp;
        <Link to={SIGN_IN_ROUTE}>{tr('Sign In')}</Link>
      </FormText>
    </div>
  </FormWrap>
);

export default SuccessRegistration;
