import React from 'react';
import { useLocation } from 'react-router';
import qs from 'qs';

import { FilterIcon, SearchIcon } from '../../../../../../components/Icons';
import translate from '../../../../../../utils/translate';
import { useUsersSearch, useUsersFilter } from '../../hooks';
import { removeEmptyParams } from '../../helpers';
import { SearchFieldName } from '../UsersSearchModal/Form';
import './styles.scss';

const UsersTableHeader = () => {
  const { actions: { showSearchModal } } = useUsersSearch();
  const { actions: { showFilterModal } } = useUsersFilter();
  const location = useLocation();

  const params = removeEmptyParams(qs.parse(location.search, { ignoreQueryPrefix: true }));
  // remove param from search
  const activeParams = Object.keys(params).filter((param) => param !== SearchFieldName);

  return (
    <div className="users-table-header">
      <div className="mg-r-10">
        <button className="users-table-header__btn" type="button" onClick={showFilterModal}>
          <FilterIcon />
          <span className="users-table-header__filter">{translate('Filtered by')}</span>
          <span className="users-table-header__filter-list">
            {`${activeParams.length}/6`}
          </span>
        </button>
      </div>

      <div>
        <button className="users-table-header__btn" type="button" onClick={showSearchModal}>
          <SearchIcon />
        </button>
      </div>
    </div>
  );
};

export default UsersTableHeader;
