import { takeEvery, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { hide } from 'redux-modal';

import translate from '../../../utils/translate';
import ACCOUNT from './types';
import { RESET_PASSWORD_MODAL, BRAND_INFO_MODAL, INVITE_USER_MODAL, ACCOUNT_INFO_MODAL } from '../../../constants/modals';

function* runChangePasswordSuccess() {
  yield put(hide(RESET_PASSWORD_MODAL));
  yield toast.success(translate('Password chaged successfully'));
}

function* runEditAccountDetails() {
  yield put(hide(ACCOUNT_INFO_MODAL));
  yield toast.success(translate('Account details saved successfully'));
}

function* runInviteUser() {
  yield put(hide(INVITE_USER_MODAL));
  yield toast.success(translate('Invitation email has been sent'));
}

export default function* account() {
  yield takeEvery(ACCOUNT.CHANGE_PASSWORD.SUCCESS, runChangePasswordSuccess);
  yield takeEvery(ACCOUNT.EDIT_ACCOUNT_DETAILS.SUCCESS, runEditAccountDetails);
  yield takeEvery(ACCOUNT.INVITE_USER.SUCCESS, runInviteUser);
}
