import {
  takeEvery, put, call, select, take, all,
} from 'redux-saga/effects';
import { show, hide } from 'redux-modal';
import { toast } from 'react-toastify';
import _get from 'lodash/get';

import { CAMPAIGN_MODAL, CAMPAIGN_EDIT_TRAY_MODAL, SUGGEST_OTHER_MODAL, ONLINE_CAMPAIGN_STATUS, TEST_EMAILS_MODAL, START_MAILING_MODAL, VALIDATE_DOMAIN_MODAL, UPDATE_PRODUCT_STOCK, CAMPAIGN_FOLLOW_UP_PREVIEW_MODAL, SUGGEST_OTHER_QUESTION_MODAL } from '../../../constants/modals';
import tr from '../../../utils/translate';
import {
  nextStep,
  getDeviceCampaignDetails,
  getDeviceCampaigns,
  setStep,
  firstStep,
  editInterface,
  setFollowUp,
  setFollowUpTheme,
  sendFollowUpPreviewEmail,
  createdCampaign,
  deleteSession
} from './actions';
import CAMPAIGNS from './types';
import { DEVICE_CAMPAIGN } from '../../../constants/campaigns';
import { deleteSessionDevices } from '../../devices/services/actions';

function* runCreateCampaignSuccess({ payload }) {
  yield call(toast.success, tr(`Campaign ${payload.res.name} created`));
  yield put(show(CAMPAIGN_MODAL, { data: { id: payload.res.id, isNew: true } }));
  yield put(setStep({ step: 1 }));
}

function* runCampaignSuccess({ payload: { req } }) {
  if (_get(req, 'data.direct_mail.email_list_text')) {
    yield call(toast.success, tr('Users imported from text field'));
  }
  yield put(nextStep());
}

function* runupdateProductsListSuccess(action) {
  const id = _get(action, ['payload', 'req', 'params', 'id'], null);
  yield put(getDeviceCampaignDetails(id));
  yield put(nextStep());
}

function* runUpdateInterfaceSuccess({ payload: { req } }) {
  if (req.meta && req.meta.isEmailListFile) {
    yield call(toast.success, tr('Users imported from CSV file'));
  }
  yield put(nextStep());
}

function* runUpdateCampaignInterfaceFailure() {
  yield call(toast.error, tr('User import failed'));
}

function* runFollowUpSuggestSuccess() {
  yield put(hide(SUGGEST_OTHER_QUESTION_MODAL));
  yield call(toast.success, tr('Your question has been sent'));
}

function* runUpdateFollowUpSuccess({ payload }) {
  if (payload.req.meta.isNextStep) {
    yield put(nextStep());
  }
}

function* runFollowUpDesign({ payload: { params, data, type, isNextStep, isNew } }) {
  const {
    background_url = '',
    background,
    colors,
    font,
    ...themeData
  } = data;

  yield all([
    put(setFollowUp(params.id, themeData, { type })),
    put(setFollowUpTheme(params.themeId, { background_url, background: background || '', colors, font })),
  ]);

  if (isNextStep) {
    yield put(createdCampaign(params.id, { isNew }));
  }
}

function* runFollowUpPreview() {
  const [{ payload: { res } }] = yield all([
    take(CAMPAIGNS.SET_FOLLOW_UP.SUCCESS),
    take(CAMPAIGNS.SET_FOLLOW_UP_THEME.SUCCESS),
  ]);
  yield put(show(CAMPAIGN_FOLLOW_UP_PREVIEW_MODAL, { data: { preview_url: res.preview_url } }));
}

function* runFollowUpPreviewEmail({ payload }) {
  yield all([
    take(CAMPAIGNS.SET_FOLLOW_UP.SUCCESS),
    take(CAMPAIGNS.SET_FOLLOW_UP_THEME.SUCCESS),
  ]);
  yield put(sendFollowUpPreviewEmail(payload.id, payload.data.recipient));
  yield take(CAMPAIGNS.SEND_FOLLOW_UP_PREVIEW_EMAIL.SUCCESS);
  yield call(toast.success, tr('Message successfully sent to email'));
}

function* runSetAssociatedDevicesSuccess(action) {
  const id = _get(action, ['payload', 'req', 'params', 'id'], null);

  /* there is some issue with returning whole info about devices
    in the response for the SET_ASSOCIATED_DEVICES
    get updated data from another request
  */
  yield put(getDeviceCampaignDetails(id));
  yield put(nextStep());
}

// function* runUploadMainEmailSuccess(action) {
//   const params = _get(action, ['payload', 'req', 'params'], null);
//   yield put(editInterface(params.id, {file: params.file, email_reciept: params.email_reciept}));
// }

function* runUpdateProductSuccess() {
  yield put(hide(CAMPAIGN_EDIT_TRAY_MODAL));
  yield call(toast.success, tr('Product modified'));
}

function* runExportConfigSuccess() {
  yield call(toast.success, tr('Config exported'));
}

function* runSetCampaignType({ payload }) {
  if (payload.campaign_type === DEVICE_CAMPAIGN) {
    yield put(nextStep());
  }
  yield put(nextStep());
}

function* runExportStatisticsSuccess(action) {
  const { res } = action.payload;
  const state = yield select();
  const cpgId = action.payload.req.params.id;
  const { campaigns: { details } } = state;
  const campaign = details[cpgId];
  const filename = `${campaign.name || 'campaign'}.${new Date().toISOString()}.csv`;

  const element = document.createElement('a');
  element.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(res)}`);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);

  yield call(toast.success, tr('Statistics exported'));
}

function* runExportEmailCSVSuccess(action) {
  const { res } = action.payload;
  const state = yield select();
  const cpgId = action.payload.req.params.id;
  const { campaigns: { details } } = state;
  const campaign = details[cpgId];
  const company = campaign.company && campaign.company.name || ''
  const filename = `${company} user list ${campaign.name || 'campaign'} ${new Date().toISOString()}.csv`;

  const element = document.createElement('a');
  element.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(res)}`);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);

  yield call(toast.success, tr('User list exported'));
}

function* runEditSocialNetworksSuccess() {
  yield put(nextStep());
}

function* runCreatedCampaignSuccess({ payload }) {
  const campaignId = payload.req.params.id;
  yield all([
    put(nextStep()),
    put(getDeviceCampaignDetails(campaignId))
  ]);
}

function* runCloseModal() {
  yield put(hide(CAMPAIGN_MODAL));
  yield put(firstStep());
  yield call(toast.success, tr('Thank you for submitting campaign'));
}

function* runSendSuggestionSucces() {
  yield put(hide(SUGGEST_OTHER_MODAL));
  yield call(toast.success, tr('Your suggestion is sent'));
}

function* runApproveSucces() {
  yield put(hide(ONLINE_CAMPAIGN_STATUS));
  yield call(toast.success, tr('Campaign is accepted'));
}

function* runRejectSucces() {
  yield put(hide(ONLINE_CAMPAIGN_STATUS));
  yield call(toast.success, tr('Campaign is rejected'));
}

function* runSendTestMailsSuccess() {
  yield put(hide(TEST_EMAILS_MODAL));
  yield call(toast.success, tr('Test emails are sent'));
}

function* runStartMailing() {
  yield put(hide(START_MAILING_MODAL));
  yield call(toast.success, tr('Mailing service is started'));
}

function* runValidateDomainSuccess() {
  yield put(hide(VALIDATE_DOMAIN_MODAL));
  yield call(toast.success, tr('Domain is validated'));
}

function* runAddProductStockSuccess() {
  yield put(hide(UPDATE_PRODUCT_STOCK));
  yield call(toast.success, tr('Product stock is updated'));
}

function* deleteSessions({ payload }) {
  const arr = [];

  if (payload.campaign_id) {
    payload.sessions.forEach((session) => {
      arr.push(put(deleteSession({ campaign_id: payload.campaign_id, poll_session_id: session })));
      arr.push(take(CAMPAIGNS.DELETE_SESSION.SUCCESS));
    });
  } else if (payload.deviceId) {
    payload.sessions.forEach((session) => {
      arr.push(put(deleteSessionDevices({ id: payload.deviceId, poll_session_id: session })));
      arr.push(take(CAMPAIGNS.DELETE_SESSION.SUCCESS));
    });
  }

  yield all(arr);
  yield call(payload.getNextPage, 0);
}

export default function* campaigns() {
  yield takeEvery(CAMPAIGNS.CREATE_CAMPAIGN.SUCCESS, runCreateCampaignSuccess);
  yield takeEvery(CAMPAIGNS.UPDATE_INTERFACE.SUCCESS, runUpdateInterfaceSuccess);
  yield takeEvery([
    CAMPAIGNS.EDIT_CAMPAIGN.SUCCESS,
  ], runCampaignSuccess);
  yield takeEvery([
    CAMPAIGNS.EDIT_CAMPAIGN.FAILURE,
    CAMPAIGNS.UPDATE_INTERFACE.FAILURE,
  ], runUpdateCampaignInterfaceFailure);
  yield takeEvery(CAMPAIGNS.SET_ASSOCIATED_DEVICES.SUCCESS, runSetAssociatedDevicesSuccess);
  yield takeEvery(CAMPAIGNS.CREATE_PRODUCT.SUCCESS, runUpdateProductSuccess);
  yield takeEvery(CAMPAIGNS.UPDATE_PRODUCT.SUCCESS, runUpdateProductSuccess);
  yield takeEvery([
    CAMPAIGNS.UPDATE_PRODUCT_SELECTION.SUCCESS,
    CAMPAIGNS.UPDATE_PRODUCT_SELECTION_WITH_QUANTITY.SUCCESS,
  ], runupdateProductsListSuccess);
  yield takeEvery(CAMPAIGNS.EXPORT_CONFIG.SUCCESS, runExportConfigSuccess);
  yield takeEvery([
    CAMPAIGNS.EXPORT_STATISTICS.SUCCESS,
    CAMPAIGNS.EXPORT_CUSTOM_STATISTICS.SUCCESS,
    CAMPAIGNS.EXPORT_UNSUBSCRIBES.SUCCESS,
  ], runExportStatisticsSuccess);
  yield takeEvery(CAMPAIGNS.GET_EMAIL_CSV_FILE.SUCCESS, runExportEmailCSVSuccess);
  yield takeEvery(CAMPAIGNS.SET_CAMPAIGN_TYPE, runSetCampaignType);
  yield takeEvery([
    CAMPAIGNS.EDIT_SOCIAL_NETWORKS.SUCCESS,
    CAMPAIGNS.SET_DEMOGRAPHICS.SUCCESS,
    CAMPAIGNS.UPDATE_EMAIL_INTERFACE_DATA.SUCCESS,
    CAMPAIGNS.SET_FILTERED_USER_LIST.SUCCESS,
  ], runEditSocialNetworksSuccess)
  yield takeEvery(CAMPAIGNS.CLOSE_MODAL, runCloseModal);
  yield takeEvery(CAMPAIGNS.CREATED_CAMPAIGN.SUCCESS, runCreatedCampaignSuccess);
  yield takeEvery(CAMPAIGNS.APPROVE_CAMPAIGN.SUCCESS, runApproveSucces);
  yield takeEvery(CAMPAIGNS.REJECT_CAMPAIGN.SUCCESS, runRejectSucces);
  yield takeEvery(CAMPAIGNS.SEND_SUGGESTION.SUCCESS, runSendSuggestionSucces);
  yield takeEvery(CAMPAIGNS.SEND_TEST_MAILS.SUCCESS, runSendTestMailsSuccess);
  yield takeEvery(CAMPAIGNS.RUN_MAILING_SERVICE.SUCCESS, runStartMailing);
  yield takeEvery(CAMPAIGNS.VALIDATE_DOMAIN.SUCCESS, runValidateDomainSuccess);
  // yield takeEvery(CAMPAIGNS.UPLOAD_MAIN_EMAIL.SUCCESS, runUploadMainEmailSuccess);
  yield takeEvery(CAMPAIGNS.ADD_PRODUCT_STOCK.SUCCESS, runAddProductStockSuccess);
  yield takeEvery(CAMPAIGNS.SET_FOLLOW_UP.SUCCESS, runUpdateFollowUpSuccess);
  yield takeEvery(CAMPAIGNS.SEND_FOLLOW_UP_SUGGEST.SUCCESS, runFollowUpSuggestSuccess);
  yield takeEvery(CAMPAIGNS.FOLLOW_UP_PREVIEW_EMAIL, runFollowUpPreviewEmail);
  yield takeEvery(CAMPAIGNS.FOLLOW_UP_PREVIEW, runFollowUpPreview);
  yield takeEvery(CAMPAIGNS.FOLLOW_UP_DESIGN, runFollowUpDesign);

  yield takeEvery(CAMPAIGNS.DELETE_SESSIONS.base, deleteSessions);
}
