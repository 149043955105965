import {
  takeEvery, call, delay, put,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { SUCCESS_REGISTRATION_ROUTE, SIGN_IN_ROUTE } from '../../../constants/routes';
import history from '../../../utils/history';
import translate from '../../../utils/translate';
import { getAccountDetails } from '../../account/services/actions';
import { setAuthHeader, removeAuthHeader } from '../../../utils/apiService';
import { tokenRefresh } from './actions';
import AUTH from './types';

function* runAuthSuccess({ payload: { res } }) {
  yield call(setAuthHeader, res.token);
  yield put(getAccountDetails());
  yield delay(270000); //* 270000 = 4.5 min
  yield put(tokenRefresh(res.token));
}

function* runRefreshToken({ payload: { res } }) {
  yield delay(270000); //* 270000 = 4.5 min

  const token = localStorage.getItem('auth');
  try {
    if (token) {
      yield put(tokenRefresh(res.token));
      yield call(setAuthHeader, res.token);
    } else {
      yield call(removeAuthHeader);
      yield history.push('/');
    }
  } catch (error) {
    yield call(removeAuthHeader);
    yield history.push('/');
  }
}

function* runSignOut() {
  yield call(removeAuthHeader);
  yield history.push('/');
  window.location.reload(true);
}

function* runSignUpSuccess() {
  yield history.push(SUCCESS_REGISTRATION_ROUTE);
}

function* runForgotPasswordSuccess() {
  yield toast.success(translate('Please check your email for further instructions'));
}

function* runChangePasswordSuccess() {
  yield history.push('/');
  yield toast.success(translate('Password chaged'));
}

function* runConfirmInvitationSuccess() {
  yield toast.success(translate('Access has been requested; You can Sign In'));
  yield history.push(SIGN_IN_ROUTE);
}

function* receiveInviteSuccess() {
}

export default function* auth() {
  yield takeEvery(AUTH.SIGN_IN.SUCCESS, runAuthSuccess);
  yield takeEvery(AUTH.TOKEN_REFRESH.SUCCESS, runRefreshToken);
  yield takeEvery(AUTH.SIGN_OUT, runSignOut);
  yield takeEvery(AUTH.SIGN_UP.SUCCESS, runSignUpSuccess);
  yield takeEvery(AUTH.FORGOT_PASSWORD.SUCCESS, runForgotPasswordSuccess);
  yield takeEvery(AUTH.CHANGE_PASSWORD.SUCCESS, runChangePasswordSuccess);
  yield takeEvery(AUTH.CONFIRM_INVITATION.SUCCESS, runConfirmInvitationSuccess);
  yield takeEvery(AUTH.GET_INVITATION.SUCCESS, receiveInviteSuccess);
  yield takeEvery(AUTH.CONFIRM_BRAND_INVITATION.SUCCESS, runConfirmInvitationSuccess);
}
