import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import PieChart from '../../../components/PieChart';
import PieChartLegend from '../../../components/PieChartLegend';
import Frame from '../../../components/Frame';
import { getArraySum } from '../../../helpers/arrayHelpers';
import './styles.scss';

const formatChartData = (data) => ({
  labels: _map(data, 'label'),
  values: _map(data, 'count'),
});

const QAChart = ({ chartData }) => {
  const colors = ['#BBC8F3', '#66A4FB', '#EE8CE5', '#B0CCFA', '#65E0E0', '#FF745C', '#FF2626'];
  const data = formatChartData(chartData.answers);

  const generatedLegends = () => {
    const dataSum = getArraySum(data.values);
    return data.values.map((value, index) => {
      const percentValue = Math.round((value / dataSum) * 100);
      return (
        <div className="qa-legend-wrap" key={index}>
          <PieChartLegend
            title={data.labels[index]}
            subtitle=""
            badgeColorCode={colors[index]}
          />
          <div className="qa-legend-value" key={`legend-value-${index}`}>
            {percentValue}
            {' '}
%
          </div>
        </div>

      );
    });
  };

  return (
    <Frame className="qa-chart-wrapper mg-b-30" classNameBody="qa-pie-chart">
      <div>
        <PieChart chartData={data.values} labels={data.labels} width={300} />
      </div>
      <div className="qa-pie-chart-legend">
        {generatedLegends()}
      </div>
    </Frame>
  );
};

QAChart.propTypes = {
  chartData: PropTypes.shape({
    label: PropTypes.string,
    count: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
};

QAChart.defaultProps = {
  chartData: {},
};

export default QAChart;
