import React from 'react';
import PropTypes from 'prop-types';
import _sortBy from 'lodash/sortBy';
import Frame from '../Frame';
import ProductListItem from './ProductListItem';
import Button from '../Button';
import { ExpandPlusIcon } from '../Icons';
import tr from '../../utils/translate';


const ProductList = (props) => {
  const {
    header,
    data,
    className,
    onClickEdit,
    maxProducts,
  } = props;

  const maxProductsByTray = Object.values(maxProducts);
  const sortedData = _sortBy(data, (v) => v.number);
  return (
    <Frame header={header} className={className}>
      <div className="product__add">
        <a onClick={onClickEdit}>
          <ExpandPlusIcon />
          {tr('Add product')}
        </a>
      </div>
      {sortedData.map((tray, index) => (
        <ProductListItem
          key={index}
          data={tray}
          maxProducts={maxProductsByTray[index]}
          onClickEdit={onClickEdit}
          appendFieldLabel=" "
        />
      ))}
    </Frame>
  );
};

ProductList.propTypes = {
  className: PropTypes.string,
  onClickEdit: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  header: PropTypes.string,
  maxProducts: PropTypes.objectOf(PropTypes.string).isRequired,
};

ProductList.defaultProps = {
  className: null,
  header: null,
  onClickEdit: null,
};

export default ProductList;
