import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import useModal from '../../../../utils/useModal';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import ACCOUNT from '../../services/types';
import { RESET_PASSWORD_MODAL } from '../../../../constants/modals';
import { changePassword as changePasswordAction } from '../../services/actions';

const selector = createSelector(
  createLoadingSelector(ACCOUNT.CHANGE_PASSWORD.base),
  (isLoading) => ({
    isLoading,
  }),
);

const useResetPasswordModal = () => {
  const dispatch = useDispatch();

  const changePassword = useCallback((values) => {
    dispatch(changePasswordAction(values));
  }, [dispatch]);

  const { hideModal } = useModal(RESET_PASSWORD_MODAL);

  return {
    actions: {
      hideModal,
      changePassword,
    },
    data: useSelector(selector),
  };
};

export default useResetPasswordModal;
