import DEPARTMENT from './types';

const defaultState = {
  list: [],
  listMini: [],
  filteredListMini: [],
  inviteMemberListDep: [],
  activeDepartment: null,
  activeDepartmentData: null,
  count: null,
  activePage: 1,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case DEPARTMENT.GET_DEPARTMENTS.SUCCESS:
      return {
        ...state,
        list: payload.res.results,
        count: payload.res.count,
        activePage: payload.req.data.page || 1,
      };
    case DEPARTMENT.GET_DEPARTMENTS_MINI.SUCCESS:
      return {
        ...state,
        listMini: payload.res,
      };
    case DEPARTMENT.FILTER_DEPARTMENTS_MINI.SUCCESS:
      return {
        ...state,
        filteredListMini: payload.res,
      };
    case DEPARTMENT.GET_DEPARTMENT.SUCCESS:
      return {
        ...state,
        activeDepartmentData: payload.res,
      };
    case DEPARTMENT.SET_ACTIVE_DEPARTMENT:
      return {
        ...state,
        activeDepartment: payload.department,
      };
    case DEPARTMENT.CREATE_DEPARTMENT.SUCCESS:
      return {
        ...state,
        list: [
          ...state.list,
          payload.res,
        ],
        listMini: [
          ...state.listMini,
          payload.res,
        ],
      };
    case DEPARTMENT.EDIT_DEPARTMENT.SUCCESS:
      return {
        ...state,
        list: state.list.map((department) => (
          department.id === payload.res.id ? { ...department, ...payload.res } : department
        )),
        listMini: state.listMini.map((department) => (
          department.id === payload.res.id ? { ...department, ...payload.res } : department
        )),
      };
    case DEPARTMENT.ACTIVATE_DEPARTMENT.SUCCESS:
      const activatedDepartment = state.list.find((department) => department.id === payload.req.params.id) || {};

      return {
        ...state,
        list: state.list.map((department) => (
          department.id === payload.req.params.id ? { ...department, is_active: true } : department
        )),
        listMini: [
          ...state.listMini,
          activatedDepartment,
        ],
      };
    case DEPARTMENT.DEACTIVATE_DEPARTMENT.SUCCESS:
      return {
        ...state,
        list: state.list.map((department) => (
          department.id === payload.req.params.id ? { ...department, is_active: false } : department
        )),
        listMini: state.listMini.filter((department) => department.id !== payload.req.params.id),
      };
      
    case DEPARTMENT.INVITE_MEMBER_GET_DEPARTMENTS_MINI.SUCCESS:
      return {
        ...state,
        inviteMemberListDep: payload.res,
      };
    default:
      return state;
  }
};
