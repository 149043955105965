import React from 'react';
import PropTypes from 'prop-types';

import translate from '../../../../utils/translate';
import PageHeader from '../../../../components/PageHeader';
import Table from '../../../../components/Table';
import { useUsers } from './hooks';
import { getUserColumns } from '../../../../helpers/tableData';
import UsersTableHeader from './components/UsersTableHeader';
import UsersSearchModal from './components/UsersSearchModal';
import UsersFilterModal from './components/UsersFilterModal';
import './styles.scss';

const Main = ({ location }) => {
  const {
    data: { users, isLoading, pagination },
    actions: {
      changeSorting,
      getNextPage,
    },
    sortBy,
  } = useUsers(location);
  const columns = getUserColumns(true, changeSorting, sortBy);

  return (
    <div className="page-inner pd-t-25 pd-b-25">
      <PageHeader title={translate('Customers')} />
      <div className="row">
        <div className="col-md-12 customers-table">
          <Table
            caption={`${translate('All customers')} (${users.length} total)`}
            header={<UsersTableHeader />}
            columns={columns}
            data={users}
            loading={isLoading}
            pagination={{...pagination, getNextPage}}
          />
        </div>
      </div>

      <UsersSearchModal location={location} />
      <UsersFilterModal location={location} />
    </div>
  );
};

Main.propTypes = {
  location: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Main;
