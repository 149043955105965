import { createRequestTypes } from '../../../utils/actionHelpers';

export default createRequestTypes('USERS', [
  'READ_USERS_LIST',
  'READ_LOCATIONS_LIST',
  'READ_USER_DETAILS',
  'READ_USER_DEVICES',
  'READ_USER_CAMPAIGNS',
  'READ_USER_POLLS'
]);
