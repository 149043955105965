import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './styles.scss';

const FrameHeader = ({ title, rightBlock, className }) => (
  <div className={cn('stat-frame-header', className)}>
    <div className="stat-frame-header__title">{title}</div>
    <div className="stat-frame-header__right-block">{rightBlock}</div>
  </div>
);

FrameHeader.propTypes = {
  rightBlock: PropTypes.node,
  title: PropTypes.node,
  className: PropTypes.string,
};

FrameHeader.defaultProps = {
  rightBlock: null,
  title: null,
  className: null,
};

export default FrameHeader;
