import React from 'react';
import PropTypes from 'prop-types';
import _sum from 'lodash/sum';
import moment from 'moment';
import PredictedGenderChart from '../../../../shared/statistic/PredictedGenderChart';
import QuestionCharts from '../../../../components/QuestionCharts';
import FrameHeader from '../../../../components/FrameHeader';
import FollowUpBadges from '../../widgets/FollowUpBadges';
import MailBadges from '../../widgets/MailBadges';
import Frame from '../../../../components/Frame';
import Loader from '../../../../components/Loader';
import tr from '../../../../utils/translate';
import { formatBeforeSend } from '../../../../utils/dates';
import './styles.scss';

const CampaignFollowUpsTab = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (!props.data) return <Loader size="lg" />;

  const {
    data: {
      demographics,
      responses,
      ...statistic
    } = {},
  } = props;

  const mailBadgesInfo = {
    opened: statistic.opened,
    // opened: statistic.unique_opens,
    clicked: statistic.clicked,
    bounced: statistic.bounced,
    unsubscribed: statistic.unsubscribed,
  };

  const badgesInfo = {
    responses: statistic.total_responses,
    totalVisits: statistic.total_visits,
    completionRate: statistic.completion_rate,
    averageCompletionTime: statistic.average_completion_time,
  };

  const mailsStattistics = () => {
    const mailStatData = [
      [
        { label: tr('Succesful delivery'), count: statistic.delivered },
        { label: tr('Total opens'), count: statistic.total_opened },
        { label: tr('Last opened'), count: statistic.last_opened && statistic.last_opened, isTime: true },
        { label: tr('Forwarded'), count: 0 },
      ],
      [
        { label: tr('Clicks per unique opens'), count: statistic.clicks_per_opens },
        { label: tr('Total clicks'), count: statistic.total_clicks },
        { label: tr('Last clicked'), count: statistic.last_clicked && statistic.last_clicked, isTime: true },
        { label: tr('Abuse reports'), count: statistic.abuse_reports },
      ],
    ];
    return (
      <>
        <MailBadges stats={mailBadgesInfo} />
        <div className="mail-stat-text-block-wrapper">
          {mailStatData.map((block, index) => (
            <Frame key={index} className="mail-stat-text-block">
              {block.map((item) => (
                <div key={item.label} className="text-block-item">
                  <span className="text-block-item-title">{item.label}</span>
                  <span className="text-block-item-value">{ item.isTime && item.count ? formatBeforeSend(item.count) : item.count}</span>
                </div>
              ))}
            </Frame>
          ))}
        </div>
      </>
    );
  };

  const PredictedGenderBlock = () => {
    const predictedGender = demographics ? demographics.gender : [];
    return (
      <PredictedGenderChart
        chartData={predictedGender}
      />
    );
  };

  const PredictedAgeBlock = () => {
    const predictedAge = demographics ? demographics.age : [];
    return (
      <div className="predicted-age-wrapper">
        <h3>{tr('Age')}</h3>
        <div className="predicted-chart-age-wrapper">
          {predictedAge.length > 0 ? (
            predictedAge.map((ageData, index) => (
              <div className="age-item" key={index}>
                <span className="age-item-title">{ageData.label}</span>
                <div className="age-item-bar">
                  <div className="age-item-progress" style={{ width: `${ageData.percents}%` }} />
                </div>
                <span className="age-item-value">{`${ageData.percents}%`}</span>
              </div>
            ))
          ) : (
            <h3>No data</h3>
          )}

        </div>
      </div>
    );
  };


  return (
    <>
      <FollowUpBadges stats={badgesInfo} />
      {mailsStattistics()}
      <Frame
        header={<FrameHeader title={tr('Response summary')} />}
        className="mg-t-30 predicted_demographics_wrapper"
        classNameBody="follow_ups_summary"
      >
        {responses
          ? Object.values(responses).map((question, index) => (
            <div className="follow_ups_question" key={index}>
              <h4 className="follow_ups_question_title">{`${index + 1}. ${question.title}`}</h4>
              <p className="follow_ups_question_descrioption">
                {`${_sum(Object.values(question.answers.count))} out of ${question.answers.amount} people answered this question`}
              </p>
              <div className="follow_ups_question_result">
                <QuestionCharts type={question.type} answers={question.answers} />
              </div>
            </div>
          ))
          : (<div className="follow_ups_question"><h3>No data</h3></div>)}
      </Frame>
      <Frame
        header={<FrameHeader title={tr('Predicted demographics')} />}
        className="mg-t-30 predicted_demographics_wrapper"
      >
        {PredictedGenderBlock()}
        {PredictedAgeBlock()}
      </Frame>
    </>
  );
};

CampaignFollowUpsTab.propTypes = {
  data: PropTypes.shape({}),
};

CampaignFollowUpsTab.defaultProps = {
  data: undefined,
};

export default CampaignFollowUpsTab;
