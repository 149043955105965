import React from 'react';
import {
  reduxForm,
  propTypes as propTypesReduxForm,
} from 'redux-form';

import Input from '../../../../../../../components/Input';
// import Select from '../../../../../../../components/Select';
// import Loader from '../../../../../../../components/Loader';
import ModalFormFooter from '../../../../../../../components/ModalFormFooter';
import translate from '../../../../../../../utils/translate';
import { useHeaderIssue } from '../../hooks';
// import { DevicesIcon, CampaignsTableIcon } from '../../../../../../../components/Icons';
import validate from './validate';

const ReportIssueForm = ({
  handleSubmit,
}) => {
  const {
    actions: { hideModal, hanleIssueSubmit },
    data: {
      // devices,
      // campaigns,
      // isLoadingDevices,
      // isLoadingCampaigns,
      isLoadingIssue,
    },
  } = useHeaderIssue();

  // if (isLoadingDevices || isLoadingCampaigns) return <Loader />;

  return (
    <form onSubmit={handleSubmit(hanleIssueSubmit)}>
      <div className="modal-body">
        {/* <div className="row mg-b-15">
          <div className="col-md-6">
            <Select
              name="device"
              options={devices}
              prepend={<DevicesIcon />}
            />
          </div>

          <div className="col-md-6">
            <Select
              name="campaign"
              options={campaigns}
              prepend={<CampaignsTableIcon />}
            />
          </div>
        </div> */}

        <div className="row">
          <div className="col-md-12">
            <Input
              name="issue"
              type="textarea"
              placeholder={translate('Write your message...')}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <ModalFormFooter
          handleClose={hideModal}
          confirmText={translate('Send')}
          isLoading={isLoadingIssue}
        />
      </div>
    </form>
  );
};

ReportIssueForm.propTypes = {
  ...propTypesReduxForm,
};

const reduxFormConfig = {
  form: 'REPORT_ISSUE_FORM',
  validate,
};


export default reduxForm(reduxFormConfig)(ReportIssueForm);
