import { applyMiddleware, compose } from 'redux';
// import logger from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import history from '../utils/history';

export const sagaMiddleware = createSagaMiddleware();

const middleware = compose(
  applyMiddleware(
    // logger,
    sagaMiddleware,
    routerMiddleware(history),
  ),
  /* eslint-disable */
  window && window.devToolsExtension ? window.devToolsExtension() : (devTools) => devTools,
);

export default middleware;
