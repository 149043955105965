import { createSelector } from 'reselect';
import createLoadingSelector from '../../../selectors/loadingSelector';
import DEVICES from '../services/types';
import CAMPAIGNS from '../../campaigns/services/types';

export const devicesListSelector = (state) => state.devices.list;

export const devicesPaginationSelector = (state) => (
  {
    count: state.devices.count,
    next: state.devices.next,
    previous: state.devices.previous,
    activePage: state.devices.activePage,
  }
)

export const deviceLoadingSelector = createLoadingSelector(DEVICES.GET_ALL_DEVICES.base);

export const deviceInfoLoadingSelector = createLoadingSelector([
  DEVICES.GET_DIVECE_INFO.base,
  CAMPAIGNS.READ_DETAILS.base,
]);

export const emailReceiptLoadingSelector = createLoadingSelector([
  DEVICES.CREATE_EMAIL_RECEIPT.base,
  DEVICES.DELETE_EMAIL_RECEIPT.base,
]);

export const layoutLoadingSelector = createLoadingSelector([
  DEVICES.CREATE_LAYOUT.base,
  DEVICES.DELETE_LAYOUT.base,
]);

export const rebootDeviceLoadingSelector = createLoadingSelector([
  DEVICES.REBOOT_DEVICE.base,
]);

export const deviceStatisticLoadingSelector = createLoadingSelector(
  DEVICES.GET_DEVICE_STATISTIC.base,
);

export const createDeviceInfoByIdSelector = (id) => createSelector(
  (state) => state.devices.details,
  (details) => details[id],
);
