import React from 'react';

import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import ModalFormFooter from '../../../../components/ModalFormFooter';
import tr from '../../../../utils/translate';
import ReduxForm from '../../../../components/ReduxForm';
import Modal from '../../../../components/Modal';
import { ACCOUNT_INFO_MODAL } from '../../../../constants/modals';
import { ACCOUNT } from '../../../../constants/forms';
import useBrandModal from './hooks';
import validate from './validate';

const BrandModal = () => {
  const {
    actions: {
      hideModal,
      putAccountDetails,
    },
    data: {
      initialValues,
      isLoading,
    },
  } = useBrandModal();

  return (
    <Modal name={ACCOUNT_INFO_MODAL} title={tr('Edit account info')} size="sm">
      <ReduxForm
        form={ACCOUNT.ACCOUNT_FORM}
        validate={validate}
        formData={{
          initialValues,
          onSubmit: putAccountDetails,
        }}
      >
        <div className="modal-body">
          <Input
            name="first_name"
            prependFieldLabel={tr('First name')}
            className="pd-b-15"
          />

          <Input
            name="last_name"
            prependFieldLabel={tr('Last name')}
            className="pd-b-15"
          />

          <Input
            name="email"
            prependFieldLabel={tr('Email address')}
            className="pd-b-15"
          />
        </div>
        <div className="modal-footer">
          <ModalFormFooter
            handleClose={hideModal}
            confirmText={tr('Save changes')}
            isLoading={isLoading}
          />
        </div>
      </ReduxForm>
    </Modal>
  );
};

export default BrandModal;
