import React from 'react';
import Modal from '../../../../components/Modal';
import { OVERVIEW_WIDGET_MODAL } from '../../../../constants/modals';
import UnsubscribedForm from './UnsubscribedForm';

const UnsubscribedModal = () => (
  <Modal name={OVERVIEW_WIDGET_MODAL} size="md">
    {({ handleHide, ...initialValues }) => (
      <UnsubscribedForm />
    )}
  </Modal>
);

export default UnsubscribedModal;
