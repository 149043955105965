import tr from '../../../../utils/translate';

const validate = ({
  age_from,
  age_to,
}) => {
  const errors = {};

  if (age_from && age_to) {
    if (age_from > age_to) {
      errors.age_to = tr('End age must be bigger than Start age');
    }
  }

  return errors;
};

export default validate;
