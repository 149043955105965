import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import _find from 'lodash/find';

import { createProduct as createProductAction, updateProduct as updateProductAction } from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import PRODUCT from '../../services/types';

const selector = (product_id) => createSelector(
  createLoadingSelector(PRODUCT.CREATE_PRODUCT.base),
  createLoadingSelector(PRODUCT.UPDATE_PRODUCT.base),
  (state) => _find(state.products.list, ['id', product_id]),
  (state) => state.brands.activeBrand,
  (state) => state.brands.listMini,
  (state) => state.account.accountDetails,
  (isLoadingCreate, isLoadingUpdate, product, activeBrand, brands, account) => ({
    isLoadingCreate,
    isLoadingUpdate,
    product,
    activeBrand,
    brands,
    account,
  }),
);

const useProductForm = (id) => {
  const dispatch = useDispatch();

  const editTray = useCallback((data) => {
    if (id) {
      dispatch(updateProductAction(id, data));
    } else {
      dispatch(createProductAction(data));
    }
  }, [dispatch, id]);

  const submit = useCallback((data) => {
    const {
      img,
      ...otherData
    } = data;
    const [image] = img instanceof Array ? img : [];
    editTray(
      {
        ...otherData,
        img: image || '',
      },
    );
  }, [editTray]);

  return {
    data: useSelector(selector(id)),
    actions: {
      submit,
    },
  };
};

export default useProductForm;
