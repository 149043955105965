import React from 'react';

import { IssueIcon } from '../../../../../components/Icons';
import IssueModal from './components/IssueModal';
import { useHeaderIssue } from './hooks';
import './styles.scss';

const HeaderIssue = () => {
  const { actions: { showModal } } = useHeaderIssue();
  return (
    <div className="header-issue">
      <button type="button" className="header-issue__btn" onClick={showModal}>
        <IssueIcon />
      </button>
      <IssueModal />
    </div>
  );
};

export default HeaderIssue;
