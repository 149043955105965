import { toast } from 'react-toastify';
import { createAction } from 'redux-actions';
import ACCOUNT from './types';
import { createActionRequest } from '../../../utils/actionHelpers';
import history from '../../../utils/history';
import tr from '../../../utils/translate';
import { ACCOUNT_ROUTE } from '../../../constants/routes';

export const getCountries = createActionRequest(ACCOUNT.GET_COUNTRIES, 'get', '/bo/accounts/countries/');

export const getAccounts = createActionRequest(ACCOUNT.GET_ACCOUNTS, 'get', '/bo/accounts/company-users/');

export const getFacebookAccounts = createActionRequest(ACCOUNT.GET_FACEBOOK_ACCOUNTS, 'get', '/bo/accounts/facebook/');

export const getFacebookLink = createActionRequest(ACCOUNT.GET_FACEBOOK_LINK, 'get', '/bo/oauth/facebook/', {
  onSuccess: ({ url }) => window.open(url, '_blank'),
});

export const addFacebookAccount = createActionRequest(ACCOUNT.ADD_FACEBOOK_ACCOUNT, 'post', '/bo/oauth/facebook/', {
  onSuccess: () => {
    history.push(ACCOUNT_ROUTE);
  },
});

export const removeFacebookAccount = createActionRequest(ACCOUNT.REMOVE_FACEBOOK_ACCOUNT, 'delete', '/bo/accounts/facebook/{id}/', {
  setDataPath: (id) => ({ id }),
});

export const getAccountDetails = createActionRequest(ACCOUNT.GET_ACCOUNT_DETAILS, 'get', '/bo/accounts/details/');

export const putAccountDetails = createActionRequest(ACCOUNT.EDIT_ACCOUNT_DETAILS, 'put', '/bo/accounts/details/');

export const changePassword = createActionRequest(ACCOUNT.CHANGE_PASSWORD, 'post', '/bo/accounts/change-password/');

export const inviteUser = createActionRequest(ACCOUNT.INVITE_USER, 'post', '/bo/invitations/');

export const getSocialInfoById = createActionRequest(ACCOUNT.GET_SOCIAL_INFO_BY_ID, 'get', '/bo/accounts/facebook/{id}/', {
  setDataPath: (id) => ({ id }),
});

export const getSocialAdAccountsById = createActionRequest(ACCOUNT.GET_SOCIAL_AD_ACCOUNT_BY_ID, 'get', '/bo/accounts/facebook/{id}/ad_accounts/', {
  setDataPath: (id) => ({ id }),
});

export const getSocialAvailableAdAccountsById = createActionRequest(ACCOUNT.GET_SOCIAL_AVAILABLE_AD_ACCOUNT_BY_ID, 'get', '/bo/accounts/facebook/{id}/available-ad-accounts/', {
  setDataPath: (id) => ({ id }),
});

export const adAccounts = createActionRequest(ACCOUNT.AD_ACCOUNTS, 'post', '/bo/accounts/ad-accounts/', {
  onSuccess: () => {
    toast.success(tr('Ad Accounts Successful'));
  },
});

export const deleteAdAccounts = createActionRequest(ACCOUNT.DELETE_AD_ACCOUNTS, 'delete', '/bo/accounts/ad-accounts/{id}/', {
  setDataPath: (id, socialId) => ({ id, socialId }),
  setData: (id, socialId) => ({ id, socialId }),
  onSuccess: () => {
    toast.success(tr('Delete Ad Accounts Successful'));
  },
});

export const deleteAdAccountFromStore = createAction(ACCOUNT.DELETE_AD_ACCOUNT_FROM_STORE);
