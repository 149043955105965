
import PRODUCT from './types';

const defaultState = {
  list: [],
  count: null,
  activePage: 1,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case PRODUCT.READ_PRODUCTS_LIST.SUCCESS: {
      return {
        ...state,
        list: payload.res.results,
        count: payload.res.count,
        activePage: payload.req.data.page || 1,
      };
    }

    case PRODUCT.CREATE_PRODUCT.SUCCESS:
      return {
        ...state,
        list: [
          ...state.list,
          payload.res,
        ],
      };

    case PRODUCT.UPDATE_PRODUCT.SUCCESS: 
      return {
        ...state,
        list: state.list.map(
          (product) => (product.id === payload.res.id
            ? { ...payload.res }
            : product
          ),
        ),
      };
      
    default:
      return state;
  }
};
