import tr from '../../../../../utils/translate';
import {
  DashboardIcon,
  CampaignsIcon,
  DevicesIcon,
  NotificationsIcon,
  ClientsIcon,
  FollowUpsIcon,
  AccountIcon,
  SidemenuPlanetIcon,
  StartIcon,
  TeamIcon,
  ProductIcon,
} from '../../../../../components/Icons';
import {
  ACCOUNT_ROUTE,
  CAMPAIGNS_ROUTE,
  USERS_ROUTE,
  DEVICES_ROUTE, FOLLOW_ROUTE,
  HOME_ROUTE,
  NOTIFICATIONS_ROUTE,
  DEPARTMENTS_ROUTE,
  BRANDS_ROUTE,
  EMPLOYEES_ROUTE,
  PRODUCTS_ROUTE,
} from '../../../../../constants/routes';

import {
  ROLE_COMPANY_ADMIN, ROLE_DEPARTMENT_ADMIN, ROLE_SUPERADMIN, ROLE_CONTENT_MANAGER,
} from '../../../../../constants/roles';

const adminRoutes = (access_level) => [
  {
    name: tr('Dashboard'),
    route: HOME_ROUTE,
    exact: true,
    icon: DashboardIcon,
  },
  {
    name: tr('Brands'),
    route: BRANDS_ROUTE,
    exact: true,
    icon: SidemenuPlanetIcon,
    disabled: access_level !== ROLE_SUPERADMIN,
  },
  {
    name: tr('Teams'),
    route: DEPARTMENTS_ROUTE,
    exact: true,
    icon: TeamIcon,
    disabled: ![ROLE_SUPERADMIN, ROLE_COMPANY_ADMIN].includes(access_level),
  },
  {
    name: tr('Members'),
    route: EMPLOYEES_ROUTE,
    exact: true,
    icon: ClientsIcon,
  },
  {
    name: tr('Devices'),
    route: DEVICES_ROUTE,
    exact: false,
    icon: DevicesIcon,
  },
  {
    name: tr('Campaigns'),
    route: CAMPAIGNS_ROUTE,
    exact: false,
    icon: CampaignsIcon,
  },
  {
    name: tr('Products'),
    route: PRODUCTS_ROUTE,
    exact: true,
    icon: ProductIcon,
  },
  {
    name: tr('Notifications'),
    route: NOTIFICATIONS_ROUTE,
    exact: false,
    icon: NotificationsIcon,
  },
  {
    name: tr('Customers'),
    route: USERS_ROUTE,
    exact: false,
    icon: StartIcon,
  },
  {
    name: tr('Follow'),
    route: FOLLOW_ROUTE,
    exact: false,
    icon: FollowUpsIcon,
  },
  {
    name: tr('Account'),
    route: ACCOUNT_ROUTE,
    exact: true,
    icon: AccountIcon,
  },
];

const contentManagerRoutes = [
  {
    name: tr('Devices'),
    route: DEVICES_ROUTE,
    exact: true,
    icon: DevicesIcon,
  },
  {
    name: tr('Account'),
    route: ACCOUNT_ROUTE,
    exact: true,
    icon: AccountIcon,
  },
];

const getAccessLevelRoutes = (access_level) => {
  switch (access_level) {
    case ROLE_COMPANY_ADMIN:
    case ROLE_SUPERADMIN:
    case ROLE_DEPARTMENT_ADMIN:
      return adminRoutes(access_level);
    case ROLE_CONTENT_MANAGER:
      return contentManagerRoutes;
    default:
      return [];
  }
};

export default getAccessLevelRoutes;
