import useModal from '../../../../../utils/useModal';
import { UPDATE_PRODUCT_STOCK } from '../../../../../constants/modals';

const useProductTable = () => {
  const { showModal: openAddProductStockModal } = useModal(UPDATE_PRODUCT_STOCK);
  return {
    actions: {
        openAddProductStockModal
    },
  };
};

export default useProductTable;
