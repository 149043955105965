import React from 'react';
import Table from '../../../../../components/Table';
import tr from '../../../../../utils/translate';

import { socialColumns } from './data';

const SocialMediaTable = ({ detailedInfo }) => (
  <div className="mg-b-30">

    <Table caption={tr('Detailed info')} columns={socialColumns} data={detailedInfo} />

  </div>
);

export default SocialMediaTable;
