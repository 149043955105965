import React from 'react';

import tr from '../../../../utils/translate';
import Tabs from '../../../../components/Tabs';
import PageHeader from '../../../../components/PageHeader';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import ReduxForm from '../../../../components/ReduxForm';
import Select from '../../../../components/Select';
import { CAMPAIGNS_ROUTE } from '../../../../constants/routes';
import { FILTER_PERIOD_OPTIONS } from '../../../../constants/common';
import { getDatesRange } from '../../../../utils/dates';

import CampaignInfoTab from '../CampaignInfoTab';
import CampaignOverviewTab from '../CampagnOverviewTab';
import CampaignDetailedTab from '../CampaignDetailedTab';
import CampaignFollowUpsTab from '../CampaignFollowUpsTab';
import CampaignModal from '../CampaignModal';
import TestEmailModal from '../TestEmailModal';
import UncontrolledDropdown from '../../../../components/DropDown/UncontrolledDropdown';
import DropdownToggle from '../../../../components/DropDown/DropdownToggle';
import DropdownMenu from '../../../../components/DropDown/DropdownMenu';
import DropDownItem from '../../../../components/DropDown/DropDownItem';
import useCampaignDetails from './hooks';
import './styles.scss';
import { ROLE_SUPERADMIN, ROLE_COMPANY_ADMIN, ROLE_DEPARTMENT_ADMIN } from '../../../../constants/roles';
import {
  DEVICE_CAMPAIGN, CAMPAIGN_REJECTED, CAMPAIGN_CREATED, CAMPAIGN_PARTLY_CREATED, ONLINE_EMAIL_CAMPAIGN, CAMPAIGN_AWAITING_APPROVAL,
} from '../../../../constants/campaigns';
import CampaignStatusModal from '../CampaignStatusModal';
import SuccessCreateModal from '../SuccessCreateModal';
import CopySocialLinkModal from '../CopySocialLinkModal';
import StartMailingModal from '../StartMailingModal';


const breadcrumbs = [
  { to: CAMPAIGNS_ROUTE, body: tr('Campaigns') },
];

const CampaignDetails = () => {
  const {
    actions,
    data: {
      campaign,
      statistic,
      statisticInteractions,
      statisticFacebook,
      isLoadingConfig,
      isLoadingStatistics,
      all_campaigns,
      access_level,
      isTestEmailsLoading,
      social_networks,
      ad_campaign,
    },
    sortBy,
    stat_period,
    campaignProducts,
    deleteSessionAdd
  } = useCampaignDetails();

  if (!campaign) return <Loader page size="lg" />;
  const allowEdit = (campaign.company && campaign.company.is_active)
    && (campaign.department === null || (campaign.department !== null && campaign.department.is_active))
    && [CAMPAIGN_REJECTED, CAMPAIGN_CREATED, CAMPAIGN_PARTLY_CREATED, CAMPAIGN_AWAITING_APPROVAL].includes(campaign.status);

  const {
    stats_visibility,
  } = campaign;

  const {
    ...otherStats
  } = statistic || {};

  const dateRange = getDatesRange(stat_period);

  const headerButtons = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {[ROLE_SUPERADMIN, ROLE_COMPANY_ADMIN, ROLE_DEPARTMENT_ADMIN].includes(access_level) && campaign.type_is === ONLINE_EMAIL_CAMPAIGN
        && (
          <Button
            onClick={actions.handleTestEmailClick}
            className="mg-r-10"
            disabled={isTestEmailsLoading}
            color="primary"
          >
            {tr('Send test email')}
          </Button>
        )}

      {[ROLE_SUPERADMIN].includes(access_level) && campaign.type_is === ONLINE_EMAIL_CAMPAIGN && campaign.status === 'approved' && (campaign.direct_mail && !campaign.direct_mail.is_sent)
        && (
          <Button
            onClick={actions.openStartMailingModal}
            className="mg-r-10"
            color="primary"
          >
            {tr('Start mailing')}
          </Button>
        )}
      {access_level === ROLE_SUPERADMIN && campaign.status !== 'approved' && campaign.type_is !== DEVICE_CAMPAIGN
        && (
          <Button
            onClick={() => actions.handleStatusButtonsClick('approve')}
            className="mg-r-10"
            disabled={isLoadingStatistics}
            color="primary"
          >
            {tr('Approve')}
          </Button>
        )}
      {access_level === ROLE_SUPERADMIN && campaign.status === CAMPAIGN_AWAITING_APPROVAL && campaign.type_is !== DEVICE_CAMPAIGN
        && (
          <Button
            onClick={() => actions.handleStatusButtonsClick('reject')}
            className="mg-r-40"
            disabled={isLoadingStatistics}
            color="light"
          >
            {tr('Reject')}
          </Button>
        )}
      {(campaign.type_is === DEVICE_CAMPAIGN || (campaign.status === 'approved' && campaign.type_is !== DEVICE_CAMPAIGN))
        && campaign.company?.report_template && (
          <Button
            onClick={actions.handleExportCustomStatistics}
            className="mg-r-10"
            disabled={isLoadingStatistics}
            color="light"
          >
            {tr('Export Custom statistics')}
          </Button>
      )}
      {(campaign.type_is === DEVICE_CAMPAIGN || (campaign.status === 'approved' && campaign.type_is !== DEVICE_CAMPAIGN))
        && (
        <UncontrolledDropdown className="mg-r-10">

          <DropdownToggle>

            <Button
              color="light"
            >
              {tr('Export statistics')}
            </Button>

          </DropdownToggle>

          <DropdownMenu position="right">

            <DropDownItem toggle tag="div">

              <Button
                onClick={() => actions.handleExportStatistics('')}
                disabled={isLoadingStatistics}
                color="link"
              >
                {tr('Full')}
              </Button>

            </DropDownItem>

            <DropDownItem toggle tag="div">

              <Button
                onClick={() => actions.handleExportStatistics('on')}
                disabled={isLoadingStatistics}
                color="link"
              >
                {tr('Unique')}
              </Button>

            </DropDownItem>

          </DropdownMenu>

        </UncontrolledDropdown>
        )}
      {allowEdit && <Button onClick={actions.handleClickEditCampaign}>{tr('Edit campaign')}</Button>}
    </div>
  );

  const tabs = [
    { key: 'campaign_info', name: tr('Campaign Info'), block: <CampaignInfoTab campaign={campaign} products={campaignProducts} access_level={access_level} social_networks={social_networks} ad_campaign={ad_campaign} /> },
    { key: 'overview', name: tr('Overview'), block: <CampaignOverviewTab statisticFacebook={statisticFacebook} contacts={statistic?.contact_count} stats_visibility={stats_visibility} statData={otherStats} dateRange={dateRange} campaign_type={campaign.type_is} social_networks={campaign.social_networks} ad_campaign={ad_campaign} /> },
    { key: 'detailed', name: tr('Detailed'), block: stats_visibility && stats_visibility.user_interactions && <CampaignDetailedTab campaign_type={campaign.type_is} statData={statisticInteractions} dateRange={dateRange} handleSorting={actions.handleSorting} sortBy={sortBy} deleteSessionAdd campaign_id={campaign.id} /> },
    { key: 'follow_ups', name: tr('Follow ups'), block: <CampaignFollowUpsTab campaignId={campaign.id} data={statistic && statistic.follow_up} /> },
  ];

  const filterForm = (
    <ReduxForm
      onChange={(event) => event.target && actions.handlePeriodChange(event.target.value)}
      formData={{
        initialValues: { stat_period },
      }}
      form="COMPAIGN_STAT_FILTER"
      enableReinitialize
      className="campaign-statistic-filter"
    >
      <Select
        fieldLabel={tr('Filtered by')}
        classNameWrapper="stat_period_filter"
        name="stat_period"
        placeholder={tr('Filter period')}
        options={FILTER_PERIOD_OPTIONS}
      />
    </ReduxForm>
  );
  const title = access_level === ROLE_SUPERADMIN ? `${campaign.name} (${campaign.company ? campaign.company.name : ''})` : campaign.name;
  return (
    <div className="page-inner pd-t-25 pd-b-25 page-campaign-info">
      <PageHeader title={title} list={breadcrumbs}>
        {headerButtons}
      </PageHeader>
      <div>
        <Tabs customHeaderAdd={filterForm}>
          {tabs.map((tab, index) => (
            tab.block ? (
              <Tabs.Pane
                key={index}
                name={tab.key}
                label={tab.name}
              >
                {tab.block}
              </Tabs.Pane>
            )
              : null
          ))}
        </Tabs>

      </div>
      <CampaignModal />
      <CampaignStatusModal />
      <TestEmailModal campaign_id={campaign.id} />
      <SuccessCreateModal />
      <CopySocialLinkModal />
      <StartMailingModal campaign_id={campaign.id} />
    </div>
  );
};

export default CampaignDetails;
