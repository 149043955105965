import emailValidator from 'email-validator';

import translate from '../../../../utils/translate';

const validate = ({
  company_name,
  first_name,
  last_name,
  email,
}) => {
  const errors = {};

  if (!company_name) errors.company_name = translate('Company name is required');

  if (!first_name) errors.first_name = translate('First name is required');
  else if (first_name.length > 30) errors.first_name = translate('First name must be no longer than 30 characters');

  if (!last_name) errors.last_name = translate('Last name is required');
  else if (last_name.length > 150) errors.last_name = translate('First name must be no longer than 150 characters');

  if (!email) errors.email = translate('Email is required');
  else if (!emailValidator.validate(email)) errors.email = translate('Invalid Email');

  return errors;
};

export default validate;
