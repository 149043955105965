import tr from '../../../../utils/translate';
import { formatDateNoUtc } from '../../../../utils/dates';

export const facebookColumns = () => (
  [
    {
      name: tr('Name'),
      key: 'name',
    },
    {
      name: tr('Connected Date'),
      key: 'connected_at',
      render: ({ connected_at }) => (connected_at ? formatDateNoUtc(connected_at) : tr('No data')),
    },
    {
      name: tr('Status'),
      key: 'disconnected',
    },
    {
      name: tr('Action'),
      key: 'action',
    },
  ]
);
