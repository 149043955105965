import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { formValueSelector, change } from 'redux-form';
import CAMPAIGNS from '../../services/types';
import {
  sendFollowUpSuggest,
} from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';

const loadingSelector = createLoadingSelector(CAMPAIGNS.SEND_SUGGESTION.base);
const formSelector = formValueSelector('SUGGEST_OTHER_QUESTION_FORM');

const selector = createSelector(
  loadingSelector,
  (state) => formSelector(state, 'question', 'type', 'answer', 'answers'),
  (isLoading, formData) => ({
    isLoading,
    formData,
  }),
);

const useSuggestOther = (campaignId) => {
  const dispatch = useDispatch();
  const data = useSelector(selector);

  const submitSuggestOther = useCallback((fields) => {
    dispatch(sendFollowUpSuggest({
      campaign_id: campaignId,
      content: fields.question,
      question_type: fields.type,
      choices: fields.answers.length ? fields.answers : undefined,
    }));
  }, [campaignId, dispatch]);

  const handleAddAnswer = useCallback(() => {
    dispatch(change('SUGGEST_OTHER_QUESTION_FORM', 'answers', [...data.formData.answers, data.formData.answer]));
    dispatch(change('SUGGEST_OTHER_QUESTION_FORM', 'answer', ''));
  }, [data.formData.answer, data.formData.answers, dispatch]);

  const handleDeleteAnswer = useCallback((index) => {
    const answers = [...data.formData.answers];
    answers.splice(index, 1);
    dispatch(change('SUGGEST_OTHER_QUESTION_FORM', 'answers', answers));
  }, [data.formData.answers, dispatch]);

  return {
    data,
    actions: {
      submitSuggestOther,
      handleAddAnswer,
      handleDeleteAnswer,
    },
  };
};

export default useSuggestOther;
