import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import _find from 'lodash/find';

import { editDepartment as editDepartmentAction, createDepartment as createDepartmentAction } from '../services/actions';
import createLoadingSelector from '../../../selectors/loadingSelector';
import DEPARTMENT from '../services/types';

const selector = (department_id) => createSelector(
  createLoadingSelector(DEPARTMENT.GET_DEPARTMENTS.base),
  (state) => state.account.countries,
  (state) => _find(state.departments.list, ['id', department_id]),
  (state) => state.brands.listMini,
  (state) => state.account.accountDetails.access_level,
  (isLoading, countries, department, brands, access_level) => ({
    isLoading,
    countries,
    department,
    brands,
    access_level,
  }),
);

const useBrandModal = (id) => {
  const dispatch = useDispatch();
  const editDepartment = useCallback((data) => {
    dispatch(editDepartmentAction(id, data));
  }, [dispatch, id]);

  const createDepartment = useCallback((data) => {
    dispatch(createDepartmentAction({
      ...data,
      country: Number(data.country),
    }));
  }, [dispatch]);

  return {
    data: useSelector(selector(id)),
    actions: {
      editDepartment,
      createDepartment,
    },
  };
};

export default useBrandModal;
