import React from 'react';

import tr from '../../../utils/translate';
import Table from '../../../components/Table';
import createColumns from './columns';
import useCampaignsTable from './hooks';
import './styles.scss';

const tabs = [
  { key: 'active', name: tr('Active') },
  { key: 'disabled', name: tr('Disabled') },
];

const BrandsTable = () => {
  const {
    data: {
      isLoading,
      brands,
      pagination,
    },
    actions: {
      showEditModal,
      getAllBrands,
      brandStatusChange,
      changeSorting,
      getNextPage,
    },
    activeTab,
    setActiveTab,
    sortBy,
  } = useCampaignsTable();

  const columns = createColumns(showEditModal, brandStatusChange, changeSorting, sortBy);

  const handleSelectTab = (index, { name }) => {
    getAllBrands({ is_active: name === tabs[0].key });
    setActiveTab(name);
  };

  return (
    <>
      <Table
        caption={tr('Brands')}
        className="brands-list-table"
        tabs={tabs}
        columns={columns}
        data={{ [activeTab]: brands }}
        onSelectTab={handleSelectTab}
        loading={isLoading}
        pagination={{...pagination, getNextPage }}
      />
    </>
  );
};

export default BrandsTable;
