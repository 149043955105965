import moment from 'moment';

export const formatDate = (date, format = 'DD MMM YYYY') => date && moment(date).utc().format(format);

export const formatDateTime = (date, format = 'DD MMM YYYY HH:mm:ss') => moment(date).utc().format(format);

export const formatEndOfDay = (date, format = 'DD MMM YYYY HH:mm:ss') => moment(date).endOf('day').utc().format(format);

export const formatStartOfDay = (date, format = 'DD MMM YYYY HH:mm:ss') => moment(date).startOf('day').utc().format(format);

export const formatDateNoUtc = (date, format = 'DD MMM YYYY') => moment(date).format(format);

export const formatBeforeSend = (date, format = 'YYYY-MM-DD HH:mm:ss') => moment(date).format(format);

export const formatTime = (date, format = 'HH:mm:ss') => moment(date).format(format);

export const transformDuration = (seconds) => {
  const duration = moment.duration(seconds || 0, 'seconds');

  const days = duration.days() ? `${duration.days()} d ` : '';
  const hours = duration.hours() ? `${duration.hours()} hr ` : '';
  const minutes = duration.minutes() ? `${duration.minutes()} min ` : '';
  return `${days}${hours}${minutes}${duration.seconds()} sec`;
};

export const substractPeriod = (period, format = 'DD.MM.YYYY') => {
  switch (period) {
    case 'week':
      return period && moment().startOf('isoWeek').format(format);
    case 'month':
    case 'year':
      return period && moment().startOf(period).format(format);
    case 'last7':
      return period && moment().subtract(7, 'days').utc().format(format);
    case 'last30':
      return period && moment().subtract(30, 'days').utc().format(format);
    default:
      return null;
  }
};

export const getDatesRange = (period, format = 'DD.MM.YYYY') => {
  if (period === 'all') {
    return null;
  }
  const startDate = substractPeriod(period);
  const currentDate = moment().format(format);
  return `${startDate ? `${startDate} - ` : ''}${currentDate}`;
};
