import moment from 'moment';

import translate from '../../../../../../utils/translate';

const validate = ({
  date_from,
  date_to,
  age_from,
  age_to,
}) => {
  const errors = {};

  if (date_from && date_to) {
    if (moment(date_to).isBefore(date_from) || moment(date_to).isSame(date_from)) {
      errors.date_to = translate('End date must be bigger than Start date');
    }
  }

  if (age_from && age_to) {
    if (age_from > age_to) {
      errors.age_to = translate('End age must be bigger than Start age');
    }
  }

  return errors;
};

export default validate;
