import React, { useState } from 'react';
import sendRequest from '../../../../../utils/apiService';

const FacebookPlacesAutocomplete = ({
	accountId,
	onSelect,
	children,
}) => {
	const [state, setState] = useState({
		loading: false,
		suggestions: [],
		savedSuggestions: {},
		value: '',
	});

	const clearSuggestions = () => {
		setState((prevState) => ({
			...prevState,
			suggestions: [],
		}));
	};

	const request = async (value) => {
		if (value.length) {
			if(state.savedSuggestions[value]) {
				setState((prevState) => ({
					...prevState,
					suggestions: prevState.savedSuggestions[value],
				}));
			} else {
				setState((prevState) => ({
					...prevState,
					loading: true,
				}));

				const data = await sendRequest({
					method: 'get',
					path: `bo/accounts/ad-accounts/${accountId}/search_location/`,
					data: {
						q: value
					},
				});

				setState((prevState) => {
					const suggestions = data.map((p) => ({
						key: p.key,
						name: p.name,
						country_name: p.country_name,
						country_code: p.country_code,
						region_id: p.region_id,
						region: p.region,
						radius: p.radius,
						distance_unit: p.distance_unit,
						type: p.type
					}));

					return {
						...prevState,
						loading: false,
						savedSuggestions: {
							...prevState.savedSuggestions,
							[value]: suggestions,
						},
						suggestions,
					}
				});
			}
		}
	};

	const getInputProps = (options = {}) => ({
		...options,
		type: 'text',
		autoComplete: 'off',
		value: state.value,
		onChange: (event) => {
			const { value } = event.target;

			setState((prevState) => ({
				...prevState,
				value,
			}));

			if (!value) {
				clearSuggestions();

				return;
			}

			request(value);
		},
	});

	const getSuggestionItemProps = (suggestion, options = {}) => ({
		...options,
		key: suggestion.key,
		role: 'option',
		onClick: (event) => {
			if (event && event.preventDefault) {
				event.preventDefault();
			}

			clearSuggestions();

			setState((prevState) => ({
				...prevState,
				value: '',
			}))

			onSelect(suggestion);
		},
	});

	return children({
		getInputProps,
		getSuggestionItemProps,
		loading: state.loading,
		suggestions: state.suggestions,
	});
};

export default FacebookPlacesAutocomplete;