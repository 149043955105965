import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';

import store from './utils/store';
import Routes from './routes';
import history from './utils/history';

function App() {
  return (
    <Provider store={store} key="provider">
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop={false}
        closeButton={false}
        hideProgressBar
        closeOnClick
        pauseOnHover
      />
    </Provider>
  );
}

export default App;
