import React from 'react';
import { Field } from 'redux-form';
import { BackarrowIcon } from '../../../../../components/Icons';
import ReduxForm from '../../../../../components/ReduxForm';
import Select from '../../../../../components/Select';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Input';
import InterestsFacebookField from '../../InterestsFacebookField';
import LocationFacebookField from '../../LocationFacebookField';
import GenderCheckboxes from '../../GenderCheckboxes';
import validate from '../validate';
import tr from '../../../../../utils/translate';
import FacebookPagePreview from '../FacebookPagePreview';

const oldOptions = [
	{ label: '', value: '' },
	...new Array(53).fill(0).map((_, index) => ({
		label: index + 13,
		value: index + 13,
	})),
];

const TargetingForm = ({ form, audience_size, fb_pages, fb_targetings, onSubmit, previousPage }) => {
	const objPage = (fb_pages || []).find(() => form?.values?.facebook_page);

	const audienceOptions = (fb_targetings || []).map(({ age_min, age_max, genders, interests, publisher_platforms}, index) => {
		const interestList = interests.map(({ name }) => name).join(',');

		const gender = genders.length > 0
			? (genders === 1 ? 'Male' : 'Female')
			: 'All';

		return {
			label: `Age: ${age_min}-${age_max}; gender ${gender}; interests: ${interestList}; publisher platforms: ${publisher_platforms.join(',')}`,
			value: index,
		};
	});

	return (
		<ReduxForm
			// enableReinitialize
			form="CAMPAIGN_FACEBOOK_FORM"
			destroyOnUnmount={false}
			forceUnregisterOnUnmount={true}
			validate={validate}
			formData={{
				onSubmit,
			}}
		>

			<div className="campaign-location-form pd-x-25 pd-y-50">

				<div className="row">

					<div className="col-md-6">

						{/*<div className="mg-b-30">

						 <Select
						 fieldLabel={tr('Special category')}
						 name="category"
						 options={[]}
						 />

						 </div>*/}

						<h4 className="cdf-subtitle mg-b-10">

							{tr('Audience')}

						</h4>

						<div className="row mg-b-30">

							<Input
								id="new_audience"
								name="from_audience"
								type="radio"
								className="col checkbox"
								appendFieldLabel={tr('New Audience')}
								value="new"
							/>

							<Input
								id="exist_audience"
								name="from_audience"
								type="radio"
								className="col checkbox"
								appendFieldLabel={tr('Select A Saved Audience')}
								value="exist"
							/>

						</div>

						{form?.values?.from_audience === 'exist' && (
							<div className="mg-b-30">

								<Select
									name="audience"
									options={[{label: '', value: ''}, ...audienceOptions]}
								/>

							</div>
						)}

						<div className="mg-b-30">

							<Field
								fieldLabel={tr('Select location')}
								name="location"
								className="locations-form-field"
								component={LocationFacebookField}
								accountId={form?.values?.ad_account}
							/>

						</div>

						<div className="mg-b-30">

							<Field
								name="interests"
								fieldLabel={tr('Select interests')}
								component={InterestsFacebookField}
								accountId={form?.values?.ad_account}
							/>

						</div>

						<div className="mg-b-30">

							<Field
								name="gender"
								component={GenderCheckboxes}
							/>

						</div>

						<h4 className="cdf-subtitle mg-b-10">
							{tr('Age Range')}
						</h4>

						<div className="mg-b-30 row">

							<div className="col-md-6">

								<Select
									name="age_from"
									fieldLabel={tr('From')}
									options={oldOptions}
								/>

							</div>

							<div className="col-md-6">

								<Select
									name="age_to"
									fieldLabel={tr('To')}
									options={oldOptions}
								/>

							</div>

						</div>

						<h4 className="cdf-subtitle mg-b-10">
							{tr('Placement')}
						</h4>

						<div className="row mg-b-30">

							<Input
								id="placement.instagram"
								name="placement.instagram"
								type="checkbox"
								className="col checkbox"
								appendFieldLabel={tr('Instagram')}
								// value="instagram"
							/>

							<Input
								id="placement.messenger"
								name="placement.messenger"
								type="checkbox"
								className="col checkbox"
								appendFieldLabel={tr('Messenger')}
								// value="messenger"
							/>

							<Input
								id="placement.facebook"
								name="placement.facebook"
								type="checkbox"
								className="col checkbox"
								appendFieldLabel={tr('Facebook')}
								// value="facebook"
							/>

							<Input
								id="placement"
								name="placement"
								className="col-12"
								type="hidden"
							/>

						</div>

					</div>

					<div className="col-md-6 pd-l-60">

						<FacebookPagePreview
							audience_size={audience_size}
							title={objPage?.name}
							icon={objPage?.image_url}
							image={form?.values?.image}
							description={form?.values?.text_body}
							content={form?.values?.headline}
							call_to_action={form?.values?.call_to_action}
						/>

					</div>

				</div>

			</div>

			<div className="modal-footer">

				<Button
					color="light"
					className="mg-r-30"
					onClick={previousPage}
				>

					<BackarrowIcon />

					{tr('Back')}

				</Button>

				<Button
					type="submit"
				>

					{tr('Next')}

				</Button>

			</div>

		</ReduxForm>
	);
};

export default TargetingForm;