import {
  takeEvery, put, call,
} from 'redux-saga/effects';
import { hide } from 'redux-modal';
import { toast } from 'react-toastify';
import _get from 'lodash/get';
import { EMPLOYEE_ROLE_MODAL, DEACTIVATE_USER_MODAL, INVITE_DEPARTMENT_MODAL, ASSIGN_DEVICES_MEMBER } from '../../../constants/modals';
import tr from '../../../utils/translate';
import EMPLOYEES from './types';

function* runChangeUserRole() {
  yield put(hide(EMPLOYEE_ROLE_MODAL));
  yield call(toast.success, tr('Member access level changed'));
}

function* runActivateUserSuccess() {
  yield put(hide(DEACTIVATE_USER_MODAL));
  yield call(toast.success, tr('Member is activated'));
}

function* runDeactivateUserSuccess() {
  yield put(hide(DEACTIVATE_USER_MODAL));
  yield call(toast.success, tr('Member is deactivated'));
}

function* runInviteUser() {
  yield put(hide(INVITE_DEPARTMENT_MODAL));
  yield toast.success(tr('Invitation email has been sent'));
}

function* runSetUserDevices() {
  yield put(hide(ASSIGN_DEVICES_MEMBER));
  yield toast.success(tr('Member is updated'));
}


export default function* departments() {
  yield takeEvery(EMPLOYEES.CHANGE_ROLE.SUCCESS, runChangeUserRole);
  yield takeEvery(EMPLOYEES.ACTIVATE_EMPLOYEE.SUCCESS, runActivateUserSuccess);
  yield takeEvery(EMPLOYEES.DEACTIVATE_EMPLOYEE.SUCCESS, runDeactivateUserSuccess);
  yield takeEvery(EMPLOYEES.INVITE_USER.SUCCESS, runInviteUser);
  yield takeEvery(EMPLOYEES.SET_USER_DEVICES.SUCCESS, runSetUserDevices);
}
