import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import useModal from '../../../../utils/useModal';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import EMPLOYEES from '../../services/types';
import { INVITE_DEPARTMENT_MODAL } from '../../../../constants/modals';
import { inviteUser as inviteUserAction } from '../../services/actions';
import { getDepartmentsMini, inviteMemberGetDepartmentsMini } from '../../../department/services/actions';

const selector = createSelector(
  createLoadingSelector(EMPLOYEES.INVITE_USER.base),
  (state) => state.brands.listMini,
  (state) => state.departments.inviteMemberListDep,
  (state) => state.account.accountDetails.access_level,
  (state) => state.brands.activeBrand,
  (state) => state.departments.activeDepartment,
  (state) => state.modal[INVITE_DEPARTMENT_MODAL] || {},
  (isLoading, brands, departments, access_level, company, department, modal) => ({
    isLoading,
    departments,
    access_level,
    company,
    department,
    modal,
    brands,
  }),
);

const useInviteModal = () => {
  const dispatch = useDispatch();

  const inviteUser = useCallback((values) => {
    dispatch(inviteUserAction({ ...values }));
    dispatch(getDepartmentsMini({ is_active: 1 }));
  }, [dispatch]);

  const { hideModal } = useModal(INVITE_DEPARTMENT_MODAL);

  const data = useSelector(selector);

  const getDepartments = useCallback((params) => {
    dispatch(inviteMemberGetDepartmentsMini(params));
  }, []);

  return {
    actions: {
      hideModal,
      inviteUser,
      getDepartments,
    },
    data,
  };
};

export default useInviteModal;
