import React from 'react';
import { Field } from 'redux-form';
import tr from '../../../../utils/translate';
import Button from '../../../../components/Button';
import ReduxForm from '../../../../components/ReduxForm';
import {
  BackarrowIcon, MailIcon, SocialTargetingIcon, WebsiteIcon,
} from '../../../../components/Icons';
import { ONLINE_SOCIAL_CAMPAIGN, ONLINE_EMAIL_CAMPAIGN, ONLINE_EMBEDED_CAMPAIGN } from '../../../../constants/campaigns';
import CampaignTypeField from '../CampaignTypeField';
import useOnlineCampaignType from './hooks';

export const campaignTypes = [
  {
    value: ONLINE_SOCIAL_CAMPAIGN,
    label: tr('Social Media targeting'),
    icon: <SocialTargetingIcon />,
  },
  {
    value: ONLINE_EMAIL_CAMPAIGN,
    label: tr('Email targeting'),
    icon: <MailIcon />,
  },
  {
    value: ONLINE_EMBEDED_CAMPAIGN,
    label: tr('Website Embedding'),
    icon: <WebsiteIcon />,
  },
];


const CampaignType = () => {
  const {
    actions: {
      setCampaignType,
      goToBack,
    },
  } = useOnlineCampaignType();

  return (
    <ReduxForm
      enableReinitialize
      form="CAMPAIGN_ONLINE_TYPE_FORM"
      formData={{
        onSubmit: setCampaignType,
        initialValues: {
          campaign_type: ONLINE_SOCIAL_CAMPAIGN,
        },
      }}
    >
      <div className="campaign-type-form pd-x-25 pd-y-50">
        <h3 className="cadf-title mg-b-40">{tr('What type of online campaign would you like to create?')}</h3>
        <Field
          name="campaign_type"
          component={CampaignTypeField}
          list={campaignTypes}
        />
      </div>
      <div className="modal-footer">
        <Button color="light" className="mg-r-30" onClick={goToBack}>
          <BackarrowIcon />
          {tr('Back')}
        </Button>
        <Button
          type="submit"
        >
          {tr('Next')}
        </Button>
      </div>
    </ReduxForm>
  );
};

export default CampaignType;
