import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import createLoadingSelector from '../../../../selectors/loadingSelector';
import AUTH from '../../services/types';
import { loginAccount as loginAccountAction } from '../../services/actions';

const selector = createSelector(
  createLoadingSelector(AUTH.SIGN_IN.base),
  (isLoading) => ({
    isLoading,
  }),
);

const useSignIn = () => {
  const dispatch = useDispatch();

  const loginAccount = useCallback((values) => {
    dispatch(loginAccountAction(values));
  }, [dispatch]);

  return {
    actions: {
      loginAccount,
    },
    data: useSelector(selector),
  };
};

export default useSignIn;
