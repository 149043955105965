import tr from '../../../utils/translate';
import emailValidator from 'email-validator';

const validate = ({ brand_name, email }) => {
  const errors = {};

  if (!brand_name) {
    errors.brand_name = tr('Brand name is required.');
  }

  if (!email) errors.email = tr('Email is required');
  else if (!emailValidator.validate(email)) errors.email = tr('Invalid Email');

  return errors;
};

export default validate;
