import React from 'react';
import Widget from '../../../../components/Widget';
import {
  DevicesIcon,
  BookmarkIcon,
  InfoIcon,
  GeoMarkerIcon,
} from '../../../../components/Icons';
import tr from '../../../../utils/translate';

const InfoBadges = (props) => {
  const { device, actions, disableEdit } = props;
  return (
    <div className="info-badges row mg-b-30">
      <div className="col col-md-3">
        <Widget
          color="yellow"
          icon={<DevicesIcon />}
          title={tr('Device ID')}
          subtitle={`#${device.serial_number}`}
          buttons={disableEdit ? [] : [{
            label: tr('Edit'),
            onClick: actions.showEditDevice,
          }]}
        />
      </div>
      <div className=" col col-md-3">
        <Widget
          color="green"
          icon={<BookmarkIcon />}
          title={tr('Label')}
          subtitle={device.name}
        />
      </div>
      <div className="col col-md-3">
        <Widget
          color="blue"
          icon={<InfoIcon />}
          title={tr('Status')}
          subtitle={device.status}
        />
      </div>
      <div className="col col-md-3">
        <Widget
          color="red"
          icon={<GeoMarkerIcon />}
          title={tr('Location')}
          subtitle={device.location}
          onClick={undefined}
        />
      </div>
    </div>
  );
};

export default InfoBadges;
