import React from 'react';
import tr from '../../../../utils/translate';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import ReduxForm from '../../../../components/ReduxForm';
import { TEST_EMAILS_MODAL } from '../../../../constants/modals';
import useSendTestEmails from './hooks';

import validate from './validate';

const TestEmailModal = ({ campaign_id }) => {
  const { actions: { handleYes }, data: { isLoading } } = useSendTestEmails(campaign_id);
  return (
    <Modal name={TEST_EMAILS_MODAL} size="lg" title="Send test emails">
      {({ handleHide, ...initialValues }) => (
        <ReduxForm
          enableReinitialize
          form="SEND_TEST_EMAILS_FORM"
          validate={validate}
          formData={{
            onSubmit: handleYes,
          }}
        >
          <div className="compaign-details-form pd-x-25 pd-y-50">
            <div className="mg-b-30">
              <Input
                name="emails"
                prependFieldLabel={tr('Enter emails separated by commas')}
              />
            </div>
          </div>
          <div className="modal-footer">
            <Button color="light" className="mg-r-30" onClick={handleHide}>
              {tr('Cancel')}
            </Button>
            <Button
              type="submit"
              status={isLoading ? 'disabled' : 'active'}
              disabled={isLoading}
              className="btn-block"
            >
              {tr('Send')}
            </Button>
          </div>
        </ReduxForm>
      )}
    </Modal>
  );
};

export default TestEmailModal;
