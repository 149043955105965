import React from 'react';
import { Route, Switch } from 'react-router';

import { AppLayout } from '../../modules/layout';
import Dashboard from '../../modules/dashboard';
import Account from '../../modules/account/components';
import { AddNewDevice, DeviceInfo, Devices } from '../../modules/devices/containers';
import { Campaigns, CampaignDetails } from '../../modules/campaigns/containers';
import Users from '../../modules/users/components/Main';
import UserDetails from '../../modules/users/components/UserDetails';
import Departments from '../../modules/department';
import Brands from '../../modules/brand';
import Employees from '../../modules/employees/components/Main';
import Products from '../../modules/product/components/Main';
import {
  HOME_ROUTE,
  ACCOUNT_ROUTE,
  DEVICES_ROUTE,
  DEVICES_ADD_ROUTE,
  DEVICES_INFO_ROUTE,
  CAMPAIGNS_ROUTE,
  CAMPAIGNS_DETAILS_ROUTE,
  USERS_ROUTE,
  USERS_DETAILS_ROUTE,
  DEPARTMENTS_ROUTE,
  BRANDS_ROUTE,
  EMPLOYEES_ROUTE,
  PRODUCTS_ROUTE, FACEBOOK_AUTH_ROUTE
} from '../../constants/routes';
import { ROLE_COMPANY_ADMIN, ROLE_SUPERADMIN } from '../../constants/roles';
import FacebookAuth from '../../modules/FacebookAuth';

const AdminRoutes = ({ access_level }) => (
  <AppLayout>
    <Switch>
      <Route exact path={HOME_ROUTE} component={Dashboard} />
      <Route
        exact
        path={DEVICES_ROUTE}
        // eslint-disable-next-line react/jsx-props-no-spreading
        render={(props) => <Devices {...props} allowAdd />}
      />
      <Route exact path={DEVICES_ADD_ROUTE} component={AddNewDevice} />
      <Route exact path={DEVICES_INFO_ROUTE} component={DeviceInfo} />
      <Route exact path={CAMPAIGNS_ROUTE} component={Campaigns} />
      <Route exact path={CAMPAIGNS_DETAILS_ROUTE} component={CampaignDetails} />
      <Route exact path={USERS_ROUTE} component={Users} />
      <Route exact path={USERS_DETAILS_ROUTE} component={UserDetails} />
      <Route exact path={ACCOUNT_ROUTE} component={Account} />
      <Route exact path={FACEBOOK_AUTH_ROUTE} component={FacebookAuth} />
      <Route exact path={EMPLOYEES_ROUTE} component={Employees} />
      <Route exact path={PRODUCTS_ROUTE} component={Products} />
      {[ROLE_COMPANY_ADMIN, ROLE_SUPERADMIN].includes(access_level)
      && <Route exact path={DEPARTMENTS_ROUTE} component={Departments} />}
      {access_level === ROLE_SUPERADMIN
      && <Route exact path={BRANDS_ROUTE} component={Brands} />}
    </Switch>
  </AppLayout>
);

export default AdminRoutes;
