import { createRequestTypes } from '../../../utils/actionHelpers';

export default createRequestTypes('CAMPAIGNS', [
  'READ_LIST',
  'CREATE_CAMPAIGN',
  'EDIT_CAMPAIGN',
  'READ_DETAILS',
  'GET_ASSOCIATED_DEVICES',
  'SET_ASSOCIATED_DEVICES',
  'GET_STATISTICS_PROPS',
  'SET_STATISTICS_PROPS',
  'UPDATE_INTERFACE',
  'UPDATE_TRAY',
  'UPDATE_PRODUCT',
  'CREATE_PRODUCT',
  'UPDATE_PRODUCT_SELECTION',
  'UPDATE_PRODUCT_SELECTION_WITH_QUANTITY',
  'EXPORT_CONFIG',
  'GET_PRODUCTS',
  'EXPORT_STATISTICS',
  'EXPORT_CUSTOM_STATISTICS',
  'GET_CAMPAIGN_STATISTIC',
  'GET_CAMPAIGN_STATISTIC_INTERACTIONS',
  'GET_STATISTIC_FACEBOOK',
  'EDIT_SOCIAL_NETWORKS',
  'READ_SOCIAL_NETWORKS',
  'SET_DEMOGRAPHICS',
  'APPROVE_CAMPAIGN',
  'REJECT_CAMPAIGN',
  'SEND_SUGGESTION',
  'UPDATE_EMAIL_INTERFACE_DATA',
  'FILTER_USER_DATABASE',
  'SET_FILTERED_USER_LIST',
  'GET_EMAIL_CSV_FILE',
  'SEND_TEST_MAILS',
  'RUN_MAILING_SERVICE',
  'UPLOAD_MAIN_EMAIL',
  'VALIDATE_DOMAIN',
  'ADD_PRODUCT_STOCK',
  'GET_SUGGESTIONS',
  'GET_ACTIONS',
  'GET_FOLLOW_UP_QUESTIONS',
  'GET_FOLLOW_UP_STATISTICS',
  'SET_FOLLOW_UP',
  'GET_FOLLOW_UP_THEMES',
  'SEND_FOLLOW_UP_SUGGEST',
  'SEND_FOLLOW_UP_PREVIEW_EMAIL',
  'SET_FOLLOW_UP_THEME',
  'GET_FOLLOW_UP_THEME',
  'CREATED_CAMPAIGN',
  'DELETE_SESSION',
  'DELETE_SESSIONS',
  'UNSUBSCRIBES_LIST',
  'EXPORT_UNSUBSCRIBES',
  'GET_AD_ACCOUNTS',
  'GET_FB_PAGES',
  'GET_FB_TARGETINGS',
  'GET_AD_CAMPAIGN',
  'GET_ACCOUNT_EXISTS',
  'GET_REACH_ESTIMATE',
  'CLEAR_REACH_ESTIMATE',
  'START_FACEBOOK_CAMPAIGN',
  'PAUSE_FACEBOOK_CAMPAIGN',
  'CLEAR_FACEBOOK_CAMPAIGN',
], [
  'CLOSE_MODAL',
  'FIRST_STEP',
  'NEXT_STEP',
  'PREV_STEP',
  'SET_CAMPAIGN_TYPE',
  'SET_STEP',
  'FOLLOW_UP_PREVIEW',
  'FOLLOW_UP_DESIGN',
  'FOLLOW_UP_PREVIEW_EMAIL',
]);
