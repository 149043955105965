import React, { useState, useCallback } from 'react';
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal';
import { CAMPAIGN_MODAL } from '../../../../constants/modals';
import tr from '../../../../utils/translate';
import useCampaignModal from './hooks';
import STEPS from './steps';
import './styles.scss';
import { ONLINE_SOCIAL_CAMPAIGN } from '../../../../constants/campaigns';

const AddDeviceModal = () => {
  const [editCampaignId, setEditCampaignId] = useState(null);

  const {
    data: {
      step, campaign, campaign_type, isLoading,
    },
    actions,
  } = useCampaignModal(editCampaignId);

  const getFormSteps = () => {
    const campaignType = campaign ? campaign.type_is : campaign_type;

    let formSteps = STEPS[campaignType];

    if(campaignType === ONLINE_SOCIAL_CAMPAIGN) {
      formSteps = STEPS[campaignType].default;

      if(campaign?.social_networks?.includes('facebook')) {
        formSteps = STEPS[campaignType].facebook;
      }
    }

    return formSteps;
  }

  const getCurrentStep = useCallback((initialValues) => {
    const formSteps = getFormSteps();

    const CurrentStep = editCampaignId
      ? formSteps && formSteps[step]
      : (step > 1 ? formSteps[step - 2] : STEPS.INITIAL_CREATE_STEPS[step]);

    return CurrentStep && <CurrentStep data={initialValues} />;
  }, [editCampaignId, campaign, campaign_type, step]);

  const getProgress = () => {
    const formSteps = getFormSteps();

    return ((step + 1) / (formSteps && formSteps.length + STEPS.INITIAL_CREATE_STEPS.length)) * 100;
  };

  return (
    <Modal
      size="xl"
      name={CAMPAIGN_MODAL}
      title={tr('Create new campaign')}
      onClose={actions.goToStart}
    >
      {({ handleHide, ...initialValues }) => {
        setEditCampaignId(initialValues.id || null);

        if ((initialValues.id && !campaign) || isLoading) return <Loader size="md" />;

        return (
          <>

            <div className="progress progress-xs">

              <div className="progress-bar" style={{ width: `${getProgress() || 0}%` }} />

            </div>

            {getCurrentStep(initialValues)}

          </>
        );
      }}

    </Modal>
  );
};

export default AddDeviceModal;
