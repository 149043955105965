import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';

import Modal from '../../../../../../components/Modal';
import { USERS_FILTER_MODAL } from '../../../../../../constants/modals';
import translate from '../../../../../../utils/translate';
import Form from './Form';
import { formatParams } from '../../helpers';

const UsersFilterModal = ({ location }) => {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const initialValues = formatParams(params, 'MM/DD/YYYY');

  return (
    <Modal title={translate('Filter customers')} name={USERS_FILTER_MODAL} size="lg">
      <Form initialValues={initialValues} location={location} />
    </Modal>
  );
};

UsersFilterModal.propTypes = {
  location: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default UsersFilterModal;
