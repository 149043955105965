import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

const campaignSelector = (id) => createSelector(
  (state) => state.campaigns.details[id],
  (campaign) => ({
    campaign,
  }),
);

const useCurrentCampaign = (currentCampaignId) => {
  const selector = campaignSelector(currentCampaignId);

  return {
    data: useSelector(selector),
  };
};

export default useCurrentCampaign;
