import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import { RebootDeviceIcon } from '../../../../components/Icons';
import { DEVICE_TRAYS_MODAL } from '../../../../constants/modals';
import tr from '../../../../utils/translate';
import './styles.scss';
import Table from '../../../../components/Table';
import { formatBeforeSend } from '../../../../utils/dates';

const columns = [
  {
    name: tr('Tray #'),
    key: 'tray_number',
  },
  {
    name: tr('Result'),
    key: 'status',
  },
  {
    name: tr('Product at tray'),
    key: 'product_name',
  },
  {
    name: tr('Requested on'),
    key: 'date_created',
    render: ({ date_created }) => (date_created ? formatBeforeSend(date_created) : tr('No data')),
  },
  {
    name: tr('Requested by'),
    key: 'user',
  },
];

const MotorTestDeviceModal = ({
  handleTouchTrayDevice,
  handleClose,
  deviceTrays,
  motorLogs,
  modalState,
  readTraysLog,
  deviceNumber,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const isModal = modalState !== undefined && modalState.show === true;
    if (!isModal) {
      return;
    }
    readTraysLog();
    const interval = setInterval(readTraysLog, 15000);
    return () => { clearInterval(interval); };
  }, [deviceNumber, modalState, readTraysLog]);

  return (
    <Modal title={tr('Tray test')} size="xl" name={DEVICE_TRAYS_MODAL}>
      <div className="modal-body">
        <div className="reboot-modal-body">
          <div className="reboot-modal-body__icon-wrap">
            <RebootDeviceIcon />
          </div>
          <div className="reboot-modal-body__text-wrap">
            {tr('Select the tray to test')}
            <div className="reboot-modal-body__buttons">
              {deviceTrays.map((item) => (
                <Button
                  onClick={(event) => {
                    handleTouchTrayDevice(item.number);
                    setIsLoading(true);
                    setTimeout(() => setIsLoading(false), 5000);
                  }}
                  className="btn-block"
                  key={item.number}
                  status={isLoading ? 'disabled' : 'active'}
                  disabled={isLoading}
                >
                  {tr(`#${item.number}`)}
                </Button>
              ))}
            </div>
            <Table
              caption={tr('Campaigns')}
              columns={columns}
              data={motorLogs}
              loading={false}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="reboot-modal-footer">
          <div className="reboot-modal-footer__cancel-col">
            <Button color="light" onClick={handleClose}>{tr('Cancel')}</Button>
          </div>
          <div className="reboot-modal-footer__submit-col" />
        </div>
      </div>
    </Modal>
  );
};

MotorTestDeviceModal.propTypes = {
  handleTouchTrayDevice: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  deviceNumber: PropTypes.string.isRequired,
  readTraysLog: PropTypes.func.isRequired,
  motorLogs: PropTypes.array.isRequired,
  modalState: PropTypes.shape.isRequired,
  deviceTrays: PropTypes.array.isRequired,
};

export default MotorTestDeviceModal;
