import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  setCampaignType as setCampaignTypeAction,
} from '../../services/actions';


const useCampaignType = () => {
  const dispatch = useDispatch();

  const setCampaignType = useCallback((fields) => {
    dispatch(setCampaignTypeAction(fields));
  }, [dispatch]);

  return {
    actions: {
      setCampaignType,
    },
  };
};

export default useCampaignType;
