import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import CAMPAIGNS from '../../services/types';
import createLoadingSelector from '../../../../selectors/loadingSelector';

import { firstStep, getDeviceCampaignDetails } from '../../services/actions';

const loadingSelector = createLoadingSelector(CAMPAIGNS.READ_DETAILS.base);

const setSelector = (id) => createSelector(
  loadingSelector,
  (state) => state.campaigns.editStep,
  (state) => id && state.campaigns.details[id],
  (state) => state.campaigns.campaign_type,
  (isLoading, step, campaign, campaign_type) => ({
    isLoading,
    step,
    campaign,
    campaign_type,
  }),
);

const useCampaignModal = (campaignId) => {
  const dispatch = useDispatch();
  const selector = setSelector(campaignId);
  const data = useSelector(selector);

  useEffect(() => {
    dispatch(firstStep());
  }, [dispatch]);

  const goToStart = useCallback(() => {
    dispatch(firstStep());
  }, [dispatch]);

  useEffect(() => {
    if (campaignId && !data.campaign) {
      dispatch(getDeviceCampaignDetails(campaignId));
    }
  }, [data.campaign, dispatch, campaignId]);

  return {
    data,
    actions: {
      goToStart,
    },
  };
};

export default useCampaignModal;
