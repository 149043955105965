import { CAMPAIGN_MODAL } from '../../../../constants/modals';
import useModal from '../../../../utils/useModal';

const useCampaigns = () => {
  const { showModal } = useModal(CAMPAIGN_MODAL);
  const handleClickNewCampaign = () => {
    showModal();
  };

  return {
    actions: {
      handleClickNewCampaign,
    },
  };
};

export default useCampaigns;
