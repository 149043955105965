import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import CAMPAIGNS from '../../services/types';
import {
  getDeviceCampaignDetails,
  // createCampaign as createCampaignAction,
  editCampaign as editCampaignAction,
  // firstStep,
  prevStep,
} from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';
// import { DEVICE_CAMPAIGN } from '../../../../constants/campaigns';
import { SubmissionError } from 'redux-form';

const submitLoadingSelector = createLoadingSelector(CAMPAIGNS.CREATE_CAMPAIGN.base);
const detailsLoadingSelector = createLoadingSelector(CAMPAIGNS.READ_DETAILS.base);

const createCampaignSelector = (id) => createSelector(
  detailsLoadingSelector,
  submitLoadingSelector,
  (state) => state.campaigns.details[id],
  (state) => state.account.accountDetails.access_level,
  (state) => state.brands.list,
  (state) => state.departments.list,
  (state) => state.brands.activeBrand,
  (state) => state.departments.activeDepartment,
  (state) => state.campaigns.editStep,
  (state) => state.campaigns.campaign_type,
  (isLoadingData, isLoadingSubmit, campaign, access_level, brands, departments, activeBrand, activeDepartment, step, campaign_type) => ({
    isLoadingData,
    isLoadingSubmit,
    campaign,
    access_level,
    brands,
    departments,
    activeDepartment,
    activeBrand,
    step, 
    campaign_type,
  }),
);

const useCampaignDetailsForm = (id) => {
  const dispatch = useDispatch();
  const selector = createCampaignSelector(id);
  const data = useSelector(selector);

  const openPage = useCallback(() => {
    if (id && !data.campaign) {
      dispatch(getDeviceCampaignDetails(id));
    }
  }, [data.campaign, dispatch, id]);
  // eslint-disable-next-line no-shadow
  // eslint-disable-next-line no-shadow
  const submitHandler = useCallback((fields) => {
    if (!fields.location) {
      throw new SubmissionError({ 
        'location-validations': 'Required' 
      })
    }
    dispatch(editCampaignAction(id, fields));
  }, [dispatch, id]);

  const goToPrevStep = useCallback(() => {
    dispatch(prevStep());
  }, [dispatch]);

  useEffect(() => {
    openPage();
  }, [openPage]);

  return {
    data,
    actions: {
      goToPrevStep,
      submitHandler
    },
  };
};

export default useCampaignDetailsForm;
