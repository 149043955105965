import { useEffect, useCallback, useMemo } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useModal from '../../../utils/useModal';
import translate from '../../../utils/translate';
import { RESET_PASSWORD_MODAL, INVITE_USER_MODAL, BRAND_INFO_MODAL, ACCOUNT_INFO_MODAL, BRAND_MODAL } from '../../../constants/modals';
import { signOut } from '../../auth/services/actions';
import { getCountries, getFacebookAccounts, getFacebookLink, removeFacebookAccount } from '../services/actions';
import { getBrands } from '../../brand/services/actions';
import { ROLE_COMPANY_ADMIN } from '../../../constants/roles';

const selector = createSelector(
  (state) => state.account.accountDetails,
  (state) => state.account.countries,
  (state) => state.brands.list,
  (state) => state.account.facebookAccounts,
  (accountDetails, countries, brands, facebookAccounts) => ({
    accountDetails,
    countries,
    brands,
    facebookAccounts,
  }),
);

const useAccount = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const data = useSelector(selector);

  const openPage = useCallback(() => {
    dispatch(getCountries());
    dispatch(getBrands());
    dispatch(getFacebookAccounts());
  }, [dispatch]);

  const logout = useCallback(() => {
    dispatch(signOut());
  }, [dispatch]);

  useEffect(() => {
    openPage(params.id);
  }, [openPage, params.id]);

  const { showModal: showResetPasswordModal } = useModal(RESET_PASSWORD_MODAL, {
    title: translate('Reset Password'),
  });

  const { showModal: showAccountModal } = useModal(ACCOUNT_INFO_MODAL, {
    title: translate('Edit account info'),
  });

  const { showModal: showBrandModal } = useModal(BRAND_MODAL, {
    title: translate('Edit brand info'),
  });

  const openBrandEditModal = useCallback(() => {
    if (data.accountDetails && data.accountDetails.company) {
      showBrandModal({ titleModal: translate(`Edit brand info`), company_id:  data.accountDetails.company.id });
    }
  }, [data.accountDetails]);

  const brand_info = useMemo(() => {
    if (data.accountDetails && data.accountDetails.access_level === ROLE_COMPANY_ADMIN) {
      return data.brands[0];
    }
  }, [data.accountDetails, data.brands]);


  const addFacebook = useCallback(() => {
    dispatch(getFacebookLink());
  }, [dispatch]);

  const removeFacebook = useCallback((id) => {
    dispatch(removeFacebookAccount(id));
  }, [dispatch]);

  return {
    actions: {
      showResetPasswordModal,
      showAccountModal,
      openBrandEditModal,
      addFacebook,
      removeFacebook,
      logout,
    },
    data,
    brand_info,
  };
};

export default useAccount;
