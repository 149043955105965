export const AGE_RANGE_OPTIONS = [
  {
    value: 1,
    label: '<18',
  },
  {
    value: 2,
    label: '18 - 25',
  },
  {
    value: 3,
    label: '26 - 35',
  },
  {
    value: 4,
    label: '36 - 45',
  },
  {
    value: 5,
    label: '46 - 55',
  },
  {
    value: 6,
    label: '>55',
  },
];

export const FILTER_PERIOD_OPTIONS = [
  {
    value: 'day',
    label: 'Day',
  },
  {
    value: 'week',
    label: 'Week',
  },
  {
    value: 'last7',
    label: 'Last 7 days',
  },
  {
    value: 'month',
    label: 'Month',
  },
  {
    value: 'last30',
    label: 'Last 30 days',
  },
  {
    value: 'year',
    label: 'Year',
  },
  {
    value: 'all',
    label: 'All',
  },
];

export const statNames = {
  samples_per_period: 'Products distributed per day',
  samples: 'Products distributed',
  bounce_rate: 'Bounce rate',
  engagement_time: 'Engagement time',
  samples_by_title: 'Most popular products',
  social_media: 'Social media',
  question_answers: 'Questions and answers',
  click_through_rate: 'Click through rate',
  samples_per_time_of_day: 'Time of day',
  user_interactions: 'User Interactions',
  predicted_demographics: 'Predicted demographics',
  email_statistics: 'Email statistics',
};
