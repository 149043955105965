import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const FormText = ({ children }) => (
  <p className="auth-form-text">
    {children}
  </p>
);

FormText.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormText;
