import React from 'react';
import { Route, Switch } from 'react-router';

import { AuthLayout } from '../../modules/layout';
import SignIn from '../../modules/auth/components/SignIn';
import SignUp from '../../modules/auth/components/SignUp';
import Invite from '../../modules/auth/components/Invite';
import SuccessRegistration from '../../modules/auth/components/SuccessRegistration';
import ForgotPassword from '../../modules/auth/components/ForgotPassword';
import ChangePassword from '../../modules/auth/components/ChangePassword';
import {
  CHANGE_PASSWORD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  INVITE_ROUTE,
  INVITE_COMPANY_ROUTE,
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
  SUCCESS_REGISTRATION_ROUTE,
} from '../../constants/routes';

const AnonumousRoutes = () => (
  <AuthLayout>
    <Switch>
      <Route exact path={SIGN_IN_ROUTE} component={SignIn} />
      <Route path={SIGN_UP_ROUTE} component={SignUp} />
      <Route path={CHANGE_PASSWORD_ROUTE} component={ChangePassword} />
      <Route path={FORGOT_PASSWORD_ROUTE} component={ForgotPassword} />
      <Route path={INVITE_ROUTE} component={Invite} />
      <Route path={INVITE_COMPANY_ROUTE} render={(props) => <Invite {...props} brandInvite />} />
      <Route path={SUCCESS_REGISTRATION_ROUTE} component={SuccessRegistration} />
    </Switch>
  </AuthLayout>
);

export default AnonumousRoutes;
