import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { call_to_action_options } from '../call_to_action_options';
import InfoTooltip from '../../../../../components/InfoTooltip';
import translate from '../../../../../utils/translate';
import styles from './styles.module.scss';

const FacebookPagePreview = ({ audience_size, title, icon, image, description, content, call_to_action }) => {
	const [src, setSrc] = useState(null);

	useEffect(() => {
		if (image && typeof image === 'object' && image[0]) {
			const reader = new FileReader();

			reader.onload = (e) => {
				setSrc(e.target.result);
			};

			reader.readAsDataURL(image[0]);
		} else if(typeof image === 'string') {
			setSrc(image);
		} else {
			setSrc(null);
		}
	}, [image]);

	return (
		<>

			{audience_size?.estimate_ready && (
				<div className={styles.facebookAudienceSize}>

					<h3 className={styles.facebookAudienceSize__title}>

						{translate('Estimated audience size')}

						{' '}

						<InfoTooltip
							text={translate('The number of monthly active Facebook users who match your targeting criteria.')}
						/>

					</h3>

					<p className={styles.facebookAudienceSize__content}>

						{audience_size?.users}

					</p>

				</div>
			)}

			<div className={styles.facebookPagePreview}>

				<div className={styles.facebookPagePreview__header}>

					<div className={styles.facebookPagePreview__wrap}>

						<figure className={styles.facebookPagePreview__logo}>

							{icon && (<img src={icon} alt="logo" />)}

						</figure>

						<div>

							<h2 className={styles.facebookPagePreview__title}>

								{title}

							</h2>

							<p className={styles.facebookPagePreview__position}>

								{translate('Sponsored')}

							</p>

						</div>

					</div>

					<p className={styles.facebookPagePreview__description}>{description}</p>

				</div>

				<figure className={styles.facebookPagePreview__body}>

					{src && (<img src={src} alt="logo" />)}

				</figure>

				<div className={styles.facebookPagePreview__footer}>

					<p className={styles.facebookPagePreview__content}>{content}</p>

					{call_to_action && (
						<button
							type="button"
							className={styles.facebookPagePreview__button}
						>

							{call_to_action_options.find((option) => option.value === call_to_action)?.label}

						</button>
					)}

				</div>

			</div>

		</>
	)
};

FacebookPagePreview.defaultProps = {
	audience_size: 'Select a location',
	title: 'Select a page',
	icon: '',
	image: [],
	description: 'White a message that clearly tells people about what you`re promoting.',
	content: 'White a clear and concise headline to capture viewers attention.',
};

FacebookPagePreview.propTypes = {
	title: PropTypes.string,
	position: PropTypes.string,
	image: PropTypes.array,
	description: PropTypes.string,
	content: PropTypes.string,
};

export default FacebookPagePreview;
