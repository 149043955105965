import React from 'react';
import _capitalize from 'lodash/capitalize';
import cn from 'classnames';
import tr from '../../../../utils/translate';
import SimpleButton from '../../../../components/SimpleButton';
import {
  ROLE_COMPANY_ADMIN, ROLE_CONTENT_MANAGER, ROLE_DEPARTMENT_ADMIN, ROLE_SUPERADMIN,
} from '../../../../constants/roles';

const roles = {
  [ROLE_COMPANY_ADMIN]: 'Brand admin',
  [ROLE_DEPARTMENT_ADMIN]: 'Team member',
  [ROLE_CONTENT_MANAGER]: 'Device operator',
};


export const getUserColumns = (showModal, showStatusModal, accountDetail, showAssignForm, handleSorting, sortBy) => [
  {
    name: tr('Name'),
    key: 'name',
    className: `table-column-sort ${sortBy.key === 'name' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('name'),
    render: ({ first_name, last_name }) => (
      `${first_name || ''} ${last_name || ''}`
    ),
  },
  {
    name: tr('Email'),
    key: 'email',
    className: `table-column-sort ${sortBy.key === 'email' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('email'),
  },
  {
    name: tr('Company'),
    key: 'company',
    className: `table-column-sort ${sortBy.key === 'company' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('company'),
    render: ({ company }) => company && (<span className={cn('company-info-cell', { 'company-inactive': !company.is_active })}>{company.name}</span>),
  },
  {
    name: tr('Team'),
    key: 'department',
    className: `table-column-sort ${sortBy.key === 'department' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('department'),
    render: ({ department }) => department && (<span className={cn('company-info-cell', { 'company-inactive': !department.is_active })}>{department.name}</span>),
  },
  {
    name: tr('Current Status'),
    key: 'status',
    className: `table-column-sort ${sortBy.key === 'is_active' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('is_active'),
    render: ({ is_active }) => (
      <span
        className={`cell-status cell-status_${is_active ? 'online' : 'offline'}`}
      >
        {is_active ? tr('Active') : tr('Inactive')}
      </span>
    ),
  },
  {
    name: tr('Access level'),
    key: 'access_level',
    className: `table-column-sort ${sortBy.key === 'access' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('access'),
    render: ({
      id, email, access, is_staff,
    }) => (
      <>
        {is_staff ? tr('Superadmin') : roles[access]}
        { !is_staff && (email !== accountDetail.email) && (access !== accountDetail.access_level) && [ROLE_SUPERADMIN, ROLE_COMPANY_ADMIN].includes(accountDetail.access_level) && access && (
        <SimpleButton
          className="cell-link"
          onClick={() => { showModal({ titleModal: tr(`Change role for ${email}`), id, access }); }}
        >
            (
          {tr('Change')}
)
        </SimpleButton>
        ) }
      </>
    ),
  },
  {
    name: tr('Options'),
    key: 'options',
    render: ({
      id, email, is_active, company, department, access, is_staff,
    }) => (
      !is_staff && (email !== accountDetail.email && access !== accountDetail.access_level && Object.keys(roles).indexOf(access) > Object.keys(roles).indexOf(accountDetail.access_level))
      && (
        <>

          {/* {
          access === ROLE_CONTENT_MANAGER && is_active
          && (
          <SimpleButton
            className="cell-link"
            onClick={() => {
              showAssignForm({
                titleModal: tr(`Assign devices to ${email}`), id,
              });
            }}
          >
            {tr('Devices')}
          </SimpleButton>
          )
        } */}
          {
        ((company && company.is_active) && (department === null || (department !== null && department.is_active)) || is_active)
        && (
        <SimpleButton
          className={cn('cell-link status-link', { 'status-active': !!is_active })}
          onClick={() => {
            showStatusModal({
              titleModal: tr(`${is_active ? 'Deactivate' : 'Activate'} ${email}`), id, email, is_active,
            });
          }}
        >
          {tr(`${is_active ? 'Deactivate' : 'Activate'}`)}
        </SimpleButton>
        )
        }
        </>
      )
    ),
  },
];
