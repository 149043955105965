import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import translate from '../../utils/translate';
import './styles.scss';
import { SpinnerIcon } from '../Icons';

const ModalFormFooter = ({
  handleClose,
  closeText,
  confirmText,
  isLoading,
}) => (
  <div className="modal-form-footer">
    <div className="modal-form-footer__cancel-col">
      <Button color="light" onClick={handleClose}>{closeText}</Button>
    </div>
    <div className="modal-form-footer__submit-col">
      <Button
        type="submit"
        className="btn-block"
        status={isLoading ? 'disabled' : 'active'}
        disabled={isLoading}
      >
        {isLoading ? <SpinnerIcon /> : confirmText}
      </Button>
    </div>
  </div>
);

ModalFormFooter.defaultProps = {
  closeText: translate('Close'),
};

ModalFormFooter.propTypes = {
  handleClose: PropTypes.func.isRequired,
  closeText: PropTypes.string,
  confirmText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ModalFormFooter;
