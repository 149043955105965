import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import createLoadingSelector from '../../../../selectors/loadingSelector';
import CAMPAIGNS from '../../services/types';
import { sendTestMails } from '../../services/actions';

const selector = createSelector(
  createLoadingSelector(CAMPAIGNS.SEND_TEST_MAILS.base),
  (isLoading) => ({
    isLoading,
  }),
);

const useSendTestEmails = (campaign_id) => {
  const dispatch = useDispatch();

  const handleYes = useCallback((fields) => {
    let emails = fields.emails && fields.emails.split(',');
    emails = emails.map((val) => val.trim());
    dispatch(sendTestMails(campaign_id, {email_addresses: emails}));
  }, [dispatch, campaign_id]);

  return {
    actions: {
      handleYes,
    },
    data: useSelector(selector),
  };
};

export default useSendTestEmails;
