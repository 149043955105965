import React from 'react';

import Button from '../../../../components/Button';
import PageHeader from '../../../../components/PageHeader';
import tr from '../../../../utils/translate';
import CampaignsTable from '../CampaignsTable';
import useCampaigns from './hooks';
import './styles.scss';

const Campaigns = () => {
  const { actions } = useCampaigns();

  const headerButtons = (
    <Button onClick={actions.handleClickNewCampaign}>{tr('New campaign')}</Button>
  );

  return (
    <div className="page-inner pd-t-25 pd-b-25">
      <PageHeader title={tr('Campaigns')}>
        {headerButtons}
      </PageHeader>

      <div className="row">
        <div className="col-md-12 campaigns-page-list">
          <CampaignsTable />
        </div>
      </div>
    </div>
  );
};

export default Campaigns;
