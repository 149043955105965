import React, {
  useState, useMemo, useEffect, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _map from 'lodash/map';
import ReactAutocomplete from 'react-autocomplete';

import tr from '../../../../utils/translate';
import ProductList from '../../../../components/ProductList';
import ReduxForm from '../../../../components/ReduxForm';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import Table from '../../../../components/Table';
import { BackarrowIcon } from '../../../../components/Icons';
import EditTrayModal from '../EditTrayModal';
import useProductInfo from './hooks';
import { getProductsTable } from './tableData';
import './styles.scss';

const ProductInfo = ({ data: { id } }) => {
  const [selectedProduct, setSelectedProduct] = useState('');
  const productId = useRef('');
  const [productsToAdd, setProductsToAdd] = useState([]);

  const {
    data: {
      isLoading,
      isLoadingProducts,
      campaign,
      products,
    },
    actions: {
      goToBack,
      saveSelected,
    },
  } = useProductInfo(id);

  useEffect(() => {
    if (campaign && products && products.length) {
      const campaignProducts = [];
      campaign.trays.map((item) => {
        const product = _find(products, ['id', item.product]);
        if (product) {
          product.product_stock = item.product_stock;
          campaignProducts.push(product);
        }
      });
      setProductsToAdd(campaignProducts);
    }
  }, [campaign, products]);

  const changeProductQuantity = useCallback((id, quantity) => {
    setProductsToAdd(productsToAdd.map((product) => {
      if (product.id === id) {
        product.product_stock = quantity;
      }
      return product;
    }));
  }, [productsToAdd]);

  const addProductHandler = useCallback(() => {
    if (productId.current && !_find(productsToAdd, ['id', productId.current])) {
      const product = _find(products, ['id', productId.current]);
      product.product_stock = 0;
      product && setProductsToAdd((currenstState) => [...currenstState, product]);
    }
    productId.current = '';
    setSelectedProduct('');
  }, [products, productsToAdd]);

  if ((id && !campaign) || isLoading || isLoadingProducts) return <Loader size="md" />;

  const removeProduct = (id) => {
    setProductsToAdd(productsToAdd.filter((item) => item.id !== id));
  };

  const maxProducts = _get(campaign, 'max_sempl_by_tray', {});

  const campaignType = campaign && campaign.type_is;
  return (
    <>
      <ReduxForm
        enableReinitialize
        form="EDIT_CAMPAIGN_PRODUCTS_FORM"
        formData={{
          onSubmit: saveSelected,
          initialValues: {
            products: productsToAdd,
          },
        }}
      >
        <div className="compaign-product-info-form pd-x-25 pd-y-50">
          <h3 className="cpif-title mg-b-40">{tr('Products usage')}</h3>
          <div className="add-product-input-label">{tr('Select a product to add to the campaign')}</div>
          <div className="add-product-block pd-b-20">
            <ReactAutocomplete
              wrapperProps={{
                className: 'autocomplete-select',
              }}
              items={products || []}
              inputProps={{ placeholder: 'Select product' }}
              getItemValue={(item) => item.name}
              shouldItemRender={(item, value) => {
                const name = item.name || '';
                const tag = item.tag || '';

                return name.toLowerCase().indexOf(value.toLowerCase()) > -1
                  || tag.toLowerCase().indexOf(value.toLowerCase()) > -1;
              }}
              renderItem={({
                id, name, tag, width_in, height_in, depth_in, weight_lbs,
              }, highlighted) => (
                <div
                  className="autocomplete-item"
                  key={id}
                  style={{ backgroundColor: highlighted ? '#eee' : 'transparent' }}
                >
                  {`${name} / ${tag} ${(width_in && height_in && depth_in) ? ` | Size:${width_in} x ${height_in} x ${depth_in}` : ''} ${weight_lbs ? ` | Weight:${weight_lbs} ` : ''}`}
                </div>
              )}
              value={selectedProduct}
              onChange={(e) => {
                setSelectedProduct(e.target.value);
                productId.current = '';
              }}
              onSelect={(value, item) => {
                setSelectedProduct(value);
                productId.current = item.id;
              }}
            />
            <Button className="mg-l-30" onClick={addProductHandler}>
              {tr('Add product')}
            </Button>
          </div>

          <Table
            columns={getProductsTable(removeProduct, changeProductQuantity, campaignType)}
            loading={isLoading}
            data={productsToAdd}
          />

          {/* <ProductList
            className="cpif-trays product__list"
            data={products}
            onClickEdit={showEditTray}
            maxProducts={maxProducts}
          /> */}
        </div>
        <div className="modal-footer">
          <Button color="light" className="mg-r-30" onClick={goToBack}>
            <BackarrowIcon />
            {tr('Back')}
          </Button>
          <Button type="submit" disabled={!productsToAdd || productsToAdd.length === 0}>
            {tr('Next')}
          </Button>
        </div>


      </ReduxForm>
    </>
  );
};

ProductInfo.defaultProps = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }),
};

ProductInfo.defaultProps = {
  data: {},
};

export default ProductInfo;
