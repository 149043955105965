import { INVITE_DEPARTMENT_MODAL, DEPARTMENT_MODAL, DEACTIVATE_DEPARTMENT_MODAL } from '../../constants/modals';
import useModal from '../../utils/useModal';

const useDepartments = () => {
  const { showModal: handleInviteUser } = useModal(INVITE_DEPARTMENT_MODAL);

  const { showModal: addDepartmentModal } = useModal(DEPARTMENT_MODAL);

  const { showModal: departmentStatusChange, hideModal: hideDepartmentStatusModal } = useModal(DEACTIVATE_DEPARTMENT_MODAL);


  return {
    actions: {
      handleInviteUser,
      addDepartmentModal,
      departmentStatusChange,
      hideDepartmentStatusModal,
    },
  };
};

export default useDepartments;
