import {
  takeEvery, put, call,
} from 'redux-saga/effects';
import { hide } from 'redux-modal';
import { toast } from 'react-toastify';
import _get from 'lodash/get';
import history from '../../../utils/history';
import { getBrands } from './actions';

import { BRAND_MODAL, INVITE_BRAND_MODAL, DEACTIVATE_BRAND_MODAL } from '../../../constants/modals';
import tr from '../../../utils/translate';
import BRAND from './types';

function* runBrandEditSuccess() {
  yield call(toast.success, tr('Brand updated'));
  yield put(hide(BRAND_MODAL));
  yield put(getBrands())
}

function* runInviteBrand() {
  yield put(hide(INVITE_BRAND_MODAL));
  yield toast.success(tr('Brand invitation email has been sent'));
}

function* runSetActiveBrand() {
  // alert('==')
  // yield history.push('/');
}

function* runActivateBrandSuccess() {
  yield put(hide(DEACTIVATE_BRAND_MODAL));
  yield call(toast.success, tr('Brand is activate'));
}

function* runDeactivateBrandSuccess() {
  yield put(hide(DEACTIVATE_BRAND_MODAL));
  yield call(toast.success, tr('Brand is deactivate'));
}

export default function* brands() {
  yield takeEvery(BRAND.SET_ACTIVE_BRAND, runSetActiveBrand);
  yield takeEvery(BRAND.INVITE_BRAND.SUCCESS, runInviteBrand);
  yield takeEvery(BRAND.EDIT_BRAND.SUCCESS, runBrandEditSuccess);
  yield takeEvery(BRAND.ACTIVATE_BRAND.SUCCESS, runActivateBrandSuccess);
  yield takeEvery(BRAND.DEACTIVATE_BRAND.SUCCESS, runDeactivateBrandSuccess);
  // yield takeEvery(BRAND.SET_ACTIVE_BRAND, runCreateCampaignSuccess);
  // yield takeEvery([
  //   CAMPAIGNS.EDIT_CAMPAIGN.SUCCESS,
  // ], runCampaignEditSuccess);
}
