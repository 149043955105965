import React, { useMemo } from 'react';
import { BackarrowIcon } from '../../../../../components/Icons';
import InputFileImage from '../../../../../components/InputFileImage';
import ReduxForm from '../../../../../components/ReduxForm';
import Select from '../../../../../components/Select';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Input';
import FacebookPagePreview from '../FacebookPagePreview';
import FacebookPages from './FacebookPages';
import AccountExists from './AccountExists';
import validate from '../validate';
import { call_to_action_options } from '../call_to_action_options';
import tr from '../../../../../utils/translate';

const AdForm = ({ form, campaign, audience_size, fb_pages, account_exists, ad_campaign, ad_accounts, onSubmit, previousPage }) => {
	const adAccountOptions = [{ name: '', id: '', has_payment: true}, ...(ad_accounts || [])].map((account) => ({
		label: `${account.name}${!account.has_payment ? ` - ${tr('payment method isn\'t available')}` : ''}`,
		value: account.id,
		disabled: !account.has_payment,
	}));

	const objPage = (fb_pages || []).find(() => form?.values?.facebook_page);

	const initValues = useMemo(() => {
		const values = {
			ad_account: '',
			facebook_page: '',
			from_campaign: 'new',
			account_exist: '',
			image: [],
			text_body: '',
			headline: '',
			call_to_action: 'LEARN_MORE',

			// category: '',
			from_audience: 'new',
			audience: '',
			location: {},
			age_from: '',
			age_to: '',
			gender: 3,
			interests: [],
			placement: {},

			type_budget: 'LIFETIME',
			budget_life: '',
			budget_per_day: '',
			start_time: '',
			end_time: '',
			stop_ad: false,
		};

		if(campaign && ad_campaign?.id) {
			const ad_sets = ad_campaign?.ad_sets[0];

			const ads = ad_sets?.ads[0];

			const targeting = ad_sets?.targeting;

			if(ad_campaign?.ad_account) {
				values.ad_account = ad_campaign.ad_account;
			}

			if(ads?.page_id) {
				values.facebook_page = ads.page_id;
			}

			if(ads?.file) {
				values.image = ads.file;
			}

			if(ads?.body) {
				values.text_body = ads.body;
			}

			if(ads?.title) {
				values.headline = ads.title;
			}

			if(ads?.call_to_action_type) {
				values.call_to_action = ads.call_to_action_type;
			}

			if(targeting?.geo_locations[0]) {
				values.location = targeting.geo_locations[0];
			}

			if(targeting?.interests) {
				values.interests = targeting.interests;
			}

			if(targeting?.age_min) {
				values.age_from = targeting.age_min;
			}

			if(targeting?.age_max) {
				values.age_to = targeting.age_max;
			}

			if(targeting?.genders) {
				const gender = targeting.genders[0];

				values.gender = !gender ? 3 : Number(gender);
			}

			if(targeting?.publisher_platforms) {
				values.placement = targeting.publisher_platforms.reduce((acc, item) => {
					acc[item] = true;

					return acc;
				}, {});
			}

			if(ad_sets?.budget_type) {
				values.type_budget = ad_sets.budget_type;

				if(ad_sets?.budget) {
					values[ad_sets.budget_type === 'LIFETIME' ? 'budget_life' : 'budget_per_day'] = ad_sets.budget;
				}
			}

			if(ad_sets?.start_time) {
				values.start_time = ad_sets.start_time;
			}

			if(ad_sets?.end_time) {
				values.end_time = ad_sets.end_time;
			}

			if(campaign?.stop_ad) {
				values.stop_ad = ad_sets.stop_ad;
			}
		}

		return values;
	}, [campaign, ad_campaign]);

	return (
		<ReduxForm
			// enableReinitialize
			form="CAMPAIGN_FACEBOOK_FORM"
			destroyOnUnmount={false}
			forceUnregisterOnUnmount={true}
			validate={validate}
			formData={{
				onSubmit,
				initialValues: initValues,
			}}
		>

			<div className="campaign-location-form pd-x-25 pd-y-50">

				<div className="row">

					<div className="col-md-6">

						<div className="mg-b-30">

							<Select
								fieldLabel={tr('Ad account')}
								name="ad_account"
								options={adAccountOptions}
							/>

						</div>

						<div className="mg-b-30">

							<FacebookPages
								fieldLabel={tr('Facebook page')}
								name="facebook_page"
								fb_pages={fb_pages}
							/>

						</div>

						{!campaign?.ad_campaign?.id && form?.values?.ad_account && (
							<>

								<h4 className="cdf-subtitle mg-b-10">
									{tr('Facebook campaign')}
								</h4>

								<div className="row mg-b-30">

									<Input
										id="new_campaign"
										name="from_campaign"
										type="radio"
										className="col checkbox"
										appendFieldLabel={tr('Create new campaign')}
										value="new"
									/>

									<Input
										id="exist_campaign"
										name="from_campaign"
										type="radio"
										className="col checkbox"
										appendFieldLabel={tr('Select from existing')}
										value="exist"
									/>

								</div>

								{form?.values?.from_campaign === 'exist' && (
									<div className="mg-b-30">

										<AccountExists
											fieldLabel={tr('Account exist')}
											name="account_exist"
											account_exists={account_exists}
										/>

									</div>
								)}

							</>
						)}

						<div className="mg-b-30">

							<InputFileImage
								name="image"
								label={tr('Image')}
								maxSize={2097152}
								additionalInfo={tr('Max 2 mb')}
							/>

						</div>

						<div className="mg-b-30">

							<Input
								name="text_body"
								type="textarea"
								autoComplete="off"
								prependFieldLabel={tr('Text / Body Copy')}
							/>

						</div>

						<div className="mg-b-30">

							<Input
								name="headline"
								autoComplete="off"
								prependFieldLabel={tr('Headline')}
							/>

						</div>

						<div className="mg-b-30">

							<Select
								fieldLabel={tr('Call to action')}
								name="call_to_action"
								options={call_to_action_options}
							/>

						</div>


					</div>

					<div className="col-md-6 pd-l-60">

						<FacebookPagePreview
							audience_size={audience_size}
							title={objPage?.name}
							icon={objPage?.image_url}
							image={form?.values?.image}
							description={form?.values?.text_body}
							content={form?.values?.headline}
							call_to_action={form?.values?.call_to_action}
						/>

					</div>

				</div>

			</div>

			<div className="modal-footer">

				<Button color="light" className="mg-r-30" onClick={previousPage}>

					<BackarrowIcon />

					{tr('Back')}

				</Button>

				<Button
					type="submit"
				>

					{tr('Next')}

				</Button>

			</div>

		</ReduxForm>
	);
};

export default AdForm;
