import PRODUCT from './types';
import { createActionRequest } from '../../../utils/actionHelpers';

export const getProducts = createActionRequest(
  PRODUCT.READ_PRODUCTS_LIST,
  'get',
  'bo/products/',
  {
    setDataPath: (params) => ({ params }),
  },
);

export const createProduct = createActionRequest(PRODUCT.CREATE_PRODUCT, 'post', '/bo/products/', {
  setData: (data) => data,
  format: 'form',
});

export const updateProduct = createActionRequest(PRODUCT.UPDATE_PRODUCT, 'patch', '/bo/products/{id}/', {
  setDataPath: (id) => ({ id }),
  setData: (id, data) => data,
  format: 'form',
});
