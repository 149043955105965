import React from 'react';
import Select from 'react-select';
import _map from 'lodash/map';
import tr from '../../../../utils/translate';

// import 'react-tagsinput/react-tagsinput.css';
import { jsonData } from './interests';
import './styles.scss';

const InterestsField = ({ input, data }) => {
  const formattedValue = data && data.map((v) => ({ value: v, label: v }));
  return (
    <div className="interests-input">
      <div className="interests-input-label pd-b-5">{tr('Interests')}</div>
      <Select
        defaultValue={formattedValue}
        options={jsonData}
        name="interests"
        isMulti
        classNamePrefix="interests"
        onChange={(value) => {
          const valueArray = _map(value, 'value') || [];
          return input.onChange(valueArray.join(','));
        }}
      />
    </div>
  );
};

export default InterestsField;
