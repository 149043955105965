import tr from '../../../utils/translate';

const validate = ({ name, company }) => {
  const errors = {};

  if (!name) {
    errors.name = tr('Brand name is required.');
  }
  if (!company) {
    errors.company = tr('Company name is required.');
  }

  return errors;
};

export default validate;
