import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { touch } from 'redux-form';

import CAMPAIGNS from '../../services/types';
import {
  prevStep,
  editInterface,
} from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';

const loadingSelector = createLoadingSelector(CAMPAIGNS.UPDATE_INTERFACE.base);

const createCampaignSelector = (id) => createSelector(
  loadingSelector,
  (state) => state.campaigns.details[id],
  (state) => state.form,
  (isLoading, campaign, reduxForms) => ({
    isLoading,
    campaign,
    reduxForms,
  }),
);

const useInterface = (id) => {
  const dispatch = useDispatch();
  const selector = createCampaignSelector(id);
  const data = useSelector(selector);

  const goToBack = useCallback(() => {
    dispatch(prevStep());
  }, [dispatch]);

  const submit = useCallback((fields) => {
    const {
      file: files,
      email_receipt: email_receipts,
      sample_giveaway_restriction,
      follow_up_email: follow_up_emails,
      invitation_email: invitation_emails,
      address_restriction,
    } = fields;
    const [file] = files instanceof Array ? files : [];
    const [email_receipt] = email_receipts instanceof Array ? email_receipts : [];
    const [follow_up_email] = follow_up_emails instanceof Array ? follow_up_emails : [];
    const [invitation_email] = invitation_emails instanceof Array ? invitation_emails : [];

    dispatch(editInterface(id, {
      file,
      email_receipt,
      sample_giveaway_restriction,
      follow_up_email_template: follow_up_email,
      email_template: invitation_email,
      address_restriction,
    }));
  }, [dispatch, id]);

  const submitShort = useCallback((fields) => {
    const {
      file: files,
      email_receipt: email_receipts,
      follow_up_email: follow_up_emails,
      invitation_email: invitation_emails,
    } = fields;
    const [file] = files instanceof Array ? files : [];
    const [email_receipt] = email_receipts instanceof Array ? email_receipts : [];
    const [follow_up_email] = follow_up_emails instanceof Array ? follow_up_emails : [];
    const [invitation_email] = invitation_emails instanceof Array ? invitation_emails : [];

    dispatch(editInterface(id, {
      file,
      email_receipt,
      follow_up_email_template: follow_up_email,
      email_template: invitation_email,
    }));
  }, [dispatch, id]);

  const submitGiveawayRestriction = useCallback((fields) => {
    const {
      sample_giveaway_restriction,
      address_restriction,
      email_validation,
      valid_emails_file,
    } = fields;
    const [file] = valid_emails_file instanceof Array ? valid_emails_file : [];

    dispatch(editInterface(id, {
      sample_giveaway_restriction,
      address_restriction,
      email_validation,
      valid_emails_file: file,
    }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(touch('COMPAIGN_INTERFACE_FORM', 'file'));
    dispatch(touch('COMPAIGN_INTERFACE_FORM', 'invitation_email'));
  }, [dispatch]);

  return {
    data,
    actions: {
      goToBack,
      submit,
      submitShort,
      submitGiveawayRestriction,
    },
  };
};

export default useInterface;
