import React from 'react';

import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Checkbox from '../../../../../components/Checkbox';
import tr from '../../../../../utils/translate';
import { formatDateNoUtc } from '../../../../../utils/dates';
import {
  ONLINE_EMBEDED_CAMPAIGN, ONLINE_SOCIAL_CAMPAIGN, ONLINE_EMAIL_CAMPAIGN, DEVICE_CAMPAIGN,
} from '../../../../../constants/campaigns';
// import { ROLE_COMPANY_ADMIN } from '../../../../../constants/roles';


export const infoColumns = (
  type_is,
  status,
  downloadCSVAction,
  copyLinkModal,
  validateDomainModal,
  access_level,
  isPreview = true,
  handleEditCampaign,
) => [
  {
    name: tr('Campaign name'),
    key: 'name',
  },
  {
    name: tr('Start date'),
    key: 'date_start',
    render: ({ date_start }) => (date_start ? formatDateNoUtc(date_start) : tr('No data')),
  },
  {
    name: tr('End date'),
    key: 'date_end',
    render: ({ date_end }) => (date_end ? formatDateNoUtc(date_end) : tr('No data')),
  },
  {
    name: tr('Current Status'),
    key: 'status',
    render: ({ status }) => (status
      ? (
        <span
          className={`cell-status cell-status_${status.split(' ').join('-').toLowerCase()}`}
        >
          {status.replace('_', ' ')}
        </span>
      )
      : tr('No data')
    ),
  },
  ...([ONLINE_EMBEDED_CAMPAIGN, ONLINE_SOCIAL_CAMPAIGN].includes(type_is) && status === 'approved' ? [{
    name: tr('Link'),
    key: 'online_campaign_link',
    render: ({ online_campaign_link, type_is }) => {
      const embededCode = type_is === ONLINE_EMBEDED_CAMPAIGN ? `<iframe src="${online_campaign_link}" frameborder="0" allow="encrypted-media; gyroscope; oversized-images" allowfullscreen></iframe>` : `${online_campaign_link}`;
      return (
        <div className="embeded-code-wrapper">
          <div className="embeded_code pd-r-10">{embededCode}</div>
          { type_is === ONLINE_EMBEDED_CAMPAIGN
            ? (
              <CopyToClipboard
                text={embededCode}
                onCopy={() => toast.success('Link copied to clipboard')}
              >
                <button className="copy_link mg-l-5">Copy</button>
              </CopyToClipboard>
            )
            : <button className="copy_link mg-l-5" onClick={copyLinkModal}>Copy</button>}
        </div>
      );
    },
  }] : []),
  ...([ONLINE_EMAIL_CAMPAIGN].includes(type_is) ? [{
    name: tr('Users list'),
    key: 'users_list-export',
    render: ({ email_list_count }) => (
      email_list_count
        ? (
          <>
            <span>
              {email_list_count}
              {' '}
            </span>
            <button
              onClick={(e) => {
                e.preventDefault();
                downloadCSVAction();
              }}
              className="copy_link mg-l-5"
            >
Download user list
            </button>
          </>
        )
        : tr('No data')
    ),
  }] : []),
  ...([DEVICE_CAMPAIGN].includes(type_is) ? [{
    name: tr('Enable QR code'),
    key: 'enable_qr',
    render: ({ enable_qr }) => (
      <Checkbox
        name="enable_qr"
        checked={enable_qr}
        onChange={({ target: { checked } }) => {
          handleEditCampaign({ enable_qr: checked });
        }}
      />
    ),
  }] : []),
  {
    name: tr('Questionnaire preview'),
    key: 'preview',
    render: ({ preview }) => (preview && preview.url
      ? (
        <a
          className="simple-button"
          href={`https://${preview.url}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {tr('Show')}
        </a>
      )
      : tr('Show')
    ),
  },
  ...([ONLINE_EMAIL_CAMPAIGN].includes(type_is) && !isPreview ? [{
    name: tr('Domain verification'),
    key: 'domain_verified',
    render: ({ domain_verified }) => {
      if (domain_verified === true) {
        return tr('Verified');
      } if (domain_verified === false) {
        return <button onClick={validateDomainModal} className="copy_link mg-l-5">Verify</button>;
      }
      return tr('Not required');
    },
  }] : []),
];
