import React, { useMemo, useState, useEffect, useCallback } from 'react';
// import _filter from 'lodash/filter';
import { change } from 'redux-form';
import { useDispatch } from 'react-redux';

import Modal from '../../../../components/Modal';
import ReduxForm from '../../../../components/ReduxForm';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import ModalFormFooter from '../../../../components/ModalFormFooter';
import tr from '../../../../utils/translate';
import { INVITE_DEPARTMENT_MODAL } from '../../../../constants/modals';
import { DEPARTMENT } from '../../../../constants/forms';
import { generateSelect } from '../../../../helpers/arrayHelpers';
import { ROLE_CONTENT_MANAGER, ROLE_DEPARTMENT_ADMIN, ROLE_COMPANY_ADMIN, ROLE_SUPERADMIN } from '../../../../constants/roles';
import validate from './validate';
import useInviteModal from './hooks';

const required = (value) => (value ? undefined : 'Required');

const InviteBrandModal = () => {
  const dispatch = useDispatch();

  const { actions: { hideModal, inviteUser, getDepartments }, data: { isLoading, brands, departments, access_level, company, department, modal: { show: isShowModal } } } = useInviteModal();
  // const departmentSelect = generateSelect(_filter(departments, ['is_active', true]), 'name');
  const departmentSelect = generateSelect(departments, 'name');
  const brandSelect = generateSelect(brands, 'name');
  const [selectedRole, setSelectedRole] = useState(null);
  const isSuperAdmin = useMemo(() => access_level === ROLE_SUPERADMIN, [access_level]);
  const accessLevelSelect = useMemo(() => (
    [
      { value: '', label: '' },
      ...(access_level === ROLE_COMPANY_ADMIN || isSuperAdmin ? [{ value: ROLE_COMPANY_ADMIN, label: tr('Brand admin') }] : []),
      { value: ROLE_DEPARTMENT_ADMIN, label: tr('Team member') },
      { value: ROLE_CONTENT_MANAGER, label: tr('Device operator') },
    ]
  ), [access_level, isSuperAdmin]);
  
  useEffect(() => {
    if (isShowModal) {
      getDepartments({ company });
    }
  }, [isShowModal]);

  const clearDepartment = useCallback(() => {
    dispatch(change(DEPARTMENT.INVITE_USER_FORM, 'department', ''));
  }, [dispatch]);

  return (
    <Modal name={INVITE_DEPARTMENT_MODAL} title={tr('Invite member')} size="sm">
      <ReduxForm
        form={DEPARTMENT.INVITE_USER_FORM}
        validate={validate}
        formData={{
          onSubmit: inviteUser,
          initialValues: {
            company,
            department,
          },
        }}
      >
        <div className="modal-body">
          <Input
            name="user_to"
            className="mg-b-15"
            prependFieldLabel={tr('Email address')}
          />
          <Select
            fieldLabel={tr('Select access level')}
            className="pd-b-15"
            name="access_level"
            options={accessLevelSelect}
            validate={[required]}
            onChange={(event) => setSelectedRole(event.target.value)}
          />

          {isSuperAdmin && (
            <Select
              fieldLabel={tr('Select Brand')}
              className="pd-b-30"
              name="company"
              options={brandSelect}
              validate={[required]}
              onChange={(event) => {
                getDepartments({ company: event.target.value });
                clearDepartment();
              }}
            />
          )}

          {(access_level === ROLE_COMPANY_ADMIN || isSuperAdmin)
          && (
          <Select
            disabled={selectedRole === ROLE_COMPANY_ADMIN && !isSuperAdmin}
            fieldLabel={tr('Select department')}
            className="pd-b-30"
            name="department"
            options={departmentSelect}
            supText={isSuperAdmin && selectedRole === ROLE_COMPANY_ADMIN ? '*optional' : ''}
            validate={selectedRole === ROLE_COMPANY_ADMIN ? [] : [required]}
          />
          )}

        </div>
        <div className="modal-footer">
          <ModalFormFooter
            handleClose={hideModal}
            confirmText={tr('Send invite')}
            isLoading={isLoading}
          />
        </div>
      </ReduxForm>
    </Modal>
  );
};

export default InviteBrandModal;
