import {
    takeEvery, put, call,
  } from 'redux-saga/effects';
  import  { getUserCampaigns, getUserDevices, getUserPolls } from './actions';
  import USERS from './types';
  
  
  function* runGetUserDetailsSuccess({ payload: { req, res } }) {
    const id = req.params.id;
    yield put(getUserDevices(id));
    yield put(getUserCampaigns(id));
    yield put(getUserPolls(id));
  }
  
  
  export default function* users() {
    yield takeEvery(USERS.READ_USER_DETAILS.SUCCESS, runGetUserDetailsSuccess);
  }
  