import React from 'react';
import _filter from 'lodash/filter';

import PageHeader from '../../../../components/PageHeader';
import Button from '../../../../components/Button';
import { DEVICES_ROUTE } from '../../../../constants/routes';
import ReduxForm from '../../../../components/ReduxForm';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import Frame from '../../../../components/Frame';
import AddDeviceModal from '../AddDeviceModal';
import {
  MiniBurgerIcon,
} from '../../../../components/Icons';
import tr from '../../../../utils/translate';
import { generateSelect } from '../../../../helpers/arrayHelpers';
import { ADD_NEW_DEVICE_FORM } from '../../../../constants/forms';
import { ROLE_COMPANY_ADMIN, ROLE_SUPERADMIN } from '../../../../constants/roles';
import useAddDevice from './hooks';
import validate from './validate';

const breadcrumbs = [
  { to: DEVICES_ROUTE, body: tr('Devices') },
];

// const required = (value) => (value ? undefined : 'Required');

const AddNewDevice = () => {
  const { data, actions, selectedBrand } = useAddDevice();

  const {
    access_level,
    brands,
    departments,
    activeBrand,
    activeDepartment,
  } = data;

  const headerButtons = (
    <div>
      <Button type="submit" disabled={data.isLoading}>
        {tr('Confirm')}
      </Button>
    </div>
  );

  const connectionFields = () => {
    if (![ROLE_SUPERADMIN, ROLE_COMPANY_ADMIN].includes(access_level)) {
      return null;
    }
    const brandSelect = generateSelect(brands, 'name');
    const departmentSelect = departments ? generateSelect(departments, 'name') : [];

    return ( 
      <>
        {access_level === ROLE_SUPERADMIN && (
        <Select
          disabled={!!activeBrand}
          fieldLabel={tr('Select brand')}
          classNameWrapper="col"
          name="company"
          options={brandSelect}
          onChange={(event) => { actions.handleBrandChange(event.target.value); }}
        />
        )}
        <Select
          disabled={!!activeDepartment || (access_level === ROLE_SUPERADMIN && !selectedBrand)}
          fieldLabel={tr('Select department')}
          classNameWrapper="col"
          name="department"
          options={departmentSelect}
        />
      </>
    );
  };

  return (
    <div className="page-inner pd-t-25 pd-b-25">
      <ReduxForm
        form={ADD_NEW_DEVICE_FORM}
        validate={validate}
        formData={{
          onSubmit: actions.addNewDevice,
          initialValues: {
            ...(activeBrand && { company: activeBrand }),
            ...(activeDepartment && { department: activeDepartment }),
          },
        }}
      >
        <PageHeader title={tr('Add device')} list={breadcrumbs}>
          {headerButtons}
        </PageHeader>

        <Frame
          header={(
            <>
              <h3 className="card-header-title tx-normal">{tr('Device Information')}</h3>
              <div className="card-header-btn">
                <button type="button" className="btn">
                  <MiniBurgerIcon />
                </button>
              </div>
            </>
          )}
        >
          <div className="row">
            <div className="col">
              <Input
                name="serial_number"
                disabled={data.isLoading}
                prependFieldLabel={tr('Device Serial No.')}
              />
            </div>
            <div className="col">
              <Input
                name="name"
                disabled={data.isLoading}
                prependFieldLabel={tr('Device Label')}
              />
            </div>
            <div className="col">
              <Input
                name="location"
                disabled={data.isLoading}
                prependFieldLabel={tr('Location')}
              />
            </div>
            {connectionFields()}
          </div>
        </Frame>
      </ReduxForm>

      <AddDeviceModal />
    </div>
  );
};

export default AddNewDevice;
