import translate from '../../../../utils/translate';

const validate = ({
  product_name,
  product_tag,
}) => {
  const errors = {};

  if (!product_name) errors.product_name = translate('Product name is required');
  if (!product_tag) errors.product_tag = translate('Product TAG is required');

  return errors;
};

export default validate;
