import React from 'react';
import PropTypes from 'prop-types';

import tr from '../../utils/translate';
import SimpleButton from '../SimpleButton';
import './styles.scss';

const Tray = ({ data, maxProducts, onClickEdit }) => {
  const handleClickEdit = () => onClickEdit(data);

  const productCount = data.product_available || data.product_stock;
  const progress = (productCount / maxProducts) * 100;

  return (
    <div className="tray">
      <div className="tray__header">
        <span className="tray__number">{tr(`${tr('Tray')} #${data.number}`)}</span>
        <span className="tray__name">{data.product_name || tr('No product')}</span>
      </div>
      <div className="tray__body">
        <div className="tray__info">
          {productCount ? (
            <span>{`${productCount} out of ${maxProducts}`}</span>
          ) : (
            <span>{tr('No data')}</span>
          )}
        </div>
        <div>
          {onClickEdit && (
            <SimpleButton onClick={handleClickEdit}>
              {tr('Edit')}
            </SimpleButton>
          )}
        </div>
      </div>
      <div>
        <div className="progress progress-xs">
          <div className="progress-bar" style={{ width: `${progress || 0}%` }} />
        </div>
      </div>
    </div>
  );
};

Tray.defaultProps = {
  onClickEdit: null,
};

Tray.propTypes = {
  onClickEdit: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
};

export default React.memo(Tray);
