import { createRequestTypes } from '../../../utils/actionHelpers';

export default createRequestTypes('DEVICES', [
  'GET_ALL_DEVICES',
  'GET_PRODUCTS',
  'SET_DIVECE_INFO',
  'SET_DEVICE_CAMPAIGN',
  'ADD_NEW_DEVICE',
  'GET_DIVECE_INFO',
  'GET_DIVECE_INFO_UNTRACKED',
  'UPDATE_DIVICE_TRAY',
  'CREATE_EMAIL_RECEIPT',
  'UPDATE_EMAIL_RECEIPT',
  'DELETE_EMAIL_RECEIPT',
  'CREATE_LAYOUT',
  'UPDATE_LAYOUT',
  'DELETE_LAYOUT',
  'DELETE_SESSION',
  'CREATE_SCREENSAVER',
  'UPDATE_SCREENSAVER',
  'DELETE_SCREENSAVER',
  'REBOOT_DEVICE',
  'TOUCH_TRAY',
  'READ_MOTOR_LOG',
  'GET_DEVICE_STATISTIC',
  'GET_DEVICE_CAMPAIGNS',
  'GET_CAMPAIGN_STATS_AVAILABLE',
], [
  'PAGE_DEVICE_INFO',
  'ADD_ROW_SCREENSAVER',
]);
