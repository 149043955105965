import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { TooltipInfoIcon } from '../Icons';
import './styles.scss';

export default function InfoTooltip({ text, className }) {
  return (
    <div className={cn('o-tooltip-wrapper', className)}>
      <TooltipInfoIcon />
      <div className="o-tooltip-body">
        {text}
      </div>
    </div>
  );
}

InfoTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

InfoTooltip.defaultProps = {
  className: null,
};
