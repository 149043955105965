import React from 'react';

import tr from '../../../utils/translate';
import Table from '../../../components/Table';
import createColumns from './columns';
import useDepartmentsTable from './hooks';
import './styles.scss';

const tabs = [
  { key: 'active', name: tr('Active') },
  { key: 'disabled', name: tr('Disabled') },
];

const DepartmentsTable = () => {
  const {
    data: {
      isLoading,
      departments,
      pagination,
    },
    actions: {
      addDepartmentModal,
      departmentStatusChange,
      changeSorting,
      getNextPage,
    },
    sortBy,
    activeTab,
    setActiveTab,
  } = useDepartmentsTable();
  const columns = createColumns(addDepartmentModal, departmentStatusChange, changeSorting, sortBy);

  const handleSelectTab = (index, { name }) => {
    setActiveTab(name);
  };

  return (
    <>
      <Table
        caption={tr('Teams')}
        className="departments-list-table"
        tabs={tabs}
        columns={columns}
        loading={isLoading}
        data={{ [activeTab]: departments }}
        onSelectTab={handleSelectTab}
        pagination={{...pagination, getNextPage }}
      />
    </>
  );
};

export default DepartmentsTable;
