import translate from '../../../../utils/translate';

const validate = ({
  code,
}) => {
  const errors = {};
  const letterNumber = /^[0-9a-zA-Z]+$/;
  if (!code) {
    errors.code = translate('Code is required');
  } else if (!code.replace(/ /g,'').match(letterNumber) || code.replace(/ /g,'').length !== 12) {
    errors.code = translate('Invalid code');
  }
  return errors;
};

export default validate;
