import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Notifications from './components/Notifications';
import './style.scss';

const AppLayout = ({ children }) => {
  const [isSidebarCollapsed, toggleSidebar] = useState(false);

  const handleToggleSidebar = () => toggleSidebar(!isSidebarCollapsed);

  return (
    <div className={cn('page-sidebar-fixed', { 'page-sidebar-collapsed': isSidebarCollapsed, 'page-sidebar-visible': !isSidebarCollapsed })}>
      <div className="page-container">
        <Sidebar collapsed={isSidebarCollapsed} handleToggleSidebar={handleToggleSidebar} />
        <div className="page-content">
          <Header handleToggleSidebar={handleToggleSidebar} />
          {children}
          <Notifications />
        </div>
      </div>
    </div>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLayout;
