import React from 'react';
import Table from '../../../../components/Table';
import { getUserColumns } from '../../../../helpers/tableData';
import translate from '../../../../utils/translate';
import './styles.scss';

const columns = getUserColumns(true);

const defaultData = columns.reduce((res, { key }) => ({ ...res, [key]: null }), {});

const TableUsers = ({ data }) => (
  <Table
    className="device-table-campaign"
    caption={translate('Users')}
    // tooltip={translate('Tooltip about this section')}
    columns={columns}
    data={data.length ? data : [defaultData]}
  />
);

export default TableUsers;
