import React from 'react';
import tr from '../../../../../utils/translate';
import { genders } from '../../../../../constants/campaigns';

export const socialColumns = [
  {
    name: tr('Social media'),
    key: 'social_media',
    render: ({ social_networks }) => {
      const renderData = social_networks && social_networks.map((value, index) => (
        <span key={index}>

          {value}

        </span>
      ));

      return (
        <div className="detailed_badges">

          {renderData}

        </div>
      );
    },
  },
  {
    name: tr('Demographics'),
    key: 'demographic',
    render: ({ demographic }) => demographic && (
      <div className="demographics-details-info">

        <span>

          {tr('Age from: ')}

          {' '}

          {demographic.age_from || tr('No data')}

        </span>

        <span>

          {tr('Age to: ')}

          {' '}

          {demographic.age_to || tr('No data')}

        </span>

        <span>

          {tr('Gender: ')}

          {' '}

          {demographic.gender ? genders[demographic.gender] : tr('No data')}

        </span>

        <span className="interests-info">

          {tr('Interests: ')}

          {demographic.interests || tr('No data')}

        </span>

      </div>
    ),
  },
  {
    name: tr('Locations'),
    key: 'location',
    render: ({ location }) => {
      const renderData = location && location.split(';')
        .filter((name) => name)
        .map((value, index) => (
          <span key={index}>

            {value}

          </span>
        ));

      return (
        <div className="detailed_badges locations-badges">

          {renderData}

        </div>
      );
    },
  },
];
