import React from 'react';
import Select from 'react-select';
import _map from 'lodash/map';
import tr from '../../../../utils/translate';

// import 'react-tagsinput/react-tagsinput.css';
import { AGE_RANGE_OPTIONS } from '../../../../constants/common';
import './styles.scss';

const AgeMultiselect = ({ input, data }) => {
  const formattedValue = data && data.map((v) => ({ value: v, label: v }));
  return (
    <div className="age-multiselect-input">
      <div className="age-input-label pd-b-5">{tr('Age')}</div>
      <Select
        defaultValue={formattedValue}
        options={AGE_RANGE_OPTIONS}
        name="age"
        isMulti
        classNamePrefix="age"
        value={input.value.map((item) => AGE_RANGE_OPTIONS.find(({ value }) => item === value))}
        onChange={(value) => {
          const valueArray = _map(value, 'value') || [];
          return input.onChange(valueArray);
        }}
      />
    </div>
  );
};

export default AgeMultiselect;
