import translate from '../../../../utils/translate';

const validate = ({
  product_id,
  product_stock,
  product_stock_alert,
}) => {
  const errors = {};

  if (!product_id) errors.product = translate('Product is required');

  if (!product_stock) errors.product_stock = translate('Product stock is required');

  if (!product_stock_alert) errors.product_stock_alert = translate('Low stock alert is required');

  return errors;
};

export default validate;
