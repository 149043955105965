import tr from '../../../../../utils/translate';

const validate = (campaign) => (fields) => {
  const errors = {};

  if(fields.email_validation && !(campaign.valid_emails_file || fields.valid_emails_file)) {
    errors.valid_emails_file = tr('Please upload CSV');
  }

  return errors;
};

export default validate;