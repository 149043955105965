import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import CAMPAIGNS from '../../services/types';
import {
  getDeviceCampaignDetails,
  createCampaign as createCampaignAction,
  editCampaign as editCampaignAction,
  firstStep,
  prevStep,
} from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import { DEVICE_CAMPAIGN, ONLINE_EMAIL_CAMPAIGN } from '../../../../constants/campaigns';
import { formatStartOfDay, formatEndOfDay, formatDateTime } from '../../../../utils/dates';
import { getBrandsMini } from '../../../brand/services/actions';
import { getDepartmentsMini } from '../../../department/services/actions';

const submitLoadingSelector = createLoadingSelector(CAMPAIGNS.CREATE_CAMPAIGN.base);
const detailsLoadingSelector = createLoadingSelector(CAMPAIGNS.READ_DETAILS.base);

const createCampaignSelector = (id) => createSelector(
  detailsLoadingSelector,
  submitLoadingSelector,
  (state) => state.campaigns.details[id],
  (state) => state.account.accountDetails.access_level,
  (state) => state.brands.listMini,
  (state) => state.departments.listMini,
  (state) => state.brands.activeBrand,
  (state) => state.departments.activeDepartment,
  (state) => state.campaigns.editStep,
  (state) => state.campaigns.campaign_type,
  (state) => state.account.accountDetails,
  (isLoadingData, isLoadingSubmit, campaign, access_level, brands, departments, activeBrand, activeDepartment, step, campaign_type, account) => ({
    isLoadingData,
    isLoadingSubmit,
    campaign,
    access_level,
    brands,
    departments,
    activeDepartment,
    activeBrand,
    step, 
    campaign_type,
    account
  }),
);

const useCampaignDetailsForm = (id) => {
  const dispatch = useDispatch();
  const selector = createCampaignSelector(id);
  const data = useSelector(selector);
  const [selectedBrand, setBrand] = useState(data.activeBrand);

  const openPage = useCallback(() => {
    if (id && !data.campaign) {
      dispatch(getDeviceCampaignDetails(id));
    }
  }, [data.campaign, dispatch, id]);
  // eslint-disable-next-line no-shadow
  const createCampaign = useCallback((data) => {
    if  (data.type_is !== ONLINE_EMAIL_CAMPAIGN) {
      data.date_start = formatStartOfDay(data.date_start, 'YYYY-MM-DD HH:mm');
      data.date_end = data.date_end ? formatEndOfDay(data.date_end, 'YYYY-MM-DD HH:mm') : null
    } else {
      data.date_start = formatDateTime(data.date_start, 'YYYY-MM-DD HH:mm');
      data.date_end = data.date_end ? formatDateTime(data.date_end, 'YYYY-MM-DD HH:mm') : null;
    }
    dispatch(createCampaignAction(data));
  }, [dispatch]);
  // eslint-disable-next-line no-shadow
  const editCampaign = useCallback((data) => {
    if  (data.type_is !== ONLINE_EMAIL_CAMPAIGN) {
      data.date_start = formatStartOfDay(data.date_start, 'YYYY-MM-DD HH:mm');
      data.date_end = data.date_end ? formatEndOfDay(data.date_end, 'YYYY-MM-DD HH:mm') : null
    } else {
      data.date_start = formatDateTime(data.date_start, 'YYYY-MM-DD HH:mm');
      data.date_end = data.date_end ? formatDateTime(data.date_end, 'YYYY-MM-DD HH:mm') : null;
    }
    dispatch(editCampaignAction(id, data));
  }, [dispatch, id]);

  const goToPrevStep = useCallback(() => {
    dispatch(data.campaign_type === DEVICE_CAMPAIGN ? firstStep() : prevStep());
  }, [dispatch, data.campaign_type]);

  const handleBrandChange = (brand) => {
    setBrand(brand);
    dispatch(getDepartmentsMini({ company: brand, is_active: true }));
  };

  useEffect(() => {
    openPage();
    dispatch(getBrandsMini({ is_active: true }));
  }, []);

  useEffect(() => {
    if (data.campaign && data.campaign.company) {
      const company = data.campaign.company.id;

      setBrand(company);
      dispatch(getDepartmentsMini({ company, is_active: true }));
    }
  }, [data.campaign]);


  return {
    data,
    selectedBrand,
    actions: {
      createCampaign,
      editCampaign,
      handleBrandChange,
      goToPrevStep,
    },
  };
};

export default useCampaignDetailsForm;
