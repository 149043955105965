import moment from 'moment';
import _pickBy from 'lodash/pickBy';

const validate = (values) => {
	const errors = {};

	if (!values.ad_account) {
		errors.ad_account = 'Required';
	}

	if (!values.facebook_page) {
		errors.facebook_page = 'Required';
	}

	if (values.from_campaign !== 'new' && !values.page) {
		errors.page = 'Required';
	}

	if (!values.image?.length) {
		errors.image = 'Required';
	}

	if (!values.text_body) {
		errors.text_body = 'Required';
	}

	if (!values.headline) {
		errors.headline = 'Required';
	}

	if (!values.call_to_action) {
		errors.call_to_action = 'Required';
	}

	if (values.from_audience !== 'new' && !values.audience) {
		errors.audience = 'Required';
	}

	if (!values.location?.countries?.length && !values.location?.regions?.length && !values.location?.cities?.length) {
		errors.location = 'Required';
	}

	if (!values.age_from) {
		errors.age_from = 'Required';
	}

	if (!values.age_to) {
		errors.age_to = 'Required';
	}

	if (!Object.keys(_pickBy(values.placement))?.length) {
		errors.placement = 'One is required';
	}

	if (values.age_from && values.age_to && values.age_from > values.age_to) {
		errors.age_to = 'Need biggest age';
	}

	if (values.type_budget === 'LIFETIME') {
		if(!values.budget_life) {
			errors.budget_life = 'Required';
		} else if(isNaN(Number(values.budget_life))) {
			errors.budget_life = 'Budget is not correct';
		}
	}

	if (values.type_budget === 'DAILY') {

		if(!values.budget_per_day) {
			errors.budget_per_day = 'Required';
		} else if(isNaN(Number(values.budget_per_day))) {
			errors.budget_per_day = 'Budget is not correct';
		}
	}

	if (!values.start_time) {
		errors.start_time = 'Required';
	}

	if (values.type_budget === 'LIFETIME' && !values.end_time) {
		errors.end_time = 'Required';
	}

	if(values.start_time && values.end_time) {
		const start_time = moment(values.start_time);

		const end_time = moment(values.end_time);

		const difference = end_time.diff(start_time, 'days');

		if(difference < 0) {
			errors.end_time = 'Not correct range';
		} else if(difference < 1) {
			errors.end_time = 'Short range';
		} else if(values.type_budget === 'LIFETIME' && values.budget_life < difference) {
			errors.budget_life = 'Budget is low';
		}
	}

	return errors;
};

export default validate;