import React from 'react';

import { HeaderLoguotIcon } from '../../../../../components/Icons';
import useHeaderLogout from './hooks';
import './style.scss';

const HeaderLogout = () => {
  const { logout } = useHeaderLogout();

  return (
    <div className="pd-r-10">
      <button className="header-logout-button" type="button" onClick={logout}>
        <HeaderLoguotIcon />
      </button>
    </div>
  );
};

export default HeaderLogout;
