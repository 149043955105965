import emailValidator from 'email-validator';
import tr from '../../../../utils/translate';

export const validateSend = ({ recipient }) => {
  const errors = {};

  if (!recipient) {
    errors.recipient = tr('Receiver email is required');
  } else if (!emailValidator.validate(recipient)) {
    errors.recipient = tr('Invalid Email');
  }

  return errors;
};

export const validateMain = ({ email_subject, from_email_address }) => {
  const errors = {};

  if (!email_subject) {
    errors.email_subject = tr('Email subject is required');
  }

  if (!from_email_address) {
    errors.from_email_address = tr('Sender email is required');
  } else if (!emailValidator.validate(from_email_address)) {
    errors.from_email_address = tr('Invalid Email');
  }

  return errors;
};
