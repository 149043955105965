import React from 'react';
import Modal from '../../../../../components/Modal';
import Button from '../../../../../components/Button';
import tr from '../../../../../utils/translate';
import { VIEWS_SOCIALS_DETAILS_MODAL } from '../../../../../constants/modals';
import { formatDateNoUtc } from '../../../../../utils/dates';
import { ROLE_CONTENT_MANAGER } from '../../../../../constants/roles';

const ViewSocialDetails = ({ data, onDeleteAdAccounts, onDeleteAdAccountFromStore, socialId, access_level }) => {
  const {
    name = 'loading',
    social_network = '',
    ad_accounts = [],
  } = data;

  const isEmpty = ad_accounts.length === 0;

  return (
    <Modal name={VIEWS_SOCIALS_DETAILS_MODAL} size="md" title={tr('Social Details')}>

      <div className="social_modal">

        <div className="social_modal_view_details">

          <h4>{name}</h4>

          <p>{social_network}</p>

        </div>

        <h3>{tr('Ad accounts:')}</h3>

        {isEmpty ? (
          <p>{tr('Empty ad accounts')}</p>
        ) : (
          ad_accounts.map((item) => (
            <div key={item.account_id} className="social_modal_view_details_item">

              <div className="social_modal_view_details_grid">

                <p className="social_modal_view_details_header_item mb0">{tr('Account id:')}</p>

                <p className="social_modal_view_details_header_item mb0">{tr('Name:')}</p>

                <p className="social_modal_view_details_header_item mb0">{tr('Connected Date:')}</p>

                <p className="social_modal_view_details_header_item mb0">{tr('Action:')}</p>

              </div>

              <div className="social_modal_view_details_grid">

                <div className="social_modal_view_details_info_item">

                  <p className="mb0">{item.account_id}</p>

                </div>

                <div className="social_modal_view_details_info_item">

                  <p className="mb0">{item.name}</p>

                </div>

                <div className="social_modal_view_details_info_item">

                  <p className="mb0">{item.connected_at ? formatDateNoUtc(item.connected_at) : tr('No data')}</p>

                </div>

                <div className="social_modal_view_details_info_item">

                  {access_level !== ROLE_CONTENT_MANAGER && (
                    <Button
                      onClick={() => {
                        onDeleteAdAccounts(item.id, socialId);
                        onDeleteAdAccountFromStore({
                          socialId,
                          accountId: item.account_id,
                        });
                      }}
                    >

                      {tr('Disconnect')}

                    </Button>
                  )}

                </div>

              </div>

            </div>
          ))
        )}
      </div>

    </Modal>
  );
};

export default ViewSocialDetails;
