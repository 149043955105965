import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './style.scss';

const Checkbox = (props) => {
  const {
    id,
    name,
    label,
    className,
    icon,
    ...otherProps
  } = props;
  return (
    <div className={cn('custom-control custom-checkbox', className)}>
      <input
        id={id || name}
        name={name}
        type="checkbox"
        className="custom-control-input"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
      />

      <label htmlFor={id || name} className="custom-control-label">

        <div className="checkbox-label-wrapper">

          {icon}

          {label}

        </div>

      </label>

    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  id: null,
  label: null,
  className: null,
};

export default Checkbox;
