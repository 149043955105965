import React from 'react';
import { Link } from 'react-router-dom';

import translate from '../../../../utils/translate';
import { parseRoutePath } from '../../../../utils/routingHelpers';
import { DEVICES_INFO_ROUTE, CAMPAIGNS_DETAILS_ROUTE } from '../../../../constants/routes';
import { formatDateNoUtc } from '../../../../utils/dates';

export const devicesColumns = [
  {
    name: translate('Device ID'),
    key: 'serial_number',
    render: ({ serial_number }) => `#${serial_number}`,
  },
  {
    name: translate('Label'),
    key: 'name',
    render: ({ name, serial_number: id }) => (
      <Link className="cell-link" to={parseRoutePath(DEVICES_INFO_ROUTE, { id })}>{name}</Link>
    ),
  },
  {
    name: translate('Location'),
    key: 'location',
  },
];

export const campaignsColumns = [
  {
    name: translate('Campaign name'),
    key: 'name',
    render: ({ id, name }) => (
      <Link className="cell-link" to={parseRoutePath(CAMPAIGNS_DETAILS_ROUTE, { id })}>{name}</Link>
    ),
  },
  {
    name: translate('Start date'),
    key: 'date_start',
    render: ({ date_start }) => date_start ? formatDateNoUtc(date_start) : null, // eslint-disable-line
  },
  {
    name: translate('End date'),
    key: 'date_end',
    render: ({ date_end }) => date_end ? formatDateNoUtc(date_end) : null, // eslint-disable-line
  },
  {
    name: translate('Location'),
    key: 'location',
  },
  {
    name: translate('Current Status'),
    key: 'status',
    render: ({ status }) => {
      if (status) {
        return (
          <span
            className={` cell-status_${status.split(' ').join('-').toLowerCase()}`}
          >
            {status}
          </span>
        );
      }
      return null;
    },
  },
];

export const resultsColumns = [
  {
    name: translate('Question'),
    key: 'question',
  },
  {
    name: translate('Answer'),
    key: 'answer',
  },
];
