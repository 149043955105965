import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../Breadcrumb';
import tr from '../../utils/translate';
import './styles.scss';

const PageHeader = (props) => {
  const {
    title,
    children,
    hideCrumbs,
    list,
  } = props;

  return (
    <div className="header mg-b-40">
      <div className="pd-r-20">
        <h2 className="page-title">{title}</h2>
        { !hideCrumbs && <Breadcrumb list={list} /> }
      </div>
      {children}
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.node,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      body: PropTypes.node,
    }),
  ),
  hideCrumbs: PropTypes.bool,
};

PageHeader.defaultProps = {
  title: tr('Dashboard'),
  hideCrumbs: false,
  list: [],
};

export default PageHeader;
