import React from 'react';
import Button from '../../../../../components/Button';
import tr from '../../../../../utils/translate';

export const statisticsColumns = [
  {
    name: tr('Questions'),
    key: 'questions',
    className: 'fus-questions',
    render: ({ questions }) => (
      <div className="detailed_badges">
        {questions.map((value, index) => value && value.title && <span key={index}>{value.title}</span>)}
      </div>
    ),
  },
  {
    name: '',
    key: 'preview_url',
    render: ({ preview_url }) => preview_url && (
      <div>
        <Button color="light" onClick={() => { window.open(preview_url, '_blank'); }}>
          {tr('Preview')}
        </Button>
      </div>
    ),
  },
];
