import USERS from './types';

const defaultState = {
  users: [],
  currentUser: {},
  locations: [],
  count: null,
  activePage: 1,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case USERS.READ_USERS_LIST.SUCCESS: {
      return {
        ...state,
        users: payload.res.results,
        count: payload.res.count,
        activePage: payload.req.data.page || 1,
      };
    }

    case USERS.READ_LOCATIONS_LIST.SUCCESS: {
      return {
        ...state,
        locations: payload.res.locations,
      };
    }

    case USERS.READ_USER_DETAILS.SUCCESS: {
      return {
        ...state,
        currentUser: {
          ...payload.res,
          campaigns: [],
          devices: []
        },
      };
    }
    case USERS.READ_USER_DEVICES.SUCCESS: {
      return {
        ...state,
        currentUser:{
          ...state.currentUser,
          devices: payload.res,
        },
      };
    }

    case USERS.READ_USER_CAMPAIGNS.SUCCESS: {
      return {
        ...state,
        currentUser:{
          ...state.currentUser,
          campaigns: payload.res,
        },
      };
    }

    case USERS.READ_USER_POLLS.SUCCESS: {
      return {
        ...state,
        currentUser:{
          ...state.currentUser,
          campaigns_polls: payload.res,
        },
      };
    }

    default:
      return state;
  }
};
