import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactDatePicker from 'react-datepicker';
import { Field } from 'redux-form';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

const DatePicker = (props) => {
  const {
    append,
    prepend,
    size,
    message,
    className,
    classNameControl,
    input,
    meta: { error, touched },
    dateForm,
    fieldLabel,
    classNameWrapper,
    ...otherProps
  } = props;

  return (
    <div className={cn('datepicker-wrapper', classNameWrapper)}>
      {fieldLabel && (
        <div className="input-label-wrap">
          <label htmlFor={input.name}>{fieldLabel}</label>
        </div>
      )}
      <div className={cn('input-group', className)}>
        {prepend && (
          <div className="input-group-prepend">
            <span className="input-group-text">{prepend}</span>
          </div>
        )}
        <ReactDatePicker
          autoComplete="off"
          className={cn('form-control', classNameControl, { 'is-invalid': touched && error }, size && `form-control-${size}`)}
          {...otherProps} // eslint-disable-line react/jsx-props-no-spreading
          {...input} // eslint-disable-line react/jsx-props-no-spreading
          selected={input.value ? Date.parse(input.value) : null}
          onChange={input.onChange}
          dateForm={dateForm}
        />
        {append && (
          <div className="input-group-append">
            <span className="input-group-text">{append}</span>
          </div>
        )}
        {touched && error && (
          <div className="invalid-feedback show-input-error">{error}</div>
        )}
      </div>
    </div>

  );
};

DatePicker.propTypes = {
  classNameControl: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  append: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  prepend: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  input: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ])).isRequired,
  meta: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ])).isRequired,
  dateForm: PropTypes.string.isRequired,
};

DatePicker.defaultProps = {
  classNameControl: null,
  className: null,
  size: null,
  append: null,
  prepend: null,
};

const ReduxDatePicker = (props) => (
  <Field
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    component={DatePicker}
    format={(date) => (date ? moment(date).format(props.dateForm) : null)}
    parse={(date) => (date ? moment(date).format(props.dateForm) : null)}
  />
);

ReduxDatePicker.propTypes = {
  dateForm: PropTypes.string,
};

ReduxDatePicker.defaultProps = {
  dateForm: 'MM/DD/YYYY',
};

export default ReduxDatePicker;
