import emailValidator from 'email-validator';
import tr from '../../../../utils/translate';

const validate = ({
  emails,
}) => {
  const errors = {};

  if (!emails) {
    errors.emails = tr('Emails list is required');
    return errors;
  } 

  let emailsList = emails.split(',');
  emailsList = emailsList.map((val) => val.trim());
  emailsList.map((val) => {
    if (val && !emailValidator.validate(val)) {
      errors.emails = tr('Invalid Email');
    }
  })
  return errors;
};

export default validate;
