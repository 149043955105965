import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import CAMPAIGNS from '../../services/types';
import {
  sendSuggestion,
} from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';

const loadingSelector = createLoadingSelector(CAMPAIGNS.SEND_SUGGESTION.base);
const createSuggestSelector = () => createSelector(
  loadingSelector,
  (isLoading) => ({
    isLoading,
  }),
);

const useSuggestOther = (campaignId) => {
  const dispatch = useDispatch();
  const selector = createSuggestSelector();
  const data = useSelector(selector);

  const submitSuggestOther = useCallback((fields) => {
      dispatch(sendSuggestion(campaignId, {
        category: fields.category,
        content: fields.suggest,
      }))
  }, [dispatch, campaignId]);

  return {
    data,
    actions: {
      submitSuggestOther
    },
  };
};

export default useSuggestOther;
