import { createAction } from 'redux-actions';

import AUTH from './types';
import { createActionRequest } from '../../../utils/actionHelpers';

export const tokenRefresh = createActionRequest(AUTH.TOKEN_REFRESH, 'post', '/bo/accounts/token-refresh/', {
  setData: (token) => ({ token }),
});

export const signOut = createAction(AUTH.SIGN_OUT);

export const loginAccount = createActionRequest(AUTH.SIGN_IN, 'post', '/bo/accounts/auth/');

export const createAccount = createActionRequest(AUTH.SIGN_UP, 'post', '/bo/accounts/registration/');

export const forgotPassword = createActionRequest(AUTH.FORGOT_PASSWORD, 'post', '/bo/accounts/send-reset-password-link/');

export const changePassword = createActionRequest(AUTH.CHANGE_PASSWORD, 'post', '/bo/accounts/reset-password/');

export const confirmInvitation = createActionRequest(AUTH.CONFIRM_INVITATION, 'post', '/bo/invitations/accept/');

export const confirmBrandInvitation = createActionRequest(AUTH.CONFIRM_BRAND_INVITATION, 'post', '/bo/invite-company/accept/');

export const getInvitationInfo = createActionRequest(AUTH.GET_INVITATION, 'post', '/bo/accounts/company-invitation/', {
  setData: (token) => ({ token }),
});
