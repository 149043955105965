import {
  takeEvery, put, call,
} from 'redux-saga/effects';
import { hide } from 'redux-modal';
import { toast } from 'react-toastify';
import _get from 'lodash/get';
import history from '../../../utils/history';
import  { getDepartments } from './actions';
import { DEPARTMENT_MODAL, INVITE_DEPARTMENT_MODAL, DEACTIVATE_DEPARTMENT_MODAL } from '../../../constants/modals';
import tr from '../../../utils/translate';
import DEPARTMENT from './types';

function* runDepartmentEditSuccess() {
  yield call(toast.success, tr('Team updated'));
  yield put(hide(DEPARTMENT_MODAL));
}

function* runDepartmentCreateSuccess() {
  yield call(toast.success, tr('Team created'));
  yield put(hide(DEPARTMENT_MODAL));
}

function* runInviteUser() {
  yield put(hide(INVITE_DEPARTMENT_MODAL));
  yield toast.success(tr('Invitation email has been sent'));
}

function* runSetActiveDepartment() {
  // alert('==2');
  // yield history.push('/');
}

function* runActivateDepartmnetSuccess() {
  yield put(hide(DEACTIVATE_DEPARTMENT_MODAL));
  yield call(toast.success, tr('Team is activate'));
}

function* runDeactivateDepartmnetSuccess() {
  yield put(hide(DEACTIVATE_DEPARTMENT_MODAL));
  yield call(toast.success, tr('Team is deactivate'));
}


export default function* departments() {
  yield takeEvery(DEPARTMENT.SET_ACTIVE_DEPARTMENT, runSetActiveDepartment);
  yield takeEvery(DEPARTMENT.CREATE_DEPARTMENT.SUCCESS, runDepartmentCreateSuccess);
  yield takeEvery(DEPARTMENT.EDIT_DEPARTMENT.SUCCESS, runDepartmentEditSuccess);
  yield takeEvery(DEPARTMENT.INVITE_USER.SUCCESS, runInviteUser);
  yield takeEvery(DEPARTMENT.ACTIVATE_DEPARTMENT.SUCCESS, runActivateDepartmnetSuccess);
  yield takeEvery(DEPARTMENT.DEACTIVATE_DEPARTMENT.SUCCESS, runDeactivateDepartmnetSuccess);
}
