import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import cn from 'classnames';
import { Field } from 'redux-form';

import translate from '../../utils/translate';
import { FileIcon, TrashIcon } from '../Icons';
import './styles.scss';

const UploadFile = ({
  input,
  meta: { error, touched },
  label,
  icon,
  color,
  data,
  disabled,
  onLoaded,
  onDeleted,
  hideDeleteButton,
  accept,
  maxSize,
}) => {
  let dataName;
  if (data) {
    dataName = data.name || data.file;
  }
  // eslint-disable-next-line no-useless-escape
  const [, uploadedFileName] = dataName ? dataName.match(/\/([^\/]+)$/) : [];

  const [file] = input.value || [];

  const handleDeleteFile = () => {
    input.onChange([]);

    if (onDeleted) {
      onDeleted(data, input.name);
    }
  };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div className="file-upload">
      <div className="file-upload__wrap">
        <div className={cn('file-upload__icon-wrap', `file-upload__icon-wrap_${color}`)}>
          {icon}
        </div>
        <div className="file-upload__dropzone-col">
          <Dropzone
            accept={accept}
            disabled={disabled}
            name={input.name}
            {...maxSize ? { maxSize } : {}}
            onDrop={(filesToUpload) => {
              /* need update if require multiple files support in the future */
              input.onChange(filesToUpload);

              if (onLoaded) {
                onLoaded(filesToUpload, input.name);
              }
            }}
            className="file-upload__dropzone"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <label htmlFor={input.name} className="file-upload__label">{label}</label>
                <input {...getInputProps()} />
                {(file || uploadedFileName) ? (
                  <span className="file-upload__file-name file-upload__file-name_active">{(file && file.name) || decodeURI(uploadedFileName)}</span>
                ) : (
                  <span className="file-upload__file-name">{translate('Not uploaded')}</span>
                )}
              </div>
            )}
          </Dropzone>
        </div>
        <div>
          {!hideDeleteButton && (
            <button
              type="button"
              disabled={!file && !uploadedFileName}
              onClick={handleDeleteFile}
              className="file-upload__trash-btn"
            >
              <TrashIcon />
            </button>
          )}
        </div>
      </div>
      {touched && error && (
        <div className="invalid-feedback show-input-error">{error}</div>
      )}
    </div>
  );
};
/* eslint-enable react/jsx-props-no-spreading */

UploadFile.defaultProps = {
  label: translate('Upload file'),
  color: 'blue',
  data: null,
  disabled: false,
  onLoaded: null,
  onDeleted: null,
  icon: <FileIcon />,
  hideDeleteButton: false,
  accept: '.zip',
};

UploadFile.propTypes = {
  color: PropTypes.oneOf(['yellow', 'green', 'blue', 'red']),
  input: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.number,
  ])).isRequired,
  meta: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ])).isRequired,
  label: PropTypes.string,
  icon: PropTypes.node,
  data: PropTypes.shape(),
  disabled: PropTypes.bool,
  onLoaded: PropTypes.func,
  onDeleted: PropTypes.func,
  hideDeleteButton: PropTypes.bool,
  accept: PropTypes.string,
  maxSize: PropTypes.number,
};

// eslint-disable-next-line react/jsx-props-no-spreading
const ReduxUploadFile = (props) => <Field {...props} component={UploadFile} />;

export default ReduxUploadFile;
