import _get from 'lodash/get';
import _some from 'lodash/some';

const createLoadingSelector = (actions) => (state) => {
  const actionsList = actions instanceof Array ? actions : [actions];

  return _some(actionsList, (action) => _get(state, ['loading', action]));
};

export default createLoadingSelector;
