import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ExpandMinusIcon, ExpandPlusIcon } from '../Icons';

const TableRow = ({
  expand,
  columns,
  data,
  expandRenderer,
  expandedOnLoad,
  deleteSessionAdd,
  deleteSessionHandler,
  checkedList,
}) => {
  const [isShow, setIsShow] = useState(expandedOnLoad);
  const expandContent = !!expandRenderer && expandRenderer(data);

  return (
    <>
      <tr>
        {deleteSessionAdd && (
          <td>
            <div>
              {checkedList
                ? (
                  <input
                    id={data.poll_session_id}
                    type="checkbox"
                    checked={checkedList.includes(data.poll_session_id)}
                    onChange={
                (e) => (
                  deleteSessionHandler(e.target.id, e.target.checked)
                )
              }
                  />
                ) : (
                  <input
                    id={data.poll_session_id}
                    type="checkbox"
                    onChange={
                  (e) => (
                    deleteSessionHandler(e.target.id, e.target.checked)
                  )
                }
                  />
                )}

            </div>
          </td>
        )}

        {expand && (
          <td key="expand">
            {expandContent && (
              <span
                className="table-icon-expand"
                onClick={() => setIsShow(!isShow)}
              >
                {isShow ? <ExpandMinusIcon /> : <ExpandPlusIcon />}
              </span>
            )}
          </td>
        )}

        {columns.map(({ key, render }, index) => (
          <td key={index}>{render ? render(data, index) : data[key]}</td>
        ))}
      </tr>
      {expand && expandContent && (
        <tr className={cn('table-row-expand', { 'table-row-expand_show': isShow })}>
          {/* <td colSpan={1} /> */}
          <td colSpan={columns.length + 1 + +(deleteSessionAdd || 0)}>
            {expandContent}
          </td>
        </tr>
      )}
    </>
  );
};

TableRow.propTypes = {
  expandRenderer: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    className: PropTypes.string,
    render: PropTypes.func,
  })).isRequired,
  expandedOnLoad: PropTypes.bool,
};

TableRow.defaultProps = {
  expandRenderer: null,
  expandedOnLoad: false,
};

TableRow.defaultProps = {
  expandRenderer: null,
};

export default TableRow;
