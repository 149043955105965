import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import createLoadingSelector from '../../../../selectors/loadingSelector';
import AUTH from '../../services/types';
import { createAccount as createAccountAction } from '../../services/actions';

const selector = createSelector(
  createLoadingSelector(AUTH.SIGN_UP.base),
  (isLoading) => ({
    isLoading,
  }),
);

const useSignUp = () => {
  const dispatch = useDispatch();

  const createAccount = useCallback((values) => {
    dispatch(createAccountAction(values));
  }, [dispatch]);

  return {
    actions: {
      createAccount,
    },
    data: useSelector(selector),
  };
};

export default useSignUp;
