import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { formValueSelector, change, touch } from 'redux-form';
import { toast } from 'react-toastify';
import { validateMain } from './validate';
import tr from '../../../../utils/translate';

import CAMPAIGNS from '../../services/types';
import {
  prevStep,
  followUpDesign,
  followUpPreview,
  getFollowUpTheme,
  followUpPreviewEmail,
} from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';

const detailsLoadingSelector = createLoadingSelector([
  CAMPAIGNS.GET_FOLLOW_UP_QUESTIONS.base,
  CAMPAIGNS.CREATED_CAMPAIGN.base,
]);
const submitLoadingSelector = createLoadingSelector([
  CAMPAIGNS.SET_FOLLOW_UP.base,
  CAMPAIGNS.SET_FOLLOW_UP_THEME.base,
  CAMPAIGNS.SEND_FOLLOW_UP_PREVIEW_EMAIL.base,
]);
const formSelector = formValueSelector('FOLLOW_UPS_DESIGN_FORM');

const createFollowUpsSelector = (id) => createSelector(
  detailsLoadingSelector,
  submitLoadingSelector,
  (state) => state.campaigns.details[id],
  (state) => state.campaigns.questions,
  (state) => state.campaigns.themes,
  (state) => formSelector(state, 'email', 'colors', 'font', 'background', 'first_question', 'background_url', 'from_email_address', 'email_subject'),
  (isLoadingData, isLoadingSubmit, compaign, questions, themes, formValues) => ({
    isLoadingData,
    isLoadingSubmit,
    compaign,
    questions,
    themes,
    formValues,
  }),
);

const useFollowUpsDesign = (id, isNew) => {
  const dispatch = useDispatch();
  const data = useSelector(createFollowUpsSelector(id));
  const followUpTemplate = data?.compaign?.follow_up?.email_template;
  const themeId = data?.compaign?.follow_up?.theme_id;
  const theme = data?.themes[themeId];

  const goToBack = useCallback(() => {
    dispatch(prevStep());
  }, [dispatch]);

  const handleSetValues = useCallback((values, isNextStep) => {
    const [background] = values.background instanceof Array ? values.background : [];

    dispatch(followUpDesign({ id, themeId }, { ...values, background }, { isNextStep, isNew }));
  }, [dispatch, id, isNew, themeId]);

  const handleSend = useCallback((values) => {
    if (followUpTemplate) {
      handleSetValues(data.formValues);
      dispatch(followUpPreviewEmail(id, values));
    } else {
      toast.error(tr('Please upload Follow up template'));
    }
  }, [data.formValues, dispatch, followUpTemplate, handleSetValues, id]);

  const handlePreview = useCallback(() => {
    handleSetValues(data.formValues);
    dispatch(followUpPreview(id));
  }, [data.formValues, dispatch, handleSetValues, id]);

  const handleDeleteBackground = useCallback(() => {
    dispatch(change('FOLLOW_UPS_DESIGN_FORM', 'background', ''));
    dispatch(change('FOLLOW_UPS_DESIGN_FORM', 'background_url', ''));
  }, [dispatch]);

  const handleSubmitTheme = useCallback(() => {
    dispatch(touch('FOLLOW_UPS_DESIGN_FORM', 'from_email_address', 'email_subject'));
    const errors = validateMain(data.formValues);

    if (!Object.keys(errors).length) {
      handleSetValues(data.formValues, true);
    }
  }, [data.formValues, dispatch, handleSetValues]);

  useEffect(() => {
    if (themeId) {
      dispatch(getFollowUpTheme(themeId));
    }
  }, [themeId, dispatch]);

  return {
    data: {
      ...data,
      theme,
    },
    actions: {
      goToBack,
      handleSend,
      handlePreview,
      handleDeleteBackground,
      handleSubmitTheme,
    },
  };
};

export default useFollowUpsDesign;
