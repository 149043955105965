import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../components/Modal';
import { EDIT_DEVICE_MODAL } from '../../../../constants/modals';
import ReduxForm from '../../../../components/ReduxForm';
import tr from '../../../../utils/translate';
import Input from '../../../../components/Input';
import ModalFormFooter from '../../../../components/ModalFormFooter';
import validate from './validate';
import useEditDevice from './hooks';

const EditDeviceModal = ({ data }) => {
  const { actions, data: { isLoading } } = useEditDevice();

  return (
    <Modal name={EDIT_DEVICE_MODAL} size="sm" title={tr('Edit')}>
      {({ handleHide }) => (
        <ReduxForm
          enableReinitialize
          form="EDIT_DEVICE_FORM"
          validate={validate}
          formData={{
            onSubmit: actions.editDevice,
            initialValues: data,
          }}
        >
          <div className="modal-body">
            <Input
              name="name"
              prependFieldLabel={tr('Device label')}
              className="pd-b-15"
            />
            <Input
              name="location"
              prependFieldLabel={tr('Location')}
              className="pd-b-15"
            />
          </div>
          <div className="modal-footer">
            <ModalFormFooter
              handleClose={handleHide}
              confirmText={tr('Save changes')}
              isLoading={isLoading}
            />
          </div>
        </ReduxForm>
      )}
    </Modal>
  );
};

EditDeviceModal.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    location: PropTypes.string,
  }),
};

EditDeviceModal.defaultProps = {
  data: {},
};

export default React.memo(EditDeviceModal);
