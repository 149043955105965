import React, { useRef, Fragment } from 'react';
import FacebookPlacesAutocomplete from './FacebookPlacesAutocomplete';
import translate from '../../../../utils/translate';
import './styles.scss';

const LocationFacebookField = ({ input, meta, fieldLabel, accountId }) => {
  const formInputEl = useRef(null);

  const handleSelect = async (value) => {
    let newValue = {
      ...input.value,
    };

    if (value) {
      switch(value.type) {
        case 'country':
          const countries = (newValue.countries || []);

          if(!countries.find(({ key }) => key === value.key)?.key) {
            newValue = {
              ...newValue,
              countries: [...countries, value],
              regions: (newValue.regions || []).filter((region) => region.country_code !== value.key),
              cities: (newValue.cities || []).filter((city) => city.country_code !== value.key),
            }
          }
          break;

        case 'region':
          const regions = (newValue.regions || []);

          if(!regions.find(({ key }) => key === value.key)?.key) {
            newValue = {
              ...newValue,
              countries: (newValue.countries || []).filter((country) => country.key !== value.country_code),
              regions: [...regions, value],
              cities: (newValue.cities || []).filter((city) => city.region_id !== value.key),
            };
          }
          break;

        case 'city':
          const cities = (newValue.cities || []);

          if(!cities.find(({ key }) => key === value.key)?.key) {
            newValue = {
              ...newValue,
              countries: (newValue.countries || []).filter((country) => country.key !== value.country_code),
              regions: (newValue.regions || []).filter((region) => region.key !== value.region_id),
              cities: [...cities, value],
            };
          }
          break;
      }

      input.onChange(newValue)
    }
  };

  const handleChange = (value) => {
    let newValue = {
      ...input.value,
    };

    switch(value.type) {
      case 'country':
        newValue = {
          ...newValue,
          countries: newValue.countries.filter((obj) => obj.key !== value.key),
        }
        break;

      case 'region':
        newValue = {
          ...newValue,
          regions: newValue.regions.filter((obj) => obj.key !== value.key),
        }
        break;

      case 'city':
        newValue = {
          ...newValue,
          cities: newValue.cities.filter((obj) => obj.key !== value.key),
        }
        break;
    }

    input.onChange(newValue);
  };

  const { countries = [], regions = [], cities = [] } = input.value;

  const countryList = [...new Set([
    ...regions.map(({ country_name: countryName }) => countryName),
    ...cities.map(({ country_name: countryName }) => countryName),
  ])];

  return (
    <div className="facebook-location">

      <h4 className="cdf-subtitle mg-b-10">

        {fieldLabel}

      </h4>

      {(countries.length > 0 || countryList.length > 0) && (
        <div className="facebook-location-wrap">

          <div className="facebook-location-subLabel">

            {translate('Countries')}

          </div>

          {countries.map((obj) => (
            <span
              key={obj.key}
            >

              <span className="facebook-location-item">

                {obj.name}

                <a
                  className="facebook-location-remove"
                  onClick={() => handleChange(obj)}
                />

             </span>

           </span>
          ))}

          {countryList.map((country_name) => {

            return (
              <Fragment key={country_name}>

                <h5 className="facebook-location-subLabel">

                  {country_name}

                </h5>

                {regions.filter((obj) => obj.country_name === country_name).map((obj) => (
                  <span
                    key={obj.key}
                  >

                    <span className="facebook-location-item">

                      {obj.name}

                      <a
                        className="facebook-location-remove"
                        onClick={() => handleChange(obj)}
                      />

                    </span>

                  </span>
                ))}

                {cities.filter((obj) => obj.country_name === country_name).map((obj) => (
                  <span
                    key={obj.key}
                  >

                    <span className="facebook-location-item">

                      {obj.name}

                      <a
                        className="facebook-location-remove"
                        onClick={() => handleChange(obj)}
                      />

                    </span>

                  </span>
                ))}

              </Fragment>
            )
          })}

        </div>
      )}

      <FacebookPlacesAutocomplete
        accountId={accountId}
        onSelect={handleSelect}
      >

        {({ suggestions, getInputProps, getSuggestionItemProps, loading }) => (
          <>

            <div className="input-wrapper">

              <div className="input-group">

                <input {...getInputProps({ className: 'form-control'})}/>

              </div>

            </div>

            <div className="autocomplete-dropdown-container">

              {loading ? <div>...loading</div> : null}

              {suggestions.map((suggestion) => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';

                return (
                  <div {...getSuggestionItemProps(suggestion, { className })} >

                    {suggestion.name}

                  </div>
                );
              })}

            </div>

          </>
        )}

      </FacebookPlacesAutocomplete>

      <input
        type="hidden"
        ref={formInputEl}
        name={input.name}
      />

      {meta.touched && meta.error && (
        <div className="invalid-feedback show-input-error">{meta.error}</div>
      )}

    </div>
  );
};

export default LocationFacebookField;
