import React from 'react';
import _map from 'lodash/map';
import _max from 'lodash/max';
import PropTypes from 'prop-types';
import ApexCharts from 'react-apexcharts';
import tr from '../../utils/translate';

const defaulColor = ['#4285F4'];
const defaultMultiColor = ['#2656FF', '#FF26C2', '#FF7426', '#FF2626', '#26F2FF'];

const defaultOptions = {
  dataLabels: {
    enabled: false,
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 0.6,
      shade: 'light',
      type: 'vertical',
      opacityFrom: 0.8,
      opacityTo: 0.9,
      stops: [0, 90, 100],
      inverseColors: false,
    },
  },
  tooltip: {
    enabled: true,
    marker: {
      show: false,
    },
  },
  chart: {
    id: 'basic-bar',
    toolbar: {
      show: false,
    },
  },
  states: {
    hover: {
      filter: {
        type: 'darken',
        value: 0.5,
      },
    },
  },
  plotOptions: {
    bar: {
      columnWidth: '90%',
      distributed: true,
    },
  },
  noData: {
    text: tr('No Data'),
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: -20,
    style: {
      fontSize: '24px',
      backgroundcColor: 'blue',
    },
  },
  legend: {
    show: false,
  },
};

const FormatChartData = (data, seriesName, labelField = 'date') => ({
  labels: _map(data, labelField),
  series: [
    {
      name: seriesName,
      data: _map(data, 'count'),
    },
  ],
});

const ColumnChart = ({
  chartData, isMultiColor, height, seriesName, labelField,
}) => {
  const data = FormatChartData(chartData, seriesName, labelField);
  const maxValue = _max(data.series[0].data);
  const options = {
    ...defaultOptions,
    ...{
      labels: data.labels.length ? data.labels : [''],
      colors: isMultiColor ? defaultMultiColor : defaulColor,
      plotOptions: {
        bar: {
          columnWidth: '90%',
          distributed: isMultiColor,
        },
      },
      yaxis: {
        tickAmount: maxValue < 7 ? maxValue : 7,
        labels: {
          formatter: (val) => val.toFixed(0),
        },
      },
      xaxis: {
        tickAmount: data.labels.length,
        labels: {
          rotate: -45,
          style: {
            cssClass: 'column-xaxis-label',
          },
        },

      },
    },
  };

  if (!chartData.length) {
    options.yaxis = {
      min: 0,
      max: 100,
      tickAmount: 4,
    };
  }

  return (
    <ApexCharts options={options} series={data.series} type="bar" height={height} />
  );
};

ColumnChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    count: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })),
  isMultiColor: PropTypes.bool,
  height: PropTypes.string,
  seriesName: PropTypes.string,
};

ColumnChart.defaultProps = {
  isMultiColor: false,
  height: '400',
  chartData: [],
  seriesName: '',
};

export default ColumnChart;
