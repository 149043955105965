import { createRequestTypes } from '../../../utils/actionHelpers';

export default createRequestTypes('BRAND',
  [
    'GET_BRAND_DETAILS',
    'INVITE_BRAND',
    'EDIT_BRAND',
    'GET_BRANDS',
    'ACTIVATE_BRAND',
    'DEACTIVATE_BRAND',
    'GET_BRANDS_MINI'
  ],
  [
    'SET_ACTIVE_BRAND',
  ]);
