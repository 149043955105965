import tr from '../../../../utils/translate';

const validate = ({
  serial_number,
  name,
  location,
  company,
  department,
}) => {
  const errors = {};

  if (!serial_number) {
    errors.serial_number = tr('Device Serial No. is required.');
  }

  if (!name) {
    errors.name = tr('Label is required.');
  }

  if (!location) {
    errors.location = tr('Location is required');
  }
  if (!company) {
    errors.company = tr('Brand is required');
  }
  if (!department) {
    errors.department = tr('Department is required');
  }

  return errors;
};

export default validate;
