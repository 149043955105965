import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import createLoadingSelector from '../../../selectors/loadingSelector';
import DEPARTMENT from '../services/types';
import { activateDepartment, deactivateDepartment } from '../services/actions';

const selector = createSelector(
  createLoadingSelector(DEPARTMENT.ACTIVATE_DEPARTMENT.base),
  (isLoading) => ({
    isLoading,
  }),
);

const useBrandStatusModal = () => {
  const dispatch = useDispatch();

  const handleDepartmentStatusChange = useCallback((id, isActive) => {
    dispatch(isActive ? deactivateDepartment(id) : activateDepartment(id));
  }, [dispatch]);

  return {
    actions: {
      handleDepartmentStatusChange,
    },
    data: useSelector(selector),
  };
};

export default useBrandStatusModal;
