import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import createLoadingSelector from '../../../../selectors/loadingSelector';
import CAMPAIGNS from '../../services/types';
import { startMailingService } from '../../services/actions';

const selector = createSelector(
  createLoadingSelector(CAMPAIGNS.RUN_MAILING_SERVICE.base),
  (isLoading) => ({
    isLoading,
  }),
);

const useStartMaling = () => {
  const dispatch = useDispatch();

  const handleYes = useCallback((id) => {
    dispatch(startMailingService(id));
  }, [dispatch]);

  return {
    actions: {
      handleYes,
    },
    data: useSelector(selector),
  };
};

export default useStartMaling;
