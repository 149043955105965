import React from 'react';
import _filter from 'lodash/filter';
import tr from '../../../../utils/translate';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import { SUCCESS_CREATE_MODAL } from '../../../../constants/modals';

const SuccessCreateModal = () => (
  <Modal name={SUCCESS_CREATE_MODAL} size="lg" title="Campaign created">
    {({ handleHide }) => (
      <>
        <div className="modal-body">
          <div className="reboot-modal-body">
            <div className="reboot-modal-body__text-wrap">
              {tr('Thank you for submitting your request. The Odore team will review it and respond as soon as possible.')}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="reboot-modal-footer">
            <div className="reboot-modal-footer__cancel-col">
              <Button color="light" onClick={handleHide}>{tr('Ok')}</Button>
            </div>
          </div>
        </div>
      </>
    )}
  </Modal>

);

export default SuccessCreateModal;
