import { useEffect, useState } from 'react';
// import _filter from 'lodash/filter';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import _isEmpty from 'lodash/isEmpty';

import { getUsers } from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import EMPLOYEES from '../../services/types';
import {
  EMPLOYEE_ROLE_MODAL, DEACTIVATE_USER_MODAL, INVITE_DEPARTMENT_MODAL, ASSIGN_DEVICES_MEMBER,
} from '../../../../constants/modals';
import useModal from '../../../../utils/useModal';

const selector = createSelector(
  createLoadingSelector(EMPLOYEES.READ_USERS_LIST.base),
  (state) => state.employees.list,
  (state) => state.brands.activeBrand,
  (state) => state.departments.activeDepartment,
  (state) => state.account.accountDetails,
  (state) => ({
    count: state.employees.count,
    activePage: state.employees.activePage,
  }),
  (isLoading, users, activeBrand, activeDepartment, accountDetail, pagination) => ({
    isLoading,
    users,
    activeBrand,
    activeDepartment,
    accountDetail,
    pagination,
  }),
);

export const useUsers = () => {
  const dispatch = useDispatch();
  const data = useSelector(selector);
  const [activeTab, setActiveTab] = useState('active');
  const [sortBy, setSortBy] = useState({});

  const { showModal: handleInviteUser } = useModal(INVITE_DEPARTMENT_MODAL);

  const { showModal: changeRoleModal } = useModal(EMPLOYEE_ROLE_MODAL);
  const { showModal: changeStatusModal } = useModal(DEACTIVATE_USER_MODAL);
  const { showModal: assignDevicesModal } = useModal(ASSIGN_DEVICES_MEMBER);

  const changeSorting = (field) => {
    if (sortBy.key === field) {
      setSortBy({
        key: field,
        direction: sortBy.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSortBy({
        key: field,
        direction: 'asc',
      });
    }
  };

  useEffect(() => {
    const ordering = sortBy && (sortBy.direction === 'desc' ? `-${sortBy.key}` : sortBy.key);
    dispatch(getUsers({
      ordering,
      ...(data.activeBrand && { company: data.activeBrand }),
      ...(data.activeDepartment && { department: data.activeDepartment }),
      is_active: activeTab === 'active',
    }));
  }, [data.activeBrand, data.activeDepartment, dispatch, sortBy, activeTab]);

  const getNextPage = (page) => {
    dispatch(getUsers({
      ...(data.activeBrand && { company: data.activeBrand }),
      ...(data.activeDepartment && { department: data.activeDepartment }),
      page,
      is_active: activeTab === 'active',
    }));
  }

  return {
    data,
    actions: {
      changeRoleModal,
      changeStatusModal,
      handleInviteUser,
      assignDevicesModal,
      changeSorting,
      getNextPage,
    },
    activeTab,
    setActiveTab,
    sortBy,
  };
};
