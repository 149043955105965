import React from 'react';

import Modal from '../../../../components/Modal';
import ReduxForm from '../../../../components/ReduxForm';
import Input from '../../../../components/Input';
import ModalFormFooter from '../../../../components/ModalFormFooter';
import validate from './validate';
import useAddDeviceModal from './hooks';
import { ADD_DEVICE_MODAL } from '../../../../constants/modals';
import tr from '../../../../utils/translate';

const AddDeviceModal = () => {
  const { actions, data: { isLoading } } = useAddDeviceModal();

  return (
    <Modal title={tr('Verification')} name={ADD_DEVICE_MODAL} size="sm">
      {({ handleHide, ...initialValues }) => (
        <ReduxForm
          form="ADD_DEVICE_MODAL_FORM"
          validate={validate}
          formData={{
            onSubmit: actions.addNewDevice,
            initialValues,
          }}
        >
          <div className="modal-body">
            <Input
              name="pincode"
              prependFieldLabel={tr('Enter code')}
              className="pd-b-15"
            />
          </div>
          <div className="modal-footer">
            <ModalFormFooter
              handleClose={handleHide}
              confirmText={tr('Apply')}
              isLoading={isLoading}
            />
          </div>
        </ReduxForm>
      )}
    </Modal>
  );
};

export default AddDeviceModal;
