import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import cn from 'classnames';

import { CloseIcon, AppLogoIcon, AppLogoSmallIcon } from '../../../../../components/Icons';
import ReduxForm from '../../../../../components/ReduxForm';
import Select from '../../../../../components/Select';
import tr from '../../../../../utils/translate';
import { generateSelect } from '../../../../../helpers/arrayHelpers';
import { Socials } from '../../../components';
import useAccount from '../../../../account/components/hooks';
import getAccessLevelRoutes from './routes';
import { ROLE_SUPERADMIN, ROLE_COMPANY_ADMIN } from '../../../../../constants/roles';
import useSidebar from './hooks';

import './style.scss';

const Sidebar = ({ location: { pathname }, collapsed, handleToggleSidebar }) => {
  const path = `/${pathname.split('/')[1]}`;
  const {
    data: {
      accountDetails: {
        access_level,
      },
    },
  } = useAccount();

  const {
    data: {
      brands,
      departments,
    },
    formValues,
    actions,
  } = useSidebar();

  const renderItem = (item, index) => {
    const {
      route,
      exact,
      name,
      icon: Icon,
    } = item;

    return route && (
      <li key={index} className={cn({ active: route === path })}>
        <NavLink
          to={route}
          exact={exact}
        >
          {Icon ? <Icon /> : <span />}
          <span>{name}</span>
        </NavLink>
      </li>
    );
  };

  const routes = getAccessLevelRoutes(access_level);

  const filterForm = () => {
    if (![ROLE_COMPANY_ADMIN, ROLE_SUPERADMIN].includes(access_level)) {
      return null;
    }
    const brandSelect = generateSelect(brands, 'name', '', 'All');
    const departmentsSelect = generateSelect(departments, 'name', '', 'All');
    return (
      <ReduxForm
        formData={{
          initialValues: formValues,
        }}
        form="SIDE_MENU_FILTER"
        enableReinitialize
        className="side_menu_filter"
      >
        { access_level === ROLE_SUPERADMIN && (
        <Select
          fieldLabel={tr('Brand')}
          classNameWrapper="pd-b-30"
          name="brand"
          placeholder={tr('Select brand')}
          options={brandSelect}
          onChange={(event) => { actions.handleBrandChange(event.target.value); }}
        />
        ) }
        <Select
          fieldLabel={tr('Team')}
          classNameWrapper="pd-b-50"
          name="department"
          placeholder={tr('Select department')}
          options={departmentsSelect}
          onChange={(event) => { actions.handleDepartmentChange(event.target.value); }}
        />
      </ReduxForm>
    );
  };

  return (
    <div className="page-sidebar">
      <div className={cn('logo', { 'collapsed-logo': collapsed })}>
        <Link to="/" className="logo-img">
          {collapsed ? <AppLogoSmallIcon /> : <AppLogoIcon />}
        </Link>
        <button type="button" className="close sidebar-close-button" onClick={handleToggleSidebar}>
          <CloseIcon />
        </button>
      </div>
      <div className="page-sidebar-menu">
        <ul className="accordion-menu">
          {routes.filter((route) => !route.disabled).map(renderItem)}
        </ul>
        {filterForm()}
      </div>

      <div className="sidebar-footer">
        <Socials />
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  location: PropTypes.objectOf(PropTypes.string).isRequired,
  collapsed: PropTypes.bool.isRequired,
  handleToggleSidebar: PropTypes.func.isRequired,
};

export default withRouter(Sidebar);
