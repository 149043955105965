import { createAction } from 'redux-actions';
import DEPARTMENT from './types';
import { createActionRequest } from '../../../utils/actionHelpers';

export const inviteUser = createActionRequest(DEPARTMENT.INVITE_USER, 'post', '/bo/invitations/');

export const createDepartment = createActionRequest(DEPARTMENT.CREATE_DEPARTMENT, 'post', '/bo/departments/');

export const getDepartment = createActionRequest(
  DEPARTMENT.GET_DEPARTMENT,
  'get',
  '/bo/departments/{id}/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const activateDepartment = createActionRequest(
  DEPARTMENT.ACTIVATE_DEPARTMENT,
  'get',
  '/bo/departments/{id}/activate/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const deactivateDepartment = createActionRequest(
  DEPARTMENT.DEACTIVATE_DEPARTMENT,
  'get',
  '/bo/departments/{id}/deactivate/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const editDepartment = createActionRequest(
  DEPARTMENT.EDIT_DEPARTMENT,
  'patch',
  '/bo/departments/{id}/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
);

export const filterDepartmentsMini = createActionRequest(
  DEPARTMENT.FILTER_DEPARTMENTS_MINI,
  'get',
  '/bo/departments/all-mini/',
  {
    setDataPath: (params) => ({ params }),
  },
);

export const getDepartmentsMini = createActionRequest(
  DEPARTMENT.GET_DEPARTMENTS_MINI,
  'get',
  '/bo/departments/all-mini/',
  {
    setDataPath: (params) => ({ params }),
  },
);

export const inviteMemberGetDepartmentsMini = createActionRequest(
  DEPARTMENT.INVITE_MEMBER_GET_DEPARTMENTS_MINI,
  'get',
  '/bo/departments/all-mini/',
  {
    setDataPath: (params) => ({ params }),
  },
);

export const getDepartments = createActionRequest(
  DEPARTMENT.GET_DEPARTMENTS,
  'get',
  '/bo/departments/',
  {
    setDataPath: (params) => ({ params }),
  },
);

export const setActiveDepartment = createAction(DEPARTMENT.SET_ACTIVE_DEPARTMENT);
