import React from 'react';

import translate from '../../utils/translate';
import Button from '../../components/Button';
import PageHeader from '../../components/PageHeader';
import WidgetBorder from '../../components/WidgetBorder';
import { CampaignsIcon, DevicesIcon, ClientsIcon } from '../../components/Icons';
import Loader from '../../components/Loader';
import DeviceTable from '../devices/components/TableStatus';
import CampaignsTable from '../campaigns/components/CampaignsTable';
import useDashboard from './hooks';
import { ROLE_COMPANY_ADMIN, ROLE_DEPARTMENT_ADMIN } from '../../constants/roles';
import './styles.scss';

const Dashboard = () => {
  const {
    actions,
    data: {
      isLoading,
      info,
      access_level,
    },
  } = useDashboard();

  if (isLoading) return <Loader page size="lg" />;

  const {
    campaigns_completed,
    campaigns_total,
    devices_disabled,
    devices_total,
    users_today,
    users_total,
    samples_total,
  } = info;

  const headerButtons = (
    <div>
      <Button className="mg-r-10" onClick={actions.goToAddDevice}>{translate('Add device')}</Button>
      <Button onClick={actions.newCampaign}>{translate('Add campaign')}</Button>
    </div>
  );

  const dashboardWidgets = (
    <div className="row mg-b-30 dashboard-badges">
      <div className="col col-md-3">
        <WidgetBorder
          color="yellow"
          icon={<DevicesIcon />}
          title={translate('Total Devices')}
          subtitle={devices_total || ''}
          description={(
            <>
              <span className="text-success">{devices_disabled}</span>
              {' '}
              {translate('Disabled')}
            </>
          )}
        />
      </div>
      <div className="col col-md-3">
        <WidgetBorder
          color="green"
          icon={<CampaignsIcon />}
          title={translate('Total Campaigns')}
          subtitle={campaigns_total || ''}
          description={(
            <>
              <span className="text-success">{campaigns_completed}</span>
              {' '}
              {translate('Completed')}
            </>
          )}
        />
      </div>
      <div className="col col-md-3">
        <WidgetBorder
          color="blue"
          icon={<ClientsIcon />}
          title={translate('Total interactions')}
          subtitle={users_total || '0'}
          description={(
            <>
              <span className="text-success">{users_today > 0 ? `+${users_today}` : users_today}</span>
              {' '}
              {translate('Today')}
            </>
          )}
        />
      </div>
      <div className="col col-md-3">
        <WidgetBorder
          color="yellow"
          icon={<DevicesIcon />}
          title={translate('Total samples sent')}
          subtitle={samples_total || ''}
          description={''}
        />
      </div>
    </div>
  );

  return (
    <div className="page-inner pd-t-25 pd-b-25 dashboard">
      <PageHeader title={translate('Dashboard')} hideCrumbs>
        {headerButtons}
      </PageHeader>
      {[ROLE_COMPANY_ADMIN, ROLE_DEPARTMENT_ADMIN].includes(access_level) && dashboardWidgets}
      <div className="row mg-b-30">
        <div className="col-md-12 dashboard-devices">
          <DeviceTable />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 dashboard-campaigns">
          <CampaignsTable />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
