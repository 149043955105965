import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { signOut } from '../../../../auth/services/actions';

const useHeaderLogout = () => {
  const dispatch = useDispatch();

  const logout = useCallback(() => {
    dispatch(signOut());
  }, [dispatch]);

  return {
    logout,
  };
};

export default useHeaderLogout;
