import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connectModal } from 'redux-modal';
import cn from 'classnames';

import './style.scss';
import { CloseIcon } from '../Icons';

const ModalDialog = (props) => {
  const {
    size,
    title,
    footer,
    children,
    className,
    handleHide: hideModal,
    onClose,
    data,
  } = props;

  const handleHide = () => {
    hideModal();

    if (onClose) {
      onClose(data);
    }
  };
  useEffect(() => {
    document.body.classList.add('modal-open');
    if (window.innerHeight < document.body.scrollHeight) {
      document.body.classList.add('modal-open-padding');
    }
    return () => {
      document.body.classList.remove('modal-open');
      document.body.classList.remove('modal-open-padding');
    };
  }, []);

  return (
    <>
      <div className={cn('modal show', className)} tabIndex="-1">
        <div className={cn('modal-dialog', size && `modal-${size}`)}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{data.titleModal || title}</h5>
              <button type="button" className="close" onClick={handleHide}>
                <CloseIcon />
              </button>
            </div>
            {children instanceof Function
              ? children({ handleHide, ...data })
              : children}
            {footer && (
              <div className="modal-footer">
                {footer}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="modal-backdrop show" />
    </>
  );
};

ModalDialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  data: PropTypes.shape(),
  className: PropTypes.string,
  title: PropTypes.string,
  footer: PropTypes.node,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  onClose: PropTypes.func,
};

ModalDialog.defaultProps = {
  data: {},
  className: null,
  title: '',
  footer: null,
  size: null,
  onClose: null,
};

const Modal = (props) => {
  const ReduxModal = React.useMemo(
    () => connectModal({ name: props.name })(ModalDialog),
    [props.name], // eslint-disable-line react/destructuring-assignment
  );

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ReduxModal {...props} />;
};

Modal.propTypes = {
  ...ModalDialog.propTypes,
  name: PropTypes.string.isRequired,
};

export default Modal;
