import React, { useState, Children } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './styles.scss';

const Pane = ({ children }) => <>{children}</>;

Pane.propTypes = {
  children: PropTypes.node.isRequired,
  /* eslint-disable react/no-unused-prop-types */
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  name: PropTypes.string,
  /* eslint-enable react/no-unused-prop-types */
};

Pane.defaultProps = {
  name: null,
};

const Tabs = ({
  className, children, onChange, customHeaderAdd, activeTabIndex, headerAlwaysShow,
}) => {
  const [activeIndex, setActiveIndex] = useState(activeTabIndex || 0);
  const tabs = Children.toArray(children);
  const handleClickTab = (index, data) => () => {
    if (index !== activeIndex) {
      // eslint-disable-next-line no-unused-expressions
      onChange && onChange(index, data);
      setActiveIndex(index);
    }
  };

  return (
    <div className={cn('tabs', className)}>
      <div className="tabs__header">
        <ul className="tabs__nav pagination">
          {tabs.map(({ props: { name, label } }, index) => (
            <li key={index} className={cn('page-item', { active: index === activeIndex })}>
              <span className="page-link" onClick={handleClickTab(index, { name, label })}>{label}</span>
            </li>
          ))}
        </ul>
        {(activeIndex > 0 || headerAlwaysShow) && customHeaderAdd}
      </div>
      <div className="tabs__content">
        {tabs[activeIndex]}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  customHeaderAdd: PropTypes.node,
};

Tabs.defaultProps = {
  className: null,
  onChange: null,
  customHeaderAdd: null,
};

Tabs.Pane = Pane;

export default Tabs;
