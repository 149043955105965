import React, { useState, useMemo } from 'react';
import Select from 'react-select';
import OverviewBadges from '../../widgets/OverviewBadges';
import MailBadges from '../../widgets/MailBadges';
import ColumnChart from '../../../../components/ColumnChart';
import Frame from '../../../../components/Frame';
import UnsubscribedModal from './UnsubscribedModal';
import useModal from '../../../../utils/useModal';
import Table from '../../../../components/Table';
import FacebookBadges from '../../widgets/FacebookBadges';
import SimpleButton from '../../../../components/SimpleButton';
import FrameHeader from '../../../../components/FrameHeader';
import ProductDispencesChart from '../../../../shared/statistic/ProductDispencesChart';
import TimeOfDayChart from '../../../../shared/statistic/TimeOfDayChart';
import QAChart from '../../../../shared/statistic/QAchart';
import { OVERVIEW_WIDGET_MODAL } from  '../../../../constants/modals';
// import SimpleWidget from '../../../../components/SimpleWidget';
import PredictedGenderChart from '../../../../shared/statistic/PredictedGenderChart';

import { ONLINE_EMAIL_CAMPAIGN, ONLINE_SOCIAL_CAMPAIGN, DEVICE_CAMPAIGN } from '../../../../constants/campaigns';
import { formatBeforeSend } from '../../../../utils/dates';
import translate from '../../../../utils/translate';
import tr from '../../../../utils/translate';
import './styles.scss';
import { pauseFacebookCampaign, startFacebookCampaign } from '../../services/actions';
import { useDispatch } from 'react-redux';


const tableConfiig = [
  {
    name: '',
    key: 'question',
  },
];

// const predictedAgeData = [
//   { label: '18-24', count: '20%' },
//   { label: '25-34', count: '5%' },
//   { label: '35-44', count: '10%' },
//   { label: '45-54', count: '50%' },
//   { label: '55-64', count: '5%' },
//   { label: '65+', count: '10%' },
// ];

const expandRenderer = ({ question, answers }) => (
  <QAChart key={question} chartData={{ answers }} />
);

// const predictedData = [
//   { label: 'Male', count: 40 },
//   { label: 'Female', count: 100 },
//   { label: 'Another identity', count: 1 },
//   { label: 'Unknown', count: 20 },
// ];

const typeOptions = [
  { value: 'all', label: tr('All') },
  { value: 'device', label: tr('Device') },
  { value: 'qr', label: tr('Smartphone') },
];

const CampaignOverviewTab = ({
  statisticFacebook, contacts, statData, dateRange, stats_visibility, campaign_type, social_networks = [], ad_campaign = {},
}) => {
  const dispatch = useDispatch();

  const {
    samples_per_period,
    qr_samples_per_period,
    device_samples_per_period,
    samples_per_time_of_day,
    samples_by_title,
    question_answers,
    social_media,
    mailing_statistics = {},
    predicted_demographics,
    ...otherData
  } = statData;
  const [type, setType] = useState(typeOptions[0].value);
  const { showModal: showUnsubscribesListModal } = useModal(OVERVIEW_WIDGET_MODAL);

  const badgesInfo = {
    samples: otherData.samples || 0,
    engagement: otherData.average_time || 0,
    samplesToday: otherData.samples_today || 0,
    engagementToday: otherData.average_time_today || 0,
    click_rate: otherData.click_rate,
    bounce_rate: otherData.bounce_rate,
    abandonment_rate: otherData.abandonment_rate,
    abandonment_rate_today: otherData.abandonment_rate_today,
    opt_in_rate: otherData.opt_in_rate,
    opt_in_rate_today: otherData.opt_in_rate_today,
  };

  const mailBadgesInfo = {
    opened: mailing_statistics.unique_opens,
    clicked: mailing_statistics.clicked,
    bounced: mailing_statistics.bounced,
    unsubscribed: mailing_statistics.unsubscribed,
  };

  const header = (
    <FrameHeader
      rightBlock={dateRange}
      title={(
        <div className="cot-dispenses-title">
          <div>
            {tr(`${campaign_type === DEVICE_CAMPAIGN ? 'Dispenses' : 'Products'} per day`)}
          </div>
          {campaign_type === DEVICE_CAMPAIGN && (
            <Select
              options={typeOptions}
              classNamePrefix="type-filter"
              className="type-filter-wrapper"
              defaultValue={typeOptions[0]}
              onChange={({ value }) => { setType(value); }}
            />
          )}
        </div>
      )}
    />
  );

  const despensesPerDay = useMemo(() => {
    if (type === 'qr') {
      return qr_samples_per_period;
    }

    if (type === 'device') {
      return device_samples_per_period;
    }

    return samples_per_period;
  }, [device_samples_per_period, qr_samples_per_period, samples_per_period, type]);

  const dispensesPerDayChart = (
    <Frame
      header={header}
      className="mg-b-30"
    >
      <ColumnChart chartData={despensesPerDay} seriesName={tr(campaign_type === DEVICE_CAMPAIGN ? 'Dispences' : 'Products')} />
    </Frame>
  );

  const productDispensesChart = (
    <ProductDispencesChart
      chartData={samples_by_title}
      header={<FrameHeader title={tr(`Products${campaign_type === DEVICE_CAMPAIGN ? ' dispenses' : ''}`)} rightBlock={dateRange} />}
      legendLabel={tr(campaign_type === DEVICE_CAMPAIGN ? 'dispenses' : 'products')}
    />
  );

  const timeOfDayChart = (
    <div className="col">

      <TimeOfDayChart
        chartData={samples_per_time_of_day}
        header={<FrameHeader title={tr('Time Of Day')} rightBlock={dateRange} />}
        legendLabel={tr(campaign_type === DEVICE_CAMPAIGN ? 'dispenses' : 'products')}
      />

    </div>
  );

  const socialMediaChart = () => {
    const socialNetworkData = [];

      if (social_media) {
      let unknownCount = 0;

      Object.keys(social_media).map((network) => {
        const data = social_media[network];

        if (social_networks.includes(network)) {
          socialNetworkData.push({ label: network, count: data.count });
        } else {
          unknownCount += data.count;
        }
      });
      if (unknownCount > 0) {
        socialNetworkData.push({
          label: tr('Unknown'), count: unknownCount,
        });
      }
    }
    return (
      <div className="col">

        <Frame
          header={<FrameHeader title={tr('Social Media')} rightBlock={dateRange} />}
          classNameBody="social-chart"
        >

          <ColumnChart chartData={socialNetworkData} height="260" isMultiColor labelField="label" />

        </Frame>

      </div>
    );
  };

  const qaChart = (
    <Frame
      header={<FrameHeader title={tr('Questions and answers')} rightBlock={dateRange} />}
      className="mg-b-30"
    >

      {!!(question_answers && question_answers.length) && (
        <Table
          columns={tableConfiig}
          data={question_answers}
          expandRenderer={expandRenderer}
          className="qa-stat-table"
          expandedOnLoad
        />
      )}

    </Frame>
  );

  const PredictedGenderBlock = () => {
    const predictedGender = predicted_demographics ? predicted_demographics.gender : [];

    return (
      <PredictedGenderChart
        chartData={predictedGender}
      />
    );
  };

  const PredictedAgeBlock = () => {
    const predictedAge = predicted_demographics ? predicted_demographics.age : [];

    return (
      <div className="predicted-age-wrapper">

        <h3>{tr('Age')}</h3>

        <div className="predicted-chart-age-wrapper">

          {predictedAge.length > 0 ? (
            predictedAge.map((ageData, index) => (
              <div className="age-item" key={index}>

                <span className="age-item-title">{ageData.label}</span>

                <div className="age-item-bar">

                  <div className="age-item-progress" style={{ width: `${ageData.percents}%` }} />

                </div>

                <span className="age-item-value">{`${ageData.percents}%`}</span>

              </div>
            ))
          ) : (
            <h3>No data</h3>
          )}

        </div>

      </div>
    );
  };

  const mailsStattistics = () => {
    const mailStatData = [
      [
        { label: tr('Succesful delivery'), count: mailing_statistics.delivered },
        { label: tr('Total opens'), count: mailing_statistics.opened },
        { label: tr('Last opened'), count: mailing_statistics.last_opened, isTime: true },
        { label: tr('Forwarded'), count: mailing_statistics.forwarded },
      ],
      [
        { label: tr('Click rate'), count: mailing_statistics.click_rate },
        { label: tr('Total clicks'), count: mailing_statistics.clicked },
        { label: tr('Last clicked'), count: mailing_statistics.last_clicked, isTime: true },
        { label: tr('Abuse reports'), count: mailing_statistics.abuse_reports },
      ],
    ];

    return (
      <>

        <MailBadges stats={mailBadgesInfo} showUnsubscribesListModal={showUnsubscribesListModal} />

        <UnsubscribedModal />

        <div className="mail-stat-text-block-wrapper pd-b-30">

          {mailStatData.map((block, index) => (
            <Frame key={index} className="mail-stat-text-block">

              {block.map((item) => (
                <div key={item.label} className="text-block-item">

                  <span className="text-block-item-title">{item.label}</span>

                  <span className="text-block-item-value">{ item.isTime && item.count ? formatBeforeSend(item.count) : item.count}</span>

                </div>
              ))}

            </Frame>
          ))}

        </div>

      </>
    );
  };

  const columns = [
    {
      name: translate('Name'),
      key: 'name',
    },
    {
      name: translate('Status'),
      key: 'status',
      render: ({ status }) => status && (
        <span
          className={`cell-status cell-status_${status !== 'PAUSED' ? 'online' : 'completed'}`}
        >
          {/*{status ? tr('Active') : tr('Completed')}*/}
          {status.toLowerCase()}
        </span>
      ),
    },
    {
      name: translate('Effective status'),
      key: 'effective_status',
    },
    {
      name: translate('Account Name'),
      key: 'account_name',
    },
    {
      name: translate('Type'),
      key: 'type',
    },
    {
      name: translate('Clicks'),
      key: 'clicks',
    },
    {
      name: translate('Reach'),
      key: 'reach',
    },
    {
      name: translate('Impressions'),
      key: 'impressions',
    },
    {
      name: translate('Cost per Result'),
      key: 'cost_per_result',
    },
    {
      name: translate('Amount Spent'),
      key: 'amount_spent',
    },
    {
      name: translate('Budget'),
      key: 'budget',
    },
    {
      name: translate('Action'),
      key: 'action',
      render: ({ action: { status, id } }) => status && id && (
        <>

          {status === 'PAUSED'
            ? (
              <SimpleButton
                className="cell-link"
                onClick={() => {
                  dispatch(startFacebookCampaign({
                    id,
                  }))
                }}
              >

                {tr('Start')}

              </SimpleButton>
            )
            : (
              <SimpleButton
                className="cell-link"
                onClick={() => {
                  dispatch(pauseFacebookCampaign({
                    id,
                  }));
                }}
              >

                {tr('Pause')}

              </SimpleButton>
            )}

        </>
      ),
    },
  ];

  const marketingData = [{
    name: ad_campaign?.name || '',
    status: ad_campaign?.status || '',
    effective_status: ad_campaign?.effective_status || '',
    account_name: ad_campaign?.name || '',
    type: ad_campaign?.campaign_type || '',
    clicks: statisticFacebook?.ad_campaigns?.[0]?.clicks_total || 0,
    reach: statisticFacebook?.ad_campaigns?.[0]?.reach_total || 0,
    impressions: statisticFacebook?.ad_campaigns?.[0]?.impressions_total || 0,
    cost_per_result: statisticFacebook?.ad_campaigns?.[0]?.cost_per_click_total || 0,
    amount_spent: statisticFacebook?.ad_campaigns?.[0]?.spend_total || 0,
    budget: ad_campaign?.ad_sets?.[0]?.budget || 0,
    action: ad_campaign || '',
  }];
  return (
    <>

      {campaign_type === ONLINE_SOCIAL_CAMPAIGN && social_networks.includes('facebook') && (
        <>

          <Table
            classNameWrapper="mg-b-30"
            caption={translate('Marketing campaign')}
            columns={columns}
            data={marketingData}
          />

          {Object.keys(ad_campaign).length > 0 && statisticFacebook && (
            <FacebookBadges
              statisticFacebook={statisticFacebook}
              contacts={contacts}
            />
          )}

        </>
      )}

      <OverviewBadges stats_visibility={stats_visibility} stats={badgesInfo} />
      {campaign_type === ONLINE_EMAIL_CAMPAIGN
          && stats_visibility.email_statistics && mailsStattistics()}
      {stats_visibility && stats_visibility.samples_per_period && dispensesPerDayChart}
      {stats_visibility && stats_visibility.samples_by_title && productDispensesChart}
      <div className="overview-two-column mg-b-30">
        {stats_visibility && stats_visibility.samples_per_time_of_day && timeOfDayChart}
        {stats_visibility && stats_visibility.social_media && campaign_type === ONLINE_SOCIAL_CAMPAIGN && socialMediaChart()}
      </div>
      {stats_visibility && stats_visibility.question_answers && qaChart}

      {stats_visibility.predicted_demographics
        && (
        <Frame
          header={<FrameHeader title={tr('Predicted demographics')} />}
          className="mg-t-30 predicted_demographics_wrapper"
        >
          {PredictedGenderBlock()}
          {PredictedAgeBlock()}
        </Frame>
        )}
    </>
  );
};

export default CampaignOverviewTab;
