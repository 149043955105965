import React from 'react';

import Modal from '../../../../components/Modal';
import tr from '../../../../utils/translate';
import { PRODUCT_MODAL } from '../../../../constants/modals';
import ProductForm from '../ProductForm';

const ProductModal = () => (
  <Modal title={tr('Create product')} name={PRODUCT_MODAL} size="md">
    {({ handleHide, ...initialValues }) => (
      <ProductForm data={initialValues} handleHide={handleHide} />
    )}
  </Modal>
);

export default ProductModal;
