import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import createLoadingSelector from '../../../../selectors/loadingSelector';
import { addProductStock } from '../../services/actions'; // getCompanyProducts
import DEVICES from '../../services/types';

const loadingSelector = createLoadingSelector(DEVICES.ADD_PRODUCT_STOCK.base);

const createDeviceSelector = () => createSelector(
  loadingSelector,
  (isLoading, ) => ({
    isLoading,
  }),
);

const useEditProductStock = (campaignId) => {
  const dispatch = useDispatch();

  const data = useSelector(createDeviceSelector());

  const submit = useCallback((id, data) => {
    dispatch(addProductStock( id, {...data, ...{campaign_id: campaignId}}));
  }, [dispatch, campaignId]);

  return {
    data,
    actions: {
      submit,
    },
  };
};

export default useEditProductStock;
