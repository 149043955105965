import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  getSuggestions,
} from '../../../services/actions';

const createSuggestionSlector = (id) => createSelector(
  (state) => state.campaigns.suggestions[id],
  (suggestions) => ({
    suggestions,
  }),
);

const useSuggestionTable = (id) => {
  const dispatch = useDispatch();
  const selector = createSuggestionSlector(id);
  const data = useSelector(selector);

  const openPage = useCallback((id) => {
    if (!data.suggestions) {
      dispatch(getSuggestions(id));
    }
  }, [dispatch, data.suggestions]);

  useEffect(() => {
    openPage(id);
  }, [openPage, id]);

  return {
    data,
  };
};

export default useSuggestionTable;
