import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import _find from 'lodash/find';

import { setUserDevices, getUserDevices } from '../../services/actions';
import { getDevices as getDevicesAction } from '../../../devices/services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import EMPLOYEES from '../../services/types';
import DEVICES from '../../../devices/services/types';

const selector = (id) => createSelector(
  createLoadingSelector(DEVICES.GET_ALL_DEVICES.base),
  (state) => _find(state.employees.list, ['id', id]),
  (state) => state.devices.list,
  (state) => state.employees.assigned_devices[id],
  (isLoading, user, devices, assigned_devices) => ({
    isLoading,
    user,
    devices,
    assigned_devices,
  }),
);

const useAssignDevices = (id) => {
  const dispatch = useDispatch();
  const data = useSelector(selector(id));
  const [isAllDeviceSelected, setAllDeviceSelection] = useState(false)
  const assignDevices = useCallback((formData) => {
    dispatch(setUserDevices(id, formData))
  }, [dispatch, id, isAllDeviceSelected]);

  const getDevices = useCallback(() => {
    dispatch(getDevicesAction({
      company: data.user && data.user.company && data.user.company.id,
      department: data.user && data.user.department && data.user.department.id,
    }));
    dispatch(getUserDevices(id))
  }, [dispatch, data.user]);


  useEffect(() => {
    id && getDevices();
  }, [getDevices]);

  useEffect(() => {
    setAllDeviceSelection(data.assigned_devices && data.assigned_devices.all_devices)
  }, [data.assigned_devices]);

  return {
    data,
    isAllDeviceSelected,
    actions: {
      assignDevices,
      setAllDeviceSelection
    },
  };
};

export default useAssignDevices;
