import {
  takeEvery, put, call,
} from 'redux-saga/effects';
import { hide } from 'redux-modal';
import { toast } from 'react-toastify';
import _get from 'lodash/get';
import tr from '../../../utils/translate';
import { PRODUCT_MODAL } from '../../../constants/modals';
import PRODUCT from './types';

function* runCreateProduct() {
  yield put(hide(PRODUCT_MODAL));
  yield call(toast.success, tr('Product is created'));
}

function* runUpdateProduct() {
  yield put(hide(PRODUCT_MODAL));
  yield call(toast.success, tr('Product is updated'));
}


export default function* departments() {
  yield takeEvery(PRODUCT.CREATE_PRODUCT.SUCCESS, runCreateProduct);
  yield takeEvery(PRODUCT.UPDATE_PRODUCT.SUCCESS, runUpdateProduct);
}
