import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { StockIcon, CampaignIcon } from '../../../../../components/Icons';

const Notification = ({
  title,
  text,
  date,
  type,
}) => (
  <div className="media pd-b-30">
    <div className={cn('activity-icon', 'op-6', { 'bg-primary': type === 'stock', 'bg-success': type === 'campaign' })}>
      {type === 'stock' ? <StockIcon /> : <CampaignIcon />}
    </div>
    <div className="ml-3 align-items-center">
      <h6 className="tx-13 tx-dark">{title}</h6>
      <p className="tx-12 mb-0">{text}</p>
      <span className="small">{date}</span>
    </div>
  </div>
);

Notification.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['stock', 'campaign']).isRequired,
};

export default Notification;
