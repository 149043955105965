import React from 'react';

import Loader from '../../../../components/Loader';
import PageHeader from '../../../../components/PageHeader';
import Button from '../../../../components/Button';
import Tabs from '../../../../components/Tabs';
import ReduxForm from '../../../../components/ReduxForm';
import Select from '../../../../components/Select';
import tr from '../../../../utils/translate';
import { DEVICES_ROUTE } from '../../../../constants/routes';
import { FILTER_PERIOD_OPTIONS } from '../../../../constants/common';
import { getDatesRange } from '../../../../utils/dates';

import DeviceInfoTab from '../DeviceInfoTab';
import DeviceOverviewTab from '../DeviceOverviewTab';
import DeviceDetailedTab from '../DeviceDetailedTab';
// import DeviceFollowUpsTab from '../DeviceFollowUpsTab';

import useDeviceInfo from './hooks';
import './styles.scss';

const breadcrumbs = [
  { to: DEVICES_ROUTE, body: tr('Devices') },
];

const DeviceInfo = () => {
  const { data, actions, formValues } = useDeviceInfo();
  const {
    device,
    statistic,
    motorLogs,
    modalState,
    isLoading,
    isLoadingReboot,
    stats_visibility,
  } = data;

  if (isLoading || !device) return <Loader page size="lg" />;

  const {
    user_interactions,
    campaigns,
    ...otherStats
  } = statistic || {};

  const dateRange = getDatesRange(formValues.period);

  const tabs = [
    {
      key: 'device_info',
      name: tr('Device Info'),
      block: <DeviceInfoTab device={device} actions={actions} motorLogs={motorLogs} modalState={modalState} isLoadingReboot={isLoadingReboot} />,
    },
    {
      key: 'overview',
      name: tr('Overview'),
      block: stats_visibility
        && (
          <DeviceOverviewTab
            stats_visibility={stats_visibility}
            statData={otherStats}
            dateRange={dateRange}
          />
        ),
    },
    {
      key: 'detailed',
      name: tr('Detailed'),
      block: stats_visibility && stats_visibility.user_interactions
        && <DeviceDetailedTab statData={user_interactions} dateRange={dateRange} deleteSessionAdd={true} deviceId={device.serial_number} />,
    },
    // {
    //   key: 'follow_ups',
    //   name: tr('Follow ups'),
    //   block: stats_visibility && <DeviceFollowUpsTab />,
    // },
  ];

  const headerButtons = (
    <div>
      <Button onClick={actions.showRebootModal}>{tr('Reboot device')}</Button>
      <span> </span>
      <Button onClick={actions.showTraysModal}>{tr('Test trays')}</Button>
    </div>
  );

  const formatChartData = () => (
    campaigns ? campaigns.map((campaign) => ({
      value: campaign.id,
      label: campaign.name,
    })) : [{ value: '', label: tr('Campaign') }]
  );

  const filterForm = (
    <ReduxForm
      formData={{
        initialValues: formValues,
      }}
      form="DEVICE_STAT_FILTER"
      enableReinitialize
      className="device-statistic-filter"
    >
      <Select
        fieldLabel={tr('Filtered by')}
        classNameWrapper="stat_period_filter"
        name="period"
        placeholder={tr('Filter period')}
        options={FILTER_PERIOD_OPTIONS}
        onChange={(event) => actions.handlePeriodChange(event.target.value)}
      />
      <Select
        fieldLabel={tr('Campaign')}
        classNameWrapper="stat_period_filter"
        name="campaign_id"
        placeholder={tr('Campaign')}
        options={formatChartData()}
        onChange={(event) => actions.handleCampaignChange(event.target.value)}
      />
    </ReduxForm>
  );

  return (
    <div className="page-inner pd-t-25 pd-b-25 page-device-info">
      <PageHeader title={device.name} list={breadcrumbs}>
        {headerButtons}
      </PageHeader>
      <Tabs customHeaderAdd={filterForm}>
        {tabs.map((tab, index) => (
          tab.block ? (
            <Tabs.Pane
              key={index}
              name={tab.key}
              label={tab.name}
            >
              {tab.block}
            </Tabs.Pane>
          )
            : null
        ))}
      </Tabs>
    </div>
  );
};

export default DeviceInfo;
