import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import CAMPAIGNS from '../../services/types';
import {
  prevStep,
  getAssociatedDevices,
  setAssociatedDevices,
} from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';

const submitLoadingSelector = createLoadingSelector(CAMPAIGNS.SET_ASSOCIATED_DEVICES.base);
const detailsLoadingSelector = createLoadingSelector(CAMPAIGNS.GET_ASSOCIATED_DEVICES.base);

const createCampaignSelector = (id) => createSelector(
  detailsLoadingSelector,
  submitLoadingSelector,
  (state) => state.campaigns.details[id],
  (state) => state.campaigns.associatedDevices,
  (state) => state.brands.activeBrand,
  (state) => state.departments.activeDepartment,
  (isLoadingData, isLoadingSubmit, campaign, devices, activeBrand, activeDepartment) => ({
    isLoadingData,
    isLoadingSubmit,
    campaign,
    devices,
    activeBrand,
    activeDepartment,
  }),
);

const useAssociatedDevices = (id) => {
  const dispatch = useDispatch();
  const selector = createCampaignSelector(id);
  const data = useSelector(selector);

  const goToBack = useCallback(() => {
    dispatch(prevStep());
  }, [dispatch]);

  const getDevices = useCallback(() => {
    const company = data.activeBrand ? data.activeBrand : data.campaign && data.campaign.company && data.campaign.company.d;
    const department = data.activeDepartment ? data.activeDepartment : data.campaign &&  data.campaign.department && data.campaign.department.id;
    dispatch(getAssociatedDevices({
      company,
      department: department,
    }));
  }, [data.activeBrand, data.activeDepartment, dispatch, data.campaign]);

  const setDevices = useCallback((fields) => {
    dispatch(setAssociatedDevices(id, fields));
  }, [dispatch, id]);

  useEffect(() => {
    getDevices();
  }, [getDevices]);

  return {
    data,
    actions: {
      goToBack,
      setDevices,
    },
  };
};

export default useAssociatedDevices;
