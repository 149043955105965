import React from 'react';
import PropTypes from 'prop-types';

import ReduxForm from '../../../../components/ReduxForm';
import Input from '../../../../components/Input';
import { AuthPeopleIcon } from '../../../../components/Icons/auth-icons';
import InputFileImage from '../../../../components/InputFileImage';
import ModalFormFooter from '../../../../components/ModalFormFooter';
import Modal from '../../../../components/Modal';
import { CAMPAIGN_EDIT_TRAY_MODAL } from '../../../../constants/modals';
import tr from '../../../../utils/translate';
import validate from './validate';
import useEditTray from './hooks';

const EditTrayModal = ({ countItemsInTray, campaign }) => {
  const { actions, data: { isLoading } } = useEditTray(campaign.id);
  return (
    <Modal name={CAMPAIGN_EDIT_TRAY_MODAL} size="md" title={tr('Manage product')}>
      {({ handleHide, ...initialValues }) => (
        <ReduxForm
          enableReinitialize
          form="EDIT_TRAY_FORM"
          validate={validate}
          formData={{
            onSubmit: actions.submit,
            initialValues: { ...initialValues, ...{ company: campaign.company.id } },
          }}
        >
          <div className="modal-body">
            <div className="row">
              <div className="col col-6 pd-b-15">
                <Input
                  name="name"
                  prepend={<AuthPeopleIcon />}
                  placeholder={tr('Product name')}
                />
              </div>
              <div className="col col-6">
                <Input
                  name="tag"
                  prepend={<AuthPeopleIcon />}
                  placeholder={tr('Product ID/TAG')}
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-6">
                <Input
                  name="description"
                  type="textarea"
                  rows={1}
                  placeholder={tr('This product description')}
                />
              </div>
              <div className="col col-6">
                <InputFileImage
                  name="img"
                  component={InputFileImage}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <ModalFormFooter
              handleClose={handleHide}
              confirmText={tr('Save changes')}
              isLoading={isLoading}
            />
          </div>
        </ReduxForm>
      )}
    </Modal>
  );
};

EditTrayModal.propTypes = {
  id: PropTypes.string.isRequired,
  countItemsInTray: PropTypes.shape(),
};

EditTrayModal.defaultProps = {
  countItemsInTray: {},
};

export default EditTrayModal;
