import React from 'react';
import PropTypes from 'prop-types';
import Widget from '../../../../components/Widget';
import {
  LargeClockIcon,
  PieChartIcon,
  ReplyIcon,
  EyeIcon,
} from '../../../../components/Icons';
import tr from '../../../../utils/translate';
import { transformDuration } from '../../../../utils/dates';
import './styles.scss';

const FollowUpBadges = ({ stats }) => (
  <div className="info-badges row mg-b-30">
    <div className="col">
      <Widget
        color="yellow"
        icon={<ReplyIcon />}
        title={tr('Responses')}
        subtitle={stats.responses}
      />
    </div>
    <div className="col">
      <Widget
        color="red"
        icon={<EyeIcon />}
        title={tr('Total visits')}
        subtitle={stats.totalVisits}
      />
    </div>
    <div className="col">
      <Widget
        color="green"
        icon={<PieChartIcon />}
        title={tr('Completion rate')}
        subtitle={`${stats.completionRate - stats.completionRate.toFixed(1) ? stats.completionRate.toFixed(1) : stats.completionRate}%`}
      />
    </div>
    <div className="col">
      <Widget
        color="violet"
        icon={<LargeClockIcon />}
        title={tr('Average time to complete')}
        subtitle={transformDuration(stats.averageCompletionTime)}
      />
    </div>
  </div>
);

FollowUpBadges.propTypes = {
  stats: PropTypes.shape({
    samples: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    engagement: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    samplesToday: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    engagementToday: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    through_rate: PropTypes.node,
    bounce_rate: PropTypes.node,
  }).isRequired,
};

export default FollowUpBadges;
