import BRAND from './types';

const defaultState = {
  list: [],
  listMini: [],
  activeBrand: null,
  count: null,
  activePage: 1,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case BRAND.GET_BRANDS.SUCCESS:
      return {
        ...state,
        list: payload.res.results,
        count: payload.res.count,
        activePage: payload.req.data ? (payload.req.data.page || 1) : 1,
      };
    case BRAND.GET_BRANDS_MINI.SUCCESS:
      return {
        ...state,
        listMini: payload.res,
      };
    case BRAND.EDIT_BRAND.SUCCESS:
      return {
        ...state,
        list: state.list.map((brand) => (
          brand.id === payload.res.id ? { ...brand, ...payload.res } : brand
        )),
      };
    case BRAND.SET_ACTIVE_BRAND:
      return {
        ...state,
        activeBrand: payload.brand,
      };
    case BRAND.ACTIVATE_BRAND.SUCCESS:
      return {
        ...state,
        list: state.list.map((brand) => (
          brand.id === payload.req.params.id ? { ...brand, is_active: true } : brand
        )),
      };
    case BRAND.DEACTIVATE_BRAND.SUCCESS:
      return {
        ...state,
        list: state.list.map((brand) => (
          brand.id === payload.req.params.id ? { ...brand, is_active: false } : brand
        )),
      };
    default:
      return state;
  }
};
