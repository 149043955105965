import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import EditTrayModal from '../EditTrayModal';
import Trays from '../../../../components/Trays';
import RebootDeviceModal from '../RebootDeviceModal';
import MotorTestDeviceModal from '../MotorTestDeviceModal';
import InfoBadges from '../../widgets/InfoBadges';
import tr from '../../../../utils/translate';

const DeviceInfoTab = (props) => {
  const {
    device,
    motorLogs,
    modalState,
    actions,
    isLoadingReboot,
  } = props;

  const maxProducts = _get(device, 'max_sempl_by_tray', {});

  const modalsBlock = (
    <>
      <EditTrayModal
        serial_number={device.serial_number}
        countItemsInTray={device.max_sempl_by_tray}
        company={device.company}
      />
      <RebootDeviceModal
        handleRebootDevice={actions.handleRebootDevice}
        handleClose={actions.hideRebootModal}
        isLoading={isLoadingReboot}
        deviceNumber={device.serial_number}
      />
      <MotorTestDeviceModal
        handleTouchTrayDevice={actions.handleTouchTrayDevice}
        readTraysLog={actions.readTraysLog}
        handleClose={actions.hideTraysModal}
        motorLogs={motorLogs}
        modalState={modalState}
        deviceTrays={device.trays}
        deviceNumber={device.serial_number}
      />
    </>
  );

  return (
    <>
      <InfoBadges device={device} actions={actions} disableEdit />
      <div className="row mg-b-30">
        <div className="col col-md-12">
          <Trays
            header={tr('Trays')}
            data={device.trays}
            maxProducts={maxProducts}
            onClickEdit={actions.showEditTray}
          />
        </div>
      </div>
      {modalsBlock}

    </>
  );
};

DeviceInfoTab.propTypes = {
  device: PropTypes.shape(),
  actions: PropTypes.shape(),
  modalState: PropTypes.shape(),
  motorLogs: PropTypes.array,
  isLoadingReboot: PropTypes.bool,
};

DeviceInfoTab.defaultProps = {
  device: {},
  actions: {},
  modalState: {},
  motorLogs: [],
  isLoadingReboot: false,
};

export default DeviceInfoTab;
