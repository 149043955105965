import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import tr from '../../../../utils/translate';
import Select from '../../../../components/Select';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import ReduxForm from '../../../../components/ReduxForm';
import { BackarrowIcon } from '../../../../components/Icons';
import useCampaignDetailsForm from './hooks';
import GenderCheckboxes from '../GenderCheckboxes';
import InterestsField from '../InterestsField';
import validate from './validate';
import './styles.scss';


const ageFrom = [{ value: '', label: '' }];
const ageTo = [{ value: '', label: '' }];
for (let i = 10; i <= 90; i++) {
  ageFrom.push({ value: i, label: i });
  ageTo.push({ value: i, label: i });
}

const DemographicsForm = ({ data: { id } }) => {
  const {
    data: {
      isLoadingData,
      isLoadingSubmit,
      campaign,
    },
    actions: {
      editCampaign,
      goToPrevStep,
    },
  } = useCampaignDetailsForm(id);

  if ((id && !campaign) || isLoadingData) return <Loader size="md" />;

  const { demographic } = campaign;

  const ageGenderFields = (
    <div className="mg-b-30">
      <h4 className="cdf-subtitle mg-b-10">
        {tr('Please select demographic criteria to interact with the client.')}
      </h4>
      <div className="age-gender-selection">
        <Select
          fieldLabel={tr('Age from')}
          classNameWrapper="age-selector"
          name="age_from"
          options={ageFrom}
        />
        <Select
          fieldLabel={tr('Age to')}
          classNameWrapper="age-selector"
          name="age_to"
          options={ageTo}
        />
        <Field
          name="gender"
          component={GenderCheckboxes}
        />

      </div>
    </div>
  );

  const interestsField = (
    <div className="mg-b-30">
      <h4 className="cdf-subtitle mg-b-10">
        {tr('Please type interests or behaviors about your target customer.')}
      </h4>
      <div className="row">
        <Field
          name="interests"
          component={InterestsField}
          data={demographic && demographic.interests ? demographic.interests.split(',') : []}
        />

      </div>
    </div>
  );

  return (
    <ReduxForm
      enableReinitialize
      form="COMPAIGN_DEMOGRAPHICS_FORM"
      validate={validate}
      formData={{
        onSubmit: editCampaign,
        initialValues: {
          age_from: demographic && demographic.age_from || '',
          age_to: demographic && demographic.age_to || '',
          gender: demographic && demographic.gender || 3,
          interests: demographic && demographic.interests || '',
        },
      }}
    >
      <div className="campaign-interests-form pd-x-25 pd-y-50">
        <h3 className="cdf-title mg-b-40">{tr('Demographics')}</h3>
        {ageGenderFields}
        {interestsField}
      </div>
      <div className="modal-footer">
        <Button color="light" className="mg-r-30" onClick={goToPrevStep}>
          <BackarrowIcon />
          {tr('Back')}
        </Button>
        <Button
          type="submit"
          disabled={isLoadingSubmit}
        >
          {tr('Next')}
        </Button>
      </div>
    </ReduxForm>
  );
};

DemographicsForm.defaultProps = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }),
};

DemographicsForm.defaultProps = {
  data: {},
};

export default DemographicsForm;
