import React from 'react';

import Loader from '../../../../components/Loader';
import PageHeader from '../../../../components/PageHeader';
import Button from '../../../../components/Button';
import Tabs from '../../../../components/Tabs';
import tr from '../../../../utils/translate';
import { DEVICES_ROUTE } from '../../../../constants/routes';

import DeviceInfoTab from './DeviceInfoTab';

import useDeviceInfo from './hooks';


const breadcrumbs = [
  { to: DEVICES_ROUTE, body: tr('Devices') },
];

const ContentManagerDeviceInfo = () => {
  const { data, actions } = useDeviceInfo();
  const {
    device,
    isLoading,
    motorLogs,
    modalState,
    isLoadingReboot,
  } = data;

  if (isLoading || !device) return <Loader page size="lg" />;


  const tabs = [
    { key: 'device_info', name: tr('Device Info'), block: <DeviceInfoTab device={device} actions={actions} motorLogs={motorLogs} modalState={modalState} isLoadingReboot={isLoadingReboot} isContentManager /> },
  ];

  const headerButtons = (
    <div>
      <Button onClick={actions.showRebootModal}>{tr('Reboot device')}</Button>
      <span> </span>
      <Button onClick={actions.showTraysModal}>{tr('Test trays')}</Button>
    </div>
  );

  return (
    <div className="page-inner pd-t-25 pd-b-25 page-device-info">
      <PageHeader title={device.name} list={breadcrumbs}>
        {headerButtons}
      </PageHeader>
      <Tabs>
        {tabs.map((tab, index) => (
          <Tabs.Pane
            key={index}
            name={tab.key}
            label={tab.name}
          >
            {tab.block}
          </Tabs.Pane>
        ))}
      </Tabs>
    </div>
  );
};

export default ContentManagerDeviceInfo;
