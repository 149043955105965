import React from 'react';
import Modal from '../../../../../components/Modal';
import Button from '../../../../../components/Button';
import tr from '../../../../../utils/translate';
import { ADD_SOCIALS_MODAL } from '../../../../../constants/modals';

const AddSocialsModal = ({ onSubmit }) => {
  return (
    <Modal name={ADD_SOCIALS_MODAL} size="md" title={tr('Social')}>
      <div className="social_modal">
        <h2>{tr('Get Connected')}</h2>
        <p className="social_modal_add_text">{tr('Use the social accounts of your company and team to give your contacts a chance to really connect.')}</p>

        <Button onClick={onSubmit}>{tr('Connect Account')}</Button>
      </div>
    </Modal>
  );
};

export default AddSocialsModal;
