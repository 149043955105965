import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DELETE_MODAL } from '../../../../constants/modals';
import useModal from '../../../../utils/useModal';
import { deleteSessionDevices } from '../../../devices/services/actions';
import { deleteSession, deleteSessions } from '../../services/actions';

const useDeleteModal = (/*pollSessions, campaign_id*/) => {
  const dispatch = useDispatch();

  const { hideModal } = useModal(DELETE_MODAL);

  const deleteHandler = useCallback((pollSessions, campaign_id, getNextPage) => {
    dispatch(deleteSessions({
      sessions: pollSessions.idsToDeleteArr,
      campaign_id,
      getNextPage,
    }));
    /*pollSessions.idsToDeleteArr.forEach((session) => {
      dispatch(deleteSession({ campaign_id, poll_session_id: session }));
    });*/

    // getNextPage(0);

    hideModal();
  },
  [hideModal, dispatch]);

  const deleteDeviceHandler = useCallback((pollSessions, deviceId, getNextPage) => {
    dispatch(deleteSessions({
      sessions: pollSessions,
      deviceId,
      getNextPage,
    }));
    /*pollSessions.forEach((session) => {
      dispatch(deleteSessionDevices({ id: deviceId, poll_session_id: session }));
    });*/

    // getNextPage(0);

    hideModal();
  },
  [hideModal, dispatch]);

  return {
    actions: {
      hideModal,
      deleteHandler,
      deleteDeviceHandler,
    },
  };
};

export default useDeleteModal;
