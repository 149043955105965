import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import PieChart from '../../../components/PieChart';
import PieChartLegendSimple from '../../../components/PieChartLegendSimple';
import Frame from '../../../components/Frame';
import tr from '../../../utils/translate';
import { getArraySum } from '../../../helpers/arrayHelpers';

import './styles.scss';

const formatChartData = (data) => ({
  labels: _map(data, 'label'),
  values: _map(data, 'count'),
  percents: _map(data, 'percents'),
});

const colors = ['#A1C7EB', '#EB855B', '#491A5B', '#F5D281'];

const PredictedGenderChart = ({ chartData }) => {
  const data = formatChartData(chartData);
  const generatedLegends = () => {
    const dataSum = getArraySum(data.values);
    if (!dataSum) {
      return null;
    }
    return data.values.map((value, index) => {
      const percentValue = data.percents[index] || 0;
      return <PieChartLegendSimple key={index} title={data.labels[index]} subtitle={`${value} ${tr('dispenses')}`} badgeValue={`${percentValue}%`} badgeColorCode={colors[index]} />;
    });
  };

  return (
    <div className="predicted-geneder-wrapper">
      <h3>{tr('Gender')}</h3>
      <div className="predicted-geneder-chart-wrapper">
        <div>
          <PieChart chartData={data.values} labels={data.labels} colors={colors} />
        </div>
        <div className="predicted-pie-chart-legend">
          {generatedLegends()}
        </div>
      </div>
    </div>
  );
};

PredictedGenderChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    count: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })),
  header: PropTypes.node,
};

PredictedGenderChart.defaultProps = {
  chartData: [],
  header: null,
};

export default PredictedGenderChart;
