import React from 'react';
import { Link } from 'react-router-dom';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import FormText from '../common/FormText';
import validate from '../SignUp/validate';
import { SIGN_IN_ROUTE, SIGN_UP_ROUTE } from '../../../../constants/routes';
import ReduxForm from '../../../../components/ReduxForm';
import tr from '../../../../utils/translate';
import FormWrap from '../common/FormWrap';
import { AUTH } from '../../../../constants/forms';
import useForgotPassword from './hooks';

const ForgotPassword = () => {
  const { actions: { forgotPassword }, data: { isLoading } } = useForgotPassword();

  return (
    <FormWrap title={tr('Recover')} subtitle={tr('Enter email for your Odore account')}>
      <ReduxForm
        form={AUTH.FORGOT_PASSWORD_FORM}
        validate={validate}
        formData={{
          onSubmit: forgotPassword,
        }}
      >
        <Input
          name="email"
          prependFieldLabel={tr('Email address')}
          className="pd-b-15"
        />

        <div className="pd-t-4 mg-b-30">
          <Button
            type="submit"
            className="btn-block"
            status={isLoading ? 'disabled' : 'active'}
            disabled={isLoading}
          >
            {tr('Send link to email')}
          </Button>
        </div>

        <div className="text-center">
          <FormText>
            <Link to={SIGN_IN_ROUTE}>{tr('Sign In')}</Link>
            &nbsp;or&nbsp;
            <Link to={SIGN_UP_ROUTE}>{tr('Sign Up')}</Link>
          </FormText>
        </div>
      </ReduxForm>
    </FormWrap>
  );
};

export default ForgotPassword;
