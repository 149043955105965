import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import AUTH from '../../modules/auth/services/types';
import { tokenRefresh } from '../../modules/auth/services/actions';
import createLoadingSelector from '../../selectors/loadingSelector';

import Routes from '../components';

const selector = createSelector(
  createLoadingSelector(AUTH.TOKEN_REFRESH.base),
  (state) => state.auth.isAuthorized,
  (state) => state.account.accountDetails.access_level,
  (isLoading, isAuthorized, access_level) => ({
    isLoading,
    isAuthorized,
    access_level,
  }),
);

const mapDispatchToProps = {
  tokenRefresh,
};

export default connect(selector, mapDispatchToProps)(Routes);
