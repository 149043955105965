import React from 'react';
import PropTypes from 'prop-types';
import tr from '../../../../utils/translate';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import ReduxForm from '../../../../components/ReduxForm';
import Input from '../../../../components/Input';
import {
  InstagramLogo, FacebookLogo, WechatLogo, LineLogo, SnapchatLogoIcon, SnapchatLogoText,
} from '../../../../components/Icons/social';
import { BackarrowIcon } from '../../../../components/Icons';
import SuggestOtherModal from '../SuggestOtherModal';
import useSocialMediaInfo from './hooks';
import './styles.scss';

const socialMediaList = {
  instagram: {
    icon: <InstagramLogo />,
  },
  facebook: {
    icon: <FacebookLogo />,
  },
  snapchat: {
    icon: <div className="snapchat-form-logo">
      <div className="mg-r-10"><SnapchatLogoIcon /></div>
      <SnapchatLogoText />
          </div>,
  },
  wechat: {
    icon: <WechatLogo />,
  },
  line: {
    icon: <LineLogo />,
  },
};

const SocialMediaInfo = ({ data: { id } }) => {
  const {
    data: {
      isLoading,
      isLoadingSubmit,
      isSocialLoadig,
      campaign,
      social_networks,
      facebookAccounts,
    },
    actions: {
      goToBack,
      submitHandler,
      submitSuggestOther,
      showSuggestOther,
    },
  } = useSocialMediaInfo(id);

  if ((id && !campaign && !social_networks) || isSocialLoadig || isLoading) {
    return <Loader size="md" />;
  }

  const disabled = {
    facebook: !facebookAccounts?.length,
  };

  const selected = {};

  // eslint-disable-next-line no-return-assign
  social_networks.map((item) => selected[item.slug] = disabled[item.slug] ? false : item.is_included);

  return (
    <>

      <ReduxForm
        enableReinitialize
        keepDirtyOnReinitialize
        form="COMPAIGN_SOCIAL_MEDIA_FORM"
        formData={{
          onSubmit: submitHandler,
          initialValues: {
            social_networks: selected,
          },
        }}
      >

        <div className="campaign-social-info-form pd-x-25 pd-y-50">

          <h3 className="cadf-title mg-b-40">{tr('Social media channels')}</h3>

          <div className="statistic-description">

            {tr('Please select the channels that you wish to use. We adapt the experiences for each relevant channel.')}

          </div>

          <div className="checkbox-wrapper">

            {social_networks.map(({ slug }) => (
              <Input
                type="checkbox"
                key={slug}
                id={`social_networks.${slug}`}
                name={`social_networks.${slug}`}
                appendFieldLabel={socialMediaList[slug].icon}
                className="pd-b-15"
                disabled={disabled[slug]}
              />
            ))}

          </div>

          <Input
            type="hidden"
            name="suggest_other"
            className="hidden-input"
          />

          <div className="statistic-description pd-b-15">{tr('or')}</div>

          <div className="suggest-button">

            <Button color="light" className="" onClick={showSuggestOther}>

              {tr('Suggest other')}

            </Button>

          </div>

        </div>

        <div className="modal-footer">

          <Button color="light" className="mg-r-30" onClick={goToBack}>

            <BackarrowIcon />

            {tr('Back')}

          </Button>

          <Button
            type="submit"
            disabled={isLoadingSubmit}
          >

            {tr('Next')}

          </Button>

        </div>

      </ReduxForm>

      <SuggestOtherModal campaignId={campaign.id} category="social_network" />

    </>
  );
};

SocialMediaInfo.defaultProps = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }),
};

SocialMediaInfo.defaultProps = {
  data: {},
};

export default SocialMediaInfo;
