import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import createLoadingSelector from '../../../../selectors/loadingSelector';
import CAMPAIGNS from '../../services/types';
import { validateDomain as validateDomainAction } from '../../services/actions';

const selector = createSelector(
  createLoadingSelector(CAMPAIGNS.VALIDATE_DOMAIN.base),
  (isLoading ) => ({
    isLoading,
  }),
);

const useValidateDomain = (id) => {
  const dispatch = useDispatch();

  const validateDomain = useCallback((values) => {
    const { code } = values;
    dispatch(validateDomainAction(id, {code: code.replace(/ /g,'')}));
  }, [dispatch, id]);

  return {
    actions: {
      validateDomain,
    },
    data: useSelector(selector),
  };
};

export default useValidateDomain;
