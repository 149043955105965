import React from 'react';
import PropTypes from 'prop-types';
import _filter from 'lodash/filter';
import tr from '../../../../utils/translate';
import { generateSelect } from '../../../../helpers/arrayHelpers';
import { ROLE_COMPANY_ADMIN, ROLE_SUPERADMIN } from '../../../../constants/roles';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import DatePicker from '../../../../components/DatePicker';
import ReduxForm from '../../../../components/ReduxForm';
import { CalendarIcon, BackarrowIcon } from '../../../../components/Icons';
import { ONLINE_EMAIL_CAMPAIGN, DEVICE_CAMPAIGN } from '../../../../constants/campaigns';
import useCampaignDetailsForm from './hooks';
import validate from './validate';
import './styles.scss';
import { formatBeforeSend } from '../../../../utils/dates';
import moment from 'moment';

// const required = (value) => (value ? undefined : 'Required');

const DetailsForm = ({ data: { id } }) => {
  const {
    data: {
      isLoadingData,
      isLoadingSubmit,
      campaign,
      access_level,
      brands,
      departments,
      activeBrand,
      activeDepartment,
      step,
      campaign_type,
      account,
    },
    actions: {
      createCampaign,
      editCampaign,
      handleBrandChange,
      goToPrevStep,
    },
    selectedBrand,
  } = useCampaignDetailsForm(id);

  if ((id && !campaign) || isLoadingData) return <Loader size="md" />;

  const nameField = (
    <div className="mg-b-30">
      <h4 className="cdf-subtitle mg-b-10">
        {tr('Please tell us a bit more about your campaign to get started')}
      </h4>

      <Input
        name="name"
        autoComplete="off"
        prependFieldLabel={tr('Name of your campaign')}
      />
    </div>
  );

  const emailSenderAddress = (
    <div className="mg-b-30 mg-t-30">
      <h4 className="cdf-subtitle mg-b-10">
        {tr('Please provide from email info')}
      </h4>
      <div className="row mg-b-30">
        <Input
          autoComplete="off"
          name="direct_mail.from_email_address"
          prependFieldLabel={tr('From email address')}
          className="col"
        />
        <Input
          autoComplete="off"
          name="direct_mail.email_subject"
          prependFieldLabel={tr('Subject')}
          className="col"
        />
      </div>

    </div>
  );

  const descriptionField = (
    <div className="mg-b-30">
      <h4 className="cdf-subtitle mg-b-10">
        {tr('Please tell us a little more information about your campaign')}
      </h4>

      <Input
        name="description"
        placeholder={tr('Write a description')}
        autoComplete="off"
        rows={1}
        type="textarea"
      />
    </div>
  );

  const getMinDate = () => {
    const today = moment();
    const tomorrow = moment(today);
    return ((campaign && campaign.type_is === DEVICE_CAMPAIGN) || (campaign_type === DEVICE_CAMPAIGN)) ? today.toDate() : tomorrow.toDate();
  };

  const dateFields = (
    <div>
      <h4 className="cdf-subtitle mg-b-10">
        {tr('How long do you want to run your campaign for?')}
      </h4>
      <div className="row">
        <div className="col-md-6">
          <DatePicker
            name="date_start"
            dateForm={((campaign && campaign.type_is === ONLINE_EMAIL_CAMPAIGN) || (campaign_type === ONLINE_EMAIL_CAMPAIGN)) ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}
            {...((campaign && campaign.type_is === ONLINE_EMAIL_CAMPAIGN) || (campaign_type === ONLINE_EMAIL_CAMPAIGN)) ? { showTimeSelect: true } : {}}
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            fieldLabel={tr('Start date')}
            append={<CalendarIcon />}
            minDate={getMinDate()}
          />
        </div>
        <div className="col-md-6">
          <DatePicker
            name="date_end"
            // timeFormat="HH:mm"
            {...((campaign && campaign.type_is === ONLINE_EMAIL_CAMPAIGN) || (campaign_type === ONLINE_EMAIL_CAMPAIGN)) ? { showTimeSelect: true } : {}}
            timeFormat="HH:mm"
            timeIntervals={30}
            timeCaption="Time"
            dateForm={((campaign && campaign.type_is === ONLINE_EMAIL_CAMPAIGN) || (campaign_type === ONLINE_EMAIL_CAMPAIGN)) ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}
            fieldLabel={tr('End date')}
            append={<CalendarIcon />}
            minDate={getMinDate()}
          />
        </div>
      </div>
    </div>
  );

  const connectionFields = () => {
    if (![ROLE_SUPERADMIN, ROLE_COMPANY_ADMIN].includes(access_level)) {
      return (
        <>
          <Input type="hidden" name="company" />
          <Input type="hidden" name="department" />
        </>
      );
    }
    const brandsList = generateSelect(brands, 'name');
    const departmentSelect = generateSelect(departments || [], 'name');
    return (
      <div className="row mg-b-30">
        {access_level === ROLE_SUPERADMIN ? (
          <Select
            disabled={!!activeBrand || (campaign && campaign.company)}
            fieldLabel={tr('Select brand')}
            classNameWrapper="col"
            name="company"
            options={brandsList}
            onChange={(event) => { handleBrandChange(event.target.value); }}
          />
        )
          : <Input type="hidden" name="company" />}

        <Select
          disabled={!!activeDepartment || (access_level === ROLE_SUPERADMIN && !selectedBrand)}
          fieldLabel={tr('Select department')}
          classNameWrapper="col"
          name="department"
          options={departmentSelect}
        />
      </div>
    );
  };
  return (
    <ReduxForm
      enableReinitialize
      form="COMPAIGN_DETAILS_FORM"
      validate={validate}
      autoComplete="off"
      formData={{
        onSubmit: id ? editCampaign : createCampaign,
        initialValues: campaign ? {
          name: campaign.name,
          date_start: campaign.date_start && formatBeforeSend(campaign.date_start, 'YYYY-MM-DD HH:mm'),
          date_end: campaign.date_end && formatBeforeSend(campaign.date_end, 'YYYY-MM-DD HH:mm'),
          description: campaign.description,
          company: campaign.company && campaign.company.id,
          department: campaign.department && campaign.department.id,
          type_is: campaign.type_is,
          ...campaign.direct_mail && {
            direct_mail: {
              from_email_address: campaign.direct_mail.from_email_address,
              email_subject: campaign.direct_mail.email_subject,
            },
          },
        } : {
          company: activeBrand || (account.company && account.company.id),
          department: activeDepartment || (account.department && account.department.id),
          type_is: campaign_type,
        },
      }}
    >
      <div className="compaign-details-form pd-x-25 pd-y-50">
        <h3 className="cdf-title mg-b-40">{tr('Campaign Details')}</h3>
        {nameField}
        {connectionFields()}
        {descriptionField}
        {dateFields}
        {(campaign_type === ONLINE_EMAIL_CAMPAIGN || campaign && campaign.type_is === ONLINE_EMAIL_CAMPAIGN) && emailSenderAddress}
        <Input
          type="hidden"
          disabled
          name="type_is"
        />
      </div>
      <div className="modal-footer">
        {step > 0 && (
          <Button color="light" className="mg-r-30" onClick={goToPrevStep} disabled={!!campaign}>
            <BackarrowIcon />
            {tr('Back')}
          </Button>
        )}
        <Button
          type="submit"
          disabled={isLoadingSubmit}
        >
          {tr('Next')}
        </Button>
      </div>
    </ReduxForm>
  );
};

DetailsForm.defaultProps = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }),
};

DetailsForm.defaultProps = {
  data: {},
};

export default DetailsForm;
