import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import { DEACTIVATE_DEPARTMENT_MODAL } from '../../../constants/modals';
import tr from '../../../utils/translate';
import useBrandStatusModal from './hooks';
import './styles.scss';

const BrandStatusModal = () => (
  <Modal name={DEACTIVATE_DEPARTMENT_MODAL} size="lg">
    {({ handleHide, ...initialValues }) => (
      <BrandStatusModalContent values={initialValues} handleHide={handleHide} />
    )}
  </Modal>
);

const BrandStatusModalContent = ({ values, handleHide }) => {
  const { actions: { handleDepartmentStatusChange }, data: { isLoading, brand } } = useBrandStatusModal();
  return (
    <>
      <div className="modal-body">
        <div className="reboot-modal-body">
          <div className="reboot-modal-body__text-wrap">
            {`${tr(`Do you want to ${values.is_active ? 'deactivate' : 'activate'} `)}${values.name} department?`}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="reboot-modal-footer">
          <div className="reboot-modal-footer__cancel-col">
            <Button color="light" onClick={handleHide}>{tr('Cancel')}</Button>
          </div>
          <div className="reboot-modal-footer__submit-col">
            <Button
              onClick={() => { handleDepartmentStatusChange(values.id, values.is_active); }}
              className="btn-block"
              status={isLoading ? 'disabled' : 'active'}
              disabled={isLoading}
            >
              {tr(`Yes, ${values.is_active ? 'deactivate' : 'activate'}`)}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandStatusModal;
