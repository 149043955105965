import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Frame from '../Frame';
import Loader from '../Loader';
import Tooltip from '../Tooltip';
import Tabs from '../Tabs';
import TableRow from './TableRow';
import './style.scss';
import Pagination from '../Pagination';

const Table = (props) => {
  const {
    tabs,
    data,
    hover,
    header,
    footer,
    loading,
    caption,
    tooltip,
    columns,
    className,
    classNameWrapper,
    responsive,
    onSelectTab,
    expandRenderer,
    expandedOnLoad,
    customHeaderAdd,
    deleteSessionAdd,
    deleteSessionHandler,
    checkedList,
    pagination,
  } = props;

  const isNeedPagination = pagination && (pagination.count > 20 || pagination?.hasOwnProperty('next_page'));

  const isTypeArrows = isNeedPagination && pagination?.hasOwnProperty('next_page');

  const renderTable = (dataTable) => {
    if (loading || !dataTable) {
      return <div className="table-content-empty"><Loader /></div>;
    }

    const hasExpand = !!expandRenderer;
    const renderHead = () => (
      <thead>
        <tr>
          {deleteSessionAdd && <th className="table-col-expand" key="deleteSessionAdd" />}
          {hasExpand && <th className="table-col-expand" key="expand" />}
          {columns.map(({ name, className: classNameCol, sorting }, index) => (
            <th className={classNameCol} key={index} onClick={sorting}>{name}</th>
          ))}
        </tr>
      </thead>
    );

    return (
      <div className="table-with-pagination">
        <div className={cn({ 'scroll-content': isNeedPagination || true })}>
          <table className={cn('table', className, { 'table-hover': hover, 'table-responsive-sm': responsive })}>
            {renderHead()}
            <tbody>
              {dataTable.map((dataRow, i) => (
                <TableRow
                  key={dataRow.id || i}
                  expand={hasExpand}
                  columns={columns}
                  data={dataRow}
                  expandRenderer={expandRenderer}
                  expandedOnLoad={expandedOnLoad}
                  deleteSessionAdd={deleteSessionAdd}
                  deleteSessionHandler={deleteSessionHandler}
                  checkedList={checkedList}
                />
              ))}
              {/*isNeedPagination && <tr className="scroll-content-bottom" />*/}
            </tbody>
          </table>
        </div>
        { isNeedPagination && <Pagination data={pagination} isTypeArrows={isTypeArrows} /> }
      </div>
    );
  };
  const renderTabs = () => (
    <Tabs onChange={onSelectTab} customHeaderAdd={customHeaderAdd} headerAlwaysShow>
      {tabs.map((tab, index) => (
        <Tabs.Pane
          key={index}
          name={tab.key}
          label={tab.name}
        >
          {renderTable(data[tab.key])}
        </Tabs.Pane>
      ))}
    </Tabs>
  );

  return (
    <Frame
      className={cn('table-wrapper', classNameWrapper)}
      classNameHeader="mg-b-0 table-header"
      header={!tabs && (caption || header) && (
        <>
          {caption && <div className="table-header__caption tx-normal">{caption}</div>}
          {header && <div className="table-header__body">{header}</div>}
          {tooltip && <div className="table-header__tooltip"><Tooltip label={tooltip}>#</Tooltip></div>}
        </>
      )}
      footer={footer}
    >
      {tabs ? renderTabs() : renderTable(data)}
    </Frame>
  );
};

Table.propTypes = {
  tooltip: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
  hover: PropTypes.bool,
  loading: PropTypes.bool,
  responsive: PropTypes.bool,
  caption: PropTypes.string,
  className: PropTypes.string,
  classNameWrapper: PropTypes.string,
  onSelectTab: PropTypes.func,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.shape(),
  ]).isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
    key: PropTypes.string.isRequired,
  })),
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    className: PropTypes.string,
    render: PropTypes.func,
    sorting: PropTypes.func,
  })).isRequired,
  expandRenderer: PropTypes.func,
  expandedOnLoad: PropTypes.bool,
  customHeaderAdd: PropTypes.node,
};

Table.defaultProps = {
  hover: false,
  responsive: false,
  loading: false,
  tooltip: null,
  header: null,
  footer: null,
  caption: null,
  className: null,
  classNameWrapper: null,
  onSelectTab: null,
  tabs: null,
  expandRenderer: null,
  expandedOnLoad: false,
};

export default Table;
