import React, { useState } from 'react';
import _find from 'lodash/find';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import tr from '../../../../utils/translate';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import { COPY_SOCIAL_LINK_MODAL } from '../../../../constants/modals';

const CopySocialLinkModal = () => {
  const [selectedMedia, setSelectedMedia] = useState(null);

  return (
    <Modal name={COPY_SOCIAL_LINK_MODAL} size="lg" title="Copy link">
      {({ handleHide, ...initialValues }) => {
        const { social_networks = [], link } = initialValues;
        const options = social_networks.map(({ slug, name }) => ({ label: name, value: slug }));
        const activatedList = social_networks.filter(({ is_included }) => is_included);
        const defaultSocial = activatedList.length === 1 ? activatedList[0].slug : null;
        const currentValue = selectedMedia
          ? _find(options, { value: selectedMedia })
          : _find(options, { value: defaultSocial });

        return (
          <>
            <div className="modal-body">
              <div className="reboot-modal-body">
                <div className="reboot-modal-body__text-wrap">
                  <Select
                    className="basic-single pd-b-20"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={false}
                    name="color"
                    options={options}
                    value={currentValue}
                    onChange={({ value }) => setSelectedMedia(value)}
                  />

                  <a href={`${link}${currentValue ? `?soc=${currentValue.value}` : ''}`} target="_blank">
                    {`${link}${currentValue ? `?soc=${currentValue.value}` : ''}`}
                  </a>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="reboot-modal-footer">
                <div className="reboot-modal-footer__cancel-col">
                  <Button color="light" onClick={handleHide}>{tr('Close')}</Button>
                  <CopyToClipboard
                    text={`${link}${currentValue ? `?soc=${currentValue.value}` : ''}`}
                    onCopy={() => toast.success('Link copied to clipboard')}
                  >
                    <Button className="mg-l-20">{tr('Copy')}</Button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </Modal>
  );
};

export default CopySocialLinkModal;
