import React from 'react';
import PropTypes from 'prop-types';
import Widget from '../../../../components/Widget';
import {
  FollowUpsIcon,
  LargeClockIcon,
  PieChartIcon,
  BottleIcon,
} from '../../../../components/Icons';
import tr from '../../../../utils/translate';
import { transformDuration } from '../../../../utils/dates';

const OverviewBadges = ({ stats, stats_visibility }) => (
  <div className="info-badges row mg-b-30">
    {stats_visibility.samples
      && (
      <div className="col">
        <Widget
          color="yellow"
          icon={<BottleIcon />}
          title={tr('Samples distributed')}
          description={`${stats.samplesToday} today`}
          subtitle={`${stats.samples} ${tr('samples')}`}
        />
      </div>
      )}
    {stats_visibility.engagement_time
      && (
      <div className="col">
        <Widget
          color="green"
          icon={<LargeClockIcon />}
          title={tr('Engagement time')}
          subtitle={transformDuration(stats.engagement)}
          description={`${transformDuration(stats.engagementToday)} today`}
        />
      </div>
      )}
    {stats_visibility.click_through_rate
      && (
      <div className="col">
        <Widget
          color="blue"
          icon={<FollowUpsIcon />}
          title={tr('Email click through rate')}
          subtitle={tr('No data')}
        />
      </div>
      )}
    {stats_visibility.bounce_rate
      && (
      <div className="col">
        <Widget
          color="red"
          icon={<PieChartIcon />}
          title={tr('Email bounce rate')}
          subtitle={tr('No data')}
        />
      </div>
      )}
  </div>
);

OverviewBadges.propTypes = {
  stats: PropTypes.shape({
    samples: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    engagement: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    samplesToday: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    engagementToday: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    through_rate: PropTypes.string,
    bounce_rate: PropTypes.string,
  }).isRequired,
};

export default OverviewBadges;
