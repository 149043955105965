import React from 'react';
import PropTypes from 'prop-types';
import Widget from '../../../../components/Widget';
import {
  FollowUpsIcon, EmailOpen, EmailClicked, EmailBounce, EmailUnsub,
} from '../../../../components/Icons';
import tr from '../../../../utils/translate';

const MailInfoBadges = ({ stats, showUnsubscribesListModal }) => (
  <div className="info-badges row mg-b-30">
    <div className="col">
      <Widget
        color="cyan"
        icon={<EmailOpen />}
        title={tr('Unique opens')}
        subtitle={`${stats.opened}`}
      />
    </div>
    <div className="col">
      <Widget
        color="dark_blue"
        icon={<EmailClicked />}
        title={tr('Clicked')}
        subtitle={stats.clicked}
      />
    </div>
    <div className="col">
      <Widget
        color="pink"
        icon={<EmailBounce />}
        title={tr('Bounced')}
        subtitle={stats.bounced}
      />
    </div>
    <div className="col" onClick={() => {
      showUnsubscribesListModal({ titleModal: tr(`Unsubscribes list`), id: 1 });
    }}>
      <Widget
        color="violet"
        icon={<EmailUnsub />}
        title={tr('Unsubscribed')}
        subtitle={stats.unsubscribed}
      />
    </div>
  </div>
);

MailInfoBadges.propTypes = {
  stats: PropTypes.shape({
    opened: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    clicked: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    bounced: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    unsubscribed: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
};

export default MailInfoBadges;
