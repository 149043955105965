import React from 'react';

import Modal from '../../../components/Modal';
import tr from '../../../utils/translate';
import { DEPARTMENT_MODAL } from '../../../constants/modals';
import DepartmentForm from '../DepartmentForm';

const DepartmentModal = () => (
  <Modal title={tr('Create team')} name={DEPARTMENT_MODAL} size="sm">
    {({ handleHide, ...initialValues }) => (
      <DepartmentForm data={initialValues} handleHide={handleHide} />
    )}
  </Modal>
);

export default DepartmentModal;
