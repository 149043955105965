import _omit from 'lodash/omit';
import CAMPAIGNS from './types';

const defaultState = {
  list: [],
  details: {},
  editStep: 0,
  associatedDevices: [],
  statistic: {},
  statisticInteractions: {},
  statisticFacebook: {},
  suggestions: {},
  actions: {},
  products: [],
  selected_users: 0,
  stats_visibility: {
    values: {},
    names: {},
  },
  social_networks: [],
  questions: [],
  themes: null,
  unsubscribed_list: [],
  count: null,
  activePage: 1,
  ad_accounts: null,
  fb_pages: {},
  fb_targetings: {},
  ad_campaigns: {},
  account_exists: {},
  audience_size: {},
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case CAMPAIGNS.READ_LIST.SUCCESS:
      return {
        ...state,
        list: payload.res.results,
        count: payload.res.count,
        activePage: payload.req.data.page || 1,
      };

    case CAMPAIGNS.READ_DETAILS.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.res.id]: payload.res,
        },
        list: state.list.map((item) => (item.id === payload.res.id ? payload.res : item)),
      };

    case CAMPAIGNS.CREATE_CAMPAIGN.SUCCESS:
      return {
        ...state,
        list: [
          ...state.list,
          payload.res,
        ],
        editStep: 0,
      };

    case CAMPAIGNS.EDIT_CAMPAIGN.SUCCESS:
      return {
        ...state,
        list: state.list.map((campaign) => (
          campaign.id === payload.res.id ? payload.res : campaign
        )),
        details: {
          ...state.details,
          [payload.res.id]: payload.res,
        },
      };
    case CAMPAIGNS.SET_DEMOGRAPHICS.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.req.params.id]: {
            ...state.details[payload.req.params.id],
            demographic: payload.res,
          },
        },
      };

    case CAMPAIGNS.GET_ASSOCIATED_DEVICES.SUCCESS:
      return {
        ...state,
        associatedDevices: payload.res,
      };

    case CAMPAIGNS.READ_SOCIAL_NETWORKS.SUCCESS:
      return {
        ...state,
        social_networks: payload.res,
      };

    case CAMPAIGNS.CREATE_PRODUCT.SUCCESS:
      const products = state.products.slice();
      products.push({ ...payload.res });
      return {
        ...state,
        products,
      };

    case CAMPAIGNS.UPDATE_PRODUCT.SUCCESS:
      return {
        ...state,
        products: state.products.map(
          (product) => (product.id === payload.res.id
            ? { ...payload.res }
            : product
          ),
        ),
      };

    case CAMPAIGNS.UPDATE_INTERFACE.SUCCESS:
    case CAMPAIGNS.UPDATE_EMAIL_INTERFACE_DATA:
    case CAMPAIGNS.SET_FILTERED_USER_LIST:
    case CAMPAIGNS.UPLOAD_MAIN_EMAIL:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.req.params.id]: {
            ...state.details[payload.req.params.id],
            ...payload.res,
          },
        },
      };
    case CAMPAIGNS.GET_CAMPAIGN_STATISTIC.SUCCESS:
      return {
        ...state,
        statistic: {
          ...state.statistic,
          [payload.req.data.campaign_id]: {
            ...(state.statistic[payload.req.data.campaign_id] || {}),
            ...payload.res,
          },
        },
      };
    case CAMPAIGNS.GET_CAMPAIGN_STATISTIC_INTERACTIONS.SUCCESS:
      return {
        ...state,
        statisticInteractions: {
          ...state.statisticInteractions,
          [payload.req.data.campaign_id]: {
            ...state.statisticInteractions[payload.req.data.campaign_id],
            start: payload.res.start,
            stop: payload.res.stop,
            next_page: payload.res.next_page,
            list: payload.res.results,
            activePage: payload.req.data.page || 0,
          },
        },
      };
    case CAMPAIGNS.GET_STATISTIC_FACEBOOK.SUCCESS:
      return {
        ...state,
        statisticFacebook: {
          ...state.statisticFacebook,
          [payload.req.params.id]: payload.res,
        },
      };

    case CAMPAIGNS.GET_SUGGESTIONS.SUCCESS:
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          [payload.req.params.id]: payload.res,
        },
      };
    case CAMPAIGNS.SEND_FOLLOW_UP_SUGGEST.SUCCESS:
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          [payload.req.data.campaign_id]: [
            ...(state.suggestions[payload.req.data.campaign_id] || []),
            {
              category: 'follow_up',
              category_verbose: 'Follow up',
              ...payload.res,
            },
          ],
        },
      };
    case CAMPAIGNS.GET_ACTIONS.SUCCESS:
      return {
        ...state,
        actions: {
          ...state.actions,
          [payload.req.params.id]: payload.res,
        },
      };
    case CAMPAIGNS.SEND_SUGGESTION.SUCCESS:
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          [payload.req.params.id]: [...(state.suggestions[payload.req.params.id] || []), ...[payload.res]],
        },
      };
    case CAMPAIGNS.FILTER_USER_DATABASE.SUCCESS:
      return {
        ...state,
        selected_users: payload.res.count,
      };
    case CAMPAIGNS.GET_STATISTICS_PROPS.SUCCESS:
      return {
        ...state,
        stats_visibility: {
          ...state.statistic,
          [payload.req.params.id]: payload.res,
        },
      };
    case CAMPAIGNS.GET_PRODUCTS.SUCCESS:
      return {
        ...state,
        products: payload.res,
      };
    case CAMPAIGNS.SET_STATISTICS_PROPS.SUCCESS:
      return {
        ...state,
        editStep: state.editStep + 1,
        stats_visibility: {
          ...state.statistic,
          [payload.req.params.id]: payload.res,
        },
        list: state.list.map((campaign) => (
          campaign.id === payload.res.id ? payload.res : campaign
        )),
        details: {
          ...state.details,
          [payload.res.id]: payload.res,
        },
      };
    case CAMPAIGNS.FIRST_STEP:
      return {
        ...state,
        editStep: 0,
        campaign_type: null,
      };
    case CAMPAIGNS.SET_CAMPAIGN_TYPE:
      return {
        ...state,
        campaign_type: payload.campaign_type,
      };
    case CAMPAIGNS.NEXT_STEP:
      return {
        ...state,
        editStep: state.editStep + 1,
      };
    case CAMPAIGNS.PREV_STEP:
      return {
        ...state,
        editStep: state.editStep > 0 ? state.editStep - 1 : 0,
      };

    case CAMPAIGNS.SET_STEP:
      return {
        ...state,
        editStep: payload.step,
      };
    case CAMPAIGNS.APPROVE_CAMPAIGN.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.req.params.id]: {
            ...state.details[payload.req.params.id],
            status: 'approved',
          },
        },
        actions: {
          ...state.actions,
          [payload.req.params.id]: [
            ...state.actions[payload.req.params.id],
            ...[{
              type_verbose: 'Approve',
              comment: [payload.req.data.comment],
            }],
          ],
        },
      };
    case CAMPAIGNS.REJECT_CAMPAIGN.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.req.params.id]: {
            ...state.details[payload.req.params.id],
            status: 'rejected',
          },
        },
        actions: {
          ...state.actions,
          [payload.req.params.id]: [
            ...state.actions[payload.req.params.id],
            ...[{
              type_verbose: 'Reject',
              comment: [payload.req.data.comment],
            }],
          ],
        },
      };
    case CAMPAIGNS.VALIDATE_DOMAIN.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.req.params.id]: {
            ...state.details[payload.req.params.id],
            domain_validated: true,
          },
        },
      };
    case CAMPAIGNS.ADD_PRODUCT_STOCK.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.req.data.campaign_id]: {
            ...state.details[payload.req.data.campaign_id],
            trays: state.details[payload.req.data.campaign_id].trays.map(
              (tray) => (tray.id === payload.req.params.id
                ? { ...tray, product_stock: payload.res.product_stock }
                : tray
              ),
            ),
          },
        },
      };
    case CAMPAIGNS.GET_FOLLOW_UP_QUESTIONS.SUCCESS:
      return {
        ...state,
        questions: payload.res,
      };
    case CAMPAIGNS.SET_FOLLOW_UP.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.req.params.id]: {
            ...(state.details[payload.req.params.id] || {}),
            follow_up: payload.res,
          },
        },
      };
    case CAMPAIGNS.GET_FOLLOW_UP_STATISTICS.SUCCESS:
      return {
        ...state,
        statistic: {
          ...state.statistic,
          [payload.req.params.id]: {
            ...(state.statistic[payload.req.params.id] || {}),
            follow_up: payload.res,
          },
        },
      };
    case CAMPAIGNS.GET_FOLLOW_UP_THEMES.REQUEST:
      return {
        ...state,
        themes: null,
      };
    case CAMPAIGNS.GET_FOLLOW_UP_THEMES.SUCCESS:
      return {
        ...state,
        themes: payload.res.reduce((res, item) => ({ ...res, [item.id]: item }), {}),
      };
    case CAMPAIGNS.GET_FOLLOW_UP_THEME.SUCCESS:
      return {
        ...state,
        themes: {
          ...state.themes,
          [payload.res.id]: payload.res,
        },
      };
    case CAMPAIGNS.UNSUBSCRIBES_LIST.SUCCESS:
      return {
        ...state,
        unsubscribed_list: [
          ...payload.res
        ],
      };

    case CAMPAIGNS.GET_AD_ACCOUNTS.REQUEST:
      return {
        ...state,
        ad_accounts: [],
      };

    case CAMPAIGNS.GET_AD_ACCOUNTS.SUCCESS:
      return {
        ...state,
        ad_accounts: [
          ...payload.res
        ],
      };

    case CAMPAIGNS.GET_FB_PAGES.REQUEST:
      return {
        ...state,
        fb_pages: {
          ...state.fb_pages,
          [payload.API_CALL.params.id]: [],
        },
      };

    case CAMPAIGNS.GET_FB_PAGES.SUCCESS:
      return {
        ...state,
        fb_pages: {
          ...state.fb_pages,
          [payload.req.params.id]: payload.res
        },
      };

    case CAMPAIGNS.GET_FB_TARGETINGS.REQUEST:
      return {
        ...state,
        fb_targetings: {
          ...state.fb_targetings,
          [payload.API_CALL.params.id]: [],
        },
      };

    case CAMPAIGNS.GET_FB_TARGETINGS.SUCCESS:
      return {
        ...state,
        fb_targetings: {
          ...state.fb_targetings,
          [payload.req.params.id]: payload.res
        },
      };

    case CAMPAIGNS.GET_AD_CAMPAIGN.REQUEST:
    case CAMPAIGNS.GET_AD_CAMPAIGN.FAILURE:
      return {
        ...state,
        ad_campaigns: {
          ...state.ad_campaigns,
          [payload.API_CALL.params.id]: {},
        },
      };

    case CAMPAIGNS.GET_AD_CAMPAIGN.SUCCESS:
      return {
        ...state,
        ad_campaigns: {
          ...state.ad_campaigns,
          [payload.req.params.id]: payload.res
        },
      };

    case CAMPAIGNS.GET_ACCOUNT_EXISTS.REQUEST:
    case CAMPAIGNS.GET_ACCOUNT_EXISTS.FAILURE:
      return {
        ...state,
        account_exists: {
          ...state.account_exists,
          [payload.API_CALL.params.id]: [],
        },
      };

    case CAMPAIGNS.GET_ACCOUNT_EXISTS.SUCCESS:
      return {
        ...state,
        account_exists: {
          ...state.account_exists,
          [payload.req.params.id]: payload.res
        },
      };

    case CAMPAIGNS.GET_REACH_ESTIMATE.SUCCESS:
      return {
        ...state,
        audience_size: payload.res,
      };

    case CAMPAIGNS.CLEAR_REACH_ESTIMATE.base:
      return {
        ...state,
        audience_size: {},
      };

    case CAMPAIGNS.START_FACEBOOK_CAMPAIGN.SUCCESS:
    case CAMPAIGNS.PAUSE_FACEBOOK_CAMPAIGN.SUCCESS:
      return {
        ...state,
        ad_campaigns: {
          ...state.ad_campaigns,
          [payload.res.id]: {
            ...state.ad_campaigns[payload.res.id],
            status: payload.res.status,
            effective_status: payload.res.effective_status,
          }
        },
      };

    case CAMPAIGNS.CLEAR_FACEBOOK_CAMPAIGN.base:
      return {
        ...state,
        ad_accounts: null,
        fb_pages: {},
        fb_targetings: {},
        ad_campaigns: {},
        account_exists: {},
        audience_size: {},
      };

    default:
      return state;
  }
};
