import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  reduxForm,
  propTypes as propTypesReduxForm,
} from 'redux-form';

import compose from '../../utils/compose';

const FormWrapper = (props, ref) => {
  const {
    handleSubmit,
    onSubmit,
    onChange,
    children,
    className,
    autoComplete,
    ...data
  } = props;

  return (
    <Form
      ref={ref}
      className={className}
      onSubmit={onSubmit ? handleSubmit(onSubmit) : () => {}}
      onChange={onChange}
      autoComplete={autoComplete}
    >
      {children instanceof Function ? children(data) : children}
    </Form>
  );
};

// FormWrapper.propTypes = {
//   ...propTypesReduxForm,
//   onSubmit: PropTypes.func,
//   onChange: PropTypes.func,
//   className: PropTypes.string,
//   children: PropTypes.oneOfType([
//     PropTypes.node,
//     PropTypes.func,
//   ]).isRequired,
// };

// FormWrapper.defaultProps = {
//   onSubmit: () => {},
//   onChange: () => {},
//   className: null,
// };

const ReduxForm = (props) => {
  const {
    className,
    children,
    formData,
    autoComplete,
    ...reduxFormData
  } = props;

  const CurrentForm = React.useMemo(() => compose(
    forwardRef,
    reduxForm(reduxFormData),
  )(FormWrapper), []); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <CurrentForm autoComplete={autoComplete} className={className} {...formData}>{children}</CurrentForm>;
};

ReduxForm.propTypes = {
  form: PropTypes.string.isRequired,
  formData: PropTypes.shape(),
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};

ReduxForm.defaultProps = {
  className: null,
  formData: {},
};

export default ReduxForm;
