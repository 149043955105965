import React from 'react';
import Table from '../../../../../components/Table';
import tr from '../../../../../utils/translate';

import { suggestionColumns } from './data';
import useSuggestionTable from './hooks';
import './styles.scss';

const SuggestionsTable = ({ campaignId }) => {
  const {
    data: {
      suggestions,
    },
  } = useSuggestionTable(campaignId);
  if (!suggestions || suggestions.length === 0) return null;

  return (
    <>
      <div className="mg-b-30">
        <Table caption={tr('Suggestions')} columns={suggestionColumns()} data={suggestions || []} />
      </div>
    </>
  );
};

export default SuggestionsTable;
