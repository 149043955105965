import tr from '../../../../utils/translate';

const validate = ({ pincode }) => {
  const errors = {};

  if (!pincode) {
    errors.pincode = tr('Pincode is required.');
  }

  return errors;
};

export default validate;
