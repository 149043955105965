import tr from '../../../../utils/translate';

const validate = ({ 
  brand_name, 
  description, 
  name, 
  tag,
}) => {
  const errors = {};

  if (!brand_name) {
    errors.brand_name = tr('Brand name is required.');
  }

  if (!name) {
    errors.name = tr('Product name is required.');
  }

  if (!tag) {
    errors.tag = tr('Product tag is required.');
  }

  if (description && description.length > 255) {
    errors.description = tr('Description must be no longer than 255 characters');
  }

  return errors;
};

export default validate;
