export const call_to_action_options = [
	{ label: 'Learn More', value: 'LEARN_MORE' },
	{ label: 'Apply Now', value: 'APPLY_NOW' },
	{ label: 'Book Now', value: 'BOOK_TRAVEL' },
	{ label: 'Contact Us', value: 'CONTACT_US' },
	{ label: 'Download', value: 'DOWNLOAD' },
	{ label: 'Get Offer', value: 'GET_OFFER' },
	{ label: 'Get Quote', value: 'GET_QUOTE' },
	{ label: 'Get Showtimes', value: 'GET_SHOWTIMES' },
	{ label: 'Listen Now', value: 'LISTEN_NOW' },
	{ label: 'Get Access', value: 'PAY_TO_ACCESS' },
	{ label: 'Request Time', value: 'REQUEST_TIME' },
	{ label: 'Shop Now', value: 'SHOP_NOW' },
	{ label: 'Sign Up', value: 'SIGN_UP' },
	{ label: 'Subscribe', value: 'SUBSCRIBE' },
	{ label: 'Watch More', value: 'WATCH_MORE' },
	{ label: 'Send WhatsApp Message', value: 'WHATSAPP_MESSAGE' },
];