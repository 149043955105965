import React from 'react';
import Table from '../../../../../components/Table';
import tr from '../../../../../utils/translate';

import { statisticsColumns } from './data';
import { statNames } from '../../../../../constants/common';
import './styles.scss';

const StatMetricTable = ({ campaign }) => {
  const statistics = campaign.stats_visibility || {};
  const statData = Object.keys(statistics).map((key) => statistics[key] && statNames[key]);
  return (
    <div className="mg-b-30 statistics_info_table">
      <Table caption={tr('Statistics data')} columns={statisticsColumns} data={[{ metric: statData }]} />
    </div>
  );
};

export default StatMetricTable;
