import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import tr from '../../../../utils/translate';
import Table from '../../../../components/Table';
import { ACCESS_OWNER } from '../../../../constants/api';
import { accountColumns } from './columns';

const AccountInfo = ({
  showResetPasswordModal,
  email,
  firstName,
  lastName,
  country,
  registrationDate,
  countries,
}) => {
  const data = [{
    firstName,
    lastName,
    email,
    country: _get(countries.find((itemX) => itemX.id === country), 'label', ''),
    registrationDate,
  }];

  return (
    <div className="account-info">
      <Table className="mg-b-0" caption={tr('Information')} columns={accountColumns(showResetPasswordModal)} data={data} />
    </div>
  );
};

AccountInfo.defaultProps = {
  email: null,
};

AccountInfo.propTypes = {
  showResetPasswordModal: PropTypes.func.isRequired,
  email: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default React.memo(AccountInfo);
