import React from 'react';
import { Link } from 'react-router-dom';

import tr from '../../../../utils/translate';
import { parseRoutePath } from '../../../../utils/routingHelpers';
import { CAMPAIGNS_DETAILS_ROUTE } from '../../../../constants/routes';
import { formatDateNoUtc } from '../../../../utils/dates';

const columns = [
  {
    name: tr('Campaign name'),
    key: 'name',
    render: ({ name, id }) => (name && id
      ? (<Link className="cell-link" to={parseRoutePath(CAMPAIGNS_DETAILS_ROUTE, { id })}>{name}</Link>)
      : tr('No data')
    ),
  },
  {
    name: tr('Start date'),
    key: 'start_date',
    render: ({ date_start }) => (date_start ? formatDateNoUtc(date_start) : tr('No data')),
  },
  {
    name: tr('End date'),
    key: 'end_date',
    render: ({ date_end }) => (date_end ? formatDateNoUtc(date_end) : tr('No data')),
  },
  {
    name: tr('Current status'),
    key: 'status',
    render: ({ status }) => status && (
      <span
        className={`cell-status cell-status_${status.split(' ').join('-').toLowerCase()}`}
      >
        {status.replace('_', ' ')}
      </span>
    ),
  },
];

export default columns;
