import { all, fork } from 'redux-saga/effects';

import api from './apiSaga';
import authSagas from '../auth/services/sagas';
import accountSagas from '../account/services/sagas';
import campaignsSagas from '../campaigns/services/sagas';
import devicesSagas from '../devices/services/sagas';
import appLayoutSagas from '../layout/AppLayout/services/sagas';
import brandSagas from '../brand/services/sagas';
import departmentSagas from '../department/services/sagas';
import employeeSagas from '../employees/services/sagas';
import userSagas from '../users/services/sagas';
import productSagas from '../product/services/sagas';

function* rootSaga() {
  yield all([
    fork(api),
    fork(authSagas),
    fork(accountSagas),
    fork(campaignsSagas),
    fork(devicesSagas),
    fork(appLayoutSagas),
    fork(brandSagas),
    fork(departmentSagas),
    fork(employeeSagas),
    fork(userSagas),
    fork(productSagas),
  ]);
}

export default rootSaga;
