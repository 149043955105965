import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import SimpleButton from '../../../../components/SimpleButton';
import tr from '../../../../utils/translate';
import { parseRoutePath } from '../../../../utils/routingHelpers';
import { CAMPAIGNS_DETAILS_ROUTE } from '../../../../constants/routes';
import { formatDateNoUtc } from '../../../../utils/dates';
import { ROLE_COMPANY_ADMIN, ROLE_SUPERADMIN } from '../../../../constants/roles';
import {
  CAMPAIGN_PARTLY_CREATED, CAMPAIGN_CREATED, CAMPAIGN_REJECTED, CAMPAIGN_AWAITING_APPROVAL,
} from '../../../../constants/campaigns';
import {
  DevicesIcon, MailIcon, SocialTargetingIcon, WebsiteIcon,
} from '../../../../components/Icons';

const typeIconsDictionary = {
  1: <DevicesIcon />,
  2: <SocialTargetingIcon />,
  3: <MailIcon />,
  4: <WebsiteIcon />,
};

const typeTooltipDictionary = {
  1: tr('Device campaign'),
  2: tr('Social media targeting'),
  3: tr('Email targeting'),
  4: tr('Website embedding'),
};

const createColumns = (showModal, access_level, handleSorting, sortBy) => [
  {
    name: tr('Campaign name'),
    key: 'name',
    className: `table-column-sort ${sortBy.key === 'name' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('name'),
    render: ({ id, name, type_is }) => (
      <div>
        <span data-tip={typeTooltipDictionary[type_is]} className="cell-icon">{typeIconsDictionary[type_is]}</span>
        <Link className="cell-link mg-l-10" to={parseRoutePath(CAMPAIGNS_DETAILS_ROUTE, { id })}>{name}</Link>
      </div>
    ),
  },
  {
    name: tr('Start date'),
    key: 'date_start',
    className: `table-column-sort ${sortBy.key === 'date_start' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('date_start'),
    render: ({ date_start }) => (date_start ? formatDateNoUtc(date_start) : tr('No data')),
  },
  {
    name: tr('End date'),
    key: 'date_end',
    className: `table-column-sort ${sortBy.key === 'date_end' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('date_end'),
    render: ({ date_end }) => (date_end ? formatDateNoUtc(date_end) : tr('No data')),
  },
  ...(access_level === ROLE_SUPERADMIN ? [{
    name: tr('Brand'),
    key: 'company',
    className: `table-column-sort ${sortBy.key === 'company' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('company'),
    render: ({ company }) => company && (<span className={cn('company-info-cell', { 'company-inactive': !company.is_active })}>{company.name}</span>),
  }] : []),
  ...([ROLE_COMPANY_ADMIN, ROLE_SUPERADMIN].includes(access_level) ? [{
    name: tr('Team'),
    key: 'department',
    className: `table-column-sort ${sortBy.key === 'department' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('department'),
    render: ({ department }) => department && (<span className={cn('department-info-cell', { 'dep-inactive': !department.is_active })}>{department.name}</span>),
  }] : []),
  {
    name: tr('Current Status'),
    key: 'status',
    className: `table-column-sort ${sortBy.key === 'status' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('status'),
    render: ({ status }) => (status
      ? (
        <span
          className={`cell-status cell-status_${status.split(' ').join('-').toLowerCase()}`}
        >
          {status.replace('_', ' ')}
        </span>
      )
      : tr('No data')
    ),
  },
  {
    name: tr('Created date'),
    key: 'date_created',
    className: `table-column-sort ${sortBy.key === 'date_created' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('date_created'),
    render: ({ date_created }) => (date_created ? formatDateNoUtc(date_created) : tr('No data')),
  },
  {
    name: tr('Options'),
    key: 'options',
    render: ({
      id, name, company = {}, department = {}, status,
    }) => (
        (company && company.is_active) && (department === null || (department !== null && department.is_active)) && ([CAMPAIGN_PARTLY_CREATED, CAMPAIGN_CREATED, CAMPAIGN_REJECTED, CAMPAIGN_AWAITING_APPROVAL].includes(status))
        && (
          <SimpleButton
            className="cell-link"
            onClick={() => { showModal({ titleModal: tr(`Edit campaign ${name}`), id }); }}
          >
            {tr('Edit')}
          </SimpleButton>
        )
      ),
  },
];

export default createColumns;
