import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import useModal from '../../../utils/useModal';
import createLoadingSelector from '../../../selectors/loadingSelector';
import BRAND from '../services/types';
import { INVITE_BRAND_MODAL } from '../../../constants/modals';
import { inviteBrand as inviteBrandAction } from '../services/actions';

const selector = createSelector(
  createLoadingSelector(BRAND.INVITE_BRAND.base),
  (isLoading) => ({
    isLoading,
  }),
);

const useInviteModal = () => {
  const dispatch = useDispatch();

  const inviteBrand = useCallback((values) => {
    dispatch(inviteBrandAction({ ...values }));
  }, [dispatch]);

  const { hideModal } = useModal(INVITE_BRAND_MODAL);

  return {
    actions: {
      hideModal,
      inviteBrand,
    },
    data: useSelector(selector),
  };
};

export default useInviteModal;
