import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';

import Table from '../../../../components/Table';
import { parseRoutePath } from '../../../../utils/routingHelpers';
import Checkbox from '../../../../components/Checkbox';
import { DEVICES_INFO_ROUTE } from '../../../../constants/routes';
import tr from '../../../../utils/translate';

const TableDevices = ({
  input, options, disable, ...props
}) => {
  const columns = [
    {
      name: tr('Label'),
      key: 'name',
      render: ({ serial_number, name }) => (
        <>
          <Checkbox
            disabled={disable}
            name={serial_number.toString()}
            label={name}
            checked={input.value && input.value.includes(serial_number)}
            onChange={({ target: { checked } }) => {
              const values = [...input.value];
              if (checked) {
                values.push(serial_number);
              } else {
                values.splice(values.indexOf(serial_number), 1);
              }

              return input.onChange(values);
            }}
          />

        </>
      ),
    },
    {
      name: tr('Device id'),
      key: 'serial_number',
      render: ({ serial_number: id }) => (
        <Link className="cell-link" to={parseRoutePath(DEVICES_INFO_ROUTE, { id })}>{id}</Link>
      ),
    },
    {
      name: tr('Location'),
      key: 'location',
    },
  ];

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Table columns={columns} data={options} {...props} />
  );
};

TableDevices.propTypes = {
  input: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.number,
    PropTypes.array,
  ])).isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

// eslint-disable-next-line react/jsx-props-no-spreading
const ReduxTableDevices = (props) => <Field {...props} component={TableDevices} />;

ReduxTableDevices.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ReduxTableDevices;
