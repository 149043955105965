import React from 'react';
import tr from '../../../../utils/translate';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import { TrashIcon } from '../../../../components/Icons';
import ReduxForm from '../../../../components/ReduxForm';
import { SUGGEST_OTHER_QUESTION_MODAL } from '../../../../constants/modals';
import useSuggestOther from './hook';
import validate from './validate';
import './styles.scss';

const questionTypes = [
  { value: 'yes_no', label: 'Yes/No' },
  { value: 'rating', label: 'Rate 1-10' },
  { value: 'multiple_choice', label: 'Multiple' },
  { value: 'dropdown', label: 'Dropdown' },
];

const SuggestOtherQuestionModal = ({ campaignId }) => {
  const {
    data: {
      isLoading,
      formData,
    },
    actions: {
      submitSuggestOther,
      handleAddAnswer,
      handleDeleteAnswer,
    },
  } = useSuggestOther(campaignId);
  return (
    <Modal name={SUGGEST_OTHER_QUESTION_MODAL} size="lg">
      {({ handleHide }) => (
        <ReduxForm
          enableReinitialize
          form="SUGGEST_OTHER_QUESTION_FORM"
          validate={validate}
          formData={{
            onSubmit: submitSuggestOther,
            initialValues: {
              answers: [],
              type: 'yes_no',
            },
          }}
        >
          <div className="suggest-question-form pd-x-25 pd-y-50">
            <h3 className="cdf-title mg-b-40">{tr('Suggest other')}</h3>
            <div className="mg-b-30">
              <Input
                name="question"
                prependFieldLabel={tr('Enter a question')}
                rows={1}
                type="textarea"
              />
            </div>
            <div className="mg-b-30">
              <Select
                name="type"
                fieldLabel={tr('Select type')}
                options={questionTypes}
              />
            </div>
            {['multiple_choice', 'dropdown'].includes(formData.type) && (
              <div className="row mg-b-30">
                <div className="col-md-9">
                  <Input
                    name="answer"
                    prependFieldLabel={tr('Enter an answer option')}
                  />
                </div>
                <div className="col-md-3 sqf-btn-add">
                  <Button
                    color="light"
                    disabled={!formData.answer}
                    onClick={handleAddAnswer}
                  >
                    {tr('Add one more')}
                  </Button>
                </div>
              </div>
            )}
            {formData && formData.answers && !!formData.answers.length && (
              <div className="sqf-answers mg-t-20">
                <h4 className="sqf-answers-title">{tr('Answers')}</h4>
                <div className="sqf-answers-wrapper">
                  <div className="sqf-answers-list">
                    {formData.answers.map((answer, index) => (
                      <div key={index}>{answer}</div>
                    ))}
                  </div>
                  <div className="sqf-answers-deletes">
                    {formData.answers.map((answer, index) => (
                      <Button
                        key={index}
                        rounded
                        outline
                        color="light"
                        className="sqf-btn-delete"
                        onClick={() => handleDeleteAnswer(index)}
                      >
                        <TrashIcon />
                      </Button>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <Button color="light" className="mg-r-30" onClick={handleHide}>
              {tr('Cancel')}
            </Button>
            <Button
              type="submit"
              status={isLoading ? 'disabled' : 'active'}
              disabled={isLoading}
            >
              {tr('Save')}
            </Button>
          </div>
        </ReduxForm>
      )}
    </Modal>
  );
};

export default SuggestOtherQuestionModal;
