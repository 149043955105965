import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../../../../../components/Checkbox';

const UsersGenderField = ({ input, list }) => (
  <div className="row">
    {list.map(({ value, label }) => (
      <div className="col-md-4 mg-r-5" key={value}>
        <Checkbox
          name={value}
          label={label}
          checked={value === input.value}
          onChange={(event) => {
            let newValue = input.value;
            if (event.target.checked) {
              newValue = value;
            } else {
              newValue = '';
            }
            return input.onChange(newValue);
          }}
        />
      </div>
    ))}
  </div>
);

UsersGenderField.propTypes = {
  input: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.number,
    PropTypes.array,
  ])).isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UsersGenderField;
