import DEVICES from './types';

const defaultState = {
  list: [],
  details: {},
  statistic: {},
  products: {
    list: [],
  },
  motorlogs: {},
  count: null,
  activePage: 1,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case DEVICES.GET_DIVECE_INFO.SUCCESS:
    case DEVICES.GET_DIVECE_INFO_UNTRACKED.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.res.serial_number]: payload.res,
        },
      };

    case DEVICES.DELETE_SESSION.SUCCESS:
      const currentDetails = state.statistic[payload.id];
      return {
        ...state,
        statistic: {
          ...state.statistic,
          [payload.id]: {
            ...currentDetails,
            user_interactions: currentDetails.user_interactions.filter((user) => payload.poll_session_id !== user.poll_session_id),
          },
        },
      };


    case DEVICES.GET_ALL_DEVICES.SUCCESS:
      return {
        ...state,
        list: payload.res.results,
        count: payload.res.count,
        activePage: payload.req.data.page || 1,
      };

    case DEVICES.CREATE_EMAIL_RECEIPT.SUCCESS:
    case DEVICES.UPDATE_EMAIL_RECEIPT.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.res.device]: {
            ...state.details[payload.res.device],
            email_receipt: payload.res,
          },
        },
      };

    case DEVICES.DELETE_EMAIL_RECEIPT.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.req.params.serial_number]: {
            ...state.details[payload.req.params.serial_number],
            email_receipt: null,
          },
        },
      };

    case DEVICES.CREATE_LAYOUT.SUCCESS:
    case DEVICES.UPDATE_LAYOUT.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.res.device]: {
            ...state.details[payload.res.device],
            layout: payload.res,
          },
        },
      };

    case DEVICES.DELETE_LAYOUT.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.req.params.serial_number]: {
            ...state.details[payload.req.params.serial_number],
            layout: null,
          },
        },
      };

    case DEVICES.UPDATE_DIVICE_TRAY.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.req.params.serial_number]: {
            ...state.details[payload.req.params.serial_number],
            trays: state.details[payload.req.params.serial_number].trays.map(
              (tray) => (tray.number === payload.req.params.id
                ? { ...payload.res, number: payload.req.params.id }
                : tray
              ),
            ),
          },
        },
      };

    case DEVICES.CREATE_SCREENSAVER.SUCCESS: {
      const { screensavers } = state.details[payload.res.device];
      const screensaversUploaded = screensavers.filter(({ id }) => id);
      const screensaversEmptyCount = screensavers.length - screensaversUploaded.length;
      const screensaversEmpty = screensaversEmptyCount
        ? (new Array(screensaversEmptyCount - 1)).fill({})
        : [];

      return {
        ...state,
        details: {
          ...state.details,
          [payload.res.device]: {
            ...state.details[payload.res.device],
            screensavers: [
              ...screensaversUploaded,
              payload.res,
              ...screensaversEmpty,
            ],
          },
        },
      };
    }

    case DEVICES.UPDATE_SCREENSAVER.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.req.params.serial_number]: {
            ...state.details[payload.req.params.serial_number],
            screensavers: state.details[payload.req.params.serial_number].screensavers.map(
              (file) => (file.id === payload.req.params.id ? payload.res : file),
            ),
          },
        },
      };
    case DEVICES.READ_MOTOR_LOG.SUCCESS:
      return {
        ...state,
        motorlogs: {
          ...state.motorlogs,
          [payload.req.params.id]: payload.res
        },
      };

    case DEVICES.DELETE_SCREENSAVER.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.req.params.serial_number]: {
            ...state.details[payload.req.params.serial_number],
            screensavers: state.details[payload.req.params.serial_number].screensavers.filter(
              ({ id }) => id !== payload.req.params.id,
            ),
          },
        },
      };

    case DEVICES.ADD_ROW_SCREENSAVER:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.serial_number]: {
            ...state.details[payload.serial_number],
            screensavers: [
              ...state.details[payload.serial_number].screensavers,
              {},
            ],
          },
        },
      };

    case DEVICES.GET_DEVICE_STATISTIC.SUCCESS:
      return {
        ...state,
        statistic: {
          ...state.statistic,
          [payload.req.data.serial_number]: {
            ...state.statistic[payload.req.data.serial_number],
            ...payload.res,
          },
        },
      };

    case DEVICES.GET_PRODUCTS.SUCCESS:
      const { results, ...props } = payload.res;
      return {
        ...state,
        products: {
          list: results || [],
          ...props
        },
      };

    case DEVICES.GET_DEVICE_CAMPAIGNS.SUCCESS:
      return {
        ...state,
        statistic: {
          ...state.statistic,
          stats_visibility: null,
          [payload.req.data.serial_number]: {
            ...payload.res,
          },
        },
      };
    case DEVICES.GET_CAMPAIGN_STATS_AVAILABLE.SUCCESS:
      return {
        ...state,
        statistic: {
          ...state.statistic,
          stats_visibility: payload.res && payload.res.stats_visibility,
        },
      };

    default:
      return state;
  }
};
