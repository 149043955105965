import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import useModal from '../../../../utils/useModal';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import ACCOUNT from '../../services/types';
import { ACCOUNT_INFO_MODAL } from '../../../../constants/modals';
import { putAccountDetails as putAccountDetailsAction } from '../../services/actions';

const selector = createSelector(
  (state) => state.account.accountDetails,
  createLoadingSelector(ACCOUNT.EDIT_ACCOUNT_DETAILS.base),
  (initialValues, isLoading) => ({
    initialValues,
    isLoading,
  }),
);

const useBrandModal = () => {
  const dispatch = useDispatch();

  const putAccountDetails = useCallback((values) => {
    dispatch(putAccountDetailsAction(values));
  }, [dispatch]);

  const { hideModal } = useModal(ACCOUNT_INFO_MODAL);

  return {
    actions: {
      hideModal,
      putAccountDetails,
    },
    data: useSelector(selector),
  };
};

export default useBrandModal;
