import { useCallback, useEffect } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import useModal from '../../../utils/useModal';
import createLoadingSelector from '../../../selectors/loadingSelector';
import DEPARTMENT from '../services/types';
import { INVITE_DEPARTMENT_MODAL } from '../../../constants/modals';
import { getDepartmentsMini, inviteUser as inviteUserAction } from '../services/actions';
import { getBrandsMini } from '../../brand/services/actions';

const selector = createSelector(
  createLoadingSelector(DEPARTMENT.INVITE_USER.base),
  (state) => state.departments.listMini,
  (state) => state.brands.listMini,
  (state) => state.account.accountDetails.access_level,
  (isLoading, departments, brands, access_level) => ({
    isLoading,
    departments,
    brands,
    access_level,
  }),
);

const useInviteModal = () => {
  const dispatch = useDispatch();

  const inviteUser = useCallback((values) => {
    dispatch(inviteUserAction({ ...values }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDepartmentsMini({ is_active: 1 }));
    dispatch(getBrandsMini({ is_active: 1 }));
  }, [dispatch])

  const { hideModal } = useModal(INVITE_DEPARTMENT_MODAL);

  return {
    actions: {
      hideModal,
      inviteUser,
    },
    data: useSelector(selector),
  };
};

export default useInviteModal;
