import React, { useState } from 'react';

import useUserDetails from './hooks';
import Loader from '../../../../components/Loader';
import PageHeader from '../../../../components/PageHeader';
import Table from '../../../../components/Table';
import Button from '../../../../components/Button';
import Results from './components/Results';
import translate from '../../../../utils/translate';
import { USERS_ROUTE } from '../../../../constants/routes';
import {
  devicesColumns,
  campaignsColumns,
} from './tableData';
import { getUserColumns } from '../../../../helpers/tableData';

const breadcrumbs = [
  { to: USERS_ROUTE, body: translate('Users') },
];

const userColumns = getUserColumns(false);

const UserDetails = () => {
  const { data: { isLoading, user } } = useUserDetails();
  const [activeIndex, setActiveIndex] = useState(0);

  if (isLoading) return <Loader page size="lg" />;

  const handleClickTab = (index) => () => setActiveIndex(index);

  const userData = [user];
  const devicesData = user.devices || [];
  const campaignsData = user.campaigns || [];
  const pollsData = user.campaigns_polls || {};

  const getHeaderButtons = () => (
    <div className="mg-b-30">
      <div className="btn-group bg-card">
        <Button
          onClick={handleClickTab(0)}
          outline={!!activeIndex}
          color={!activeIndex ? 'primary' : 'secondary'}
        >
          {translate('User info')}
        </Button>
        <Button
          onClick={handleClickTab(1)}
          outline={!activeIndex}
          color={activeIndex ? 'primary' : 'secondary'}
        >
          {translate('Results')}
        </Button>
      </div>
    </div>
  );

  const getPageContent = () => (
    !activeIndex ? (
      <>
        <div className="row mg-b-30">
          <div className="col-md-12">
            <Table
              caption={translate('Main info')}
              columns={userColumns}
              data={userData}
            />
          </div>
        </div>

        <div className="row mg-b-30">
          <div className="col-md-12">
            <Table
              caption={translate('Devices that client interacted with')}
              columns={devicesColumns}
              data={devicesData}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Table
              caption={translate('Campaigns that client took part in')}
              columns={campaignsColumns}
              data={campaignsData}
            />
          </div>
        </div>
      </>
    ) : (
      <Results campaigns={campaignsData} polls={pollsData} />
    )
  );

  return (
    <div className="page-inner pd-t-25 pd-b-25">
      <PageHeader title={user.id} list={breadcrumbs} />
      {getHeaderButtons()}
      {getPageContent()}
    </div>
  );
};

export default UserDetails;
