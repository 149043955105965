import USERS from './types';
import { createActionRequest } from '../../../utils/actionHelpers';

export const getUsers = createActionRequest(
  USERS.READ_USERS_LIST,
  'get',
  '/bo/customers/',
  {
    setData: (params) => (params),
  },
);

export const getUserDetails = createActionRequest(
  USERS.READ_USER_DETAILS,
  'get',
  '/bo/customers/{id}/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const getUserDevices = createActionRequest(
  USERS.READ_USER_DEVICES,
  'get',
  '/bo/customers/{id}/devices/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const getUserCampaigns = createActionRequest(
  USERS.READ_USER_CAMPAIGNS,
  'get',
  '/bo/customers/{id}/campaigns/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const getUserPolls= createActionRequest(
  USERS.READ_USER_POLLS,
  'get',
  '/bo/customers/{id}/polls/',
  {
    setDataPath: (id) => ({ id }),
  },
);


export const getLocations = createActionRequest(USERS.READ_LOCATIONS_LIST, 'get', '/bo/devices/locations/');
