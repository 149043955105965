import tr from '../../../../../../../utils/translate';

const validate = ({
  issue,
}) => {
  const errors = {};

  if (!issue) errors.issue = tr('Message is required');

  return errors;
};

export default validate;
