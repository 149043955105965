import React from 'react';

import tr from '../../../../../utils/translate';

export const actionsColumns = () => [
  {
    name: tr('Action type'),
    key: 'type_verbose',
  },
  {
    name: tr('Comment'),
    key: 'comment',
    className: 'suggestion-wrap-header',
    render: ({ comment }) => <div className="suggestion-wrap-content">{comment}</div>,
  },
];
