import React from 'react';

import translate from '../../../../utils/translate';
import PageHeader from '../../../../components/PageHeader';
import Button from '../../../../components/Button';
import Table from '../../../../components/Table';
import ProductModal from '../ProductModal';
import { useProducts } from './hooks';
import { ROLE_DEPARTMENT_ADMIN } from '../../../../constants/roles';
import { getProductsTable } from './tableData';
import './styles.scss';

const Main = () => {
  const {
    data: {
      products,
      isLoading,
      accessLevel,
      pagination,
    },
    sortBy,
    actions: {
      showProductModal,
      changeSorting,
      getNextPage,
    },
  } = useProducts();
  const isEdit = accessLevel !== ROLE_DEPARTMENT_ADMIN;
  const columns = getProductsTable(showProductModal, isEdit, changeSorting, sortBy);

  const headerButtons = isEdit && (
    <div>
      <Button onClick={showProductModal}>{translate('Create product')}</Button>
    </div>
  );

  return (
    <div className="page-inner pd-t-25 pd-b-25">
      <PageHeader title={translate('Products')}>
        {headerButtons}
      </PageHeader>
      <div className="row">
        <div className="col-md-12 products-table">
          <Table
            caption={translate('All products')}
            columns={columns}
            loading={isLoading}
            data={products}
            pagination={{...pagination, getNextPage }}
          />
          <ProductModal />
        </div>
      </div>
    </div>
  );
};

export default Main;
