import tr from '../../../../utils/translate';

const validate = ({
  old_password,
  password,
  password_confirm,
}) => {
  const errors = {};

  if (!old_password) {
    errors.old_password = tr('Old password is required.');
  }

  if (!password) {
    errors.password = tr('New password is required.');
  } else if (password.length < 8) {
    errors.password = tr('New password must contain at least 8 characters.');
  } else if (!password.match(/[A-Z]/g)) {
    errors.password = tr('New password must contain capital letter.');
  } else if (!password.match(/[a-z]/g)) {
    errors.password = tr('New password must contain small letter.');
  } else if (!password.match(/[0-9]/g)) {
    errors.password = tr('New password must contain number.');
  }

  if (!password_confirm) errors.password_confirm = tr('Confirm password is required');
  else if (password_confirm !== password) errors.password_confirm = tr('New password and Confirm password must be the same');

  return errors;
};

export default validate;
