import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'redux-form';
import tr from '../../utils/translate';
import SimpleButton from '../SimpleButton';
import Input from '../Input';
import './styles.scss';

const ProductListItem = ({ data, maxProducts, onClickEdit }) => {
  const handleClickEdit = () => onClickEdit(data);

  return (
    <div className="product">
      <div className="product__header">
        <Input
          type="checkbox"
          name={`item_${data.id}`}
          key={`item_${data.id}`}
        />
      </div>
      <div className="product__body">
        <span className="product__number">
          {tr(`${tr('Tag')} #${data.tag}`)}
          <br />
        </span>
        <span className="product__name">{data.name || tr('<unnamed>')}</span>
      </div>
      <div className="product__footer">
        <div>
          {onClickEdit && (
            <SimpleButton onClick={handleClickEdit}>
              {tr('Edit')}
            </SimpleButton>
          )}
        </div>
      </div>
    </div>
  );
};

ProductListItem.defaultProps = {
  onClickEdit: null,
};

ProductListItem.propTypes = {
  onClickEdit: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
};

export default React.memo(ProductListItem);
