import { createRequestTypes } from '../../../utils/actionHelpers';

export default createRequestTypes('ACCOUNT', [
  'RESET_PASSWORD',
  'INVITE_USER',
  'GET_ACCOUNT_DETAILS',
  'EDIT_ACCOUNT_DETAILS',
  'GET_COUNTRIES',
  'GET_ACCOUNTS',
  'GET_FACEBOOK_LINK',
  'GET_FACEBOOK_ACCOUNTS',
  'ADD_FACEBOOK_ACCOUNT',
  'REMOVE_FACEBOOK_ACCOUNT',
  'CHANGE_PASSWORD',
  'GET_SOCIAL_INFO_BY_ID',
  'GET_SOCIAL_AD_ACCOUNT_BY_ID',
  'GET_SOCIAL_AVAILABLE_AD_ACCOUNT_BY_ID',
  'AD_ACCOUNTS',
  'DELETE_AD_ACCOUNTS',
  'DELETE_AD_ACCOUNT_FROM_STORE',
]);
