import DASHBOARD from './types';

export default (state = {}, { type, payload }) => {
  switch (type) {
    case DASHBOARD.READ_DASHBOARD.SUCCESS: {
      return payload.res;
    }

    default:
      return state;
  }
};
