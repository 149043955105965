import React from 'react';
import { Field } from 'redux-form';
import tr from '../../../../utils/translate';
import Button from '../../../../components/Button';
import ReduxForm from '../../../../components/ReduxForm';
import { DevicesIcon, PlanetIcon } from '../../../../components/Icons';
import { DEVICE_CAMPAIGN, ONLINE_CAMPAIGN } from '../../../../constants/campaigns';
import CampaignTypeField from '../CampaignTypeField';
import useCampaignType from './hooks';

export const campaignTypes = [
  {
    value: DEVICE_CAMPAIGN,
    label: tr('Device campaign'),
    icon: <DevicesIcon />,
  },
  {
    value: ONLINE_CAMPAIGN,
    label: tr('Online campaign'),
    icon: <PlanetIcon />,
  },
];


const CampaignType = () => {
  const {
    actions: {
      setCampaignType,
    },
  } = useCampaignType();
  return (
    <ReduxForm
      enableReinitialize
      form="CAMPAIGN_TYPE_FORM"
      formData={{
        onSubmit: setCampaignType,
        initialValues: {
          campaign_type: DEVICE_CAMPAIGN,
        },
      }}
    >
      <div className="campaign-type-form pd-x-25 pd-y-50">
        <h3 className="cadf-title mg-b-40">{tr('What type of campaign would you like to create?')}</h3>
        <Field
          name="campaign_type"
          component={CampaignTypeField}
          list={campaignTypes}
        />
      </div>
      <div className="modal-footer">
        <Button
          type="submit"
        >
          {tr('Next')}
        </Button>
      </div>
    </ReduxForm>
  );
};

export default CampaignType;
