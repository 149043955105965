import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import _isEmpty from 'lodash/isEmpty';

import createLoadingSelector from '../../../../../selectors/loadingSelector';
import { ISSUE_MODAL } from '../../../../../constants/modals';
import useModal from '../../../../../utils/useModal';
import { getDevices } from '../../../../devices/services/actions';
import { getDeviceCampaigns } from '../../../../campaigns/services/actions';
import DEVICES from '../../../../devices/services/types';
import CAMPAIGNS from '../../../../campaigns/services/types';
import LAYOUT from '../../services/types';
import translate from '../../../../../utils/translate';
import { postIssue } from '../../services/actions';

const selector = createSelector(
  createLoadingSelector(DEVICES.GET_ALL_DEVICES.base),
  createLoadingSelector(CAMPAIGNS.READ_LIST.base),
  createLoadingSelector(LAYOUT.POST_ISSUE.base),
  (state) => state.devices.list,
  (state) => state.campaigns.list,
  (state) => state.brands.activeBrand,
  (state) => state.departments.activeDepartment,
  (isLoadingDevices, isLoadingCampaigns, isLoadingIssue, devices, campaigns, activeBrand, activeDepartment) => ({
    isLoadingDevices,
    isLoadingCampaigns,
    isLoadingIssue,
    devices: [
      { value: '', label: translate('Devices') },
      ...devices.map((d) => ({ value: d.serial_number, label: d.name })),
    ],
    campaigns: [
      { value: '', label: translate('Campaigns') },
      ...campaigns.map((c) => ({ value: c.id, label: c.name })),
    ],
    activeBrand,
    activeDepartment,
  }),
);

export const useHeaderIssue = () => {
  const dispatch = useDispatch();
  const data = useSelector(selector);

  const openPage = useCallback(() => {
    dispatch(getDevices({
      ...(data.activeBrand && { company: data.activeBrand }),
      ...(data.activeDepartment && { department: data.activeDepartment }),
    }));
    dispatch(getDeviceCampaigns({
      ...(data.activeBrand && { company: data.activeBrand }),
      ...(data.activeDepartment && { department: data.activeDepartment }),
    }));
  }, [data.activeBrand, data.activeDepartment, dispatch]);

  useEffect(() => {
    openPage();
  }, [openPage]);

  const { showModal, hideModal } = useModal(ISSUE_MODAL);

  const hanleIssueSubmit = useCallback((values) => {
    if (_isEmpty(values)) {
      hideModal();
      return;
    }

    dispatch(postIssue(values));
  }, [dispatch, hideModal]);

  return {
    data,
    actions: {
      showModal,
      hideModal,
      hanleIssueSubmit,
    },
  };
};
