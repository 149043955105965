import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { getAccountDetails } from '../../../../account/services/actions';

const selector = createSelector(
  (state) => state.account.accountDetails,
  (accountDetails) => ({
    accountDetails,
  }),
);

const useHeaderProfile = () => {
  const dispatch = useDispatch();
  const { accountDetails } = useSelector(selector);

  const getAccount = useCallback(() => {
    dispatch(getAccountDetails());
  }, [dispatch]);

  useEffect(() => {
    getAccount();
  }, [getAccount]);

  return {
    accountDetails,
  };
};

export default useHeaderProfile;
