/*eslint no-mixed-operators: 0*/
import React from 'react';
import cn from 'classnames';
import SimpleButton from '../../../components/SimpleButton';
import tr from '../../../utils/translate';
import { formatDateNoUtc } from '../../../utils/dates';

const createColumns = (showModal, showStatusModal, handleSorting, sortBy) => [
  {
    name: tr('Team name'),
    key: 'name',
    className: `table-column-sort ${sortBy.key === 'name' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('name'),
  },
  {
    name: tr('Company'),
    key: 'company',
    className: `table-column-sort ${sortBy.key === 'company' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('company'),
    render: ({ company }) => company && (<span className={cn('department-company-cell', { 'company-inactive': !company.is_active })}>{company.name}</span>),
  },
  {
    name: tr('Country'),
    key: 'country',
    className: `table-column-sort ${sortBy.key === 'country' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('country'),
    render: ({ country }) => ((country && country.name) ? country.name : ''),
  },
  {
    name: tr('Members'),
    key: 'team_count',
    className: `table-column-sort ${sortBy.key === 'team_count' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('team_count'),
  },
  {
    name: tr('Devices'),
    key: 'devices_count',
    className: `table-column-sort ${sortBy.key === 'devices_count' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('devices_count'),
  },
  {
    name: tr('Registration date'),
    key: 'registration_date',
    className: `table-column-sort ${sortBy.key === 'date_registration' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('date_registration'),
    render: ({ date_registration }) => (date_registration ? formatDateNoUtc(date_registration) : tr('No data')),
  },
  {
    name: tr('Current Status'),
    key: 'status',
    className: `table-column-sort ${sortBy.key === 'is_active' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('is_active'),
    render: ({ is_active }) => (
      <span
        className={`cell-status cell-status_${is_active ? 'online' : 'offline'}`}
      >
        {is_active ? tr('Active') : tr('Inactive')}
      </span>
    ),
  },
  {
    name: tr('Options'),
    key: 'options',
    render: ({
      id, name, is_active, company,
    }) => (
      (company && company.is_active)
      && (
        <>
          <SimpleButton
            className="cell-link"
            onClick={() => { showModal({ titleModal: tr(`Edit department ${name}`), id }); }}
          >
            {tr('Edit')}
          </SimpleButton>
          <SimpleButton
            className={cn('cell-link status-link', { 'status-active': !!is_active })}
            onClick={() => {
              showStatusModal({
                titleModal: tr(`${is_active ? 'Deactivate' : 'Activate'} ${name}`), id, name, is_active,
              });
            }}
          >
            {tr(`${is_active ? 'Deactivate' : 'Activate'}`)}
          </SimpleButton>

        </>
      )
    ),
  },
];

export default createColumns;
