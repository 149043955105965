import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import createLoadingSelector from '../../../../selectors/loadingSelector';
import { editDeviceTray, getCompanyProducts } from '../../services/actions';
import DEVICES from '../../services/types';

const useEditTray = (serial_number, company) => {
  const dispatch = useDispatch();
  const editTray = useCallback((id, data) => {
    dispatch(editDeviceTray(serial_number, id, data));
  }, [dispatch, serial_number]);
  const submit = useCallback((data) => {
    const {
      number,
      file: files,
      ...otherData
    } = data;
    const [file] = files instanceof Array ? files : [];
    editTray(
      number,
      {
        ...otherData,
        number,
        file,
      },
    );
  }, [editTray]);

  const getProducts = useCallback(() => {
    dispatch(getCompanyProducts({company: company}));
  }, [dispatch]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const loadingSelector = createLoadingSelector(DEVICES.UPDATE_DIVICE_TRAY.base);
  const createDeviceSelector = () => createSelector(
    loadingSelector,
    (state) => state.devices.products.list || [],
    (isLoading, products) => ({
      isLoading,
      products,
    }),
  );
  const data = useSelector(createDeviceSelector());


  return {
    data,
    actions: {
      submit,
    },
  };
};

export default useEditTray;
