export const RESET_PASSWORD_MODAL = 'RESET_PASSWORD_MODAL';
export const INVITE_USER_MODAL = 'INVITE_USER_MODAL';
export const INVITE_BRAND_MODAL = 'INVITE_BRAND_MODAL';
export const INVITE_DEPARTMENT_MODAL = 'INVITE_DEPARTMENT_MODAL';
export const BRAND_INFO_MODAL = 'BRAND_INFO_MODAL';
export const ACCOUNT_INFO_MODAL = 'ACCOUNT_INFO_MODAL';
export const ADD_DEVICE_MODAL = 'ADD_DEVICE_MODAL';
export const EDIT_DEVICE_MODAL = 'EDIT_DEVICE_MODAL';
export const DEVICE_EDIT_TRAY_MODAL = 'DEVICE_EDIT_TRAY_MODAL';
export const DEVICE_EDIT_CAMPAIGN_MODAL = 'DEVICE_EDIT_CAMPAIGN_MODAL';
export const CAMPAIGN_EDIT_TRAY_MODAL = 'CAMPAIGN_EDIT_TRAY_MODAL';
export const CAMPAIGN_FOLLOW_UP_PREVIEW_MODAL = 'CAMPAIGN_FOLLOW_UP_PREVIEW_MODAL';
export const USERS_SEARCH_MODAL = 'USERS_SEARCH_MODAL';
export const USERS_FILTER_MODAL = 'USER_FILTER_MODAL';
export const CAMPAIGN_MODAL = 'CAMPAIGN_MODAL';
export const DELETE_MODAL = 'DELETE_MODAL';
export const ISSUE_MODAL = 'ISSUE_MODAL';
export const REBOOT_DEVICE_MODAL = 'REBOOT_DEVICE_MODAL';
export const BRAND_MODAL = 'BRAND_MODAL';
export const DEPARTMENT_MODAL = 'DEPARTMENT_MODAL';
export const DEACTIVATE_BRAND_MODAL = 'DEACTIVATE_BRAND_MODAL';
export const DEACTIVATE_DEPARTMENT_MODAL = 'DEACTIVATE_DEPARTMENT_MODAL';
export const DEACTIVATE_USER_MODAL = 'DEACTIVATE_USER_MODAL';
export const EMPLOYEE_ROLE_MODAL = 'EMPLOYEE_ROLE_MODAL';
export const DEVICE_TRAYS_MODAL = 'DEVICE_TRAYS_MODAL';
export const SUGGEST_OTHER_MODAL = 'SUGGEST_OTHER_MODAL';
export const SUGGEST_OTHER_QUESTION_MODAL = 'SUGGEST_OTHER_QUESTION_MODAL';
export const PRODUCT_MODAL = 'PRODUCT_MODAL';
export const ASSIGN_DEVICES_MEMBER = 'ASSIGN_DEVICES_MEMBER';
export const ONLINE_CAMPAIGN_STATUS = 'ONLINE_CAMPAIGN_STATUS';
export const TEST_EMAILS_MODAL = 'TEST_EMAILS_MODAL';
export const SUCCESS_CREATE_MODAL = 'SUCCESS_CREATE_MODAL';
export const COPY_SOCIAL_LINK_MODAL = 'COPY_SOCIAL_LINK_MODAL';
export const START_MAILING_MODAL = 'START_MAILING_MODAL';
export const VALIDATE_DOMAIN_MODAL = 'VALIDATE_DOMAIN_MODAL';
export const UPDATE_PRODUCT_STOCK = 'UPDATE_PRODUCT_STOCK';
export const OVERVIEW_WIDGET_MODAL = 'OVERVIEW_WIDGET_MODAL';
export const ADD_SOCIALS_MODAL = 'ADD_SOCIALS_MODAL';
export const CHOOSE_SOCIALS_MODAL = 'CHOOSE_SOCIALS_MODAL';
export const VIEWS_SOCIALS_DETAILS_MODAL = 'VIEWS_SOCIALS_DETAILS_MODAL';
export const SOCIAL_AD_ACCOUNTS_MODAL = 'SOCIAL_AD_ACCOUNTS_MODAL';
