import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import tr from '../../../../utils/translate';
import Table from '../../../../components/Table';
import { facebookColumns } from './columns';
import SimpleButton from '../../../../components/SimpleButton';
import UncontrolledDropdown from '../../../../components/DropDown/UncontrolledDropdown';
import DropdownToggle from '../../../../components/DropDown/DropdownToggle';
import DropdownMenu from '../../../../components/DropDown/DropdownMenu';
import DropDownItem from '../../../../components/DropDown/DropDownItem';
import AddSocialsModal from './AddSocialsModal';
import ChooseSocialsModal from './ChooseSocialsModal';
import ViewSocialDetails from './ViewSocialDetails';
import SocialAdAccountsModal from './SocialAdAccountsModal';
import useSocialAccount from './hooks';

import './styles.scss';
import { ROLE_CONTENT_MANAGER } from '../../../../constants/roles';

const SocialAccounts = ({ accounts, addAccount, removeAccount, access_level }) => {
  const [socialId, setSocialId] = useState(false);

  const {
    actions: {
      showAddSocialsModal,
      hideAddSocialsModal,
      showChooseSocialsModal,
      hideChooseSocialsModal,
      showViewSocialDetailsModal,
      showSocialAdAccountsModal,
      onSocialInfoById,
      onAvailableAdAccountById,
      onConnectSocialById,
      onDeleteAdAccounts,
      onDeleteAdAccountFromStore,
    },
    data: {
      socialsDetails,
      socialsAvailableAccounts,
    },
  } = useSocialAccount();

  const onAddSubmit = useCallback(() => {
    hideAddSocialsModal();
    showChooseSocialsModal();
  }, [hideAddSocialsModal, showChooseSocialsModal]);

  const onChooseSubmit = useCallback(() => {
    hideChooseSocialsModal();
    addAccount();
  }, [addAccount, hideChooseSocialsModal]);

  const list = useMemo(() => (
    accounts.map((account) => {
      const {
        name,
        id,
        connected_at,
        disconnected,
      } = account;

      const handleRemove = () => {
        removeAccount(id);
      };

      const onViewDetails = () => {
        setSocialId(id);
        onSocialInfoById(id);
        showViewSocialDetailsModal();
      };

      const onConnectAccounts = () => {
        setSocialId(id);
        onAvailableAdAccountById(id);
        showSocialAdAccountsModal();
      };
  
      return ({
        name,
        connected_at,
        disconnected: disconnected ? 'Not active' : 'Active',
        action: (
          <UncontrolledDropdown>
  
            <DropdownToggle>
  
              {tr('Actions')}
  
            </DropdownToggle>
  
            <DropdownMenu position="right">
  
              <DropDownItem toggle>

                <SimpleButton onClick={onViewDetails}>

                  {tr('View Details')}

                </SimpleButton>

              </DropDownItem>

              {access_level !== ROLE_CONTENT_MANAGER && (
                <>

                  <DropDownItem toggle>

                    <SimpleButton onClick={onConnectAccounts}>

                      {tr('Connect ad account')}

                    </SimpleButton>

                  </DropDownItem>

                  <DropDownItem toggle>

                    <SimpleButton onClick={handleRemove}>

                      {tr('Disconnect user account')}

                    </SimpleButton>

                  </DropDownItem>

                </>
              )}
  
            </DropdownMenu>
  
          </UncontrolledDropdown>
        ),
      });
    })
  ), [accounts]);

  return (
    <div className="social_accounts">

      <Table
        className="mg-b-0"
        caption={tr('Social Accounts')}
        header={access_level !== ROLE_CONTENT_MANAGER && (
          <SimpleButton
	          className="mg-l-20"
	          onClick={showAddSocialsModal}
          >
	          {tr('Add Social')}
          </SimpleButton>
        )}
        columns={facebookColumns(removeAccount)}
        data={list}
      />

      <AddSocialsModal
        onSubmit={onAddSubmit}
      />

      <ChooseSocialsModal
        onSubmit={onChooseSubmit}
      />

      <ViewSocialDetails
        data={socialsDetails[socialId] || {}}
        onDeleteAdAccounts={onDeleteAdAccounts}
        onDeleteAdAccountFromStore={onDeleteAdAccountFromStore}
        socialId={socialId}
        access_level={access_level}
      />

      <SocialAdAccountsModal
        data={socialsAvailableAccounts[socialId] || []}
        onConnectSocialById={onConnectSocialById}
        socialId={socialId}
      />

    </div>
  );
};

SocialAccounts.propTypes = {
  accounts: PropTypes.array.isRequired,
  removeAccount: PropTypes.func.isRequired,
  addAccount: PropTypes.func.isRequired,
};

export default SocialAccounts;
