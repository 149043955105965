import { takeEvery, put, call } from 'redux-saga/effects';
import { hide } from 'redux-modal';
import { toast } from 'react-toastify';

import { ISSUE_MODAL } from '../../../../constants/modals';
import tr from '../../../../utils/translate';
import LAYOUT from './types';

function* runReportIssueSuccess() {
  yield call(toast.success, tr('Issue reported'));
  yield put(hide(ISSUE_MODAL));
}

export default function* campaigns() {
  yield takeEvery(LAYOUT.POST_ISSUE.SUCCESS, runReportIssueSuccess);
}
