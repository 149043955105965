import React from 'react';
import _capitalize from 'lodash/capitalize';
import Bar from './Bar';
import Column from './Column';
import './styles.scss';

const QuestionCharts = ({ type, answers }) => {
  if (type === 'yes_no') {
    return (
      <Bar
        size="md"
        width="55"
        data={
          Object.keys(answers.count).map((name) => {
            const value = answers.amount && ((answers.count[name] / answers.amount) * 100);
            const prepend = value - value.toFixed(1) ? value.toFixed(1) : value;

            return {
              value,
              prepend: _capitalize(name),
              bar: {
                append: `${prepend}%`,
                tooltip: `${_capitalize(name)}: ${answers.count[name]} responses (${prepend}%)`,
              },
            };
          })
        }
      />
    );
  }

  if (type === 'rating') {
    return (
      <Column
        size="md"
        width="65"
        data={
          Object.keys(answers.count).map((name, index, array) => {
            const value = answers.amount && ((answers.count[name] / answers.amount) * 100);
            const append = value - value.toFixed(1) ? value.toFixed(1) : value;

            return {
              value,
              append: `${append}%`,
              bar: {
                prepend: name,
                tooltip: `Rate ${name}: ${answers.count[name]} responses (${append}%)`,
              },
              labels: {
                bottom: (index === 0 && 'Not for me') || (index === array.length - 1 && 'Loved it!'),
              },
            };
          })
        }
      />
    );
  }
  if (type === 'opinion_scale') {
    return (
      <Column
        size="md"
        width="65"
        data={
          Object.keys(answers.count).map((name, index, array) => {
            const value = answers.amount && ((answers.count[name] / answers.amount) * 100);
            const append = value - value.toFixed(1) ? value.toFixed(1) : value;

            return {
              value,
              append: `${append}%`,
              bar: {
                prepend: name,
                tooltip: `Opinion ${name}: ${answers.count[name]} responses (${append}%)`,
              },
              labels: {
                bottom: (index === 0 && 'Not for me') || (index === array.length - 1 && 'Loved it!'),
              },
            };
          })
        }
      />
    );
  }

  if (type === 'multiple_choice') {
    return (
      <Bar
        size="md"
        width="55"
        data={
          Object.keys(answers.count).map((name) => {
            const value = answers.amount && ((answers.count[name] / answers.amount) * 100);
            const prepend = value - value.toFixed(1) ? value.toFixed(1) : value;

            return {
              value,
              bar: {
                title: _capitalize(name),
                append: `${prepend}%`,
                tooltip: `${_capitalize(name)}: ${answers.count[name]} responses (${prepend}%)`,
              },
            };
          })
        }
      />
    );
  }

  return (
    <ol className="questions-list">
      {
        Object.keys(answers.count).map((name, index) => (
          <li key={index}>{name}</li>
        ))
      }
    </ol>
  );
};

export default QuestionCharts;
