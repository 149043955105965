import React from 'react';

import Modal from '../../../components/Modal';
import ReduxForm from '../../../components/ReduxForm';
import Input from '../../../components/Input';
import ModalFormFooter from '../../../components/ModalFormFooter';
import tr from '../../../utils/translate';
import { INVITE_BRAND_MODAL } from '../../../constants/modals';
import { BRAND } from '../../../constants/forms';
import validate from './validate';
import useInviteModal from './hooks';

const InviteBrandModal = () => {
  const { actions: { hideModal, inviteBrand }, data: { isLoading } } = useInviteModal();

  return (
    <Modal name={INVITE_BRAND_MODAL} title={tr('Invite brand')} size="sm">
      <ReduxForm
        form={BRAND.INVITE_BRAND_FORM}
        validate={validate}
        formData={{
          onSubmit: inviteBrand,
        }}
      >
        <div className="modal-body">
          <Input
            name="user_to"
            className="mg-b-30"
            prependFieldLabel={tr('Email address')}
          />
          <Input
            name="company_name"
            prependFieldLabel={tr('Brand name')}
          />
        </div>
        <div className="modal-footer">
          <ModalFormFooter
            handleClose={hideModal}
            confirmText={tr('Send invite')}
            isLoading={isLoading}
          />
        </div>
      </ReduxForm>
    </Modal>
  );
};

export default InviteBrandModal;
