import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';

import Modal from '../../../../../../components/Modal';
import { USERS_SEARCH_MODAL } from '../../../../../../constants/modals';
import translate from '../../../../../../utils/translate';
import Form from './Form';
import { formatParams } from '../../helpers';

const UsersSearchModal = ({ location }) => {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const initialValues = formatParams(params, 'MM/DD/YYYY');

  return (
    <Modal title={translate('Search')} name={USERS_SEARCH_MODAL} size="sm">
      <Form initialValues={initialValues} location={location} />
    </Modal>
  );
};

UsersSearchModal.propTypes = {
  location: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default UsersSearchModal;
