import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  addRowScreensaver,
  createScreensaver,
  updateScreensaver,
  deleteScreensaver,
} from '../../services/actions';

const useTableScreensaver = (serial_number) => {
  const dispatch = useDispatch();
  const addRowImage = useCallback(() => {
    dispatch(addRowScreensaver(serial_number));
  }, [dispatch, serial_number]);
  const uploadImage = useCallback((id, file) => {
    if (id) {
      dispatch(updateScreensaver(serial_number, id, file));
    } else {
      dispatch(createScreensaver(serial_number, file));
    }
  }, [dispatch, serial_number]);
  const deleteImage = useCallback((id) => {
    dispatch(deleteScreensaver(serial_number, id));
  }, [dispatch, serial_number]);

  return {
    actions: {
      addRowImage,
      uploadImage,
      deleteImage,
    },
  };
};

export default useTableScreensaver;
