import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import CAMPAIGNS from '../../services/types';
import {
  getDeviceCampaignDetails,
  editCampaign as editCampaignAction,
  prevStep,
  setFileteredUsersList,
  getFileteredUsersList,
  nextStep,
  editInterface,
} from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import { getUsers } from '../../../users/services/actions';

const submitLoadingSelector = createLoadingSelector(CAMPAIGNS.CREATE_CAMPAIGN.base);
const detailsLoadingSelector = createLoadingSelector(CAMPAIGNS.READ_DETAILS.base);

const createCampaignSelector = (id) => createSelector(
  detailsLoadingSelector,
  submitLoadingSelector,
  (state) => state.campaigns.details[id],
  (state) => state.campaigns.selected_users,
  (state) => state.users.users,
  (isLoadingData, isLoadingSubmit, campaign, selected_users, users) => ({
    isLoadingData,
    isLoadingSubmit,
    campaign,
    selected_users,
    users,
  }),
);

const genderValues = {
  3: '',
  1: 'male',
  2: 'female',
};

const useCampaignDetailsForm = (id) => {
  const dispatch = useDispatch();
  const selector = createCampaignSelector(id);
  const data = useSelector(selector);

  const openPage = useCallback(() => {
    if (id && !data.campaign) {
      dispatch(getDeviceCampaignDetails(id));
    }
    if (data.campaign && data.campaign.company && data.campaign.company.id) {
      dispatch(getUsers({
        company: data.campaign.company.id,
      }));
    }
  }, [data.campaign, dispatch, id]);

  const goToPrevStep = useCallback(() => {
    dispatch(prevStep());
  }, [dispatch]);

  const goToNextStep = useCallback(() => {
    dispatch(nextStep());
  }, [dispatch]);

  const editCampaign = useCallback((formValue) => {
    switch (formValue.type) {
      case 'database': {
        dispatch(setFileteredUsersList(id, {
          ...formValue.age && formValue.age.length > 0 ? { age_list: formValue.age } : {},
          gender: formValue.gender,
          interests: formValue.interests || '',
        }));
        break;
      }
      case 'csv': {
        const {
          file: files,
        } = formValue;
        const [file] = files instanceof Array ? files : [];
        dispatch(editInterface(id, { email_list_file: file, delimiter_type: 1 }, true));
        break;
      }
      case 'copypaste': {
        const csvValues = formValue.contact_info && formValue.contact_info.split('\n');
        const trimedArr = csvValues.map((val) => val.trim());
        dispatch(editCampaignAction(id, { direct_mail: { email_list_text: trimedArr.join('\n') } }));
        break;
      }
      default: break;
    }
  }, [dispatch, id]);

  const handleFormChange = useCallback((formValue) => {
    if (formValue && formValue.type === 'database') {
      dispatch(getFileteredUsersList({
        age_list: formValue.age ? formValue.age.join(',') : '',
        gender: genderValues[formValue.gender] || '',
        interests: formValue.interests || '',
        company: data.campaign && data.campaign.company && data.campaign.company.id,
      }));
    }
  }, [data.campaign, dispatch]);

  useEffect(() => {
    openPage();
  }, [openPage]);

  return {
    data,
    actions: {
      editCampaign,
      goToPrevStep,
      handleFormChange,
      goToNextStep,
    },
  };
};

export default useCampaignDetailsForm;
