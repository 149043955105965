import { useEffect, useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getUserDetails, getUserDevices, getUserCampaigns, getUserPolls } from '../../services/actions';
import USERS from '../../services/types';
import createLoadingSelector from '../../../../selectors/loadingSelector';

const selector = createSelector(
  createLoadingSelector(USERS.READ_USER_DETAILS.base),
  (state) => state.users.currentUser,
  (isLoading, user) => ({
    isLoading,
    user,
  }),
);

const useUserDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const openPage = useCallback((id) => {
    dispatch(getUserDetails(id));
  }, [dispatch]);

  useEffect(() => {
    openPage(params.id);
  }, [openPage, params.id]);

  return {
    data: useSelector(selector),
  };
};

export default useUserDetails;
