import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import createLoadingSelector from '../../../../selectors/loadingSelector';
import { getDeviceCampaigns } from '../../../campaigns/services/actions';
import { setDeviceCampaign } from '../../services/actions';
import DEVICES from '../../services/types';

const selector = createSelector(
  createLoadingSelector(DEVICES.SET_DIVECE_INFO.base),
  (state) => state.campaigns.list,
  (isLoading, campaigns) => ({
    isLoading,
    campaigns,
  }),
);

const useEditDeviceCampaign = (serial_number, company, department) => {
  const dispatch = useDispatch();

  const openPage = useCallback((id) => {
    dispatch(getDeviceCampaigns({company, department}));
  }, [dispatch]);

  useEffect(() => {
    openPage();
  }, [openPage]);

  const editDevice = useCallback(({ campaign }) => {
    dispatch(setDeviceCampaign(serial_number, { campaign }));
  }, [dispatch, serial_number]);

  return {
    data: useSelector(selector),
    actions: {
      editDevice,
    },
  };
};

export default useEditDeviceCampaign;
