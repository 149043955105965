import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { hide, show } from 'redux-modal';

const useModal = (nameModal, props = {}) => {
  const dispatch = useDispatch();
  const hideModal = useCallback(() => {
    dispatch(hide(nameModal));
  }, [dispatch, nameModal]);
  const showModal = useCallback((params) => {
    let newParams = {};
    // check if params !== event
    if (params && !params.target) {
      newParams = params;
    }

    dispatch(show(nameModal, { data: { ...props, ...newParams } }));
  }, [dispatch, nameModal, props]);

  return {
    hideModal,
    showModal,
  };
};

export default useModal;
