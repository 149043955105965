import { createActionType } from '../utils/actionHelpers';

export const ACCOUNT = createActionType('ACCOUNT', [
  'BRAND_FORM',
  'INVITE_USER_FORM',
  'CHANGE_PASSWORD_FORM',
  'ACCOUNT_FORM',
]);

export const AUTH = createActionType('ACCOUNT', [
  'CHANGE_PASSWORD_FORM',
  'FORGOT_PASSWORD_FORM',
  'CONFIRM_INVITE_FORM',
  'SIGN_IN_FORM',
  'SIGN_UP_FORM',
]);

export const ADD_NEW_DEVICE_FORM = 'ADD_NEW_DEVICE_FORM';

export const CREATE_DEVICE_CAMPAIGN_FORM = 'CREATE_DEVICE_CAMPAIGN_FORM';

export const EDIT_DEVICE_CAMPAIGN_FORM = 'EDIT_DEVICE_CAMPAIGN_FORM';

export const CREATE_DEVICE_CAMPAIGN_DEVICES_FORM = 'CREATE_DEVICE_CAMPAIGN_DEVICES_FORM';

export const CREATE_DEVICE_CAMPAIGN_LAYOUT_FORM = 'CREATE_DEVICE_CAMPAIGN_LAYOUT_FORM';

export const BRAND = createActionType('BRAND', [
  'EDIT_BRAND_FORM',
  'INVITE_BRAND_FORM',
]);

export const DEPARTMENT = createActionType('DEPARTMENT', [
  'EDIT_DEPARTMENT_FORM',
  'INVITE_USER_FORM',
]);

export const EMPLOYEE = createActionType('EMPLOYEE', [
  'CHANGE_ROLE',
  'CHANGE_BRAND',
  'CHANGE_DEPARTMENT',
  'ASSIGN_DEVICES',
]);
