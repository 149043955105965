import { SHOW_NOTIFICATIONS, HIDE_NOTIFICATIONS } from './types';

const defaultState = {
  isNotificationsShown: false,
};

export default (state = defaultState, action) => {
  const {
    type,
  } = action;
  switch (type) {
    case SHOW_NOTIFICATIONS:
      return { isNotificationsShown: true };

    case HIDE_NOTIFICATIONS:
      return { isNotificationsShown: false };

    default:
      return state;
  }
};
