import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { getProducts } from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import PRODUCT from '../../services/types';
import { PRODUCT_MODAL } from '../../../../constants/modals';
import useModal from '../../../../utils/useModal';

const selector = createSelector(
  createLoadingSelector(PRODUCT.READ_PRODUCTS_LIST.base),
  (state) => state.products.list,
  (state) => state.brands.activeBrand,
  (state) => state.account.accountDetails.access_level,
  (state) => ({
    count: state.products.count,
    activePage: state.products.activePage,
  }),
  (isLoading, products, activeBrand, accessLevel, pagination) => ({
    isLoading,
    products,
    activeBrand,
    accessLevel,
    pagination,
  }),
);

export const useProducts = () => {
  const dispatch = useDispatch();
  const data = useSelector(selector);
  const [sortBy, setSortBy] = useState({});

  const { showModal: showProductModal } = useModal(PRODUCT_MODAL);

  const changeSorting = (field) => {
    if (sortBy.key === field) {
      setSortBy({
        key: field,
        direction: sortBy.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSortBy({
        key: field,
        direction: 'asc',
      });
    }
  };


  useEffect(() => {
    const ordering = sortBy && (sortBy.direction === 'desc' ? `-${sortBy.key}` : sortBy.key);
    dispatch(getProducts({
      ordering,
      ...(data.activeBrand && { company: data.activeBrand }),
    }));
  }, [data.activeBrand, dispatch, sortBy]);

  const getNextPage = (page) => {
    dispatch(getProducts({
      ...(data.activeBrand && { company: data.activeBrand }),
      page,
    }));
  };

  return {
    data,
    sortBy,
    actions: {
      changeSorting,
      showProductModal,
      getNextPage,
    },
  };
};
