import { combineReducers } from 'redux';
import { connectRouter as router } from 'connected-react-router';

import { reducer as modal } from 'redux-modal';
import { reducer as form } from 'redux-form';
import history from '../../utils/history';
import auth from '../auth/services/reducer';
import dashboard from '../dashboard/services/reducer';
import devices from '../devices/services/reducer';
import appLayout from '../layout/AppLayout/services/reducer';
import loading from './loadingReducer';
import account from '../account/services/reducer';
import campaigns from '../campaigns/services/reducer';
import users from '../users/services/reducer';
import brands from '../brand/services/reducer';
import departments from '../department/services/reducer';
import employees from '../employees/services/reducer';
import products from '../product/services/reducer';

import AUTH from '../auth/services/types';

const appReducer = combineReducers({
  router: router(history),
  modal,
  form,
  // -- start connecting reducers
  auth,
  dashboard,
  account,
  devices,
  appLayout,
  loading,
  campaigns,
  users,
  brands,
  departments,
  employees,
  products,
  // -- end connecting reducers
});

const rootReducer = (state, action) => {
  if (action.type === AUTH.SIGN_OUT) {
    state = undefined; // eslint-disable-line no-param-reassign
  }

  return appReducer(state, action);
};

export default rootReducer;
