import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import PageHeader from '../../../../components/PageHeader';
import tr from '../../../../utils/translate';
import TableStatus from '../TableStatus';
import useDevices from './hooks';
import './styles.scss';

const Main = ({ allowAdd }) => {
  const { handleClickAddDevice } = useDevices();

  const headerButtons = (
    <Button onClick={handleClickAddDevice}>{tr('Add device')}</Button>
  );

  return (
    <div className="page-inner pd-t-25 pd-b-25">
      <PageHeader title={tr('Devices')}>
        {allowAdd && headerButtons}
      </PageHeader>
      <div className="row">
        <div className="col-md-12 devices-page-list">
          <TableStatus />
        </div>
      </div>
    </div>
  );
};

Main.propType = {
  allowAdd: PropTypes.bool,
};

Main.defaultProps = {
  allowAdd: false,
};
export default Main;
