import { useEffect, useCallback, useRef } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import {
  pageDeviceInfo,
  setEmailReceipt,
  updateEmailReceipt,
  deleteEmailReceipt,
  setLayout,
  updateLayout,
  deleteLayout,
  rebootDevice,
  readMotorLog,
  touchDeviceTray,
  getDeviceCampaigns,
  getDeviceStatistic,
} from '../../services/actions';
import {
  deviceInfoLoadingSelector,
  createDeviceInfoByIdSelector,
  emailReceiptLoadingSelector,
  layoutLoadingSelector,
  rebootDeviceLoadingSelector,
} from '../../selectors';
import {
  EDIT_DEVICE_MODAL,
  DEVICE_EDIT_TRAY_MODAL,
  DEVICE_EDIT_CAMPAIGN_MODAL,
  DEVICE_TRAYS_MODAL,
  REBOOT_DEVICE_MODAL,
} from '../../../../constants/modals';
import useModal from '../../../../utils/useModal';
import { setParams } from '../../../../helpers/urlParamsHelpers';

const createDeviceSelector = (id) => createSelector(
  createDeviceInfoByIdSelector(id),
  deviceInfoLoadingSelector,
  emailReceiptLoadingSelector,
  layoutLoadingSelector,
  rebootDeviceLoadingSelector,
  (state) => state.devices.statistic[id],
  (state) => state.devices.statistic.stats_visibility,
  (state) => state.devices.motorlogs[id],
  (state) => state.modal[DEVICE_TRAYS_MODAL],
  (device, isLoading, isLoadingEmailReceipt, isLoadingLayout, isLoadingReboot, statistic, stats_visibility, motorLogs, modalState) => ({
    device,
    isLoading,
    isLoadingEmailReceipt,
    isLoadingLayout,
    isLoadingReboot,
    statistic,
    stats_visibility,
    motorLogs,
    modalState
  }),
);

const useDeviceInfo = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const selector = createDeviceSelector(params.id);
  const data = useSelector(selector);
  const history = useHistory();
  const location = useLocation();
  const currentParams = useRef(qs.parse(location.search, { ignoreQueryPrefix: true }));
  const initialRender = useRef(true);

  const openPage = useCallback((id) => {
    dispatch(pageDeviceInfo(id));
  }, [dispatch]);

  const uploadEmailReceipt = useCallback(([file]) => {
    if (data.device && data.device.email_receipt) {
      dispatch(updateEmailReceipt(params.id, file));
    } else {
      dispatch(setEmailReceipt(params.id, file));
    }
  }, [data.device, dispatch, params.id]);

  const removeEmailReceipt = useCallback(() => {
    dispatch(deleteEmailReceipt(params.id));
  }, [dispatch, params.id]);

  const uploadLayout = useCallback(([file]) => {
    if (data.device && data.device.layout) {
      dispatch(updateLayout(params.id, file));
    } else {
      dispatch(setLayout(params.id, file));
    }
  }, [data.device, dispatch, params.id]);

  const removeLayout = useCallback(() => {
    dispatch(deleteLayout(params.id));
  }, [dispatch, params.id]);

  const handleRebootDevice = useCallback(() => {
    dispatch(rebootDevice(params.id));
  }, [dispatch, params.id]);

  const handlePeriodChange = (value) => {
    currentParams.current = { period: value };
    const url = setParams(currentParams.current);
    history.push(`?${url}`);
  };

  const handleCampaignChange = (value) => {
    currentParams.current = { ...currentParams.current, ...{ campaign_id: value } };
    const url = setParams(currentParams.current);
    history.push(`?${url}`);
  };

  useEffect(() => {
    dispatch(getDeviceCampaigns({ serial_number: params.id, period: currentParams.current.period || 'all' }));
  }, [dispatch, currentParams.current.period, params.id]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      currentParams.current.campaign_id && dispatch(getDeviceStatistic({
        serial_number: params.id,
        period: currentParams.current.period || 'all',
        campaign_id: currentParams.current.campaign_id,
      }));
    }
  }, [dispatch, currentParams.current.campaign_id, params.id]);

  const handleTouchTrayDevice = useCallback((data) => {
    dispatch(touchDeviceTray(params.id, data));
  }, [dispatch, params.id]);

  const readTraysLog = useCallback((req) => {
    dispatch(readMotorLog(params.id));
  }, [dispatch, params.id]);

  const { showModal: showEditDevice } = useModal(EDIT_DEVICE_MODAL);
  const { showModal: showEditTray } = useModal(DEVICE_EDIT_TRAY_MODAL);
  const { showModal: showEditDeviceCampaign } = useModal(DEVICE_EDIT_CAMPAIGN_MODAL);
  const { showModal: showRebootModal, hideModal: hideRebootModal } = useModal(REBOOT_DEVICE_MODAL);
  const { showModal: showTraysModal, hideModal: hideTraysModal} = useModal(DEVICE_TRAYS_MODAL);

  useEffect(() => {
    openPage(params.id);
  }, [openPage, params.id]);

  return {
    data,
    formValues: {
      period: currentParams.current.period || 'all',
      campaign_id: currentParams.current.campaign_id,
    },
    actions: {
      showEditDevice,
      showEditTray,
      showRebootModal,
      hideRebootModal,
      showTraysModal,
      hideTraysModal,
      handleTouchTrayDevice,
      readTraysLog,
      uploadEmailReceipt,
      removeEmailReceipt,
      uploadLayout,
      removeLayout,
      showEditDeviceCampaign,
      handleRebootDevice,
      handlePeriodChange,
      handleCampaignChange,
    },
  };
};

export default useDeviceInfo;
