import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import _filter from 'lodash/filter';

import CAMPAIGNS from '../../services/types';
import {
  prevStep,
  setStatisticsInfo,
} from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import { SubmissionError } from 'redux-form';
import useModal from '../../../../utils/useModal';
import { SUGGEST_OTHER_MODAL } from '../../../../constants/modals';

const submitLoadingSelector = createLoadingSelector(CAMPAIGNS.SET_STATISTICS_PROPS.base);
const detailsLoadingSelector = createLoadingSelector(CAMPAIGNS.GET_STATISTICS_PROPS.base);

const createCampaignSelector = (id) => createSelector(
  detailsLoadingSelector,
  submitLoadingSelector,
  (state) => state.campaigns.details[id],
  (state) => state.campaigns.stats_visibility[id],
  (isLoadingData, isLoadingSubmit, campaign, stats) => ({
    isLoadingData,
    isLoadingSubmit,
    campaign,
    stats,
  }),
);

const useStatisticsInfo = (id) => {
  const dispatch = useDispatch();
  const selector = createCampaignSelector(id);
  const data = useSelector(selector);

  const goToBack = useCallback(() => {
    dispatch(prevStep());
  }, [dispatch]);

  const setStatistics = useCallback((fields) => {
    const checkSelection = _filter(fields.stats_visibility, (val) => !!val)
    if (!checkSelection || checkSelection.length === 0) {
      throw new SubmissionError({ 
        'custom-validation': 'Select at least one metric' 
      })
    }
    dispatch(setStatisticsInfo(id, fields));
  }, [dispatch, id]);

  const { showModal: showSuggestOther } = useModal(SUGGEST_OTHER_MODAL);

  return {
    data,
    actions: {
      goToBack,
      setStatistics,
      showSuggestOther
    },
  };
};

export default useStatisticsInfo;
