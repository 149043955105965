import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import createLoadingSelector from '../../../../selectors/loadingSelector';
import EMPLOYEES from '../../services/types';
import { activateUser, deactivateUser } from '../../services/actions';

const selector = createSelector(
  createLoadingSelector(EMPLOYEES.ACTIVATE_EMPLOYEE.base),
  (isLoading) => ({
    isLoading,
  }),
);

const useUserStatusModal = () => {
  const dispatch = useDispatch();

  const handleUserStatusChange = useCallback((id, isActive) => {
    dispatch(isActive ? deactivateUser(id) : activateUser(id));
  }, [dispatch]);


  return {
    actions: {
      handleUserStatusChange,
    },
    data: useSelector(selector),
  };
};

export default useUserStatusModal;
