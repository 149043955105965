import React from 'react';

import { Link } from 'react-router-dom';
import { parseRoutePath } from '../../../../../utils/routingHelpers';
import tr from '../../../../../utils/translate';
import { DEVICES_INFO_ROUTE } from '../../../../../constants/routes';

export const devicesColumns = [
  {
    name: tr('Device id'),
    key: 'serial_number',
    render: ({ serial_number }) => `#${serial_number}`,
  },
  {
    name: tr('Label'),
    key: 'label',
    render: ({ serial_number: id, name }) => (
      <Link className="cell-link" to={parseRoutePath(DEVICES_INFO_ROUTE, { id })}>{name || tr('Check Info')}</Link>
    ),
  },
  {
    name: tr('Location'),
    key: 'location',
    render: ({ location }) => location || tr('No data'),
  },
];
