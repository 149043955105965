import React from 'react';

import ReduxForm from '../../../../components/ReduxForm';
import Select from '../../../../components/Select';
import ModalFormFooter from '../../../../components/ModalFormFooter';
import Modal from '../../../../components/Modal';
import useRoleModal from './hooks';
import { EMPLOYEE } from '../../../../constants/forms';
import { EMPLOYEE_ROLE_MODAL } from '../../../../constants/modals';
import tr from '../../../../utils/translate';
import {
  ROLE_SUPERADMIN, ROLE_COMPANY_ADMIN, ROLE_DEPARTMENT_ADMIN, ROLE_CONTENT_MANAGER,
} from '../../../../constants/roles';

const required = (value) => (value ? undefined : 'Required');

const getRoleDropdown = (access_level) => [
  { value: '', label: '' },
  ...(access_level === ROLE_SUPERADMIN ? [{ value: ROLE_COMPANY_ADMIN, label: tr('Brand admin') }] : []),
  { value: ROLE_DEPARTMENT_ADMIN, label: tr('Team member') },
  { value: ROLE_CONTENT_MANAGER, label: tr('Device operator') },
];

const RoleModal = () => (
  <Modal title={tr('Change user role')} name={EMPLOYEE_ROLE_MODAL} size="md">
    {({ handleHide, ...initialValues }) => (
      <RoleForm data={initialValues} handleHide={handleHide} />
    )}
  </Modal>
);


const RoleForm = ({ data, handleHide }) => {
  const {
    actions, data: {
      isLoading, access_level,
    },
  } = useRoleModal(data.id);

  return (
    <ReduxForm
      form={EMPLOYEE.CHANGE_ROLE}
      formData={{
        onSubmit: actions.changeRole,
        initialValues: {
          access: data.access,
        },
      }}
    >
      <div className="modal-body">
        <Select
          name="access"
          placeholder={tr('Choose role')}
          className="pd-b-15"
          options={getRoleDropdown(access_level)}
          validate={[required]}
        />
      </div>
      <div className="modal-footer">
        <ModalFormFooter
          handleClose={handleHide}
          confirmText={tr('Apply')}
          isLoading={isLoading}
        />
      </div>
    </ReduxForm>
  );
};

export default RoleModal;
