import tr from '../../utils/translate';
import { INVITE_BRAND_MODAL, BRAND_MODAL, DEACTIVATE_BRAND_MODAL } from '../../constants/modals';
import useModal from '../../utils/useModal';

const useBrands = () => {
  const { showModal: handleInviteBrand } = useModal(INVITE_BRAND_MODAL);

  const { showModal: AddBrandModal } = useModal(BRAND_MODAL);
  const { showModal: brandStatusChange, hideModal: hideBrandStatusModal } = useModal(DEACTIVATE_BRAND_MODAL);

  return {
    actions: {
      handleInviteBrand,
      AddBrandModal,
      brandStatusChange,
      hideBrandStatusModal
    },
  };
};

export default useBrands;
