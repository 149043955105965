import { useEffect, useState } from 'react';
// import _filter from 'lodash/filter';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getDepartments } from '../services/actions';
import createLoadingSelector from '../../../selectors/loadingSelector';
import DEPARTMENT from '../services/types';
import useModal from '../../../utils/useModal';
import { DEPARTMENT_MODAL, DEACTIVATE_DEPARTMENT_MODAL } from '../../../constants/modals';

const selector = createSelector(
  createLoadingSelector(DEPARTMENT.GET_DEPARTMENTS.base),
  (state) => state.departments.list,
  (state) => state.brands.activeBrand,
  (state) => ({
    count: state.departments.count,
    activePage: state.departments.activePage,
  }),
  (isLoading, departments, activeBrand, pagination) => ({
    isLoading,
    departments,
    activeBrand,
    pagination,
  }),
);

const useDepartmentsTable = () => {
  const dispatch = useDispatch();
  const data = useSelector(selector);
  const [sortBy, setSortBy] = useState({});

  const { showModal: addDepartmentModal } = useModal(DEPARTMENT_MODAL);
  const { showModal: departmentStatusChange } = useModal(DEACTIVATE_DEPARTMENT_MODAL);

  const [activeTab, setActiveTab] = useState('active');

  const changeSorting = (field) => {
    if (sortBy.key === field) {
      setSortBy({
        key: field,
        direction: sortBy.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSortBy({
        key: field,
        direction: 'asc',
      });
    }
  };

  useEffect(() => {
    const ordering = sortBy && (sortBy.direction === 'desc' ? `-${sortBy.key}` : sortBy.key);
    dispatch(getDepartments({ company: data.activeBrand, ordering, is_active: activeTab === 'active', }));
  }, [data.activeBrand, dispatch, sortBy, activeTab]);

  const getNextPage = (page) => {
    const ordering = sortBy && (sortBy.direction === 'desc' ? `-${sortBy.key}` : sortBy.key);
    dispatch(getDepartments({
      ordering,
      page,
      is_active: activeTab === 'active',
    }));
  }

  return {
    data,
    activeTab,
    setActiveTab,
    sortBy,
    actions: {
      addDepartmentModal,
      departmentStatusChange,
      changeSorting,
      getNextPage,
    },
  };
};

export default useDepartmentsTable;
