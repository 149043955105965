import React, { useMemo, useState } from 'react';
import _filter from 'lodash/filter';

import Modal from '../../../components/Modal';
import ReduxForm from '../../../components/ReduxForm';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import ModalFormFooter from '../../../components/ModalFormFooter';
import tr from '../../../utils/translate';
import { INVITE_DEPARTMENT_MODAL } from '../../../constants/modals';
import { DEPARTMENT } from '../../../constants/forms';
import { generateSelect } from '../../../helpers/arrayHelpers';
import {
  ROLE_CONTENT_MANAGER, ROLE_DEPARTMENT_ADMIN, ROLE_COMPANY_ADMIN, ROLE_SUPERADMIN,
} from '../../../constants/roles';
import validate from './validate';
import useInviteModal from './hooks';

const required = (value) => (value ? undefined : 'Required');

const InviteBrandModal = () => {
  const {
    actions: { hideModal, inviteUser }, data: {
      isLoading, departments, access_level, brands,
    },
  } = useInviteModal();

  const departmentSelect = generateSelect(departments, 'name');
  const brandsSelect = generateSelect(brands, 'name');
  const [selectedRole, setSelectedRole] = useState(ROLE_DEPARTMENT_ADMIN);
  const accessLevelSelect = useMemo(() => (
    [
      ...([ROLE_COMPANY_ADMIN, ROLE_SUPERADMIN].includes(access_level) ? [{ value: ROLE_COMPANY_ADMIN, label: tr('Brand admin') }] : []),
      { value: ROLE_DEPARTMENT_ADMIN, label: tr('Team  member') },
      { value: ROLE_CONTENT_MANAGER, label: tr('Device operator') },
    ]
  ), [access_level]);


  return (
    <Modal name={INVITE_DEPARTMENT_MODAL} title={tr('Invite member')} size="sm">
      <ReduxForm
        form={DEPARTMENT.INVITE_USER_FORM}
        validate={validate}
        formData={{
          onSubmit: inviteUser,
          initialValues: {
            access_level: ROLE_DEPARTMENT_ADMIN,
          },
        }}
      >
        <div className="modal-body">
          <Input
            name="user_to"
            className="mg-b-15"
            prependFieldLabel={tr('Email address')}
          />
          <Select
            fieldLabel={tr('Select role')}
            className="pd-b-15"
            name="access_level"
            options={accessLevelSelect}
            validate={[required]}
            onChange={(event) => setSelectedRole(event.target.value)}
          />
          {access_level === ROLE_SUPERADMIN && selectedRole === ROLE_COMPANY_ADMIN
            && (
            <Select
              fieldLabel={tr('Select company')}
              className="pd-b-30"
              name="company"
              options={brandsSelect}
              validate={[required]}
            />
            )}
          {(access_level === ROLE_COMPANY_ADMIN || (access_level === ROLE_SUPERADMIN && [ROLE_DEPARTMENT_ADMIN, ROLE_CONTENT_MANAGER].includes(selectedRole)))
            && (
            <Select
              disabled={access_level === ROLE_COMPANY_ADMIN && selectedRole === ROLE_COMPANY_ADMIN}
              fieldLabel={tr('Select team')}
              className="pd-b-30"
              name="department"
              options={departmentSelect}
              validate={access_level === ROLE_COMPANY_ADMIN && selectedRole === ROLE_COMPANY_ADMIN ? [] : [required]}
            />
            )}

        </div>
        <div className="modal-footer">
          <ModalFormFooter
            handleClose={hideModal}
            confirmText={tr('Send invite')}
            isLoading={isLoading}
          />
        </div>
      </ReduxForm>
    </Modal>
  );
};

export default InviteBrandModal;
