import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import tr from '../../../../utils/translate';
import Button from '../../../../components/Button';
import ReduxForm from '../../../../components/ReduxForm';
import Loader from '../../../../components/Loader';
import { BackarrowIcon } from '../../../../components/Icons';
import useFollowUpsTheme from './hooks';
import './styles.scss';

const FollowUpsTheme = ({ data: { id } }) => {
  const {
    data: {
      isLoadingData,
      isLoadingSubmit,
      themes,
      followUp,
      currentTheme,
    },
    actions: {
      goToBack,
      setTheme,
    },
  } = useFollowUpsTheme(id);

  if (isLoadingData) return <Loader size="md" />;

  return (
    <ReduxForm
      enableReinitialize
      form="FOLLOW_UP_THEMES_FORM"
      formData={{
        onSubmit: setTheme,
        initialValues: {
          theme: followUp.theme_id,
        },
      }}
    >
      <div className="follow-up-themes-form pd-x-25 pd-y-50">
        <h3 data-tip="tip" className="cadf-title mg-b-40">{tr('Follow up')}</h3>
        <div className="fuqf-description">{tr('Choose a theme for follow up')}</div>
        <div className="themes-wrapper">
          {themes && Object.values(themes).map(({ id: idTheme, name, preview_img }) => (
            <div key={idTheme}>
              <Field
                name="theme"
                component={(field) => (
                  <input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field.input}
                    type="radio"
                    id={idTheme}
                    value={idTheme}
                    checked={idTheme === currentTheme}
                    onChange={() => { field.input.onChange(idTheme); }}
                  />
                )}
              />
              <label htmlFor={idTheme}>
                {preview_img && <img src={preview_img} alt="bg" />}
                <span>{name}</span>
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="modal-footer">
        <Button color="light" className="mg-r-30" onClick={goToBack}>
          <BackarrowIcon />
          {tr('Back')}
        </Button>
        <Button color="light" className="mg-r-30" onClick={() => { setTheme({ theme: '' }); }}>
          {tr('I will create my own')}
        </Button>
        <Button
          type="submit"
          disabled={isLoadingSubmit || !currentTheme}
        >
          {tr('Next')}
        </Button>
      </div>
    </ReduxForm>
  );
};

FollowUpsTheme.defaultProps = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }),
};

FollowUpsTheme.defaultProps = {
  data: {},
};

export default FollowUpsTheme;
