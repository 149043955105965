import React, { useMemo } from 'react';
import cx from 'classnames';
import './styles.scss';

const TypeArrow = ({ data }) => {
  const { activePage, start, stop, next_page, getNextPage } = data;

  return (
    <>

      <div className="page">
        {`${start} - ${stop}`}
      </div>

      <div
        className={cx(
          'page',
          { disabled: activePage === 0 },
        )}
        onClick={() => activePage !== 0 && getNextPage(activePage - 1)}
      >

        {'<'}

      </div>

      <div
        className={cx(
          'page',
          { disabled: !next_page },
        )}
        onClick={() => next_page && getNextPage(activePage + 1)}
      >

        {'>'}

      </div>

    </>
  );
};

const TypeStandard = ({ data }) => {
  const { activePage, count, getNextPage } = data;

  const length = Math.ceil((count / 20) || 0);

  return (
    <>

      <div className="page" onClick={() => activePage !== 1 && getNextPage(activePage - 1)}>{'<'}</div>

      {activePage > 4 && (
        <>

          <div className="page" onClick={() => getNextPage(1)}>1</div>

          <div className="page">...</div>

        </>
      )}

      {activePage < 5
        ? (
          Array.from(Array(length < 5 ? length : 5), (_, i) => (
            <div
              key={i}
              className={cx('page', { active: activePage === i + 1 })}
              onClick={(page) => page !== activePage && getNextPage(i + 1)}
            >
              {i + 1}
            </div>
          )))
        : (
          <>

            <div className="page" onClick={() => getNextPage(activePage - 1)}>{activePage - 1}</div>

            <div className="page active">{activePage}</div>

            {activePage !== length && (
              <div className="page" onClick={() => getNextPage(activePage + 1)}>{activePage + 1}</div>
            )}

          </>
        )}

      {(activePage < length - 1 && length > 5) && (
        <>
          <div className="page">...</div>
          <div className="page" onClick={() => getNextPage(length)}>{length}</div>
        </>
      )}

      <div className="page" onClick={() => length !== activePage && getNextPage(activePage + 1)}>{'>'}</div>

    </>
  )
}

export default function Pagination({ data, isTypeArrows }) {
  const TypePagination = useMemo(() => isTypeArrows ? TypeArrow : TypeStandard, [isTypeArrows]);

  return (
    <div className="pagination">

      <div className="list">

        <TypePagination
          data={data}
        />

      </div>

    </div>
  );
}
