import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { SpinnerIcon } from '../Icons';
import './styles.scss';

const Loader = ({ page, size }) => (
  <div className={cn('loader', `loader__${size}`, { loader__page: page })}>
    <SpinnerIcon />
  </div>
);

Loader.propTypes = {
  page: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

Loader.defaultProps = {
  page: false,
  size: 'sm',
};

export default Loader;
