import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import CAMPAIGNS from '../../../services/types';
import { getFollowUpQuestions } from '../../../services/actions';
import createLoadingSelector from '../../../../../selectors/loadingSelector';

const detailsLoadingSelector = createLoadingSelector(CAMPAIGNS.GET_FOLLOW_UP_QUESTIONS.base);

const selector = createSelector(
  detailsLoadingSelector,
  (state) => state.campaigns.questions,
  (isLoadingData, questions) => ({
    isLoadingData,
    questions,
  }),
);

const useFollowUpsQuestions = () => {
  const dispatch = useDispatch();
  const data = useSelector(selector);

  useEffect(() => {
    if (!data.questions || !data.questions.length) {
      dispatch(getFollowUpQuestions());
    }
  }, [data.questions, dispatch]);

  return {
    data,
  };
};

export default useFollowUpsQuestions;
