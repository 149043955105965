import React from 'react';

import tr from '../../../../../utils/translate';

export const suggestionColumns = () => [
  {
    name: tr('Topic'),
    key: 'category_verbose',
  },
  {
    name: tr('Suggestion'),
    key: 'content',
    render: ({ content }) => <div>{content}</div>,
  },
  {
    name: tr('Type'),
    key: 'question_type_verbose',
    render: ({ question_type_verbose }) => <div>{question_type_verbose}</div>,
  },
  {
    name: tr('Choices'),
    key: 'choices',
    render: ({ choices }) => choices && !!choices.length && <div>{choices.join(', ')}</div>,
  },
];
