import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

import CAMPAIGNS from '../../services/types';
import {
  prevStep,
  setFollowUp,
  getFollowUpThemes,
} from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';

const submitLoadingSelector = createLoadingSelector(CAMPAIGNS.SET_FOLLOW_UP.base);
const detailsLoadingSelector = createLoadingSelector(CAMPAIGNS.GET_FOLLOW_UP_THEMES.base);
const formSelector = formValueSelector('FOLLOW_UP_THEMES_FORM');

const createFollowUpsSelector = (id) => createSelector(
  detailsLoadingSelector,
  submitLoadingSelector,
  (state) => state.campaigns.themes,
  (state) => state.campaigns.details[id].follow_up,
  (state) => formSelector(state, 'theme'),
  (isLoadingData, isLoadingSubmit, themes, followUp, currentTheme) => ({
    isLoadingData,
    isLoadingSubmit,
    followUp,
    themes,
    currentTheme,
  }),
);

const useFollowUpsTheme = (id) => {
  const dispatch = useDispatch();
  const data = useSelector(createFollowUpsSelector(id));

  const goToBack = useCallback(() => {
    dispatch(prevStep());
  }, [dispatch]);

  const setTheme = useCallback((values) => {
    dispatch(setFollowUp(id, { theme_id: values.theme }, { isNextStep: true }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getFollowUpThemes());
  }, [dispatch]);

  return {
    data,
    actions: {
      goToBack,
      setTheme,
    },
  };
};

export default useFollowUpsTheme;
