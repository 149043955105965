import React from 'react';
import { Link } from 'react-router-dom';

import { SIGN_IN_ROUTE } from '../../../../constants/routes';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import FormText from '../common/FormText';
import tr from '../../../../utils/translate';
import FormWrap from '../common/FormWrap';
import ReduxForm from '../../../../components/ReduxForm';
import { AUTH } from '../../../../constants/forms';
import validate from './validate';
import useInvite from './hooks';

const Invite = ({ brandInvite }) => {
  const { actions, data: { isLoading, form } } = useInvite(brandInvite);

  return (
    <FormWrap title={tr('Welcome')} subtitle={tr('Get started with your Odore account')}>
      <ReduxForm
        form={AUTH.CONFIRM_INVITE_FORM}
        validate={validate}
        enableReinitialize
        formData={{
          onSubmit: actions.confirmInvitation,
          initialValues: form,
        }}
      >
        {brandInvite
          ? (
            <Input
              disabled
              name="company_name"
              className="pd-b-15"
            />
          )
          : (
            <>
              <Input
                disabled
                prependFieldLabel={tr('Company name')}
                name="company_name"
                className="pd-b-15"
              />
            </>
          )}
        <Input
          disabled
          prependFieldLabel={tr('Email')}
          name="email"
          className="pd-b-15"
        />

        <Input
          name="first_name"
          prependFieldLabel={tr('First name')}
          className="pd-b-15"
        />

        <Input
          name="last_name"
          prependFieldLabel={tr('Last name')}
          className="pd-b-15"
        />

        <Input
          name="city"
          prependFieldLabel={tr('City')}
          className="pd-b-30"
        />

        <Input
          name="state"
          prependFieldLabel={tr('State')}
          className="pd-b-30"
        />

        <Input
          name="address"
          prependFieldLabel={tr('Address')}
          className="pd-b-30"
        />

        <Input
          name="zip"
          prependFieldLabel={tr('Zip')}
          className="pd-b-30"
        />

        <Input
          name="password"
          prependFieldLabel={tr('Password')}
          type="password"
          className="pd-b-15"
        />

        <Input
          name="repassword"
          prependFieldLabel={tr('Confirm password')}
          type="password"
          className="pd-b-15"
        />

        <div className="pd-t-4 mg-b-30">
          <Button
            type="submit"
            className="btn-block"
            status={isLoading ? 'disabled' : 'active'}
            disabled={isLoading}
          >
            {tr('Complete registration')}
          </Button>
        </div>

        <div className="text-center">
          <FormText>
            {tr('Already have an account?')}
            &nbsp;
            <Link to={SIGN_IN_ROUTE}>{tr('Sign In')}</Link>
          </FormText>
        </div>
      </ReduxForm>
    </FormWrap>
  );
};

export default Invite;
