import React from 'react';
import Table from '../../../../../components/Table';
import tr from '../../../../../utils/translate';

import { actionsColumns } from './data';
import useActionsTable from './hooks';
import './styles.scss';

const ActionsTable = ({ campaignId }) => {
  const {
    data: {
      actions,
    },
  } = useActionsTable(campaignId);
  return (
    <>
      <div className="mg-b-30">
        <Table caption={tr('Actions')} columns={actionsColumns()} data={actions || []} />
      </div>
    </>
  );
};

export default ActionsTable;
