import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip';
import tr from '../../../../utils/translate';
import SuggestOtherQuestionModal from '../SuggestOtherQuestionModal';
import Button from '../../../../components/Button';
import ReduxForm from '../../../../components/ReduxForm';
import Input from '../../../../components/Input';
import Loader from '../../../../components/Loader';
import { BackarrowIcon } from '../../../../components/Icons';
import useFollowUpsQuestions from './hooks';
import './styles.scss';

const FollowUpsQuestions = ({ data: { id } }) => {
  const {
    data: {
      isLoadingData,
      isLoadingSubmit,
      questions,
      followUp,
    },
    actions: {
      goToBack,
      setQuestions,
      showSuggestOther,
    },
  } = useFollowUpsQuestions(id);

  if (isLoadingData) return <Loader size="md" />;

  const initQuestions = followUp && followUp.questions.reduce(
    (res, item) => ({ ...res, [item]: true }),
    {},
  );

  return (
    <>
      <ReduxForm
        enableReinitialize
        form="FOLLOW_UP_QUESTIONS_FORM"
        formData={{
          onSubmit: setQuestions,
          initialValues: {
            questions: initQuestions || {},
            delay: (followUp && followUp.delay) || 1,
          },
        }}
      >
        <div className="follow-up-questions-form pd-x-25 pd-y-50">
          <h3 data-tip="tip" className="cadf-title mg-b-40">{tr('Follow up')}</h3>
          <div className="fuqf-description">{tr('Choose the questions you\'d like to ask clients in a follow up')}</div>
          <div className="checkbox-wrapper">
            {questions.map(({ id: idQuestion, title }) => (
              <Input
                type="checkbox"
                key={idQuestion}
                id={`questions.${idQuestion}`}
                name={`questions.${idQuestion}`}
                appendFieldLabel={title}
                className="pd-b-15"
              />
            ))}
            <div className="pd-b-15 suggest-other-wrapper">
              <Button color="light" onClick={showSuggestOther}>
                {tr('Suggest other')}
              </Button>
            </div>
          </div>
          <div className="fuqf-delay">
            <div className="fuqf-description fuqf-delay-description">{tr('Send within number of days')}</div>
            <Input
              min={1}
              type="number"
              id="delay"
              name="delay"
              className="fuqf-delay-input"
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button color="light" className="mg-r-30" onClick={goToBack}>
            <BackarrowIcon />
            {tr('Back')}
          </Button>
          <Button
            type="submit"
            disabled={isLoadingSubmit}
          >
            {tr('Next')}
          </Button>
        </div>
      </ReduxForm>
      <Tooltip />
      <SuggestOtherQuestionModal campaignId={id} />
    </>
  );
};

FollowUpsQuestions.defaultProps = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }),
};

FollowUpsQuestions.defaultProps = {
  data: {},
};

export default FollowUpsQuestions;
