import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import _find from 'lodash/find';

import { changeUserRole } from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import EMPLOYEES from '../../services/types';

const selector = () => createSelector(
  createLoadingSelector(EMPLOYEES.CHANGE_ROLE.base),
  (state) => state.account.accountDetails.access_level,
  (isLoading, access_level) => ({
    isLoading,
    access_level,
  }),
);

const useRoleModal = (id) => {
  const dispatch = useDispatch();
  const changeRole = useCallback((data) => {
    dispatch(changeUserRole(id,data))
  }, [dispatch, id]);

  return {
    data: useSelector(selector(id)),
    actions: {
      changeRole,
    },
  };
};

export default useRoleModal;
