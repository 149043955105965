import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './styles.scss';

const Frame = (props) => {
  const {
    header,
    footer,
    children,
    className,
    classNameBody,
    classNameHeader,
    classNameFooter,
  } = props;

  return (
    <div className={cn('card', className)}>
      {header && <div className={cn('card-header', classNameHeader)}>{header}</div>}
      <div className={cn('card-body', classNameBody)}>
        {children}
      </div>
      {footer && <div className={cn('card-footer', classNameFooter)}>{footer}</div>}
    </div>
  );
};

Frame.propTypes = {
  children: PropTypes.node.isRequired,
  classNameFooter: PropTypes.string,
  classNameHeader: PropTypes.string,
  classNameBody: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
};

Frame.defaultProps = {
  classNameFooter: null,
  classNameHeader: null,
  classNameBody: null,
  className: null,
  footer: null,
  header: null,
};

export default Frame;
