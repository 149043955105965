import React from 'react';
import PropTypes from 'prop-types';

import tr from '../../../../utils/translate';
import ReduxForm from '../../../../components/ReduxForm';
import CampaignInfo from '../CampaignInfoTab';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import {
  BackarrowIcon,
} from '../../../../components/Icons';
import useInterface from './hooks';
import './styles.scss';

const Preview = ({ data: { id, isNew } }) => {
  const {
    data: {
      isLoading,
      isLoadingProducts,
      campaign,
      social_networks,
      ad_campaign,
    },
    actions: {
      goToBack,
      submitHandler,
    },
    campaignProducts,
  } = useInterface(id, isNew);

  if ((id && !campaign) || isLoading || isLoadingProducts) return <Loader size="md" />;

  return (
    <>

      <ReduxForm
        enableReinitialize
        form="COMPAIGN_PREVIEW"
        formData={{
          onSubmit: submitHandler,
        }}
      >

        <div className="campaign-preview-step pd-x-25 pd-y-50">

          <h3 className="cif-title mg-b-40">{tr('Summary')}</h3>

          <CampaignInfo
            campaign={campaign}
            products={campaignProducts}
            social_networks={social_networks}
            ad_campaign={ad_campaign}
            isPreview
          />

        </div>

        <div className="modal-footer">

          <Button color="light" className="mg-r-30" onClick={goToBack}>

            <BackarrowIcon />

            {tr('Back')}

          </Button>

          <Button type="submit" disabled={isLoading}>

            {tr('Finish')}

          </Button>

        </div>

      </ReduxForm>

    </>
  );
};

Preview.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }),
};

Preview.defaultProps = {
  data: {},
};

export default Preview;
