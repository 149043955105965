import { createAction } from 'redux-actions';

import CAMPAIGNS from './types';
import { createActionRequest } from '../../../utils/actionHelpers';

export const getDeviceCampaigns = createActionRequest(
  CAMPAIGNS.READ_LIST,
  'get',
  '/bo/campaigns/',
  {
    setDataPath: (params) => ({ params }),
  },
);

export const createCampaign = createActionRequest(CAMPAIGNS.CREATE_CAMPAIGN, 'post', '/bo/campaigns/', {});

export const editSocialNetworks = createActionRequest(
  CAMPAIGNS.EDIT_SOCIAL_NETWORKS,
  'put',
  '/bo/campaigns/{id}/social_networks/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
);

export const editCampaign = createActionRequest(
  CAMPAIGNS.EDIT_CAMPAIGN,
  'patch',
  '/bo/campaigns/{id}/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
  true,
);

export const getDeviceCampaignDetails = createActionRequest(
  CAMPAIGNS.READ_DETAILS,
  'get',
  '/bo/campaigns/{id}/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const getCampaignSocialNetworks = createActionRequest(
  CAMPAIGNS.READ_SOCIAL_NETWORKS,
  'get',
  '/bo/campaigns/{id}/social_networks_available/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const getAssociatedDevices = createActionRequest(
  CAMPAIGNS.GET_ASSOCIATED_DEVICES,
  'get',
  '/bo/devices/available/',
  {
    setDataPath: (params) => ({ params }),
  },
);

export const setAssociatedDevices = createActionRequest(
  CAMPAIGNS.SET_ASSOCIATED_DEVICES,
  'put',
  '/bo/campaigns/{id}/devices_edit/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
);

export const setDemographics = createActionRequest(
  CAMPAIGNS.SET_DEMOGRAPHICS,
  'put',
  '/bo/campaigns/{id}/demographics/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
);

export const editInterface = createActionRequest(
  CAMPAIGNS.UPDATE_INTERFACE,
  'patch',
  '/bo/campaigns/{id}/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
    setMeta: (id, data, isEmailListFile) => ({ isEmailListFile }),
    format: 'form',
  },
);

export const editProductAction = createActionRequest(CAMPAIGNS.UPDATE_PRODUCT, 'patch', '/bo/products/{number}/', {
  setDataPath: (number) => ({ number }),
  setData: (number, data) => data,
  format: 'form',
});

export const createProductAction = createActionRequest(CAMPAIGNS.CREATE_PRODUCT, 'post', '/bo/products/', {
  setData: (data) => data,
  format: 'form',
});

export const saveProductsSelected = createActionRequest(
  CAMPAIGNS.UPDATE_PRODUCT_SELECTION,
  'put',
  '/bo/campaigns/{id}/device/products/', {
  setDataPath: (id) => ({ id }),
  setData: (id, data) => data,
  format: 'form',
},
);

export const saveProductsWithQuantity = createActionRequest(
  CAMPAIGNS.UPDATE_PRODUCT_SELECTION_WITH_QUANTITY,
  'put',
  '/bo/campaigns/{id}/online/products/', {
  setDataPath: (id) => ({ id }),
  setData: (id, data) => data,
},
);

export const exportConfig = createActionRequest(
  CAMPAIGNS.EXPORT_CONFIG,
  'get',
  '/bo/campaigns/{id}/export/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const exportStatistics = createActionRequest(
  CAMPAIGNS.EXPORT_STATISTICS,
  'get',
  'bo/campaigns/{id}/report/?unique={unique}',
  {
    setDataPath: ({ id, unique }) => ({ id, unique }),
  },
);

export const exportCustomStatistics = createActionRequest(
  CAMPAIGNS.EXPORT_CUSTOM_STATISTICS,
  'get',
  'bo/campaigns/{id}/custom_report/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const getCsvFile = createActionRequest(
  CAMPAIGNS.GET_EMAIL_CSV_FILE,
  'get',
  'bo/campaigns/{id}/directmail_csvfile/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const getCampaignStatistic = createActionRequest(
  CAMPAIGNS.GET_CAMPAIGN_STATISTIC,
  'get',
  'bo/statistics',
  {
    setDataPath: (params) => ({ params }),
  },
);

export const getCampaignStatisticInteractions = createActionRequest(
  CAMPAIGNS.GET_CAMPAIGN_STATISTIC_INTERACTIONS,
  'get',
  'bo/statistics/interactions/',
  {
    setDataPath: (params) => ({ params }),
  },
);

export const getStatisticFacebook = createActionRequest(
  CAMPAIGNS.GET_STATISTIC_FACEBOOK,
  'get',
  'bo/campaigns/{id}/marketing-statistic/',
  {
    setDataPath: (params) => ({ id: params.campaign_id, params }),
  },
);

export const getCompanyProducts = createActionRequest(
  CAMPAIGNS.GET_PRODUCTS,
  'get',
  'bo/products/',
  {
    setDataPath: (params) => ({ params }),
  },
);

export const setStatisticsInfo = createActionRequest(
  CAMPAIGNS.SET_STATISTICS_PROPS,
  'put',
  'bo/campaigns/{id}/statistics_config/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
);
export const approveCampaign = createActionRequest(
  CAMPAIGNS.APPROVE_CAMPAIGN,
  'put',
  '/bo/campaigns/{id}/approve/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
);

export const rejectCampaign = createActionRequest(
  CAMPAIGNS.REJECT_CAMPAIGN,
  'put',
  '/bo/campaigns/{id}/reject/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
);

export const uploadEmailInterfaceData = createActionRequest(
  CAMPAIGNS.UPDATE_EMAIL_INTERFACE_DATA,
  'patch',
  '/bo/campaigns/{id}/directmail/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
    format: 'form',
  },
);

export const uploadMainEmail = createActionRequest(
  CAMPAIGNS.UPLOAD_MAIN_EMAIL,
  'patch',
  '/bo/campaigns/{id}/directmail/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
    format: 'form',
  },
);

export const getFileteredUsersList = createActionRequest(
  CAMPAIGNS.FILTER_USER_DATABASE,
  'get',
  '/bo/customers/count/',
  {
    setDataPath: (params) => ({ params }),
  },
);

export const setFileteredUsersList = createActionRequest(
  CAMPAIGNS.SET_FILTERED_USER_LIST,
  'patch',
  '/bo/campaigns/{id}/respondent_targeting/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
);

export const sendSuggestion = createActionRequest(
  CAMPAIGNS.SEND_SUGGESTION,
  'post',
  '/bo/campaigns/{id}/add_suggestion/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
);

export const sendFollowUpSuggest = createActionRequest(
  CAMPAIGNS.SEND_FOLLOW_UP_SUGGEST,
  'post',
  '/bo/follow-up-suggestions/',
);

export const sendFollowUpPreviewEmail = createActionRequest(
  CAMPAIGNS.SEND_FOLLOW_UP_PREVIEW_EMAIL,
  'post',
  '/bo/campaigns/{id}/follow_up/send/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, recipient) => ({ recipient }),
  },
);

export const sendTestMails = createActionRequest(
  CAMPAIGNS.SEND_TEST_MAILS,
  'post',
  '/bo/campaigns/{id}/send_test_message/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
);

export const startMailingService = createActionRequest(
  CAMPAIGNS.RUN_MAILING_SERVICE,
  'post',
  '/bo/campaigns/{id}/send/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
);

export const validateDomain = createActionRequest(
  CAMPAIGNS.VALIDATE_DOMAIN,
  'post',
  '/bo/campaigns/{id}/verify_domain/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
);

export const addProductStock = createActionRequest(
  CAMPAIGNS.ADD_PRODUCT_STOCK,
  'patch',
  '/bo/campaign-trays/{id}/add_products/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
);

export const getSuggestions = createActionRequest(
  CAMPAIGNS.GET_SUGGESTIONS,
  'get',
  '/bo/campaigns/{id}/suggestions/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const getCampaignActions = createActionRequest(
  CAMPAIGNS.GET_ACTIONS,
  'get',
  '/bo/campaigns/{id}/actions/',
  {
    setDataPath: (id) => ({ id }),
  },
);

export const createdCampaign = createActionRequest(
  CAMPAIGNS.CREATED_CAMPAIGN,
  'put',
  '/bo/campaigns/{id}/created/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
  },
);

export const getFollowUpQuestions = createActionRequest(
  CAMPAIGNS.GET_FOLLOW_UP_QUESTIONS,
  'get',
  '/bo/follow-up-questions',
);

export const setFollowUp = createActionRequest(
  CAMPAIGNS.SET_FOLLOW_UP,
  'patch',
  '/bo/campaigns/{id}/follow_up/',
  {
    setDataPath: (id) => ({ id }),
    setData: (id, data) => data,
    setMeta: (id, data, meta) => meta,
  },
);

export const getFollowUpThemes = createActionRequest(
  CAMPAIGNS.GET_FOLLOW_UP_THEMES,
  'get',
  '/bo/follow-up-themes/',
);

export const getFollowUpTheme = createActionRequest(
  CAMPAIGNS.GET_FOLLOW_UP_THEME,
  'get',
  '/bo/follow-up-themes/{theme_id}/',
  {
    setDataPath: (theme_id) => ({ theme_id }),
  },
);

export const setFollowUpTheme = createActionRequest(
  CAMPAIGNS.SET_FOLLOW_UP_THEME,
  'patch',
  '/bo/follow-up-themes/{theme_id}/',
  {
    setDataPath: (theme_id) => ({ theme_id }),
    setData: (theme_id, data) => data,
    format: 'form',
  },
  true,
);

export const getFollowUpStatistic = createActionRequest(
  CAMPAIGNS.GET_FOLLOW_UP_STATISTICS,
  'get',
  '/bo/campaigns/{id}/follow_up/statistics/',
  {
    setDataPath: ({ campaign_id }) => ({ id: campaign_id }),
    setData: ({ period }) => ({ period }),
  },
);

export const followUpPreview = createAction(
  CAMPAIGNS.FOLLOW_UP_PREVIEW,
  (id, data) => ({ id, data }),
);

export const followUpPreviewEmail = createAction(
  CAMPAIGNS.FOLLOW_UP_PREVIEW_EMAIL,
  (id, data) => ({ id, data }),
);

export const followUpDesign = createAction(
  CAMPAIGNS.FOLLOW_UP_DESIGN,
  (params, data, { type, isNextStep, isNew }) => ({
    params, data, type, isNextStep, isNew,
  }),
);

export const deleteSession = createActionRequest(
  CAMPAIGNS.DELETE_SESSION,
  'delete',
  'bo/campaigns/{id}/poll_sessions/{poll_session_id}/',
  {
    setDataPath: (props) => {
      const { campaign_id, poll_session_id } = props;
      return { id: campaign_id, poll_session_id };
    },
  },
);

export const deleteSessions = createAction(
  CAMPAIGNS.DELETE_SESSIONS.base,
);

export const unsubscribesList = createActionRequest(
  CAMPAIGNS.UNSUBSCRIBES_LIST,
  'get',
  '/bo/campaigns/{id}/unsubscribes_list/',
  {
    setDataPath: ({ id }) => ({ id })
  }
);

export const exportUnsubscribes = createActionRequest(
  CAMPAIGNS.EXPORT_UNSUBSCRIBES,
  'get',
  'bo/campaigns/{id}/unsubscribes_report/',
  {
    setDataPath: ({ id }) => ({ id }),
  },
);

export const getAdAccounts = createActionRequest(
  CAMPAIGNS.GET_AD_ACCOUNTS,
  'get',
  'bo/accounts/ad-accounts/',
);

export const getFbPages = createActionRequest(
  CAMPAIGNS.GET_FB_PAGES,
  'get',
  'bo/accounts/ad-accounts/{id}/pages/',
  {
    setDataPath: ({ id }) => ({ id }),
  },
);

export const getFbTargetings = createActionRequest(
  CAMPAIGNS.GET_FB_TARGETINGS,
  'get',
  '/bo/accounts/ad-accounts/{id}/targetings/',
  {
    setDataPath: ({ id }) => ({ id }),
  },
);

export const getAdCampaign = createActionRequest(
  CAMPAIGNS.GET_AD_CAMPAIGN,
  'get',
  'bo/marketing/ad-campaigns/{id}/',
  {
    setDataPath: ({ id }) => ({ id }),
  },
);

export const getAccountExists = createActionRequest(
  CAMPAIGNS.GET_ACCOUNT_EXISTS,
  'get',
  'bo/marketing/ad-campaigns/?ad_account={id}',
  {
    setDataPath: ({ id }) => ({ id }),
  },
);

export const getReachEstimate = createActionRequest(
  CAMPAIGNS.GET_REACH_ESTIMATE,
  'post',
  'bo/accounts/ad-accounts/{id}/reach_estimate/',
  {
    setDataPath: ({ id }) => ({ id }),
    setData: ({ data }) => ({ ...data }),
  },
);

export const startFacebookCampaign = createActionRequest(
  CAMPAIGNS.START_FACEBOOK_CAMPAIGN,
  'post',
  'bo/marketing/ad-campaigns/{id}/activate/',
  {
    setDataPath: ({ id }) => ({ id }),
  },
);

export const pauseFacebookCampaign = createActionRequest(
  CAMPAIGNS.PAUSE_FACEBOOK_CAMPAIGN,
  'post',
  'bo/marketing/ad-campaigns/{id}/pause/',
  {
    setDataPath: ({ id }) => ({ id }),
  },
);

export const closeModal = createAction(CAMPAIGNS.CLOSE_MODAL);

export const firstStep = createAction(CAMPAIGNS.FIRST_STEP);

export const nextStep = createAction(CAMPAIGNS.NEXT_STEP);

export const prevStep = createAction(CAMPAIGNS.PREV_STEP);

export const setCampaignType = createAction(CAMPAIGNS.SET_CAMPAIGN_TYPE);

export const setStep = createAction(CAMPAIGNS.SET_STEP);

// export const closeModal = createAction(CAMPAIGNS.CLOSE_MODAL);
