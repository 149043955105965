import React from 'react';

import { Field } from 'redux-form';
import ReduxForm from '../../../../components/ReduxForm';
import ModalFormFooter from '../../../../components/ModalFormFooter';
import Modal from '../../../../components/Modal';
import useAssignDevices from './hooks';
import { EMPLOYEE } from '../../../../constants/forms';
import { ASSIGN_DEVICES_MEMBER } from '../../../../constants/modals';
import tr from '../../../../utils/translate';
import TableDevices from './TableDevices';
import Checkbox from '../../../../components/Checkbox';
import './styles.scss';

// const required = (value) => (value ? undefined : 'Required');

const AssignDevicesModal = () => (
  <Modal title={tr('Assign devices')} name={ASSIGN_DEVICES_MEMBER} size="lg">
    {({ handleHide, ...initialValues }) => (
      <RoleForm data={initialValues} handleHide={handleHide} />
    )}
  </Modal>
);

const allCheckbox = ({ input }) => (
  <Checkbox
    name="all_devices"
    label={tr('All devices')}
    className="form-all-devices"
    checked={input.value}
    onChange={(event) => input.onChange(event.target.checked)}
  />
);

const RoleForm = ({ data, handleHide }) => {
  const {
    actions,
    data: {
      isLoading, devices, assigned_devices,
    },
    isAllDeviceSelected,
  } = useAssignDevices(data.id);

  return (
    <ReduxForm
      enableReinitialize
      form={EMPLOYEE.ASSIGN_DEVICES}
      formData={{
        onSubmit: actions.assignDevices,
        initialValues: {
          ...assigned_devices && {
            all_devices: assigned_devices.all_devices,
            devices: assigned_devices.devices.map(({ serial_number }) => serial_number),
          },
        },
      }}
    >
      <div className="modal-body">
        <div className="employee-assign-devices-form pd-x-25 pd-y-50">
          <Field
            name="all_devices"
            component={allCheckbox}
            onChange={(value) => actions.setAllDeviceSelection(value)}
          />
          <h4 className="assign-device-subtitle pd-y-15 ">{tr('or select specific devices')}</h4>
          {!isLoading && (
          <TableDevices
            name="devices"
            classNameWrapper="cadf-table-wrapper"
            options={[...devices]}
            disable={isAllDeviceSelected}
          />
          )}

        </div>
      </div>
      <div className="modal-footer">
        <ModalFormFooter
          className="assign-device-footer"
          handleClose={handleHide}
          confirmText={tr('Apply')}
          isLoading={isLoading}
        />
      </div>
    </ReduxForm>
  );
};

export default AssignDevicesModal;
