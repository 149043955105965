import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Loader from '../../components/Loader';
import {
  ROLE_COMPANY_ADMIN, ROLE_DEPARTMENT_ADMIN, ROLE_SUPERADMIN, ROLE_CONTENT_MANAGER,
} from '../../constants/roles';
import { getAccountDetails } from '../../modules/account/services/actions';

import AdminRoutes from './AdminRoutes';
import AnonymousRoutes from './AnonymousRoutes';
import ContentManagerRoutes from './ContentManagerRoutes';

const Routes = ({
  isLoading, isAuthorized, tokenRefresh, access_level,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem('auth');
    if (token) {
      dispatch(getAccountDetails());
      tokenRefresh(token);
    }
  }, [dispatch, tokenRefresh]);
  const getRoutes = useMemo(() => {
    if ((!isAuthorized && isLoading) || (isAuthorized && !access_level)) {
      return <Loader page size="lg" />;
    }
    if (isAuthorized) {
      switch (access_level) {
        case ROLE_DEPARTMENT_ADMIN:
        case ROLE_SUPERADMIN:
        case ROLE_COMPANY_ADMIN:
          return <AdminRoutes access_level={access_level} />;
        case ROLE_CONTENT_MANAGER:
        default:
          return <ContentManagerRoutes />;
      }
    }
    return <AnonymousRoutes />;
  }, [isLoading, isAuthorized, access_level]);

  return getRoutes;
};

Routes.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  tokenRefresh: PropTypes.func.isRequired,
};

export default Routes;
