import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../../components/Checkbox';
import './styles.scss';
import tr from '../../../../utils/translate';

const genders = [
  {
    value: 3,
    label: tr('All'),
  },
  {
    value: 1,
    label: tr('Male'),
  },
  {
    value: 2,
    label: tr('Female'),
  },
];

const GenderField = ({ input }) => (
  <div className="gender-checkbox-wrapper">
    {genders.map(({ value, label }) => {
      return (
        <Checkbox
          key={value}
          name={String(value)}
          checked={value === input.value}
          label={label}
          onChange={(event) => {
            let newValue = input.value;
            if (event.target.checked) {
              newValue = value;
            }
            return input.onChange(newValue);
          }}
        />
      );
    })}
  </div>
);

GenderField.propTypes = {
  input: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.number,
    PropTypes.array,
  ])).isRequired,
};

export default GenderField;
