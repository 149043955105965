import React from 'react';
import { Route, Switch } from 'react-router';

import { AppLayout } from '../../modules/layout';
import Account from '../../modules/account/components';
import { Devices, ContentManagerDeviceInfo } from '../../modules/devices/containers';
import {
  HOME_ROUTE,
  ACCOUNT_ROUTE,
  DEVICES_INFO_ROUTE,
  DEVICES_ROUTE,
} from '../../constants/routes';

const ContentManagerRoutes = () => (
  <AppLayout>
    <Switch>
      <Route exact path={HOME_ROUTE} component={Devices} />
      <Route exact path={DEVICES_ROUTE} component={Devices} />
      <Route exact path={ACCOUNT_ROUTE} component={Account} />
      <Route exact path={DEVICES_INFO_ROUTE} component={ContentManagerDeviceInfo} />
    </Switch>
  </AppLayout>
);

export default ContentManagerRoutes;
