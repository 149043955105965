import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import DropdownContext from '../DropdownContext';
import './styles.scss';

const DropDownItem = ({
  tag: Tag, className, toggle, disabled, onClick, children,
}) => {
  const dropdownContext = useContext(DropdownContext);

  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault();
    } else {
      if (onClick) {
        onClick(e);
      }

      if (toggle) {
        dropdownContext.toggle(e);
      }
    }
  };

  return (
    <Tag
      className={cn('dropDownItem', className)}
      type="button"
      onClick={handleClick}
    >

      {children}

    </Tag>
  );
};

DropDownItem.defaultProps = {
  tag: 'button',
  className: '',
  onClick: undefined,
  disabled: false,
  toggle: false,
  children: '',
};

DropDownItem.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  toggle: PropTypes.bool,
  children: PropTypes.node,
};

export default DropDownItem;
