import AUTH from './types';

const defaultState = {
  isAuthorized: false,
  isAdmin: false,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case AUTH.SIGN_IN.SUCCESS:
    case AUTH.TOKEN_REFRESH.SUCCESS:
      return {
        ...state,
        isAuthorized: !!payload.res.token,
      };
    case AUTH.SIGN_OUT:
      return {
        ...state,
        isAuthorized: false,
      };
    case AUTH.GET_INVITATION.SUCCESS:
      return {
        ...state,
        company_real_name: payload.res.company_name,
      };
    default:
      return state;
  }
};
