import { createAction } from 'redux-actions';
import { createActionRequest } from '../../../utils/actionHelpers';
import DEVICES from './types';

export const getDevices = createActionRequest(
  DEVICES.GET_ALL_DEVICES,
  'get', '/bo/devices/',
  {
    setData: (params, is_active) => ({ ...params, is_active }),
  },
);

export const getDeviceInfo = createActionRequest(DEVICES.GET_DIVECE_INFO, 'get', '/bo/devices/{id}/details/', {
  setDataPath: (id) => ({ id }),
});

// use it on the same page as getDeviceInfo but doesnt show loader for better UX
export const getDeviceUntracked = createActionRequest(DEVICES.GET_DIVECE_INFO_UNTRACKED, 'get', '/bo/devices/{id}/details/', {
  setDataPath: (id) => ({ id }),
});

export const setDeviceInfo = createActionRequest(DEVICES.SET_DIVECE_INFO, 'patch', '/bo/devices/{id}/details/', {
  setDataPath: (id) => ({ id }),
  setData: (id, data) => data,
});

export const setDeviceCampaign = createActionRequest(DEVICES.SET_DEVICE_CAMPAIGN, 'patch', '/bo/devices/{id}/details/', {
  setDataPath: (id) => ({ id }),
  setData: (id, data) => data,
});

export const addDevice = createActionRequest(DEVICES.ADD_NEW_DEVICE, 'put', '/bo/devices/{id}/activate', {
  setDataPath: (id) => ({ id }),
  setData: (id, data) => data,
});

export const touchDeviceTray = createActionRequest(DEVICES.TOUCH_TRAY, 'post', '/bo/devices/{id}/touch_tray/{number}', {
  setDataPath: (id, number) => ({ id, number }),
});


export const readMotorLog = createActionRequest(DEVICES.READ_MOTOR_LOG, 'get', '/bo/devices/{id}/testlog/', {
  setDataPath: (id) => ({ id }),
});

export const getCompanyProducts = createActionRequest(
  DEVICES.GET_PRODUCTS,
  'get',
  'bo/products/',
  {},
);

export const addRowScreensaver = createAction(
  DEVICES.ADD_ROW_SCREENSAVER,
  (serial_number) => ({ serial_number }),
);

export const createScreensaver = createActionRequest(
  DEVICES.CREATE_SCREENSAVER,
  'post',
  '/bo/devices/screensavers/',
  {
    setData: (device, file) => ({ device, file }),
    format: 'form',
  },
);
export const updateScreensaver = createActionRequest(
  DEVICES.UPDATE_SCREENSAVER,
  'put',
  '/bo/devices/{serial_number}/screensavers/{id}/',
  {
    setDataPath: (serial_number, id) => ({ serial_number, id }),
    setData: (serial_number, id, file) => ({ file, device: serial_number }),
    format: 'form',
  },
);

export const deleteScreensaver = createActionRequest(
  DEVICES.DELETE_SCREENSAVER,
  'delete',
  '/bo/devices/{serial_number}/screensavers/{id}/',
  {
    setDataPath: (serial_number, id) => ({ serial_number, id }),
  },
);

export const editDeviceTray = createActionRequest(DEVICES.UPDATE_DIVICE_TRAY, 'patch', '/bo/devices/{serial_number}/trays/{id}/', {
  setDataPath: (serial_number, id) => ({ serial_number, id }),
  setData: (serial_number, id, data) => data,
  format: 'form',
});

export const setEmailReceipt = createActionRequest(DEVICES.CREATE_EMAIL_RECEIPT, 'post', '/bo/devices/email-receipt/', {
  setData: (device, file) => ({ device, file }),
  format: 'form',
});

export const updateEmailReceipt = createActionRequest(DEVICES.UPDATE_EMAIL_RECEIPT, 'put', '/bo/devices/{serial_number}/email-receipt/', {
  setDataPath: (serial_number) => ({ serial_number }),
  setData: (serial_number, file) => ({ file }),
  format: 'form',
});

export const deleteEmailReceipt = createActionRequest(
  DEVICES.DELETE_EMAIL_RECEIPT,
  'delete',
  '/bo/devices/{serial_number}/email-receipt/',
  {
    setDataPath: (serial_number) => ({ serial_number }),
  },
);

export const setLayout = createActionRequest(DEVICES.CREATE_LAYOUT, 'post', '/bo/devices/layout/', {
  setData: (device, file) => ({ device, file }),
  format: 'form',
});

export const updateLayout = createActionRequest(DEVICES.UPDATE_LAYOUT, 'put', '/bo/devices/{serial_number}/layout/', {
  setDataPath: (serial_number) => ({ serial_number }),
  setData: (serial_number, file) => ({ file }),
  format: 'form',
});

export const deleteLayout = createActionRequest(
  DEVICES.DELETE_LAYOUT,
  'delete',
  '/bo/devices/{serial_number}/layout/',
  {
    setDataPath: (serial_number) => ({ serial_number }),
  },
);

export const rebootDevice = createActionRequest(
  DEVICES.REBOOT_DEVICE,
  'put',
  'bo/devices/{id}/reboot',
  {
    setDataPath: (id) => ({ id }),
  },
);


export const getDeviceStatistic = createActionRequest(
  DEVICES.GET_DEVICE_STATISTIC,
  'get',
  'bo/statistics',
  {
    setDataPath: (params) => ({ params }),
  },
);

export const getDeviceCampaigns = createActionRequest(
  DEVICES.GET_DEVICE_CAMPAIGNS,
  'get',
  'bo/statistics/clist',
  {
    setDataPath: (params) => ({ params }),
  },
);

export const getDeviceCurrentCampaign = createActionRequest(DEVICES.GET_CAMPAIGN_STATS_AVAILABLE, 'get', '/bo/campaigns/{id}/', {
  setDataPath: (id) => ({ id }),
});

export const pageDeviceInfo = createAction(DEVICES.PAGE_DEVICE_INFO);

export const deleteSessionDevices = createActionRequest(
  DEVICES.DELETE_SESSION,
  'delete',
  'bo/devices/{id}/poll_sessions/{poll_session_id}/',
  {
    setDataPath: ({ id, poll_session_id }) => {
      return { id, poll_session_id };
    },
  },
);
