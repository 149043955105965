import React from 'react';

import Modal from '../../../../components/Modal';
import { BRAND_MODAL } from '../../../../constants/modals';
import BrandForm from '../BrandForm';

const BrandModal = () => (
  <Modal name={BRAND_MODAL} size="md">
    {({ handleHide, ...initialValues }) => (
      <BrandForm data={initialValues} handleHide={handleHide} />
    )}
  </Modal>
);

export default BrandModal;
