import React from 'react';
import { Link } from 'react-router-dom';
import _capitalize from 'lodash/capitalize';
import { USERS_DETAILS_ROUTE } from '../constants/routes';
import {
  formatTime,
  transformDuration,
  formatDateNoUtc,
} from '../utils/dates';
import { parseRoutePath } from '../utils/routingHelpers';
import translate from '../utils/translate';

export const getUserColumns = (withLink = false, handleSorting, sortBy) => [
  {
    name: translate('User id'),
    key: 'id',
    render: ({ id }) => {
      if (!id) {
        return translate('No data');
      }
      return withLink ? <Link className="cell-link" to={parseRoutePath(USERS_DETAILS_ROUTE, { id })}>{id}</Link> : id;
    },
  },
  {
    name: translate('Name'),
    key: 'name',
    className: sortBy && `table-column-sort ${sortBy.key === 'name' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('name') : undefined,
    render: ({ first_name, last_name }) => (
      `${first_name || ''} ${last_name || ''}`
    ),
  },
  {
    name: translate('Contact'),
    key: 'email',
    className: sortBy && `table-column-sort ${sortBy.key === 'email' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('email') : undefined,
  },
  {
    name: translate('Address'),
    key: 'address',
    className: sortBy && `table-column-sort ${sortBy.key === 'address' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('address') : undefined,
  },
  {
    name: translate('Phone'),
    key: 'phone_number',
    className: sortBy && `table-column-sort ${sortBy.key === 'phone_number' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('phone_number') : undefined,
  },
  {
    name: translate('Gender'),
    key: 'gender',
    className: sortBy && `table-column-sort ${sortBy.key === 'gender' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('gender') : undefined,
    render: ({ gender }) => _capitalize(gender),
  },
  {
    name: translate('Age'),
    key: 'age',
    className: sortBy && `table-column-sort ${sortBy.key === 'age' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('age') : undefined,
  },
  {
    name: translate('Registration date'),
    key: 'date_registration',
    className: sortBy && `table-column-sort ${sortBy.key === 'date_registration' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('date_registration') : undefined,
    render: ({ date_registration, date_created }) => date_registration ? formatDateNoUtc(date_registration) : formatDateNoUtc(date_created), // eslint-disable-line
  },
];

export const questionsStatColumns = (showDevice = false, handleSorting, sortBy) => [
  {
    name: translate('User id'),
    key: 'user_id',
    className: sortBy && `table-column-sort ${sortBy.key === 'user_id' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('user_id') : undefined,
    render: ({ user_id }) => {
      if (!user_id) {
        return translate('No data');
      }
      return <Link className="cell-link" to={parseRoutePath(USERS_DETAILS_ROUTE, { id: user_id })}>{user_id}</Link>;
    },
  },
  {
    name: translate('Duplicated'),
    key: 'is_duplicate',
    className: sortBy && `table-column-sort ${sortBy.key === 'is_duplicate' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('is_duplicate') : undefined,
    render: ({ is_duplicate }) => (
      `${is_duplicate ? 'Yes' : 'No'}`
    ),
  },
  {
    name: translate('Name'),
    key: 'name',
    className: sortBy && `table-column-sort ${sortBy.key === 'name' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('name') : undefined,
    render: ({ first_name, last_name }) => (
      `${first_name || ''} ${last_name || ''}`
    ),
  },
  {
    name: translate('Email'),
    key: 'email',
    className: sortBy && `table-column-sort ${sortBy.key === 'email' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('email') : undefined,
  },
  {
    name: translate('Gender'),
    key: 'gender',
    className: sortBy && `table-column-sort ${sortBy.key === 'gender' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('gender') : undefined,
    render: ({ gender }) => _capitalize(gender),
  },
  {
    name: translate('Location'),
    key: 'location',
    className: sortBy && `table-column-sort ${sortBy.key === 'location' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('location') : undefined,
  },
  ...showDevice ? [{
    name: translate('Device details'),
    key: 'device_details',
    className: sortBy && `table-column-sort ${sortBy.key === 'device_details' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('device_details') : undefined,
  }] : [],
  {
    name: translate('Date'),
    key: 'date',
    className: sortBy && `table-column-sort ${sortBy.key === 'date_created' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('date_created') : undefined,
    render: ({ date_created }) => (date_created ? formatDateNoUtc(date_created) : translate('No data')),
  },
  {
    name: translate('Source'),
    key: 'source',
    className: sortBy && `table-column-sort ${sortBy.key === 'source' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('source') : undefined,
    render: ({ source }) => _capitalize(source),
  },
  {
    name: translate('Start'),
    key: 'time_start',
    className: sortBy && `table-column-sort ${sortBy.key === 'date_created' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('date_created') : undefined,
    render: ({ date_created }) => (date_created ? formatTime(date_created) : translate('No data')),
  },
  {
    name: translate('Finish'),
    key: 'time_finish',
    className: sortBy && `table-column-sort ${sortBy.key === 'date_updated' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('date_updated') : undefined,
    render: ({ date_updated }) => (date_updated ? formatTime(date_updated) : translate('No data')),
  },
  {
    name: translate('Sample'),
    key: 'product_name',
    className: sortBy && `table-column-sort ${sortBy.key === 'product_name' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('product_name') : undefined,
  },
  {
    name: translate('Duration'),
    key: 'duration',
    className: sortBy && `table-column-sort ${sortBy.key === 'duration' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: sortBy ? () => handleSorting('duration') : undefined,
    render: ({ duration }) => (duration ? transformDuration(duration) : translate('No data')),
  },
];
