import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useParams } from 'react-router-dom';

import { DELETE_MODAL } from '../../../../constants/modals';
import CAMPAIGNS from '../../services/types';

import createLoadingSelector from '../../../../selectors/loadingSelector';
import useModal from '../../../../utils/useModal';
import { getCampaignStatisticInteractions } from '../../services/actions';

const selector = createSelector(
  createLoadingSelector(CAMPAIGNS.DELETE_SESSION.SUCCESS.base),
  (isLoading, pagination) => ({ isLoading, pagination }),
);

const useCampaignsDetailedTable = () => {
  const dispatch = useDispatch();

  const params = useParams();

  const data = useSelector(selector);

  const [pollSessions, setPollSessions] = useState({
    idsToDeleteArr: [],
  });

  const { showModal: showDeleteModal } = useModal(DELETE_MODAL);

  const getPollSessions = (id, checked) => {
    switch (checked) {
      case true:
        setPollSessions((prevIds) => ({
          idsToDeleteArr: [...prevIds.idsToDeleteArr, id],
        }));
        break;
      case false:
        const filteredIdsArray = pollSessions.idsToDeleteArr.filter((item) => item !== id);

        setPollSessions(() => ({
          idsToDeleteArr: filteredIdsArray,
        }));
        break;
      default:
        setPollSessions((prevIds) => ({
          idsToDeleteArr: [...prevIds.idsToDeleteArr, id],
        }));
        break;
    }
  };

  const handleCancel = () => setPollSessions({ idsToDeleteArr: [] });

  const getNextPage = (page) => {
    dispatch(getCampaignStatisticInteractions({
      campaign_id: params.id,
      page,
    }));
  };

  return {
    pollSessions,
    actions: {
      getPollSessions,
      showDeleteModal,
      handleCancel,
      getNextPage,
    },
    data,
  };
};

export default useCampaignsDetailedTable;
