import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { createSelector } from 'reselect';
import Table from '../../../../components/Table';
import translate from '../../../../utils/translate';
import { parseRoutePath } from '../../../../utils/routingHelpers';
import { DEVICES_INFO_ROUTE } from '../../../../constants/routes';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import { formatBeforeSend } from '../../../../utils/dates'; // formatDateTime
import { getDevices } from '../../services/actions';
import DEVICES from '../../services/types';
import { ROLE_SUPERADMIN, ROLE_COMPANY_ADMIN } from '../../../../constants/roles';
import './styles.scss';
import { devicesPaginationSelector } from '../../selectors';

const selector = createSelector(
  createLoadingSelector(DEVICES.GET_ALL_DEVICES.base),
  (state) => state.devices.list,
  (state) => state.brands.activeBrand,
  (state) => state.departments.activeDepartment,
  (state) => state.account.accountDetails.access_level,
  devicesPaginationSelector,
  (isLoading, devices, activeBrand, activeDepartment, access_level, pagination) => ({
    isLoading,
    devices,
    activeBrand,
    activeDepartment,
    access_level,
    pagination,
  }),
);

const tabs = [
  { key: 'active', name: translate('Active') },
  { key: 'disabled', name: translate('Inactive') },
];

const columns = (access_level, handleSorting, sortBy) => [
  {
    name: translate('Device ID'),
    key: 'serial_number',
    className: `table-column-sort ${sortBy.key === 'serial_number' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('serial_number'),
    render: ({ serial_number }) => `#${serial_number}`,
  },
  {
    name: translate('Label'),
    key: 'name',
    className: `table-column-sort ${sortBy.key === 'name' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('name'),
    render: ({ name, serial_number: id }) => (
      <Link className="cell-link" to={parseRoutePath(DEVICES_INFO_ROUTE, { id })}>{name}</Link>
    ),
  },
  {
    name: translate('Location'),
    key: 'location',
    className: `table-column-sort ${sortBy.key === 'location' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('location'),
  },
  ...(access_level === ROLE_SUPERADMIN ? [{
    name: translate('Brand'),
    key: 'company_name',
    className: `table-column-sort ${sortBy.key === 'company' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('company'),
    render: ({ company, company_name }) => (company ? (<span className={cn('company-info-cell', { 'company-inactive': !company.is_active })}>{company.name}</span>) : company_name),
  }] : []),
  ...([ROLE_COMPANY_ADMIN, ROLE_SUPERADMIN].includes(access_level) ? [{
    name: translate('Team'),
    key: 'dep_name',
    className: `table-column-sort ${sortBy.key === 'department' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('department'),
    render: ({ department, dep_name }) => (department ? (<span className={cn('company-info-cell', { 'company-inactive': !department.is_active })}>{department.name}</span>) : dep_name),
  }] : []),
  {
    name: translate('Last interaction'),
    key: 'last_interaction',
    className: `table-column-sort ${sortBy.key === 'last_interaction' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('last_interaction'),
    render: ({ last_interaction }) => (last_interaction ? formatBeforeSend(last_interaction) : null),
  },
  {
    name: translate('Status'),
    key: 'status',
    className: `table-column-sort ${sortBy.key === 'status' && (sortBy.direction === 'asc' ? 'table-column-asc' : 'table-column-desc') || ''}`,
    sorting: () => handleSorting('status'),
    render: ({ status }) => (
      status
      && (
      <span
        className={`cell-status cell-status_${status.split(' ').join('-').toLowerCase()}`}
      >
        {status}
      </span>
      )
    ),
  },
];

const List = () => {
  const dispatch = useDispatch();
  const {
    devices, isLoading, activeBrand, activeDepartment, access_level, pagination,
  } = useSelector(selector);
  const [activeTab, setActiveTab] = useState(tabs[0].key);
  const [sortBy, setSortBy] = useState({});

  const changeSorting = (field) => {
    if (sortBy.key === field) {
      setSortBy({
        key: field,
        direction: sortBy.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSortBy({
        key: field,
        direction: 'asc',
      });
    }
  };

  const getAllDevices = useCallback((isActive, ordering, page) => {
    dispatch(getDevices({
      ordering,
      ...(activeBrand && { company: activeBrand }),
      ...(activeDepartment && { department: activeDepartment }),
      page,
    }, isActive));
  }, [dispatch, activeBrand, activeDepartment]);

  const getNextDevices = (page) => getAllDevices(true, "", page);

  useEffect(() => {
    const sortField = sortBy && (sortBy.direction === 'desc' ? `-${sortBy.key}` : sortBy.key);
    getAllDevices(true, sortField);
  }, [getAllDevices, sortBy]);

  const handleSelectTab = (index, { name }) => {
    getAllDevices(name === tabs[0].key);
    setActiveTab(name);
  };

  return (
    <Table
      className="device-table-status"
      loading={isLoading}
      tabs={tabs}
      columns={columns(access_level, changeSorting, sortBy)}
      data={{ [activeTab]: devices }}
      onSelectTab={handleSelectTab}
      pagination={{ ...pagination, getNextPage: getNextDevices }}
    />
  );
};

export default List;
