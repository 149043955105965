import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Frame from '../Frame';
import './styles.scss';

const PieChartLegend = (props) => {
  const {
    className,
    title,
    subtitle,
    badgeValue,
    badgeColor,
    badgeColorCode,
  } = props;
  const style = badgeColorCode ? { backgroundColor: badgeColorCode } : {};
  return (
    <Frame className={cn('legend-item', className)}>
      <div className="legend pd-b-3">
        <div className="mg-r-15">
          <div className={`legend__logo legend__logo_${badgeColor}`} style={style}>
            {badgeValue}
          </div>
        </div>
        <div className="legend__info">
          <h3 className="legend__title">{title}</h3>
          <span className="legend__subtitle">{subtitle}</span>
        </div>
      </div>
    </Frame>
  );
};

PieChartLegend.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  badgeValue: PropTypes.string,
  badgeColor: PropTypes.string,
  badgeColorCode: PropTypes.string,
};

PieChartLegend.defaultProps = {
  className: null,
  subtitle: '',
  badgeColor: 'yellow',
  badgeColorCode: '',
  badgeValue: '',
};

export default PieChartLegend;
