import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import DropdownContext from '../DropdownContext';
import './styles.scss';

const DropdownToggle = ({
  className, disabled, onClick, children,
}) => {
  const dropdownContext = useContext(DropdownContext);

  const handleClick = (e) => {
    if (disabled || dropdownContext.disabled) {
      e.preventDefault();
    } else {
      if (onClick) {
        onClick(e);
      }

      dropdownContext.toggle(e);
    }
  };

  return (
    <div
      role="presentation"
      className={cn(
        'DropdownToggle',
        className,
      )}
      onClick={handleClick}
    >

      {children}

    </div>
  );
};

DropdownToggle.defaultProps = {
  className: '',
  disabled: false,
  onClick: null,
};

DropdownToggle.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default DropdownToggle;
