import React from 'react';

import ReduxForm from '../../../../components/ReduxForm';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import ModalFormFooter from '../../../../components/ModalFormFooter';
import validate from './validate';
import useBrandModal from './hooks';
import { BRAND } from '../../../../constants/forms';
import tr from '../../../../utils/translate';

const BrandForm = ({ data, handleHide }) => {
  const { actions, data: { isLoading, countries, brand } } = useBrandModal(data.company_id);
  return (
    <ReduxForm
      form={BRAND.EDIT_BRAND_FORM}
      validate={validate}
      formData={{
        onSubmit: actions.editBrand,
        initialValues: brand && {
          name: brand.name,
          country: brand.country && brand.country.id,
          email: brand.email,
        },
      }}
    >
      <div className="modal-body">
        <Input
          name="name"
          prependFieldLabel={tr('Brand name')}
          className="pd-b-15"
        />
        <Input
          name="email"
          prependFieldLabel={tr('Contact email')}
          className="pd-b-30"
        />
        <Select
          name="country"
          placeholder={tr('Country')}
          className="pd-b-15"
          options={countries}
        />
      </div>
      <div className="modal-footer">
        <ModalFormFooter
          handleClose={handleHide}
          confirmText={tr('Apply')}
          isLoading={isLoading}
        />
      </div>
    </ReduxForm>
  );
};

export default BrandForm;
