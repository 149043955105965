import {
  call,
  put,
  take,
  fork,
} from 'redux-saga/effects';
import _capitalize from 'lodash/capitalize';
import { toast } from 'react-toastify';

import sendRequest from '../../utils/apiService';
import translate from '../../utils/translate';
import { API_CALL } from '../../constants/api';
import { signOut } from '../auth/services/actions';

function* processRequest({ actions, ...payload } = {}) {
  if (!actions) return;

  try {
    const res = yield call(sendRequest, payload);

    if (payload.options && payload.options.onSuccess) {
      payload.options.onSuccess(res);
    }

    yield put({
      type: actions.SUCCESS,
      payload: {
        res,
        req: payload,
      },
    });
  } catch (res) {
    if (res.status === 500) {
      toast.error('Internal Server Error');
    } else if (res.status === 401) {
      toast.error(res.message);
      yield put(signOut());
    } else if (res.message) {
      toast.error(res.message);
    } else if (res.errors) {
      Object.keys(res.errors).slice(0, 5).forEach((field) => {
        const errors = res.errors[field];

        if (errors instanceof Array) {
          errors.forEach((error) => {
            toast.error(`${_capitalize(field.split('_').join(' '))}: ${error}`);
          });
        } else {
          toast.error(`${_capitalize(field.split('_').join(' '))}: ${errors}`);
        }
      });
    } else {
      toast.error(translate('Something went wrong'));
    }

    yield put({
      type: actions.FAILURE,
      error: true,
      payload: {
        res,
        req: payload,
      },
    });
  }
}

/**
 * Watch actions for API requests
 */
export default function* watchApiRequests() {
  while (true) {
    const { payload } = yield take((action) => action.payload && action.payload[API_CALL]);

    yield fork(processRequest, payload[API_CALL]);
  }
}
