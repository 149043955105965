import translate from '../../../../utils/translate';

const validate = ({
  name,
  location,
}) => {
  const errors = {};

  if (!name) errors.name = translate('Device name is required');

  if (!location) errors.location = translate('Location is required');

  return errors;
};

export default validate;
