import { useEffect, useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  pageDeviceInfo,
  rebootDevice,
  readMotorLog,
  touchDeviceTray,
} from '../../services/actions';
import {
  deviceInfoLoadingSelector,
  createDeviceInfoByIdSelector,
  emailReceiptLoadingSelector,
  layoutLoadingSelector,
  rebootDeviceLoadingSelector,
} from '../../selectors';
import {
  DEVICE_EDIT_TRAY_MODAL,
  REBOOT_DEVICE_MODAL,
  DEVICE_TRAYS_MODAL,
} from '../../../../constants/modals';
import useModal from '../../../../utils/useModal';

const createDeviceSelector = (id) => createSelector(
  createDeviceInfoByIdSelector(id),
  deviceInfoLoadingSelector,
  emailReceiptLoadingSelector,
  layoutLoadingSelector,
  rebootDeviceLoadingSelector,
  (state) => state.devices.motorlogs[id],
  (state) => state.modal[DEVICE_TRAYS_MODAL],
  (device, isLoading, isLoadingEmailReceipt, isLoadingLayout, isLoadingReboot, motorLogs, modalState) => ({
    device,
    isLoading,
    isLoadingEmailReceipt,
    isLoadingLayout,
    isLoadingReboot,
    motorLogs,
    modalState
  }),
);

const useDeviceInfo = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const selector = createDeviceSelector(params.id);
  const data = useSelector(selector);

  const openPage = useCallback((id) => {
    dispatch(pageDeviceInfo(id));
  }, [dispatch]);

  const handleRebootDevice = useCallback(() => {
    dispatch(rebootDevice(params.id));
  }, [dispatch, params.id]);

  const handleTouchTrayDevice = useCallback((data) => {
    dispatch(touchDeviceTray(params.id, data));
  }, [dispatch, params.id]);

  const readTraysLog = useCallback(() => {
    dispatch(readMotorLog(params.id));
  }, [dispatch, params.id]);

  const { showModal: showEditTray } = useModal(DEVICE_EDIT_TRAY_MODAL);
  const { showModal: showRebootModal, hideModal: hideRebootModal } = useModal(REBOOT_DEVICE_MODAL);
  const { showModal: showTraysModal, hideModal: hideTraysModal} = useModal(DEVICE_TRAYS_MODAL);

  useEffect(() => {
    openPage(params.id);
  }, [openPage, params.id]);

  return {
    data,
    actions: {
      showEditTray,
      showTraysModal,
      hideTraysModal,
      showRebootModal,
      hideRebootModal,
      handleRebootDevice,
      handleTouchTrayDevice,
      readTraysLog,
    },
  };
};

export default useDeviceInfo;
