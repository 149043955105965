import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import UploadFile from '../../../../components/UploadFile';
import useCurrentCampaign from './hooks';
import { FollowUpsIcon } from '../../../../components/Icons';

const DeviceEmailReceipt = ({ currentCampaignId }) => {
  const { data } = useCurrentCampaign(currentCampaignId);

  const email_receipt = _get(data, ['campaign', 'email_receipt'], null);

  return (
    <UploadFile
      name="file"
      color="green"
      data={email_receipt}
      disabled
      icon={<FollowUpsIcon />}
      hideDeleteButton
    />
  );
};

DeviceEmailReceipt.propTypes = {
  currentCampaignId: PropTypes.string.isRequired,
};

export default DeviceEmailReceipt;
