import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const FormWrap = ({ title, subtitle, children }) => (
  <div className="auth-form-wrap">
    <h2 className="auth-form-wrap__title">{title}</h2>
    <p className="auth-form-wrap__subtitle">{subtitle}</p>
    <div>
      {children}
    </div>
  </div>
);

FormWrap.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FormWrap;
