import emailValidator from 'email-validator';

import tr from '../../../../utils/translate';

const validate = ({ email, password }) => {
  const errors = {};

  if (!email) errors.email = tr('Email is required');
  else if (!emailValidator.validate(email)) errors.email = tr('Invalid Email');

  if (!password) {
    errors.password = tr('Password is required.');
  }

  return errors;
};

export default validate;
