import React from 'react';
import PropTypes from 'prop-types';
import _groupBy from 'lodash/groupBy';

import { resultsColumns } from '../../tableData';
import Table from '../../../../../../components/Table';
import translate from '../../../../../../utils/translate';

const Results = ({ campaigns, polls }) => {
  const grouppedPolls = _groupBy(polls, (v) => (v.campaign_id));
  const grouppedPollsList = Object.keys(grouppedPolls);

  if (!grouppedPollsList.length) return (<p>{translate('There is no results yet.')}</p>);
  return (
    <div>
      {grouppedPollsList.map((id) => {
        const currentCampaign = campaigns.find((camp) => camp.id === id);
        return (
          <div key={id}>
            {grouppedPolls[id].map((poll, index) => (
              <div className="row mg-b-30" key={index}>
                <div className="col-md-12">
                  <Table
                    caption={currentCampaign ? currentCampaign.name : '-'}
                    columns={resultsColumns}
                    data={poll.answers}
                  />
                </div>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

Results.propTypes = {
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  polls: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default Results;
