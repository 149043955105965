import React from 'react';
import { Link } from 'react-router-dom';

import { SIGN_UP_ROUTE, FORGOT_PASSWORD_ROUTE } from '../../../../constants/routes';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import FormText from '../common/FormText';
import validate from './validate';
import ReduxForm from '../../../../components/ReduxForm';
import tr from '../../../../utils/translate';
import FormWrap from '../common/FormWrap';
import { AUTH } from '../../../../constants/forms';
import useSignIn from './hooks';

const SignIn = () => {
  const { actions: { loginAccount }, data: { isLoading } } = useSignIn();

  return (
    <FormWrap title={tr('Sign in')} subtitle={tr('Sign in to your Odore account')}>
      <ReduxForm
        form={AUTH.SIGN_IN_FORM}
        validate={validate}
        formData={{
          onSubmit: loginAccount,
        }}
      >
        <Input
          name="email"
          prependFieldLabel={tr('Email address')}
          className="pd-b-15"
        />

        <Input
          name="password"
          type="password"
          prependFieldLabel={tr('Password')}
        />

        <div className="text-center mg-t-20 mg-b-20">
          <Link to={FORGOT_PASSWORD_ROUTE}>{tr('Forgot Password?')}</Link>
        </div>

        <div className="pd-t-4 mg-b-30">
          <Button
            type="submit"
            className="btn-block"
            status={isLoading ? 'disabled' : 'active'}
            disabled={isLoading}
          >
            {tr('Sign in')}
          </Button>
        </div>

        <div className="text-center">
          <FormText>
            {tr('Don\'t have an account?')}
            &nbsp;
            <Link to={SIGN_UP_ROUTE}>{tr('Registration')}</Link>
          </FormText>
        </div>
      </ReduxForm>
    </FormWrap>
  );
};

export default SignIn;
