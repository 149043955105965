import React, { useState, useMemo } from 'react';
import Select from 'react-select';
import OverviewBadges from '../../widgets/OverviewBadges';
import ColumnChart from '../../../../components/ColumnChart';
import Frame from '../../../../components/Frame';
import FrameHeader from '../../../../components/FrameHeader';
import tr from '../../../../utils/translate';
import './styles.scss';
import Table from '../../../../components/Table';
import ProductDispencesChart from '../../../../shared/statistic/ProductDispencesChart';
import TimeOfDayChart from '../../../../shared/statistic/TimeOfDayChart';
import QAchart from '../../../../shared/statistic/QAchart';

const tableConfiig = [
  {
    name: '',
    key: 'question',
  },
];

const typeOptions = [
  { value: 'all', label: tr('All') },
  { value: 'device', label: tr('Device') },
  { value: 'qr', label: tr('Smartphone') },
];

const expandRenderer = ({ question, answers }) => (
  <QAchart key={question} chartData={{ answers }} />
);

const DeviceOverviewTab = ({ statData, dateRange, stats_visibility }) => {
  const {
    samples_per_period,
    qr_samples_per_period,
    device_samples_per_period,
    samples_per_time_of_day,
    samples_by_title,
    question_answers,
    ...otherData
  } = statData;
  const [type, setType] = useState(typeOptions[0].value);

  const badgesInfo = {
    samples: otherData.samples || 0,
    engagement: otherData.engagement_time || 0,
    samplesToday: otherData.samples_today || 0,
    engagementToday: otherData.engagement_time_today || 0,
    through_rate: '',
    bounce_rate: '',
  };

  const header = (
    <FrameHeader
      rightBlock={dateRange}
      title={(
        <div className="cot-dispenses-title">
          <div>
            {tr('Dispenses per day')}
          </div>
          <Select
            options={typeOptions}
            classNamePrefix="type-filter"
            className="type-filter-wrapper"
            defaultValue={typeOptions[0]}
            onChange={({ value }) => { setType(value); }}
          />
        </div>
      )}
    />
  );

  const despensesPerDay = useMemo(() => {
    if (type === 'qr') {
      return qr_samples_per_period;
    }

    if (type === 'device') {
      return device_samples_per_period;
    }

    return samples_per_period;
  }, [device_samples_per_period, qr_samples_per_period, samples_per_period, type]);

  const dispensesPerDayChart = (
    <Frame
      className="mg-b-30"
      header={header}
    >
      <ColumnChart chartData={despensesPerDay} seriesName={tr('Dispenses')} />
    </Frame>
  );

  const productDispensesChart = (
    <ProductDispencesChart
      chartData={samples_by_title}
      header={<FrameHeader title={tr('Products dispenses')} rightBlock={dateRange} />}
      legendLabel={tr('products')}
    />
  );

  const timeOfDayChart = (
    <div className="col">
      <TimeOfDayChart
        chartData={samples_per_time_of_day}
        header={<FrameHeader title={tr('Time Of Day')} rightBlock={dateRange} />}
        legendLabel={tr('products')}
      />
    </div>
  );

  const socialMediaChart = (
    <div className="col">
      <Frame
        header={<FrameHeader title={tr('Social Media')} rightBlock={dateRange} />}
        classNameBody="social-chart"
      >
        <ColumnChart chartData={[]} height="260" />
      </Frame>
    </div>
  );

  const qaChart = (
    <Frame
      header={<FrameHeader title={tr('Questions and answers')} rightBlock={dateRange} />}
      className="mg-b-30"
    >
      {!!(question_answers && question_answers.length)
        && (
        <Table
          columns={tableConfiig}
          data={question_answers}
          expandRenderer={expandRenderer}
          className="qa-stat-table"
          expandedOnLoad
        />
        )}
    </Frame>
  );

  return (
    <>
      <OverviewBadges stats_visibility={stats_visibility} stats={badgesInfo} />
      {stats_visibility && stats_visibility.samples_per_period && dispensesPerDayChart}
      {stats_visibility && stats_visibility.samples_by_title && productDispensesChart}
      <div className="overview-two-column mg-b-30">
        {stats_visibility && stats_visibility.samples_per_time_of_day && timeOfDayChart}
        {stats_visibility && stats_visibility.social_media && socialMediaChart}
      </div>
      {stats_visibility && stats_visibility.question_answers && qaChart}
    </>
  );
};

export default DeviceOverviewTab;
