import _pickBy from 'lodash/pickBy';
import _identity from 'lodash/identity';

export const removeEmptyParams = (params) => _pickBy(params, _identity);

export const setParams = (params) => {
  const searchParams = new URLSearchParams();

  const keys = Object.keys(removeEmptyParams(params));
  keys.map((key) => {
    searchParams.set(key, params[key]);
    return null;
  });

  return searchParams.toString();
};
