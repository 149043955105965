import React from 'react';

import Modal from '../../../../components/Modal';
import ReduxForm from '../../../../components/ReduxForm';
import Input from '../../../../components/Input';
import ModalFormFooter from '../../../../components/ModalFormFooter';
import tr from '../../../../utils/translate';
import validate from './validate';
import useResetPasswordModal from './hooks';
import { RESET_PASSWORD_MODAL } from '../../../../constants/modals';
import { ACCOUNT } from '../../../../constants/forms';

const ResetPasswordModal = () => {
  const {
    actions: {
      hideModal,
      changePassword,
    },
    data: {
      isLoading,
    },
  } = useResetPasswordModal();

  return (
    <Modal name={RESET_PASSWORD_MODAL} size="sm" title={tr('Reset password')}>
      <ReduxForm
        form={ACCOUNT.CHANGE_PASSWORD_FORM}
        validate={validate}
        formData={{
          onSubmit: changePassword,
        }}
      >
        <div className="modal-body">
          <Input
            name="old_password"
            prependFieldLabel={tr('Old password')}
            type="password"
            className="pd-b-15"
          />

          <Input
            name="password"
            prependFieldLabel={tr('New password')}
            type="password"
            className="pd-b-15"
          />

          <Input
            name="password_confirm"
            prependFieldLabel={tr('Repeat new password')}
            type="password"
          />
        </div>
        <div className="modal-footer">
          <ModalFormFooter
            handleClose={hideModal}
            confirmText={tr('Save changes')}
            isLoading={isLoading}
          />
        </div>
      </ReduxForm>
    </Modal>
  );
};

export default ResetPasswordModal;
