import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import tr from '../../utils/translate';

const dashboardCrumb = [{ to: '/', body: tr('Dashboard') }];

const Breadcrumb = ({ list, className }) => {
  const crumbsList = [...dashboardCrumb, ...list];
  return (
    <div className={cn('breadcrumb', className)}>
      {crumbsList.map((item) => (item instanceof Object
        ? <Link className="breadcrumb-item" to={item.to} key={item.to}>{item.body}</Link>
        : <span className="breadcrumb-item active" key={item}>{item}</span>))}
    </div>
  );
};

Breadcrumb.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      body: PropTypes.node,
    }),
  ),
};

Breadcrumb.defaultProps = {
  className: 'pd-l-0 mg-b-0',
  list: [],
};

export default Breadcrumb;
