import { createRequestTypes } from '../../../utils/actionHelpers';

export default createRequestTypes('EMPLOYEES', [
  'READ_USERS_LIST',
  'CHANGE_ROLE',
  'DEACTIVATE_EMPLOYEE',
  'ACTIVATE_EMPLOYEE',
  'INVITE_USER',
  'USER_DEVICES',
  'SET_USER_DEVICES',
]);
