import React from 'react';
import _filter from 'lodash/filter';
import tr from '../../../../utils/translate';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import ReduxForm from '../../../../components/ReduxForm';
import { SUGGEST_OTHER_MODAL } from '../../../../constants/modals';
import useSuggestOther from './hook';

const SuggestOtherModal = ({ campaignId, category }) => {
  const {
    data: {
      isLoading,
    },
    actions: {
      submitSuggestOther,
    },
  } = useSuggestOther(campaignId);
  return (
    <Modal name={SUGGEST_OTHER_MODAL} size="lg">
      {({ handleHide, ...initialValues }) => (
        <ReduxForm
          enableReinitialize
          form="SUGGEST_OTHER_FORM"
          formData={{
            onSubmit: submitSuggestOther,
            initialValues: {
              category,
            },
          }}
        >
          <div className="compaign-details-form pd-x-25 pd-y-50">
            <h3 className="cdf-title mg-b-40">{tr('Suggest other')}</h3>
            <div className="mg-b-30">

              <Input
                name="suggest"
                placeholder={tr('Write a description')}
                rows={1}
                type="textarea"
              />
              <Input
                name="category"
                type="hidden"
              />
            </div>
          </div>
          <div className="modal-footer">
            <Button color="light" className="mg-r-30" onClick={handleHide}>
              {tr('Cancel')}
            </Button>
            <Button
              type="submit"
              status={isLoading ? 'disabled' : 'active'}
              disabled={isLoading}
            >
              {tr('Save')}
            </Button>
          </div>
        </ReduxForm>
      )}
    </Modal>
  );
};

export default SuggestOtherModal;
