import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Chrome from 'react-color/lib/Chrome';
import { Field } from 'redux-form';

import './styles.scss';

const SelectFont = (props) => {
  const {
    append,
    prepend,
    size,
    options,
    className,
    classNameControl,
    classNameWrapper,
    input,
    defaultValue,
    id,
    ...otherProps
  } = props;
  const [isShow, setIsShow] = useState(false);

  const handleToggleShow = useCallback(() => {
    setIsShow(!isShow);
  }, [isShow]);

  return (
    <div className={cn('select-color-wrapper', classNameWrapper)}>
      <button
        type="button"
        style={{ backgroundColor: input.value || '#fff' }}
        className={cn('select-color-value', className)}
        onClick={handleToggleShow}
      />
      {isShow && (
        <>
          <div className="select-color-bg" onClick={handleToggleShow} />
          <Chrome
          // eslint-disable-next-line react/jsx-props-no-spreading
            {...otherProps}
            disableAlpha
            className={cn('select-color-control', classNameControl)}
            color={input.value || defaultValue}
            onChange={({ hex }) => {
              input.onChange(hex);
            }}
          />
        </>
      )}
    </div>
  );
};

SelectFont.propTypes = {
  classNameControl: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  append: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  prepend: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  input: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.number,
  ])).isRequired,
  meta: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ])).isRequired,
  fieldLabel: PropTypes.string,
  classNameWrapper: PropTypes.string,
  defaultValue: PropTypes.string,
};

SelectFont.defaultProps = {
  size: null,
  fieldLabel: null,
  append: null,
  prepend: null,
  classNameControl: null,
  className: null,
  classNameWrapper: null,
  defaultValue: undefined,
};
// eslint-disable-next-line react/jsx-props-no-spreading
const ReduxSelectFont = (props) => <Field {...props} component={SelectFont} />;

export default ReduxSelectFont;
