import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import createLoadingSelector from '../../../../selectors/loadingSelector';
import AUTH from '../../services/types';
import { changePassword as changePasswordAction } from '../../services/actions';

const selector = createSelector(
  createLoadingSelector(AUTH.CHANGE_PASSWORD.base),
  (isLoading) => ({
    isLoading,
  }),
);

const useChangePassword = () => {
  const dispatch = useDispatch();

  const changePassword = useCallback((values) => {
    dispatch(changePasswordAction(values));
  }, [dispatch]);

  return {
    actions: {
      changePassword,
    },
    data: useSelector(selector),
  };
};

export default useChangePassword;
