import React from 'react';
import { Link } from 'react-router-dom';

import { ACCOUNT_ROUTE } from '../../../../../constants/routes';
import { AccountIcon } from '../../../../../components/Icons';
import useHeaderProfile from './hooks';
import './style.scss';

const HeaderProfile = () => {
  const { accountDetails } = useHeaderProfile();

  if (!accountDetails) return null;

  return (
    <Link to={ACCOUNT_ROUTE}>
      <span className="header-profile-text">{accountDetails.email}</span>
      <AccountIcon />
    </Link>
  );
};

export default HeaderProfile;
