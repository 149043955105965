import React from 'react';

import Button from '../../components/Button';
import PageHeader from '../../components/PageHeader';
import tr from '../../utils/translate';
import BrandModal from './BrandModal';
import InviteBrandModal from './InviteBrandModal';
import DeactivateModal from './DeactivateModal';
import BrandsTable from './BrandsTable';
import useBrands from './hooks';
import './style.scss';

const BrandsPage = () => {
  const { actions } = useBrands();

  const headerButtons = (
    <div>
      <Button onClick={actions.handleInviteBrand}>{tr('Invite brand')}</Button>

    </div>
  );
  return (
    <div className="page-inner pd-t-25 pd-b-25">
      <PageHeader title={tr('Brands')}>
        {headerButtons}
      </PageHeader>

      <div className="row">
        <div className="col-md-12 brands-list">
          <BrandsTable />
        </div>
      </div>
      <InviteBrandModal />
      <DeactivateModal />
      <BrandModal />
    </div>
  );
};

export default BrandsPage;
