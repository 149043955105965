import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import _map from 'lodash/map';

import CAMPAIGNS from '../../services/types';
import useModal from '../../../../utils/useModal';
import { CAMPAIGN_EDIT_TRAY_MODAL } from '../../../../constants/modals';
import {
  prevStep,
  nextStep,
  saveProductsSelected,
  saveProductsWithQuantity,
  getCompanyProducts,
} from '../../services/actions';
import createLoadingSelector from '../../../../selectors/loadingSelector';
import { DEVICE_CAMPAIGN } from '../../../../constants/campaigns';

const loadingSelector = createLoadingSelector(CAMPAIGNS.READ_DETAILS.base);
const loadingProductsSelector = createLoadingSelector(CAMPAIGNS.GET_PRODUCTS.base);

const createCampaignSelector = (id) => createSelector(
  loadingSelector,
  loadingProductsSelector,
  (state) => state.campaigns.details[id],
  (state) => (state.campaigns.products ? state.campaigns.products.results : state.campaigns.products),
  (isLoading, isLoadingProducts, campaign, products) => ({
    isLoading,
    isLoadingProducts,
    campaign,
    products,
  }),
);

const useProductInfo = (id) => {
  const dispatch = useDispatch();
  const selector = createCampaignSelector(id);
  const data = useSelector(selector);

  const getProducts = useCallback(() => {
    dispatch(getCompanyProducts({ company: data.campaign && data.campaign.company.id }));
  }, [data.campaign, dispatch]);

  const goToBack = useCallback(() => {
    dispatch(prevStep());
  }, [dispatch]);

  const saveSelected = useCallback(({ products }) => {
    if (products && products.length > 0) {
      let productsInfo = [];
      const campaignType = data.campaign.type_is;
      products.map((product) => {
        if (campaignType === DEVICE_CAMPAIGN) {
          productsInfo = _map(products, 'id');
        } else {
          productsInfo.push({ product: product.id, product_stock: parseInt(product.product_stock) });
        }
      });
      dispatch(campaignType === DEVICE_CAMPAIGN ? saveProductsSelected(id, { products: productsInfo.join(',') }) : saveProductsWithQuantity(id, { products: productsInfo }));
    }
  }, [data.campaign.type_is, dispatch, id]);

  const goToNext = useCallback(() => {
    dispatch(nextStep());
  }, [dispatch]);
  const { showModal: showEditTray } = useModal(CAMPAIGN_EDIT_TRAY_MODAL);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return {
    data,
    actions: {
      goToBack,
      goToNext,
      showEditTray,
      saveSelected,
    },
  };
};

export default useProductInfo;
