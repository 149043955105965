import { createActionRequest } from '../../../../utils/actionHelpers';
import LAYOUT, { SHOW_NOTIFICATIONS, HIDE_NOTIFICATIONS } from './types';

export const showNotificationsAction = () => ({
  type: SHOW_NOTIFICATIONS,
});

export const hideNotificationsAction = () => ({
  type: HIDE_NOTIFICATIONS,
});

export const postIssue = createActionRequest(LAYOUT.POST_ISSUE, 'post', '/bo/issue/');
