import React from 'react';
import Table from '../../../../../components/Table';
import tr from '../../../../../utils/translate';

import { infoColumns } from './data';
import CopySocialLinkModal from '../../CopySocialLinkModal';
import ValidateDomainModal from '../../ValidateDomainModal';
import useInfoTable from './hooks';

const CampaignInfoTab = ({
  campaign, isPreview, social_networks, access_level,
}) => {
  const {
    actions: {
      downloadSCV,
      handleCopyLinkCLick,
      openValidateDomainModal,
      handleEditCampaign,
    },
  } = useInfoTable(campaign, social_networks);

  const {
    name,
    date_start,
    date_end,
    status,
    preview,
    type_is,
    enable_qr,
    online_campaign_link,
    direct_mail,
  } = campaign;

  const campaignData = [{
    name,
    date_start,
    date_end,
    status,
    preview,
    enable_qr,
    online_campaign_link,
    type_is,
    ...direct_mail && {
      domain_verified: direct_mail ? direct_mail.domain_verified : null,
      from_email_address: direct_mail.from_email_address,
      email_list_count: direct_mail.email_list_count,
    },
  }];

  return (
    <>
      <div className="mg-b-30">
        <Table
          caption={tr('Main info')}
          columns={infoColumns(
            type_is,
            status,
            downloadSCV,
            handleCopyLinkCLick,
            openValidateDomainModal,
            access_level,
            isPreview,
            handleEditCampaign,
          )}
          data={campaignData}
        />
      </div>
      <CopySocialLinkModal />
      <ValidateDomainModal campaign_id={campaign.id} />
    </>
  );
};

export default CampaignInfoTab;
