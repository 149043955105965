import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import createLoadingSelector from '../../../../selectors/loadingSelector';
import { setDeviceInfo } from '../../services/actions';
import DEVICES from '../../services/types';

const useEditDevice = () => {
  const dispatch = useDispatch();
  const editDevice = useCallback(({ serial_number, name, location }) => {
    dispatch(setDeviceInfo(serial_number, { name, location }));
  }, [dispatch]);
  const isLoading = useSelector(createLoadingSelector(DEVICES.SET_DIVECE_INFO.base));

  return {
    data: {
      isLoading,
    },
    actions: {
      editDevice,
    },
  };
};

export default useEditDevice;
