import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Frame from '../Frame';
import SimpleButton from '../SimpleButton';
import './styles.scss';

const Widget = (props) => {
  const {
    classNameButtons,
    className,
    color,
    icon,
    title,
    subtitle,
    description,
    buttons,
  } = props;

  return (
    <Frame className={cn('widget-wrapper', className)}>
      <div className="widget pd-b-3">
        <div className="mg-r-15">
          <div className={cn('widget__logo', `widget__logo_${color}`)}>
            {icon}
          </div>
        </div>
        <div className="widget__info">
          <h3 className="widget__title">{title}</h3>
          <span className="widget__subtitle">{subtitle}</span>
          <div className="widget__description">{description}</div>
        </div>
      </div>
      {buttons && buttons.map(({ label, onClick, className: classNameBtn }, index) => (
        <div key={index} className={cn('mg-t-20', classNameButtons)}>
          <SimpleButton className={classNameBtn} onClick={onClick}>{label}</SimpleButton>
        </div>
      ))}
    </Frame>
  );
};

Widget.propTypes = {
  color: PropTypes.oneOf(['yellow', 'green', 'blue', 'red', 'cyan', 'violet', 'pink', 'dark_blue']).isRequired,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  description: PropTypes.node,
  className: PropTypes.string,
  classNameButtons: PropTypes.string,
};

Widget.defaultProps = {
  classNameButtons: null,
  className: null,
  subtitle: '-',
  description: '',
};

export default Widget;
