import React from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  Field,
  propTypes as propTypesReduxForm,
} from 'redux-form';

import ModalFormFooter from '../../../../../../components/ModalFormFooter';
import { CalendarIcon } from '../../../../../../components/Icons';
import DatePicker from '../../../../../../components/DatePicker';
import Loader from '../../../../../../components/Loader';
import Select from '../../../../../../components/Select';
import translate from '../../../../../../utils/translate';
import UsersGenderField from '../UsersGenderField';
import { useUsersFilter } from '../../hooks';
import { AGE_RANGE_OPTIONS } from '../../../../../../constants/common';
import validate from './validate';

export const genders = [
  { value: 'male', label: translate('Male') },
  { value: 'female', label: translate('Female') },
];

const ageFrom = [{ value: '', label: translate('Age from') }];
const ageTo = [{ value: '', label: translate('Age to') }];
for (let i = 10; i <= 90; i++) {
  ageFrom.push({ value: i, label: i });
  ageTo.push({ value: i, label: i });
}


const ageOptions = [
  {
    label: '',
    value: '',
  },
  ...AGE_RANGE_OPTIONS,
];

const UsersFilterForm = ({
  handleSubmit,
  location,
}) => {
  const {
    actions: { hideFilterModal, hanleFilterSubmit },
    data: {
      isLoadingCampaigns,
      isLoadingLocations,
      campaigns,
      locations,
    },
  } = useUsersFilter(location);

  if (isLoadingCampaigns || isLoadingLocations) return <Loader />;

  return (
    <form onSubmit={handleSubmit(hanleFilterSubmit)}>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-6 mg-b-15">
            <Select
              name="campaign"
              options={campaigns}
              fieldLabel={translate('Campaign')}
            />
          </div>

          <div className="col-md-6 mg-b-15">
            <Select
              name="location"
              options={locations}
              fieldLabel={translate('Location')}
            />
          </div>

          <div className="col-md-6 mg-b-15">
            <DatePicker
              name="date_from"
              fieldLabel={translate('Select date from')}
              append={<CalendarIcon />}
            />
          </div>

          <div className="col-md-6 mg-b-15">
            <DatePicker
              name="date_to"
              fieldLabel={translate('Select date to')}
              append={<CalendarIcon />}
            />
          </div>

          <div className="col-md-6">
            <Select
              name="age"
              options={ageOptions}
              fieldLabel={translate('Age range')}
            />
          </div>

          <div className="col-md-6">
            <Field
              name="gender"
              component={UsersGenderField}
              list={genders}
            />
          </div>

        </div>
      </div>
      <div className="modal-footer">
        <div className="row width-100">
          <div className="col-md-6" />
          <div className="col-md-6">
            <ModalFormFooter
              handleClose={hideFilterModal}
              confirmText={translate('Apply')}
              isLoading={false}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

UsersFilterForm.propTypes = {
  ...propTypesReduxForm,
  location: PropTypes.objectOf(PropTypes.string).isRequired,
};

const reduxFormConfig = {
  form: 'USERS_FILTER_FORM',
  validate,
};


export default reduxForm(reduxFormConfig)(UsersFilterForm);
