import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { destroy } from 'redux-form';
import Loader from '../../../../components/Loader';
import TargetingForm from './TargetingForm';
import BudgetForm from './BudgetForm';
import AdForm from './AdForm';
import useCampaignAdForm from './hooks';
import tr from '../../../../utils/translate';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { createAction } from 'redux-actions';
import CAMPAIGNS from '../../services/types';

const tabs = [
	{
		id: 'ad',
		name: tr('Ad'),
	},
	{
		id: 'targeting',
		name: tr('Targeting'),
	},
	{
		id: 'budget',
		name: tr('Budget'),
	},
];

const FacebookTabs = ({ data: { id } }) => {
	const dispatch = useDispatch();

	const [page, setPage] = useState(0);

	useEffect(() => {
		return () => {
			dispatch(destroy('CAMPAIGN_FACEBOOK_FORM'));

			dispatch(createAction(CAMPAIGNS.CLEAR_FACEBOOK_CAMPAIGN.base)());
		};
	}, []);

	const {
		data: {
			isLoadingData,
			isLoadingSubmit,
			campaign,
			ad_accounts,
			form,
			fb_pages,
			ad_campaigns,
			account_exists,
			audience_size,
			fb_targetings,
		},
		actions: {
			goToPrevStep,
			submitHandler,
		},
	} = useCampaignAdForm(id);

	const nextPage = () => {
		setPage((prevState) => prevState + 1);
	};

	const previousPage = () => {
		setPage((prevState) => prevState - 1);
	};

	const adCampaignId = campaign.ad_campaign?.id || form?.values?.account_exist;

	const ad_campaign = ad_campaigns[adCampaignId];

	if ((id && !campaign) || isLoadingData || (adCampaignId && !ad_campaign?.id)) return <Loader size="md" />;

	return (
		<div className="pd-t-50">

			<div
				className="facebook-tabs"
			>

				{tabs.map(({ id, name }) => (
					<div
						key={id}
						className={cn(
							'facebook-tab',
							{ 'active': id === tabs[page].id },
						)}
					>

						{name}

					</div>
				))}

			</div>

			{page === 0 && (
				<AdForm
					form={form}
					campaign={campaign}
					audience_size={audience_size}
					account_exists={account_exists}
					ad_campaign={ad_campaign}
					ad_accounts={ad_accounts}
					fb_pages={fb_pages}
					previousPage={goToPrevStep}
					onSubmit={nextPage}
				/>
			)}

			{page === 1 && (
				<TargetingForm
					form={form}
					audience_size={audience_size}
					fb_targetings={fb_targetings}
					fb_pages={fb_pages}
					previousPage={previousPage}
					onSubmit={nextPage}
				/>
			)}

			{page === 2 && (
				<BudgetForm
					form={form}
					audience_size={audience_size}
					fb_pages={fb_pages}
					isLoadingSubmit={isLoadingSubmit}
					previousPage={previousPage}
					onSubmit={submitHandler}
				/>
			)}

		</div>
	);
};

export default FacebookTabs;