import React from 'react';

import { InstagramIcon, LinkedinIcon } from '../../../../components/Icons';
import './styles.scss';

const Socials = () => (
  <ul className="list-inline socials">
    <li className="list-inline-item">
      <a
        href="https://www.odore.co.uk/"
        rel="noopener noreferrer"
        target="_blank"
        className="socials__layout-link mg-r-15"
      >
        odore.co.uk
      </a>
    </li>
    <li className="list-inline-item">
      <a
        href="https://www.linkedin.com/company/odore/"
        rel="noopener noreferrer"
        target="_blank"
        className="socials__social"
      >
        <LinkedinIcon />
      </a>
    </li>
    <li className="list-inline-item">
      <a
        href="https://www.instagram.com/odoreofficial/"
        rel="noopener noreferrer"
        target="_blank"
        className="socials__social"
      >
        <InstagramIcon />
      </a>
    </li>
  </ul>
);

export default Socials;
