import DASHBOARD from './types';
import { createActionRequest } from '../../../utils/actionHelpers';

export const getDashboard = createActionRequest(
  DASHBOARD.READ_DASHBOARD,
  'get',
  '/bo/dashboard/',
  {
    setDataPath: (params) => ({ params }),
  },
);
