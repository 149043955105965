import React from 'react';
import PropTypes from 'prop-types';

import translate from '../../../utils/translate';
import { AuthLogoIcon } from '../../../components/Icons';
import { Socials } from '../components';
import './style.scss';

const Layout = ({ children }) => (
  <div className="auth-layout">
    <div className="auth-layout__header">
      <div className="auth-layout-logo">
        <AuthLogoIcon />
      </div>
      <h2 className="auth-layout-title">{translate('Engage with consumers like never before')}</h2>
    </div>

    <div className="auth-layout__body">
      {children}
    </div>

    <div className="auth-layout__footer">
      <div className="auth-layout-footer-socials mg-b-7">
        <Socials />
      </div>

      <p className="auth-layout-text">
        {translate('By creating an account, you agree to the')}
        &nbsp;
        <a
          href="https://portal.odore.co.uk/static/OdoreLimited-Vendingmachine-TermsofUse.doc"
          download
          className="auth-layout-link"
        >
          {translate('Terms of Service')}
        </a>
      </p>
    </div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
