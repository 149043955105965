import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { bindActionCreators } from 'redux';

import { SettingsIcon } from '../../../../../components/Icons';
import Notification from '../Notification';
import { hideNotificationsAction } from '../../services/actions';
import './style.scss';

const notifications = [
  {
    id: 1,
    title: 'Report has been updated',
    text: 'Aenean vulputate eleifend tellus ligula, porttitor.',
    date: '05:00 PM Sun, 02 Feb 2019',
    type: 'stock',
  },
  {
    id: 2,
    title: 'Achievement Unlocked',
    text: 'Aenean vulputate eleifend tellus ligula, porttitor.',
    date: '05:00 PM Sun, 02 Feb 2019',
    type: 'stock',
  },
  {
    id: 3,
    title: 'Added new images',
    text: 'Aenean vulputate eleifend tellus ligula, porttitor.',
    date: '05:00 PM Sun, 02 Feb 2019',
    type: 'campaign',
  },
  {
    id: 4,
    title: 'Report has been updated',
    text: 'Aenean vulputate eleifend tellus ligula, porttitor.',
    date: '05:00 PM Sun, 02 Feb 2019',
    type: 'stock',
  },
  {
    id: 5,
    title: 'Achievement Unlocked',
    text: 'Aenean vulputate eleifend tellus ligula, porttitor.',
    date: '05:00 PM Sun, 02 Feb 2019',
    type: 'campaign',
  },
  {
    id: 6,
    title: 'Added new images',
    text: 'Aenean vulputate eleifend tellus ligula, porttitor.',
    date: '05:00 PM Sun, 02 Feb 2019',
    type: 'campaign',
  },
];

// @TODO fill with the real data
const Notifications = ({ isNotificationsShown, hideNotifications }) => (
  <div>
    <CSSTransition
      in={isNotificationsShown}
      timeout={300}
      classNames="notification-mask-wrap"
      unmountOnExit
    >
      <div className="notification-mask" onClick={hideNotifications} />
    </CSSTransition>

    <CSSTransition
      in={isNotificationsShown}
      timeout={300}
      classNames="notifications"
      unmountOnExit
    >
      <div className="notifications-wrap">
        <div className="notifications-header">
          <div className="notifications-header-link">
            <NavLink className="notifications-link" to="/">All notifications</NavLink>
          </div>
          <div>
            <button type="button" className="notifications-settings" onClick={() => {}}>
              <SettingsIcon />
            </button>
          </div>
        </div>

        <div className="notification-content">
          <div className="card-activities">
            <div className="media-list">
              {notifications.map(({
                id,
                title,
                text,
                date,
                type,
              }) => (
                <Notification key={id} title={title} text={text} date={date} type={type} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  </div>
);

Notifications.propTypes = {
  isNotificationsShown: PropTypes.bool.isRequired,
};

const mapStatetoProps = (state) => ({
  isNotificationsShown: state.appLayout.isNotificationsShown,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  hideNotifications: hideNotificationsAction,
}, dispatch);

export default connect(mapStatetoProps, mapDispatchToProps)(Notifications);
