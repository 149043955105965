import tr from '../../../../utils/translate';



const validate = (campaign) => (fields) => {
  const errors = {};

  if (!((campaign.file && campaign.file.file) || fields.file)) {
    errors.file = tr('Please upload interface template [required field]');
  }

  if (!((campaign?.direct_mail?.email_template) || fields.invitation_email)) {
    errors.invitation_email = tr('Please upload email template [required field]');
  }

  return errors;
};

export default validate;