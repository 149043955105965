import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import _filter from 'lodash/filter';
import tr from '../../../../utils/translate';
import Loader from '../../../../components/Loader';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import ReduxForm from '../../../../components/ReduxForm';
import { BackarrowIcon } from '../../../../components/Icons';
import useCampaignDetailsForm from './hooks';
import LocationField from '../LocationField';
import './styles.scss';

const required = (value) => (value ? undefined : 'Required');

const LocationForm = ({ data: { id } }) => {
  const {
    data: {
      isLoadingData,
      isLoadingSubmit,
      campaign,
    },
    actions: {
      goToPrevStep,
      submitHandler,
    },
  } = useCampaignDetailsForm(id);

  if ((id && !campaign) || isLoadingData) return <Loader size="md" />;
  return (
    <>
      <ReduxForm
        enableReinitialize
        form="CAMPAIGN_LOCATION_FORM"
        formData={{
          onSubmit: submitHandler,
          initialValue: {
            ...(campaign && { location: campaign.location && campaign.location.split(';') }),
          },
        }}
      >
        <div className="campaign-location-form pd-x-25 pd-y-50">
          <h3 className="cdf-title mg-b-10">{tr('Locations')}</h3>
          <div className="campaign-form-description pd-b-70">
            Please tell us where you would like to distribute your products. You can choose between countries and specific cities.
          </div>

          <Field
            name="location"
            className="locations-form-field"
            component={LocationField}
            data={campaign && campaign.location ? campaign.location.split(';') : []}
          />
          <Input type="hidden" name="location-validations" />
        </div>
        <div className="modal-footer">

          <Button color="light" className="mg-r-30" onClick={goToPrevStep}>
            <BackarrowIcon />
            {tr('Back')}
          </Button>
          <Button
            type="submit"
            disabled={isLoadingSubmit}
          >
            {tr('Next')}
          </Button>
        </div>
      </ReduxForm>
    </>

  );
};

LocationForm.defaultProps = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }),
};

LocationForm.defaultProps = {
  data: {},
};

export default LocationForm;
