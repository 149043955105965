import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import Frame from '../../../../components/Frame';
import ReduxForm from '../../../../components/ReduxForm';
import TableCampaign from '../TableCampaign';
import TableUsers from '../TableUsers';
import TableScreensaver from '../TableScreensaver';
import EditDeviceModal from '../EditDeviceModal';
import EditTrayModal from '../EditTrayModal';
import EditDeviceCampaignModal from '../EditDeviceCampaignModal';
import Trays from '../../../../components/Trays';
import DeviceEmailReceipt from '../DeviceEmailReceipt';
import DeviceLayout from '../DeviceLayout';
import RebootDeviceModal from '../RebootDeviceModal';
import MotorTestDeviceModal from '../MotorTestDeviceModal';
import InfoBadges from '../../widgets/InfoBadges';
import tr from '../../../../utils/translate';

const DeviceInfoTab = (props) => {
  const {
    device,
    actions,
    motorLogs,
    modalState,
    isLoadingReboot,
  } = props;

  const currentCampaignId = _get(device, ['campaign', 'id']);
  const maxProducts = _get(device, 'max_sempl_by_tray', {});

  const modalsBlock = (
    <>
      <EditDeviceModal data={device} />
      <EditTrayModal
        serial_number={device.serial_number}
        countItemsInTray={device.max_sempl_by_tray}
        company={device.company}
      />
      <EditDeviceCampaignModal
        serial_number={device.serial_number}
        currentCampaign={device.campaign}
        company={device.company}
        department={device.department}
      />
      <RebootDeviceModal
        handleRebootDevice={actions.handleRebootDevice}
        handleClose={actions.hideRebootModal}
        isLoading={isLoadingReboot}
        deviceNumber={device.serial_number}
      />
      <MotorTestDeviceModal
        handleTouchTrayDevice={actions.handleTouchTrayDevice}
        handleClose={actions.hideTraysModal}
        readTraysLog={actions.readTraysLog}
        motorLogs={motorLogs}
        modalState={modalState}
        deviceTrays={device.trays}
        deviceNumber={device.serial_number}
      />
    </>
  );

  return (
    <>
      <InfoBadges device={device} actions={actions} />
      <div className="row mg-b-30">
        <div className="col col-md-6">
          <Trays
            header={tr('Trays')}
            data={device.trays}
            maxProducts={maxProducts}
            onClickEdit={actions.showEditTray}
          />
        </div>
        <div className="col col-md-6">
          <div className="row mg-b-30 col-half">
            <div className="col col-md-12">
              <ReduxForm form="DEVICE_EMAIL_RECIPIENT" className="max-height">
                <Frame
                  header={tr('Email receipt')}
                  className="frame max-height"
                  classNameBody="align-bottom"
                  classNameHeader="frame__header"
                >
                  <DeviceEmailReceipt currentCampaignId={currentCampaignId} />
                </Frame>
              </ReduxForm>
            </div>
          </div>
          <div className="row col-half">
            <div className="col col-md-12">
              <ReduxForm form="DEVICE_LAYOUT" className="max-height">
                <Frame
                  header={tr('Layout')}
                  className="frame max-height"
                  classNameBody="align-bottom"
                  classNameHeader="frame__header"
                >
                  <DeviceLayout currentCampaignId={currentCampaignId} />
                </Frame>
              </ReduxForm>
            </div>
          </div>
        </div>
      </div>

      <div className="row mg-b-30">
        <div className="col col-md-12">
          <TableCampaign data={device.campaign} showModal={actions.showEditDeviceCampaign} />
        </div>
      </div>

      <div className="row mg-b-30">
        <div className="col col-md-12">
          <TableUsers data={device.users} />
        </div>
      </div>

      <div className="row mg-b-30">
        <div className="col col-md-12">
          <TableScreensaver serial_number={device.serial_number} data={device.screensavers} />
        </div>
      </div>
      {modalsBlock}

    </>
  );
};

DeviceInfoTab.propTypes = {
  device: PropTypes.shape(),
  actions: PropTypes.shape(),
  motorLogs: PropTypes.array,
  modalState: PropTypes.shape(),
  isLoadingReboot: PropTypes.bool,
};

DeviceInfoTab.defaultProps = {
  device: {},
  actions: {},
  motorLogs: [],
  isLoadingReboot: false,
};

export default DeviceInfoTab;
