import React from 'react';
import PageHeader from '../../../components/PageHeader';
import { ROLE_COMPANY_ADMIN, ROLE_SUPERADMIN } from '../../../constants/roles';
import Button from '../../../components/Button';
import translate from '../../../utils/translate';
import AccountBrand from './AccountBrand';
import AccountInfo from './AccountInfo';
import ResetPasswordModal from './ResetPasswordModal';
import AccountModal from './AccountModal';
import useAccount from './hooks';
import './styles.scss';
import BrandModal from './BrandModal';
import SocialAccounts from './SocialAccounts';

const Account = () => {
  const {
    actions: {
      showResetPasswordModal,
      showAccountModal,
      openBrandEditModal,
      addFacebook,
      removeFacebook,
      logout,
    },
    data: {
      accountDetails: {
        country,
        email,
        first_name,
        last_name,
        access_level,
        date_joined,
        company,
      },
      countries,
      facebookAccounts,
    },
    brand_info,
  } = useAccount();

  const headerButtons = (
    <div>
      <Button onClick={openBrandEditModal}>{translate('Edit brand info')}</Button>
    </div>
  );

  return (
    <div className="page-inner pd-t-25 pd-b-25 account-info">
      <PageHeader title={translate('Account')}>
        {access_level === ROLE_COMPANY_ADMIN && headerButtons}
      </PageHeader>

      <div className="row account-info-wrapper">
        <div className="col-md-4 brand-info">
          <AccountBrand
            handleEdit={showAccountModal}
            handleClickLogout={logout}
            companyName={brand_info && brand_info.name}
            name={`${first_name} ${last_name}`}
            access_level={access_level}
          />
        </div>

        <div className="col-md-12 pd-t-10 brand-users-info">
          <AccountInfo
            showResetPasswordModal={showResetPasswordModal}
            country={country}
            email={email}
            firstName={first_name}
            lastName={last_name}
            registrationDate={date_joined}
            countries={countries}
          />
        </div>

        <div className="col-md-12 pd-t-10">

          <SocialAccounts
            accounts={facebookAccounts}
            addAccount={addFacebook}
            removeAccount={removeFacebook}
            access_level={access_level}
          />

        </div>

      </div>

      <ResetPasswordModal />

      {[ROLE_SUPERADMIN, ROLE_COMPANY_ADMIN].includes(access_level) && <AccountModal />}

      <BrandModal />

    </div>
  );
};

export default Account;
