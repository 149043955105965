import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import useModal from '../../../../utils/useModal';
import translate from '../../../../utils/translate';
import { ADD_SOCIALS_MODAL, CHOOSE_SOCIALS_MODAL, VIEWS_SOCIALS_DETAILS_MODAL, SOCIAL_AD_ACCOUNTS_MODAL } from '../../../../constants/modals';
import { getSocialInfoById, getSocialAvailableAdAccountsById, adAccounts, deleteAdAccounts, deleteAdAccountFromStore } from '../../services/actions';

const selector = createSelector(
  (state) => state.account.socialsDetails,
  (state) => state.account.socialsAvailableAccounts,
  (socialsDetails, socialsAvailableAccounts) => ({
    socialsDetails,
    socialsAvailableAccounts,
  }),
);

const useSocialAccount = () => {
  const dispatch = useDispatch();
  const data = useSelector(selector);

  const {
    showModal: showAddSocialsModal,
    hideModal: hideAddSocialsModal,
  } = useModal(ADD_SOCIALS_MODAL, {
    title: translate('Add Social'),
  });

  const {
    showModal: showChooseSocialsModal,
    hideModal: hideChooseSocialsModal,
  } = useModal(CHOOSE_SOCIALS_MODAL, {
    title: translate('Choose Social'),
  });

  const {
    showModal: showViewSocialDetailsModal,
  } = useModal(VIEWS_SOCIALS_DETAILS_MODAL, {
    title: translate('View Social Details'),
  });

  const {
    showModal: showSocialAdAccountsModal,
  } = useModal(SOCIAL_AD_ACCOUNTS_MODAL, {
    title: translate('Social ad accounts'),
  });

  const onSocialInfoById = useCallback((id) => {
    dispatch(getSocialInfoById(id));
  }, [dispatch]);

  const onAvailableAdAccountById = useCallback((id) => {
    dispatch(getSocialAvailableAdAccountsById(id));
  }, [dispatch]);

  const onConnectSocialById = useCallback((params) => {
    dispatch(adAccounts(params));
  }, [dispatch]);
  
  const onDeleteAdAccounts = useCallback((id) => {
    dispatch(deleteAdAccounts(id));
  }, [dispatch]);
  
  const onDeleteAdAccountFromStore = useCallback((data) => {
    dispatch(deleteAdAccountFromStore(data));
  }, [dispatch]);

  return {
    actions: {
      showAddSocialsModal,
      hideAddSocialsModal,
      showChooseSocialsModal,
      hideChooseSocialsModal,
      showViewSocialDetailsModal,
      showSocialAdAccountsModal,
      onSocialInfoById,
      onAvailableAdAccountById,
      onConnectSocialById,
      onDeleteAdAccounts,
      onDeleteAdAccountFromStore,
    },
    data,
  };
};

export default useSocialAccount;
