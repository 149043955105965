import React from 'react';
import Select from '../../../../../../components/Select';

const FacebookPages = ({ fieldLabel, name, fb_pages }) => {
	const fbPagesOptions = [{ name: '', page_id: ''}, ...(fb_pages || [])].map((account) => ({
		label: account.name,
		value: account.page_id,
	}));

	return (
		<Select
			fieldLabel={fieldLabel}
			name={name}
			options={fbPagesOptions}
		/>
	);
};

export default FacebookPages;